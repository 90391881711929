
import React, { Component } from 'react'
import { connect } from 'react-redux'


import './SuccessDialog.scss';
import { Link } from 'react-router-dom';
import { FormControlLabel, Checkbox, Dialog } from '@material-ui/core';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { IAppState } from 'store';
import { ILocaleState, IDictionary } from 'store/locale/types';
import { Button } from 'components/Button';
import { IDialogState, ICloseDoneDialogAction } from 'store/dialog/types';
import { closeDoneDialog } from 'store/dialog/actions';


interface IProps {
    loc: IDictionary;
    locale: ILocaleState;
    dialog: IDialogState;
    closeDialog: () => Promise<ICloseDoneDialogAction>;
}
interface IState {
    // display: boolean;

}


class SuccessDialog extends Component<IProps> {
    state: IState = {
        // display: false
    };


    closeDialog = () => {
        // this.props.closeDialog();
        this.props.closeDialog();
    };


    render() {
        const { locale } = this.props;
        const { currentLocaleJSON: loc } = locale;

        return (
            <Dialog
                open={this.props.dialog.doneDialogDisplay}
                onClose={this.closeDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                scroll={'body'}
            >
                <div className="error-dialog-window">
                    <div className="swal2-icon swal2-success swal2-animate-success-icon" style={{display: 'flex'}}>
                        <div className="swal2-success-circular-line-left" style={{backgroundColor: 'rgb(255, 255, 255)'}}></div>
                        <span className="swal2-success-line-tip"></span>
                        <span className="swal2-success-line-long"></span>
                        <div className="swal2-success-ring"></div> 
                        <div className="swal2-success-fix" style={{backgroundColor: 'rgb(255, 255, 255)'}}></div>
                        <div className="swal2-success-circular-line-right" style={{backgroundColor: 'rgb(255, 255, 255)'}}></div>
                    </div>
  
                    <p>{loc['SUCCESS_Success Operation']}</p>
                    <Button col={2} color="primary" onClick={this.closeDialog} padding>
                        {loc.Button_OK}
                    </Button>
                </div>
            </Dialog>
        )
    };
}



const mapStateToProps = (store: IAppState) => ({
    locale: store.locale,
    loc: store.locale.currentLocaleJSON,
    dialog: store.dialog
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
    closeDialog: () => dispatch(closeDoneDialog())
});

export default connect(mapStateToProps, mapDispatchToProps)(SuccessDialog);
