import React, { Component } from 'react'
import { connect } from 'react-redux'
import { IAppState } from 'store';
import { PageHeader } from 'components/PageHeader/PageHeader';
import { ILocaleState } from 'store/locale/types';
import { FormPageTemplate } from 'components/FormPageTemplate';
import { FormBlock } from 'components/FormBlock';
import InputField from 'components/InputField/InputField';
import { IRouteCreateModel, ICreateRouteSuccessAction, ICreateRouteFailureAction, IGetRouteByIdSuccessAction, IGetRouteByIdFailureAction, IRouteState, IRouteEditModel, IEditRouteSuccessAction, IEditRouteFailureAction, RouteType, PointType, IRouteModel, IPoint as IPointSend, ITransport as ITransportSend } from 'store/route/types';

import moment from 'moment';
import Timepicker from 'components/Timepicker';
import { createRoute, getRouteById, editRoute} from 'store/route/actions';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { match } from 'react-router-dom';
import { Button } from 'components/Button';
import { FormControlLabel, Checkbox, CircularProgress, Icon } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { ICityModel } from 'store/city/types';
import { ICompanyNameModel, ICompanyModel } from 'store/company/types';
import { validateRequiredFields, emailIsValid, phoneIsValid, getErrorsFromDictionary } from 'services';
import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import WarningDialog from 'components/WarningDialog';
import { Section } from 'components/Section/Section';
import './RouteBlock.scss';

import equal from 'deep-equal';
import { IWithPageBase, withPageBase } from 'hoc/withPageBase/withPageBase';

interface IRouterParams {
    id: string;
}

interface IProps extends IWithPageBase{
    edit?: boolean;
    create?: boolean;
    view?: boolean;
    route: IRouteState;
    locale: ILocaleState;
    createRoute: (routeData: IRouteCreateModel) => Promise<ICreateRouteSuccessAction|ICreateRouteFailureAction>;
    editRoute: (routeData: IRouteEditModel) => Promise<IEditRouteSuccessAction|IEditRouteFailureAction>;
    // changeRouteStatus: (routeData: IChangeRouteStatusModel) => Promise<IChangeRouteStatusSuccessAction|IChangeRouteStatusFailureAction>;
    // editCurrentRoute: (RouteData: ICurrentRouteEditModel) => Promise<IEditCurrentRouteSuccessAction|IEditCurrentRouteFailureAction>;
    getRouteById: (id: string) => Promise<IGetRouteByIdSuccessAction|IGetRouteByIdFailureAction>;
    match: match<IRouterParams>;
    history: any;
}


interface ITransport {
    companyName: string;
    company: ICompanyNameModel;
    tonnage: number | null;
    uploadPercent: number | null;
}

interface IPoint {
    cityName: string;
    city: ICityModel;
    comment: string;
    coordinates: string;
    type: PointType;
}

interface a {
    [key: string] : any;
}

interface IState extends a {
    distance: number | null;
    downloadPoints: {[key:string]: IPoint};
    id: string;
    intermediatePoints: {[key:string]: IPoint};
    name: string;
    roads: {[key:string]: string};
    sap: string;
    time: number | null;
    transports: {[key:string]: ITransport};
    type: RouteType;
    uploadPoint: IPoint;
}

export class RouteBlock extends Component<IProps> {
    beforeUpdate = {}
    state: IState = {
        distance: null,
        downloadPoints: {
            downloadPoint_1:{
                cityName: '',
                city: {
                    name: '',
                    region: ''
                },
                comment: '',
                coordinates: '',
                type: 'DOWNLOAD'
            }
        },
        id: '',
        intermediatePoints: {
            intermediatePoint_1: {
                cityName: '',
                city: {
                    name: '',
                    region: ''
                },
                comment: '',
                coordinates: '',
                type: 'PARCKING'
            }
        },
        name: '',
        roads: {
            road_1: ''
        },
        sap: '',
        time: null,
        transports: {
            transport_1: {
                companyName: '',
                company: {
                    name: '',
                    id: ''
                },
                tonnage: null,
                uploadPercent: null
            }
        },
        type: 'TIME',
        uploadPoint: {
            cityName: '',
            city: {
                name: '',
                region: ''
            },
            comment: '',
            coordinates: '',
            type: 'UPLOAD'
        }
    }

    componentDidMount = () => {
        // console.log(this.props.route)
        // if(this.props.view && this.props.route.routeInfo){
        //     console.log(this.props.route.routeInfo)
        //     this.setState({...this.props.route.routeInfo});
        // }

        
        if(this.props.edit && this.props.match.params.id !== ''){
            console.log("EDIT GET BY ID")
            this.props.getRouteById(this.props.match.params.id)
                .then((data) => {
                    if(data.type === 'GET_ROUTE_BY_ID_SUCCESS'){
                        this.beforeUpdate = {...this.adaptToState(data.payload.record)};
                        this.setState({...this.adaptToState(data.payload.record)})
                    }
                    // const { authorities, city, company, ...rest } = this.props.route.currentRoute;
                    // this.setState({edited: {}, authorities: authorities[0], company, companyName: `${company && company.name? company.name: ''}`, city, cityName: city.name && city.region?`${city.name} (${city.region})`: '', ...rest});
                });
        }
    }

    addNewRoad = () => {
        this.setState((prevState: IState) => ({
            roads: [

            ]
        }))
    }
    
    static getDerivedStateFromProps = (nextProps: IProps, prevState: IState) => {
        // console.log('cwrp', nextProps)
        
        
            let newState: any = null;

            // if(nextProps.view && nextProps.route.routeInfo && nextProps.route.routeProfileIsLoad && !prevState.viewProfileIsLoad) {

            //     const { authorities, city, company, ...rest } = nextProps.route.routeInfo;

            //     return ({edited: {}, authorities: authorities[0], company, companyName: `${company && company.name? company.name: ''}`, city, cityName: city.name && city.region?`${city.name} (${city.region})`: '', ...rest, viewProfileIsLoad: true});
            // }

            // if(prevState.cityName && prevState.cityName !== `${prevState.city.name} (${prevState.city.region})`){
            //     return ({city: {name: '', region: ''}})
            // }

            // if(prevState.companyName && prevState.company && prevState.company.name && prevState.companyName !== `${prevState.company.name}`){
            //     return ({company: {name: '', id: ''}})
            // }

            // console.log(newState)
            return null;
        
    }

    getTitle = (): string => {

        if(this.props.edit) return this.props.route.currentRoute.name;//this.props.locale.currentLocaleJSON.RouteBlock_EditPageTitle;
        if(this.props.create) return this.props.locale.currentLocaleJSON.RouteBlock_CreatePageTitle;
        if(this.props.view) return this.props.locale.currentLocaleJSON.RouteBlock_ProfilePageTitle;

        return "";
    }

    // handleChangeField = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     console.log(event.target, event.target.name, event.target.value);
    //     let name = event.target.name;
    //     let type = event.target.type
    //     let value = type === 'number'? +event.target.value : event.target.value;

    //     // if(this.props.edit || this.props.view){
    //     //     // console.log(event.target.name, event.target.value);
    //     //     this.setState((prevState: IState) => {
    //     //         // console.log(event.target.name, event.target.value);
    //     //         return({
    //     //             [name as string]: value,
    //     //             edited: {
    //     //                 ...prevState.edited,
    //     //                 [name as string]: value
    //     //             }
    //     //         })}
    //     //     );
    //     // } else {
    //         this.setState((prevState: IState) => 
    //             ({
    //                 [name]: value
    //             })
    //         );
    //     // }

    // }

    handleEdit = () => {

    }

    handleBlock = () => {
        // if(this.state.edited){
        //     const newStatus: RouteStatusType = this.props.route.currentRoute.status === 'BLOCKED'? 'ACTIVE' : 'BLOCKED';

        //     this.props.changeRouteStatus({status: newStatus, id: this.props.match.params.id})
        //         .then((data) => {
        //             if(data.type === "CHANGE_USER_STATUS_FAILURE"){
        //                 let error = this.props.locale.currentLocaleJSON[`ERROR_${data.payload.error}`]? this.props.locale.currentLocaleJSON[`ERROR_${data.payload.error}`]: this.props.locale.currentLocaleJSON[`ERROR_UnknownError`];
        //                 this.setState({displayErrorDialog: true, errorMessages: [error]});
        //             } else {
        //                 this.props.getRouteById(this.props.match.params.id)
        //                     .then(() => {
        //                         const { status, ...rest } = this.props.route.currentRoute;
        //                         this.setState({edited: {}, authorities: authorities[0], company, companyName: `${company && company.name? company.name: ''}`, city, cityName: city.name && city.region?`${city.name} (${city.region})`: '', ...rest});
        //                     });
        //                 this.setState({status: newStatus});
        //                 this.props.history.push(`/dashboard/administration/routes`);
        //             }
                    
        //         });
        // }
    }


    handleCreate = () => {
        // if(this.props.create){
        //     let {edited, displayWarningDialog, displayErrorDialog, cityName, companyName, viewProfileIsLoad, authorities, ...createData} = this.state;
        //     this.props.createRoute({...createData, authorities: [authorities]})
        //         .then((data) => {
        //             if(data.type === "CREATE_USER_FAILURE"){
        //                 let error = this.props.locale.currentLocaleJSON[`ERROR_${data.payload.error}`]? this.props.locale.currentLocaleJSON[`ERROR_${data.payload.error}`]: this.props.locale.currentLocaleJSON[`ERROR_UnknownError`];
        //                 this.setState({displayErrorDialog: true, errorMessages: [error]});
        //             } else {
        //                 this.props.history.push(`/dashboard/administration/routes`);
        //             }
                    
        //         });
        // }
        
    }

    handleChangeDate = (name: string, value: string | moment.Moment) => {
        console.log('value', name,value);
        this.setState(
            {
                [name]: value.valueOf()
            } //as Pick<State, keyof State>
        );
    }

    renderMonth = ( props: any, month: any, year: any, selectedDate: any ) => {
        return <td {...props}>{ `month` }</td>;
    }

    selectCity = (resultCity: any) => {
        console.log("res: ",resultCity)
        this.setState({cityName: `${resultCity.name} (${resultCity.region})`});
    }

    hanldeSwitchEmail = (event: React.ChangeEvent<HTMLInputElement>) => {

    }

    hanldeSwitchPhone = (event: React.ChangeEvent<HTMLInputElement>) => {

    }


    handleChangeCompany = (resultObject: ICompanyModel) => {
        console.log('resultObject',resultObject)
        this.setState((prevState: IState) => ({
            company: {
                name: resultObject.shortName,
                id: resultObject.id
                // ...resultObject
            },
            companyName: resultObject.shortName
        }))
    }

    handleChangeCity = (resultObject: ICityModel) => {
        // console.log(resultObject)
        this.setState((prevState: IState) => ({
            city: {
                name: resultObject.name,
                region: resultObject.region
                // ...resultObject
            },
            cityName: `${resultObject.name} (${resultObject.region})`
        }))
    }

    handleCloseDialog = () => {
        this.setState({displayErrorDialog: false});
    }

    handleCloseWarningDialog = () => {
        this.setState({displayWarningDialog: false});
    }

    handleCancel = () => {
        this.props.handleCancelEdit(this.editIsValid());
    }



    //change methods

    adaptToState = (inputData: IRouteModel) => {

        let { downloadPoints, intermediatePoints, transports, roads, uploadPoint, ...restData } = inputData;

        let dps: {[key: string]: any} = {};
        downloadPoints.forEach((el, num) => {
            dps[`downloadPoint_${num}`] = {
                ...el,
                cityName: `${el.city.name}(${el.city.region})`
            };
        });
        let ips: {[key: string]: any} = {};
        intermediatePoints.forEach((el, num) => {
            ips[`intermediatePoint_${num}`] = {
                ...el,
                cityName: `${el.city.name}(${el.city.region})`
            };
        });
        let ts: {[key: string]: any} = {};
        transports.forEach((el, num) => {
            ts[`transport_${num}`] = {
                ...el,
                companyName: `${el.company.name}`
            };
        });
        let rs: {[key: string]: any} = {};
        roads.forEach((el, num) => {
            rs[`road_${num}`] = el;
        });

        let up = {
            ...uploadPoint,
            cityName: `${uploadPoint.city.name}(${uploadPoint.city.region})`
        }

        return {
            ...restData,
            downloadPoints: dps,
            intermediatePoints: ips,
            transports: ts,
            roads: rs,
            uploadPoint: up
        }
    }

    adaptToSend = (inputData: IState):IRouteCreateModel => {
        let { downloadPoints, intermediatePoints, transports, roads, uploadPoint, ...restData } = inputData;
        
        // let dps:IPointSend[] = [];
        let dps:IPointSend[] = Object.keys(downloadPoints).map((el, num) => {
            const { cityName , ...rest } = downloadPoints[el];
            return ({
                ...rest
            });
        });

        // let ips:IPointSend[] = [];
        let ips:IPointSend[] = Object.keys(intermediatePoints).map((el, num) => {
            const { cityName , ...rest } = intermediatePoints[el];
            return ({
                ...rest
            });
        });
        // let ts:ITransportSend[] = [];
        let ts:ITransportSend[] = Object.keys(transports).map((el, num) => {
            const { companyName , ...rest } = transports[el];
            return ({
                ...rest
            });
        });
        // let rs:string[] = [];
        let rs:string[] = Object.keys(roads).map((el, num) => {
            return (roads[el]);
        });

        let up = {
            ...uploadPoint,
            cityName: `${uploadPoint.city.name}(${uploadPoint.city.region})`
        }

        return {
            ...restData,
            downloadPoints: dps,
            intermediatePoints: ips,
            transports: ts,
            roads: rs,
            uploadPoint: up
        }

    }

    changeCheckbox = (fieldName: string, value: PointType) =>  {
        this.setState((prevState: IState) => ({
            intermediatePoints: {
                ...prevState.intermediatePoints,
                [fieldName]: {
                    ...prevState.intermediatePoints[fieldName],
                    type: value
                }
            }
        }))
    }

    changeFieldInArray(event: React.ChangeEvent<HTMLInputElement>, sectionName: string, arrayName: string){
        let fieldName: string = event.target.name;
        let value: string | number = event.target.value;
        this.setState((prevState: IState) => ({
            [sectionName]: {
                ...prevState[sectionName],
                [arrayName]: {
                    ...prevState[sectionName][arrayName],
                    [fieldName]: value
                }
            }
        }));
    }

    // changeFieldInTransport(event: React.ChangeEvent<HTMLInputElement>, sectionName: string, arrayName: string){
    //     let fieldName: string = event.target.name;
    //     let value: string | number = event.target.value;
    //     this.setState((prevState: IState) => ({
    //         [sectionName]: {
    //             ...prevState[sectionName],
    //             [arrayName]: {
    //                 ...prevState[sectionName][arrayName],
    //                 [fieldName]: value
    //             }
    //         }
    //     }));
    // }

    changeFieldInUpload(event: React.ChangeEvent<HTMLInputElement>){
        let fieldName: string = event.target.name;
        let value: string | number = event.target.value;
        this.setState((prevState: IState) => ({
            uploadPoint: {
                ...prevState.uploadPoint,
                [fieldName]: value
                
            }
        }));
    }

    changeFieldInRoad(event: React.ChangeEvent<HTMLInputElement>){
        let fieldName: string = event.target.name;
        let value: string | number = event.target.value;
        this.setState((prevState: IState) => ({
            roads: {
                ...prevState.roads,
                [fieldName]: value
                
            }
        }));
    }

    changeField(event: React.ChangeEvent<HTMLInputElement>){
        let fieldName: string = event.target.name;
        let value: string | number = event.target.value;
        this.setState((prevState: IState) => ({
                [fieldName]: value
        }));
    }

    changeCityInArray(row: ICityModel, sectionName: string, arrayName: string){
        // console.log(sectionName, arrayName)
        
        this.setState((prevState: IState) => ({
            [sectionName]: {
                ...prevState[sectionName],
                [arrayName]: {
                    ...prevState[sectionName][arrayName],
                    cityName: `${row.name} (${row.region})`,
                    city: {
                        name: row.name,
                        region: row.region
                    }
                }
            }
        }));
    }

    changeCompanyInArray(row: ICompanyModel, sectionName: string, arrayName: string){
        // console.log(sectionName, arrayName)
        
        this.setState((prevState: IState) => ({
            [sectionName]: {
                ...prevState[sectionName],
                [arrayName]: {
                    ...prevState[sectionName][arrayName],
                    companyName: `${row.shortName}`,
                    company: {
                        name: row.shortName,
                        id: row.id
                    }
                }
            }
        }));
    }

    changeCityInUpload(row: ICityModel){
        // console.log(sectionName, arrayName)
        
        this.setState((prevState: IState) => ({
            uploadPoint: {
                ...prevState.uploadPoint,
                cityName: `${row.name} (${row.region})`,
                city: {
                    name: row.name,
                    region: row.region
                }
            }
        }));
    }
    
    addNewIntermediatePointInArray = () => {
        
        this.setState((prevState: IState) => ({
            intermediatePoints: {
                ...prevState.intermediatePoints,
                [`intermediatePoint_${Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)}`]: {
                    city: {
                        name: '',
                        region: ''
                    },
                    cityName: '',
                    comment: '',
                    coordinates: '',
                    type: 'PARCKING'
                }
            }
        }));
    }

    deleteNewIntermediatePointInArray = (id: string) => {
        

        this.setState((prevState: IState) => {
            let { [id]: _, ...rest } = prevState.intermediatePoints;

            return {
                intermediatePoints: {
                    ...rest
                }
            }
        });
    }

    addNewDownloadPointInArray = () => {
        
        this.setState((prevState: IState) => ({
            downloadPoints: {
                ...prevState.downloadPoints,
                [`intermediatePoint_${Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)}`]: {
                    city: {
                        name: '',
                        region: ''
                    },
                    cityName: '',
                    comment: '',
                    coordinates: '',
                    type: 'DOWNLOAD'
                }
            }
        }));
    }

    deleteNewDownloadPointInArray = (id: string) => {
        

        this.setState((prevState: IState) => {
            let { [id]: _, ...rest } = prevState.downloadPoints;

            return {
                downloadPoints: {
                    ...rest
                }
            }
        });
    }

    addNewTransportInArray = () => {
        
        this.setState((prevState: IState) => ({
            transports: {
                ...prevState.transports,
                [`transport_${Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)}`]: {
                    company: {
                        name: '',
                        id: ''
                    },
                    companyName: '',
                    tonnage: null,
                    uploadPercent: null
                }
            }
        }));
    }

    deleteTransportInArray = (id: string) => {
        

        this.setState((prevState: IState) => {
            let { [id]: _, ...rest } = prevState.transports;
            console.log(id, prevState)
            return {
                transports: {
                    ...rest
                }
            }
        });
    }

    addRoadInArray = () => {
        
        this.setState((prevState: IState) => ({
            roads: {
                ...prevState.roads,
                [`road_${Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)}`]: ''
            }
        }));
    }

    deleteRoadInArray = (id: string) => {
        

        this.setState((prevState: IState) => {
            let { [id]: _, ...rest } = prevState.roads;
            console.log(id, prevState)
            return {
                roads: {
                    ...rest
                }
            }
        });
    }

    handleCreateRecord = () => {
        let data: IRouteCreateModel = this.adaptToSend(this.state);
        this.props.createRoute(data)
            .then(data => {
                if(data.type === 'CREATE_ROUTE_SUCCESS'){
                    this.props.openSuccessDialog();
                    this.props.history.push('/dashboard/routes')
                } else {
                    this.props.openErrorDialog(
                        {
                            messages: getErrorsFromDictionary(this.props.locale.currentLocaleJSON, [data.payload.error])  
                        }
                    );
                }
            })
    }

    handleEditRecord = () => {

        this.props.editRoute({...this.adaptToSend(this.state), id: this.props.match.params.id})
            .then(editData => {
                if(editData.type === 'EDIT_ROUTE_SUCCESS'){
                    this.props.openSuccessDialog();
                    this.props.history.push('/dashboard/routes')
                } else {
                    this.props.openErrorDialog(
                        {
                            messages: getErrorsFromDictionary(this.props.locale.currentLocaleJSON, [editData.payload.error])  
                        }
                    );
                }
            })
    }

    editIsValid = () => {
        return equal(this.state, this.beforeUpdate);
    }

    additionIsValid = () => {
        let { downloadPoints, intermediatePoints, transports, uploadPoint, sap, name, type, distance, time } = this.state;
        let isValid = true;
        // let newTime = time && +time;
        // console.log('distance.toString()', distance, typeof distance,distance && distance.toString(), distance && typeof distance.toString())
        if((type === 'DISTANCE' && distance === null) || (type === 'DISTANCE' && !distance)){
            // console.log('distance.toString()')
            isValid = false;
        }


        Object.keys(downloadPoints).forEach((el, num) => {
            const { cityName, city } = downloadPoints[el];
            if(cityName === '' || city.name === '' || city.region === ''){
                isValid = false;
            }
        });


        Object.keys(intermediatePoints).forEach((el, num) => {
            const { cityName, city } = intermediatePoints[el];
            if(cityName === '' || city.name === '' || city.region === ''){
                isValid = false;
            }
        });

        Object.keys(transports).forEach((el, num) => {
            const { companyName , company, tonnage, uploadPercent } = transports[el];
            if(companyName === '' || company.name === '' || company.id === '' || tonnage === null || uploadPercent === null || !tonnage || !uploadPercent ){
                isValid = false;
            }
        });

        if(
            uploadPoint.cityName === '' 
            || uploadPoint.city.name === '' 
            || uploadPoint.city.region === ''
            || sap === ''
            || name === ''
        ){
            isValid = false;
        }

        if((type === 'TIME' && time === null) || (type === 'TIME' && !time)){
            isValid = false;
        } 
        
        return isValid;
    }

    render() {
        const { locale } = this.props;
        const { currentLocaleJSON: loc } = locale;

        return (
            <div className="page-template">
                <PageHeader pageIcon={"map"} pageTitle={this.getTitle()}/>
                {/* { */
                    // !this.props.route.isFetching?
                }
                <Section title={`1. ${loc.RouteBlock_Step1Title}`}>
                    <div className="fields-section">
                        <InputField centerMode col={2} required={true} disabled={this.props.view} padding={true} placeholder={loc.RouteBlock_CityField}  label={loc.RouteBlock_CityField} type="city" onSelect={(row) => {this.changeCityInUpload(row)}} onChange={(e) => {this.changeFieldInUpload(e)}} value={this.state.uploadPoint.cityName} name='cityName'/>
                        <InputField centerMode col={2} disabled={this.props.view} padding={true} placeholder={loc.RouteBlock_GPSField}  label={loc.RouteBlock_GPSField} type="text" onChange={(e) => {this.changeFieldInUpload(e)}} value={this.state.uploadPoint.coordinates} name='coordinates'/>
                        <InputField centerMode col={8} disabled={this.props.view} padding={true} placeholder={loc.RouteBlock_CommentField}  label={loc.RouteBlock_CommentField} type="text" onChange={(e) => {this.changeFieldInUpload(e)}} value={this.state.uploadPoint.comment} name='comment'/>
                    </div>
                </Section>
                <Section title={`2. ${loc.RouteBlock_Step2Title}`}>
                    {
                        Object.keys(this.state.intermediatePoints).map((ip, ipNum) => {
                            let ipData = this.state.intermediatePoints[ip];
                            return (
                                <div className="array-section">
                                    <div className="i-point-checkbox">
                                        <p>{`${loc.RouteBlock_Point} №${ipNum + 1}`} &nbsp;&nbsp;—&nbsp;&nbsp;&nbsp; </p>
                                        <div onClick={() => {this.changeCheckbox(ip, 'PARCKING')}} className={`point-checkbox${ipData.type === 'PARCKING'? ' active': ''}`}>
                                            <span></span>
                                            <p>{loc.RouteBlock_DayStay}</p>
                                        </div>
                                        <div onClick={() => {this.changeCheckbox(ip, 'NIGHT')}} className={`point-checkbox${ipData.type === 'NIGHT'? ' active': ''}`}>
                                            <span></span>
                                            <p>{loc.RouteBlock_NightStay}</p>
                                        </div>
                                        <div onClick={Object.keys(this.state.intermediatePoints).length <= 1? () => {} : () => {this.deleteNewIntermediatePointInArray(ip)} } className={`delete-row-btn${Object.keys(this.state.intermediatePoints).length <= 1? ' disable' : ''}`}>
                                            <Icon>close</Icon>
                                        </div>
                                    </div>
                                    <div className="fields-section">
                                        <InputField centerMode col={2} required={true} disabled={this.props.view} padding={true} placeholder={loc.RouteBlock_CityField}  label={loc.RouteBlock_CityField} type="city" onSelect={(row) => {this.changeCityInArray(row, 'intermediatePoints', ip)}} onChange={(e) => {this.changeFieldInArray(e, 'intermediatePoints', ip)}} value={this.state.intermediatePoints[ip].cityName} name='cityName'/>
                                        <InputField centerMode col={2} disabled={this.props.view} padding={true} placeholder={loc.RouteBlock_GPSField}  label={loc.RouteBlock_GPSField} type="text" onChange={(e) => {this.changeFieldInArray(e, 'intermediatePoints', ip)}} value={this.state.intermediatePoints[ip].coordinates} name='coordinates'/>
                                        <InputField centerMode col={8} disabled={this.props.view} padding={true} placeholder={loc.RouteBlock_CommentField}  label={loc.RouteBlock_CommentField} type="text" onChange={(e) => {this.changeFieldInArray(e, 'intermediatePoints', ip)}} value={this.state.intermediatePoints[ip].comment} name='comment'/>
                                    </div>
                                </div>
                            )
                        })
                    }
                    
                    
                    {/* <div> */}
                        <div className="add-block-line">
                            <div onClick={this.addNewIntermediatePointInArray}>
                                <Icon>add</Icon>
                            </div>
                            <p>{loc.RouteBlock_AddPoint}</p>
                        </div>
                    {/* </div> */}
                </Section>
                <Section title={`3. ${loc.RouteBlock_Step3Title}`}>
                    {
                        Object.keys(this.state.downloadPoints).map((ip, ipNum) => {
                            // let ipData = this.state.downloadPoints[ip];
                            return (
                                <div className="array-section">
                                    <div className="i-point-checkbox">
                                        <p>{`${loc.RouteBlock_Point} №${ipNum + 1}`}</p>

                                        <div onClick={Object.keys(this.state.downloadPoints).length <= 1? () => {} : () => {this.deleteNewDownloadPointInArray(ip)} } className={`delete-row-btn${Object.keys(this.state.downloadPoints).length <= 1? ' disable' : ''}`}>
                                            <Icon>close</Icon>
                                        </div>
                                    </div>
                                    <div className="fields-section">
                                        <InputField centerMode col={2} required={true} disabled={this.props.view} padding={true} placeholder={loc.RouteBlock_CityField}  label={loc.RouteBlock_CityField} type="city" onSelect={(row) => {this.changeCityInArray(row, 'downloadPoints', ip)}} onChange={(e) => {this.changeFieldInArray(e, 'downloadPoints', ip)}} value={this.state.downloadPoints[ip].cityName} name='cityName'/>
                                        <InputField centerMode col={2} disabled={this.props.view} padding={true} placeholder={loc.RouteBlock_GPSField}  label={loc.RouteBlock_GPSField} type="text" onChange={(e) => {this.changeFieldInArray(e, 'downloadPoints', ip)}} value={this.state.downloadPoints[ip].coordinates} name='coordinates'/>
                                        <InputField centerMode col={8} disabled={this.props.view} padding={true} placeholder={loc.RouteBlock_CommentField}  label={loc.RouteBlock_CommentField} type="text" onChange={(e) => {this.changeFieldInArray(e, 'downloadPoints', ip)}} value={this.state.downloadPoints[ip].comment} name='comment'/>
                                    </div>
                                </div>
                            )
                        })
                    }
                    
                    
                    {/* <div> */}
                        <div className="add-block-line">
                            <div onClick={this.addNewDownloadPointInArray}>
                                <Icon>add</Icon>
                            </div>
                            <p>{loc.RouteBlock_AddPoint}</p>
                        </div>
                    {/* </div> */}
                </Section>
                <Section title={`4. ${loc.RouteBlock_Step4Title}`}>
                    <div className={'roads-block'}>
                        <p>{`${loc.RouteBlock_Routes}`}</p>
                        <div className="roads-fields">
                            {Object.keys(this.state.roads).map((ip, ipNum) => {
                                return (
                                    <div className="road-field">
                                        <InputField centerMode hideLabel disabled={this.props.view} padding={true} placeholder={loc.RouteBlock_RouteSAP}  label={loc.RouteBlock_RouteSAP} type="text" onChange={(e) => {this.changeFieldInRoad(e)}} value={this.state.roads[ip]} name={ip}/>
                                        <div onClick={Object.keys(this.state.roads).length <= 1? () => {} : () => {this.deleteRoadInArray(ip)} } className={`delete-row-btn${Object.keys(this.state.roads).length <= 1? ' disable' : ''}`}>
                                            <Icon>close</Icon>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    {/* <div> */}
                        <div className="add-block-line">
                            <div onClick={this.addRoadInArray}>
                                <Icon>add</Icon>
                            </div>
                            <p>{loc.RouteBlock_AddRoute}</p>
                        </div>
                    {/* </div> */}
                </Section>
                <Section title={`5. ${loc.RouteBlock_Step5Title}`}>
                    {
                        Object.keys(this.state.transports).map((ip, ipNum) => {
                            // let ipData = this.state.transports[ip];
                            return (
                                <div className="array-section">
                                    <div className={`fields-section${ipNum === 0? ' line': '' }`}>
                                        <InputField centerMode col={7} hideLabel={ipNum > 0} required={true} disabled={this.props.view} padding={true} placeholder={loc.RouteBlock_TC}  label={loc.RouteBlock_TC} autoSelect additionalFilters={{'type|full': 'TRANSPORT_COMPANY'}} type="company" onSelect={(row) => {this.changeCompanyInArray(row, 'transports', ip)}} onChange={(e) => {this.changeFieldInArray(e, 'transports', ip)}} value={this.state.transports[ip].companyName} name='companyName'/>
                                        <InputField centerMode col={2} hideLabel={ipNum > 0} required={true} disabled={this.props.view} padding={true} placeholder={loc.RouteBlock_Capacity} type="select" onChange={(e) => {this.changeFieldInArray(e, 'transports', ip)}} name="tonnage" value={this.state.transports[ip].tonnage} label={loc.RouteBlock_Capacity}
                                            options={[
                                                    {
                                                        value: 10,
                                                        label: '10',
                                                    },
                                                    {
                                                        value: 15,
                                                        label: '15',
                                                    },
                                                    {
                                                        value: 20,
                                                        label: '20',
                                                    },
                                                ]}/>
                                        <InputField centerMode col={2} hideLabel={ipNum > 0} required={true} disabled={this.props.view} padding={true} placeholder={loc.RouteBlock_Loading}  label={loc.RouteBlock_Loading} type="number" onChange={(e) => {this.changeFieldInArray(e, 'transports', ip)}} value={this.state.transports[ip].uploadPercent} name='uploadPercent'/>
                                        <div onClick={Object.keys(this.state.transports).length <= 1? () => {} : () => {this.deleteTransportInArray(ip)} } className={`delete-row-btn${Object.keys(this.state.transports).length <= 1? ' disable' : ''}`}>
                                            <Icon>close</Icon>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    
                    
                    {/* <div> */}
                        <div className="add-block-line">
                            <div onClick={this.addNewTransportInArray}>
                                <Icon>add</Icon>
                            </div>
                            <p>{loc.RouteBlock_AddTC}</p>
                        </div>
                    {/* </div> */}
                </Section>
                <Section title={`6. ${loc.RouteBlock_Step6Title}`}>
                    <div className="fields-section">
                        <InputField centerMode col={2.4} required={true} disabled={this.props.view} padding={true} placeholder={loc.RouteBlock_RouteSAP}  label={loc.RouteBlock_RouteSAP} type="text" onChange={(e) => {this.changeField(e)}} value={this.state.sap} name='sap'/>
                        <InputField centerMode col={2.4} required={true} disabled={this.props.view} padding={true} placeholder={loc.RouteBlock_RouteName}  label={loc.RouteBlock_RouteName} type="text" onChange={(e) => {this.changeField(e)}} value={this.state.name} name='name'/>
                        <InputField centerMode col={2.4} required={true} disabled={this.props.view} padding={true} placeholder={loc.RouteBlock_TariffType} type="select" onChange={(e) => {this.changeField(e)}} name="type" value={this.state.type} label={loc.RouteBlock_TariffType}
                                    options={[
                                            {
                                                value: 'DISTANCE',
                                                label: loc.RouteType_Distanse,
                                            },
                                            {
                                                value: 'TIME',
                                                label: loc.RouteType_Time,
                                            }
                                        ]}/>
                        <InputField centerMode col={2.4} required={this.state.type === "DISTANCE"} disabled={this.props.view} padding={true} placeholder={loc.RouteBlock_Distantion}  label={loc.RouteBlock_Distantion} type="number" onChange={(e) => {this.changeField(e)}} value={this.state.distance} name='distance'/>
                        <InputField centerMode col={2.4} required={this.state.type === "TIME"} disabled={this.props.view} padding={true} placeholder={loc.RouteBlock_TimeInRoad}  label={loc.RouteBlock_TimeInRoad} type="number" onChange={(e) => {this.changeField(e)}} value={this.state.time} name='time'/>
                        
                    </div>
                </Section>
                <div className="routes-btns-block">
                    <Button onClick={this.handleCancel} padding col={3}>
                        {loc.Button_Cancel}
                    </Button>
                    {
                        this.props.edit?
                            <Button 
                            col={3}
                                disabled={
                                    this.editIsValid() || !this.additionIsValid() 
                                }
                                onClick={this.handleEditRecord} 
                                padding 
                                color="primary"
                            >
                                {loc.Button_Save}
                            </Button>
                        :
                            null
                    }
                    {
                        this.props.create?
                            <Button 
                                disabled={!this.additionIsValid() }
                                onClick={this.handleCreateRecord} 
                                padding 
                                col={3} 
                                color="primary"
                            >
                                {loc.Button_Add}
                            </Button>
                        :
                            null
                    }
                </div>
                {
                    //     :
                //      <div>Spinner</div>
                }
                {/* <div className="spinner">
                    <CircularProgress />
                </div> */}
                {/* <WarningDialog display={this.state.displayWarningDialog} okAction={() => {this.props.history.goBack()}} closeDialog={this.handleCloseWarningDialog}/> */}
                {/* <ErrorDialog errorMessages={this.state.errorMessages} display={this.state.displayErrorDialog} closeDialog={this.handleCloseDialog}/> */}

            </div>
        )
    }
}

const mapStateToProps = (state: IAppState) => ({
    locale: state.locale,
    route: state.route,
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
    createRoute: (routeData: IRouteCreateModel) => dispatch(createRoute(routeData)),
    editRoute: (routeData: IRouteEditModel) => dispatch(editRoute(routeData)),
    // editCurrentRoute: (routeData: ICurrentRouteEditModel) => dispatch(editCurrentRoute(routeData)),
    // changeRouteStatus: (statusData: IChangeRouteStatusModel) => dispatch(changeRouteStatus(statusData)),
    getRouteById: (id: string) => dispatch(getRouteById(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(withPageBase(RouteBlock));
