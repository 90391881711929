import Api from "api";
import { ISearchParams, IGetListServerResponse, IDefaultServerResponse } from "store/types";
import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { AxiosPromise, AxiosResponse } from "axios";
import { IGetFileSuccessAction, IGetFileFailureAction, IGetFilePendingAction, ISendFileSuccessAction, ISendFileFailureAction, ISendFilePendingAction, IFileSendModel, EntityType } from "./types";
// import { IGetCityListSuccessAction, IGetCityListFailureAction, ICityModel, IGetCityListPendingAction } from "./types";

export const getFile: ActionCreator<
  ThunkAction<
    Promise<IGetFileSuccessAction | IGetFileFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    File,                  // The type for the data within the last action
    string,                       // The type of the parameter for the nested function 
    IGetFileSuccessAction | IGetFileFailureAction            // The type of the last action to be dispatched
  >
> = (entity: EntityType) => {
    return async (dispatch: Dispatch) => {
        
        const getFilePendingAction: IGetFilePendingAction = {
            type: 'GET_FILE_PENDING',
        };
        dispatch(getFilePendingAction);

        try{
            const authDataResponse: AxiosResponse<IDefaultServerResponse> = await Api.get(`/file/${entity}`);
            const file: File = authDataResponse.data.data; 
            const getFileSuccessAction: IGetFileSuccessAction = {
                payload: {
                    file,
                    entity
                },
                type: 'GET_FILE_SUCCESS',
            };
            return dispatch(getFileSuccessAction);
        } catch (error) {

            const getFileFailureAction: IGetFileFailureAction = {
                type: 'GET_FILE_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(getFileFailureAction);
        }
    };
};



export const sendFile: ActionCreator<
  ThunkAction<
    Promise<ISendFileSuccessAction | ISendFileFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    IFileSendModel,                       // The type of the parameter for the nested function 
    ISendFileSuccessAction | ISendFileFailureAction            // The type of the last action to be dispatched
  >
> = (fileData: IFileSendModel) => {
    return async (dispatch: Dispatch) => {
        
        const sendFilePendingAction: ISendFilePendingAction = {
            type: 'SEND_FILE_PENDING',
        };
        dispatch(sendFilePendingAction);

        try{
            let formData = new FormData();
            formData.append("file", fileData.file);
            console.log('SEND FILE ACTION', fileData)
            // {file: fileData.file}
            const authDataResponse: AxiosResponse<IDefaultServerResponse> = await Api.post(`/file/${fileData.entity}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            // const file: File = authDataResponse.data.data; 
            const sendFileSuccessAction: ISendFileSuccessAction = {
                type: 'SEND_FILE_SUCCESS',
            };
            return dispatch(sendFileSuccessAction);
        } catch (error) {

            const sendFileFailureAction: ISendFileFailureAction = {
                type: 'SEND_FILE_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(sendFileFailureAction);
        }
    };
};