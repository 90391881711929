import { 
    REQUEST, 
    SUCCESS, 
    FAILURE 
} from '../types';
    
import {
    ICityState,
    CityActions,
} from "./types";

import { Reducer } from 'redux';

const initialUserState: ICityState = {
    isFetching: false,
    list: [],
    namePart: ""
}

export const cityReducer: Reducer<ICityState, CityActions> = (
    state = initialUserState,
    action,
  ) => {
    switch (action.type) {
      case 'GET_CITY_LIST_PENDING': {
        return {
          ...state,
          isFetching: true,
        };
      }
      case 'GET_CITY_LIST_SUCCESS': {
        // console.log(parseJwt(action.authData.data.token))
        return {
          ...state,
          list: [...action.list],
          isFetching: false,
        };
      }
      case 'GET_CITY_LIST_FAILURE': {
        return {
          ...state,
          isFetching: false
        };
      }
      default:
        neverReached(action); // when a new action is created, this helps us remember to handle it in the reducer
    }
    return state;
};
  
  // tslint:disable-next-line:no-empty
const neverReached = (never: never) => {};