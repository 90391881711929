import { 
    REQUEST, 
    SUCCESS, 
    FAILURE, 
    IErrorPayload
} from '../types';
    
import {
    IActState,
    ActActions,
} from "./types";

import { Reducer } from 'redux';

const initialActState: IActState = {
    isFetching: false,
    list: [],
    candidatesList: [],
    searchParams: {},
    currentAct: {},
    currentActId: '',
    required: [
        'city',
        'company',
        'value',
        'startDate',
        'documentNumber',
        'comeBack',
        'tonnage',
        'type',
        'withSecurity',
    ]
}

export const actReducer: Reducer<IActState, ActActions> = (
    state = initialActState,
    action,
  ) => {
    
    const defaultStateWithFetchingTrue = {
        ...state,
        isFetching: true,
    };

    const defaultStateWithFetchingFalse = {
        ...state,
        isFetching: false,
    };

    const defaultErrorState = (action: { payload: IErrorPayload }) => ({
        ...state,
        isFetching: false,
        errorInfo: {
            hasError: true,
            ...action.payload
        },
    });


    switch (action.type) {

        // GET FULL LIST
        case 'GET_ACT_LIST_PENDING': {
            return { ...defaultStateWithFetchingTrue };
        }
        case 'GET_ACT_LIST_SUCCESS': {
            // console.log(parseJwt(action.authData.data.token))
            return {
                ...state,
                list: [...action.payload.list],
                pageble: {...action.payload.pageble},
                isFetching: false,
            };
        }
        case 'GET_ACT_LIST_FAILURE': {
            return {
                ...state,
                isFetching: false,
                errorInfo: {
                    hasError: true,
                    ...action.payload
                },
            };
        }

        // GET BY ID
        case 'GET_ACT_BY_ID_PENDING': {
            return { ...defaultStateWithFetchingTrue };
        }
        case 'GET_ACT_BY_ID_SUCCESS': {
            // console.log(parseJwt(action.authData.data.token))
            return {
                ...state,
                currentAct: {...action.payload.record},
                currentActId: action.payload.id,
                isFetching: false,
            };
        }
        case 'GET_ACT_BY_ID_FAILURE': {
            return { ...defaultErrorState(action) };
        }

        // get candidates
        case 'GET_ACT_CANDIDATES_PENDING': {
            return { ...defaultStateWithFetchingTrue };
        }
        case 'GET_ACT_CANDIDATES_SUCCESS': {
            // console.log(parseJwt(action.authData.data.token))
            return {
                ...state,
                candidatesList: [...action.payload.list],
                isFetching: false,
            };
        }
        case 'GET_ACT_CANDIDATES_FAILURE': {
            return { ...defaultErrorState(action) };
        }

        // CREATE comment
        case 'GET_ADD_ACT_COMMENT_PENDING': {
            return { ...defaultStateWithFetchingTrue };
        }
        case 'GET_ADD_ACT_COMMENT_SUCCESS': {
            // console.log(parseJwt(action.authData.data.token))
            return {
                ...state,
                isFetching: false,
            };
        }
        case 'GET_ADD_ACT_COMMENT_FAILURE': {
            return { ...defaultErrorState(action) };
        }

        // CREATE 
        case 'CREATE_ACT_PENDING': {
            return { ...defaultStateWithFetchingTrue };
        }
        case 'CREATE_ACT_SUCCESS': {
            // console.log(parseJwt(action.authData.data.token))
            return { ...defaultStateWithFetchingFalse };
        }
        case 'CREATE_ACT_FAILURE': {
            return { ...defaultErrorState(action) };
        }


        // EDIT
        case 'EDIT_ACT_PENDING': {
            return { ...defaultStateWithFetchingTrue };
        }
        case 'EDIT_ACT_SUCCESS': {
            // console.log(parseJwt(action.authData.data.token))
            return { ...defaultStateWithFetchingFalse };
        }
        case 'EDIT_ACT_FAILURE': {
            return { ...defaultErrorState(action) };
        }


        // CHANGE STATUS
        case 'CHANGE_ACT_STATUS_BY_LOGIST_PENDING': {
            return { ...defaultStateWithFetchingTrue };
        }
        case 'CHANGE_ACT_STATUS_BY_LOGIST_SUCCESS': {
            // console.log(parseJwt(action.authData.data.token))
            return { ...defaultStateWithFetchingFalse };
        }
        case 'CHANGE_ACT_STATUS_BY_LOGIST_FAILURE': {
            return { ...defaultErrorState(action) };
        }

        // CHANGE STATUS
        case 'CHANGE_ACT_STATUS_BY_MANAGER_PENDING': {
            return { ...defaultStateWithFetchingTrue };
        }
        case 'CHANGE_ACT_STATUS_BY_MANAGER_SUCCESS': {
            // console.log(parseJwt(action.authData.data.token))
            return { ...defaultStateWithFetchingFalse };
        }
        case 'CHANGE_ACT_STATUS_BY_MANAGER_FAILURE': {
            return { ...defaultErrorState(action) };
        }


        default:
        neverReached(action); // when a new action is created, this helps us remember to handle it in the reducer
    }
    return state;
};
  
  // tslint:disable-next-line:no-empty
const neverReached = (never: never) => {};