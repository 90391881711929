import Api from "api";
import { ISearchParams, IGetListServerResponse, IDefaultServerResponse, IGetListPayload, IErrorServerResponse, IGetDataListServerResponse, IGetByIdServerResponse, IGetByIdPayload } from "store/types";
import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { 
    IGetWarehouseListPendingAction,
    IGetWarehouseListSuccessAction,
    IGetWarehouseListFailureAction,
    IGetWarehouseByIdPendingAction,
    IGetWarehouseByIdSuccessAction,
    IGetWarehouseByIdFailureAction,
    ICreateWarehousePendingAction,
    ICreateWarehouseSuccessAction,
    ICreateWarehouseFailureAction,
    IEditWarehousePendingAction,
    IEditWarehouseSuccessAction,
    IEditWarehouseFailureAction,
    IDeleteWarehousePendingAction,
    IDeleteWarehouseSuccessAction,
    IDeleteWarehouseFailureAction,
    IWarehouseModel,
    IWarehouseCreateModel,
    IWarehouseEditModel,
    IChangeWarehouseStatusFailureAction,
    IChangeWarehouseStatusSuccessAction,
    IChangeWarehouseStatusPendingAction,
    IWarehouseChangeStatusModel,
} from "./types";
import { formatSearchParams } from "services";
import { AxiosResponse, AxiosError } from "axios";

export const getWarehouseList: ActionCreator<
  ThunkAction<
    Promise<IGetWarehouseListSuccessAction | IGetWarehouseListFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    IWarehouseModel[],                  // The type for the data within the last action
    ISearchParams,                       // The type of the parameter for the nested function 
    IGetWarehouseListSuccessAction | IGetWarehouseListFailureAction            // The type of the last action to be dispatched
  >
> = ( searchParams: ISearchParams) => {
    return async (dispatch: Dispatch) => {
        
        const getWarehouseListPendingAction: IGetWarehouseListPendingAction = {
            type: 'GET_WAREHOUSE_LIST_PENDING',
        };
        dispatch(getWarehouseListPendingAction);

        try{
            const response: AxiosResponse<IGetListServerResponse<IWarehouseModel>> = await Api.get(`/warehouses?${formatSearchParams(searchParams)}`);
            const payload: IGetListPayload<IWarehouseModel> = {
                list: response.data.data.content,
                searchParams,
                pageble: {
                    currentPage: response.data.data.number,
                    totalPages: response.data.data.totalPages,
                    totalElements: response.data.data.totalElements
                }
            }
            const getWarehouseListSuccessAction: IGetWarehouseListSuccessAction = {
                payload,
                type: 'GET_WAREHOUSE_LIST_SUCCESS',
            };
            return dispatch(getWarehouseListSuccessAction);
        } catch (error) {
            const getWarehouseListFailureAction: IGetWarehouseListFailureAction = {
                type: 'GET_WAREHOUSE_LIST_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(getWarehouseListFailureAction);
        }
    };
};

export const getWarehouseById: ActionCreator<
  ThunkAction<
    Promise<IGetWarehouseByIdSuccessAction | IGetWarehouseByIdFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    IGetByIdPayload<IWarehouseModel> ,                  // The type for the data within the last action
    string,                       // The type of the parameter for the nested function 
    IGetWarehouseByIdSuccessAction | IGetWarehouseByIdFailureAction            // The type of the last action to be dispatched
  >
> = (id: string) => {
    return async (dispatch: Dispatch) => {
        
        const getWarehouseByIdPendingAction: IGetWarehouseByIdPendingAction = {
            type: 'GET_WAREHOUSE_BY_ID_PENDING',
        };
        dispatch(getWarehouseByIdPendingAction);
        try{
            const response: AxiosResponse<IGetByIdServerResponse<IWarehouseModel>> = await Api.get(`/warehouse/${id}`);
            const payload: IGetByIdPayload<IWarehouseModel> = {
                record: response.data.data,
                id
            } 
            const getWarehouseByIdSuccessAction: IGetWarehouseByIdSuccessAction = {
                payload,
                type: 'GET_WAREHOUSE_BY_ID_SUCCESS',
            };
            return dispatch(getWarehouseByIdSuccessAction);
        } catch (error) {
            const getWarehouseByIdFailureAction: IGetWarehouseByIdFailureAction = {
                type: 'GET_WAREHOUSE_BY_ID_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(getWarehouseByIdFailureAction);
        }
    };
};

export const changeWarehouseStatus: ActionCreator<
  ThunkAction<
    Promise<IChangeWarehouseStatusSuccessAction | IChangeWarehouseStatusFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    string,                       // The type of the parameter for the nested function 
    IChangeWarehouseStatusSuccessAction | IChangeWarehouseStatusFailureAction            // The type of the last action to be dispatched
  >
> = (changeStatusData: IWarehouseChangeStatusModel) => {
    return async (dispatch: Dispatch) => {
        
        const changeWarehouseStatusPendingAction: IChangeWarehouseStatusPendingAction = {
            type: 'CHANGE_WAREHOUSE_STATUS_PENDING',
        };
        dispatch(changeWarehouseStatusPendingAction);

        try{
            const authDataResponse: AxiosResponse<IDefaultServerResponse> = await Api.get(`/warehouse/status/${changeStatusData.id}/${changeStatusData.status}`);
            // const list: IWarehouseNameModel[] = authDataResponse.data.data; 
            const changeWarehouseStatusSuccessAction: IChangeWarehouseStatusSuccessAction = {
                type: 'CHANGE_WAREHOUSE_STATUS_SUCCESS',
            };
            return dispatch(changeWarehouseStatusSuccessAction);
        } catch (error) {

            const changeWarehouseStatusFailureAction: IChangeWarehouseStatusFailureAction = {
                type: 'CHANGE_WAREHOUSE_STATUS_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(changeWarehouseStatusFailureAction);
        }
    };
};


export const createWarehouse: ActionCreator<
  ThunkAction<
    Promise<ICreateWarehouseSuccessAction | ICreateWarehouseFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    IWarehouseCreateModel,                       // The type of the parameter for the nested function 
    ICreateWarehouseSuccessAction | ICreateWarehouseFailureAction            // The type of the last action to be dispatched
  >
> = ( createdData: IWarehouseCreateModel) => {
    return async (dispatch: Dispatch) => {
        
        const createWarehousePendingAction: ICreateWarehousePendingAction = {
            type: 'CREATE_WAREHOUSE_PENDING',
        };
        dispatch(createWarehousePendingAction);

        try{
            const response: AxiosResponse<IDefaultServerResponse> = await Api.post(`/warehouse`, { ...createdData });
            const createWarehouseSuccessAction: ICreateWarehouseSuccessAction = {
                type: 'CREATE_WAREHOUSE_SUCCESS',
            };
            return dispatch(createWarehouseSuccessAction);
        } catch (error) {
            const createWarehouseFailureAction: ICreateWarehouseFailureAction = {
                type: 'CREATE_WAREHOUSE_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(createWarehouseFailureAction);
        }
    };
};

export const editWarehouse: ActionCreator<
  ThunkAction<
    Promise<IEditWarehouseSuccessAction | IEditWarehouseFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    IWarehouseEditModel,                       // The type of the parameter for the nested function 
    IEditWarehouseSuccessAction | IEditWarehouseFailureAction            // The type of the last action to be dispatched
  >
> = ( editData: IWarehouseEditModel) => {
    return async (dispatch: Dispatch) => {
        
        const editWarehousePendingAction: IEditWarehousePendingAction = {
            type: 'EDIT_WAREHOUSE_PENDING',
        };
        dispatch(editWarehousePendingAction);

        try{
            const response: AxiosResponse<IDefaultServerResponse> = await Api.put(`/warehouse`, { ...editData });
            const editWarehouseSuccessAction: IEditWarehouseSuccessAction = {
                type: 'EDIT_WAREHOUSE_SUCCESS',
            };
            return dispatch(editWarehouseSuccessAction);
        } catch (error) {
            const editWarehouseFailureAction: IEditWarehouseFailureAction = {
                type: 'EDIT_WAREHOUSE_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(editWarehouseFailureAction);
        }
    };
};


export const deleteWarehouse: ActionCreator<
  ThunkAction<
    Promise<IDeleteWarehouseSuccessAction | IDeleteWarehouseFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    string,                       // The type of the parameter for the nested function 
    IDeleteWarehouseSuccessAction | IDeleteWarehouseFailureAction            // The type of the last action to be dispatched
  >
> = ( id: string) => {
    return async (dispatch: Dispatch) => {
        
        const deleteWarehousePendingAction: IDeleteWarehousePendingAction = {
            type: 'DELETE_WAREHOUSE_PENDING',
        };
        dispatch(deleteWarehousePendingAction);

        try{
            const response: AxiosResponse<IDefaultServerResponse> = await Api.delete(`/warehouse/${id}`);
            const deleteWarehouseSuccessAction: IDeleteWarehouseSuccessAction = {
                type: 'DELETE_WAREHOUSE_SUCCESS',
            };
            return dispatch(deleteWarehouseSuccessAction);
        } catch (error) {
            const deleteWarehouseFailureAction: IDeleteWarehouseFailureAction = {
                type: 'DELETE_WAREHOUSE_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(deleteWarehouseFailureAction);
        }
    };
};