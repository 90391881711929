import Api from "api";
import { ISearchParams, IGetListServerResponse, IDefaultServerResponse, IGetListPayload, IErrorServerResponse, IGetDataListServerResponse, IGetByIdServerResponse, IGetByIdPayload, IGetUnicListPayload, IGetUnicListServerResponse } from "store/types";
import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { 
    IGetActListPendingAction,
    IGetActListSuccessAction,
    IGetActListFailureAction,
    IGetActByIdPendingAction,
    IGetActByIdSuccessAction,
    IGetActByIdFailureAction,
    ICreateActPendingAction,
    ICreateActSuccessAction,
    ICreateActFailureAction,
    IEditActPendingAction,
    IEditActSuccessAction,
    IEditActFailureAction,
    IActModel,
    IActCreateModel,
    IActEditModel,
    IChangeActStatusByLogistSuccessAction,
    IChangeActStatusByLogistFailureAction,
    IChangeActStatusByLogistPendingAction,
    IChangeActStatusByManagerPendingAction,
    IChangeActStatusByManagerFailureAction,
    IChangeActStatusByManagerSuccessAction,
    IAddActCommentPendingAction,
    IAddActCommentSuccessAction,
    IAddActCommentFailureAction,
    IGetActCandidatesPendingAction,
    IGetActCandidatesSuccessAction,
    IGetActCandidatesFailureAction,
    IActChangeStatusModel,
    IActCreateCommentModel
} from "./types";
import { formatSearchParams } from "services";
import { AxiosResponse, AxiosError } from "axios";
import { IOrderModel } from "store/order/types";

export const getActList: ActionCreator<
  ThunkAction<
    Promise<IGetActListSuccessAction | IGetActListFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    IActModel[],                  // The type for the data within the last action
    ISearchParams,                       // The type of the parameter for the nested function 
    IGetActListSuccessAction | IGetActListFailureAction            // The type of the last action to be dispatched
  >
> = ( searchParams: ISearchParams) => {
    return async (dispatch: Dispatch) => {
        
        const getActListPendingAction: IGetActListPendingAction = {
            type: 'GET_ACT_LIST_PENDING',
        };
        dispatch(getActListPendingAction);

        try{
            const response: AxiosResponse<IGetListServerResponse<IActModel>> = await Api.get(`/acts?${formatSearchParams(searchParams)}`);
            const payload: IGetListPayload<IActModel> = {
                list: response.data.data.content,
                searchParams,
                pageble: {
                    currentPage: response.data.data.number,
                    totalPages: response.data.data.totalPages,
                    totalElements: response.data.data.totalElements
                }
            }
            const getActListSuccessAction: IGetActListSuccessAction = {
                payload,
                type: 'GET_ACT_LIST_SUCCESS',
            };
            return dispatch(getActListSuccessAction);
        } catch (error) {
            const getActListFailureAction: IGetActListFailureAction = {
                type: 'GET_ACT_LIST_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(getActListFailureAction);
        }
    };
};

export const getActById: ActionCreator<
  ThunkAction<
    Promise<IGetActByIdSuccessAction | IGetActByIdFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    IGetByIdPayload<IActModel> ,                  // The type for the data within the last action
    string,                       // The type of the parameter for the nested function 
    IGetActByIdSuccessAction | IGetActByIdFailureAction            // The type of the last action to be dispatched
  >
> = (id: string) => {
    return async (dispatch: Dispatch) => {
        
        const getActByIdPendingAction: IGetActByIdPendingAction = {
            type: 'GET_ACT_BY_ID_PENDING',
        };
        dispatch(getActByIdPendingAction);
        console.log('id',id)
        try{
            const response: AxiosResponse<IGetByIdServerResponse<IActModel>> = await Api.get(`/act/${id}`);
            const payload: IGetByIdPayload<IActModel> = {
                record: response.data.data,
                id
            } 
            const getActByIdSuccessAction: IGetActByIdSuccessAction = {
                payload,
                type: 'GET_ACT_BY_ID_SUCCESS',
            };
            return dispatch(getActByIdSuccessAction);
        } catch (error) {
            const getActByIdFailureAction: IGetActByIdFailureAction = {
                type: 'GET_ACT_BY_ID_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(getActByIdFailureAction);
        }
    };
};

export const getActCandidates: ActionCreator<
  ThunkAction<
    Promise<IGetActCandidatesSuccessAction | IGetActCandidatesFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    IGetByIdPayload<IOrderModel> ,                  // The type for the data within the last action
    ISearchParams,                       // The type of the parameter for the nested function 
    IGetActCandidatesSuccessAction | IGetActCandidatesFailureAction            // The type of the last action to be dispatched
  >
> = (searchParams: ISearchParams) => {
    return async (dispatch: Dispatch) => {
        
        const getActCandidatesPendingAction: IGetActCandidatesPendingAction = {
            type: 'GET_ACT_CANDIDATES_PENDING',
        };
        dispatch(getActCandidatesPendingAction);
        try{
            const response: AxiosResponse<IGetUnicListServerResponse<IOrderModel>> = await Api.get(`/act/candidates?${formatSearchParams(searchParams, true)}`);
            const payload: IGetUnicListPayload<IOrderModel> = {
                list: response.data.data,
                // searchParams,
                // pageble: {
                //     currentPage: response.data.data.number,
                //     totalPages: response.data.data.totalPages,
                //     totalElements: response.data.data.totalElements
                // }
                // list: response.data.data
            }
            const getActCandidatesSuccessAction: IGetActCandidatesSuccessAction = {
                payload,
                type: 'GET_ACT_CANDIDATES_SUCCESS',
            };
            return dispatch(getActCandidatesSuccessAction);
        } catch (error) {
            const getActCandidatesFailureAction: IGetActCandidatesFailureAction = {
                type: 'GET_ACT_CANDIDATES_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(getActCandidatesFailureAction);
        }
    };
};


export const addActComment: ActionCreator<
  ThunkAction<
    Promise<IAddActCommentSuccessAction | IAddActCommentFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    IActCreateCommentModel,                       // The type of the parameter for the nested function 
    IAddActCommentSuccessAction | IAddActCommentFailureAction            // The type of the last action to be dispatched
  >
> = ( createdData: IActCreateCommentModel) => {
    return async (dispatch: Dispatch) => {
        
        const AddActCommentPendingAction: IAddActCommentPendingAction = {
            type: 'GET_ADD_ACT_COMMENT_PENDING',
        };
        dispatch(AddActCommentPendingAction);

        try{
            const response: AxiosResponse<IDefaultServerResponse> = await Api.post(`/act/${createdData.id}/comment/${createdData.comment}`);
            const addActCommentSuccessAction: IAddActCommentSuccessAction = {
                type: 'GET_ADD_ACT_COMMENT_SUCCESS',
            };
            return dispatch(addActCommentSuccessAction);
        } catch (error) {
            const AddActCommentFailureAction: IAddActCommentFailureAction = {
                type: 'GET_ADD_ACT_COMMENT_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(AddActCommentFailureAction);
        }
    };
};

export const createAct: ActionCreator<
  ThunkAction<
    Promise<ICreateActSuccessAction | ICreateActFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    IActCreateModel,                       // The type of the parameter for the nested function 
    ICreateActSuccessAction | ICreateActFailureAction            // The type of the last action to be dispatched
  >
> = ( createdData: IActCreateModel) => {
    return async (dispatch: Dispatch) => {
        
        const createActPendingAction: ICreateActPendingAction = {
            type: 'CREATE_ACT_PENDING',
        };
        dispatch(createActPendingAction);

        try{
            const response: AxiosResponse<IDefaultServerResponse> = await Api.post(`/act`, { ...createdData });
            const createActSuccessAction: ICreateActSuccessAction = {
                type: 'CREATE_ACT_SUCCESS',
            };
            return dispatch(createActSuccessAction);
        } catch (error) {
            const createActFailureAction: ICreateActFailureAction = {
                type: 'CREATE_ACT_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(createActFailureAction);
        }
    };
};



export const editAct: ActionCreator<
  ThunkAction<
    Promise<IEditActSuccessAction | IEditActFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    IActEditModel,                       // The type of the parameter for the nested function 
    IEditActSuccessAction | IEditActFailureAction            // The type of the last action to be dispatched
  >
> = ( editData: IActEditModel) => {
    return async (dispatch: Dispatch) => {
        
        const editActPendingAction: IEditActPendingAction = {
            type: 'EDIT_ACT_PENDING',
        };
        dispatch(editActPendingAction);

        try{
            const response: AxiosResponse<IDefaultServerResponse> = await Api.put(`/act`, { ...editData });
            const editActSuccessAction: IEditActSuccessAction = {
                type: 'EDIT_ACT_SUCCESS',
            };
            return dispatch(editActSuccessAction);
        } catch (error) {
            const editActFailureAction: IEditActFailureAction = {
                type: 'EDIT_ACT_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(editActFailureAction);
        }
    };
};



export const changeActStatusByLogist: ActionCreator<
  ThunkAction<
    Promise<IChangeActStatusByLogistSuccessAction | IChangeActStatusByLogistFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    string,                       // The type of the parameter for the nested function 
    IChangeActStatusByLogistSuccessAction | IChangeActStatusByLogistFailureAction            // The type of the last action to be dispatched
  >
> = (changeStatusData: IActChangeStatusModel) => {
    return async (dispatch: Dispatch) => {
        
        const changeActStatusByLogistPendingAction: IChangeActStatusByLogistPendingAction = {
            type: 'CHANGE_ACT_STATUS_BY_LOGIST_PENDING',
        };
        dispatch(changeActStatusByLogistPendingAction);

        try{
            const authDataResponse: AxiosResponse<IDefaultServerResponse> = await Api.put(`/act/logist/${changeStatusData.id}/status/${changeStatusData.status}`);
            // const list: IActNameModel[] = authDataResponse.data.data; 
            const changeActStatusByLogistSuccessAction: IChangeActStatusByLogistSuccessAction = {
                type: 'CHANGE_ACT_STATUS_BY_LOGIST_SUCCESS',
            };
            return dispatch(changeActStatusByLogistSuccessAction);
        } catch (error) {

            const changeActStatusByLogistFailureAction: IChangeActStatusByLogistFailureAction = {
                type: 'CHANGE_ACT_STATUS_BY_LOGIST_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(changeActStatusByLogistFailureAction);
        }
    };
};


export const changeActStatusByManager: ActionCreator<
  ThunkAction<
    Promise<IChangeActStatusByManagerSuccessAction | IChangeActStatusByManagerFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    string,                       // The type of the parameter for the nested function 
    IChangeActStatusByManagerSuccessAction | IChangeActStatusByManagerFailureAction            // The type of the last action to be dispatched
  >
> = (changeStatusData: IActChangeStatusModel) => {
    return async (dispatch: Dispatch) => {
        
        const changeActStatusByManagerPendingAction: IChangeActStatusByManagerPendingAction = {
            type: 'CHANGE_ACT_STATUS_BY_MANAGER_PENDING',
        };
        dispatch(changeActStatusByManagerPendingAction);

        try{
            const authDataResponse: AxiosResponse<IDefaultServerResponse> = await Api.put(`/act/manager/${changeStatusData.id}/status/${changeStatusData.status}`);
            // const list: IActNameModel[] = authDataResponse.data.data; 
            const changeActStatusByManagerSuccessAction: IChangeActStatusByManagerSuccessAction = {
                type: 'CHANGE_ACT_STATUS_BY_MANAGER_SUCCESS',
            };
            return dispatch(changeActStatusByManagerSuccessAction);
        } catch (error) {

            const changeActStatusByManagerFailureAction: IChangeActStatusByManagerFailureAction = {
                type: 'CHANGE_ACT_STATUS_BY_MANAGER_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(changeActStatusByManagerFailureAction);
        }
    };
};