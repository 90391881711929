import Api from "api";
import { ISearchParams, IGetListServerResponse, IDefaultServerResponse, IGetListPayload, IErrorServerResponse, IGetDataListServerResponse, IGetByIdServerResponse, IGetByIdPayload } from "store/types";
import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { 
    IGetCompanyListPendingAction,
    IGetCompanyListSuccessAction,
    IGetCompanyListFailureAction,
    IGetCompanyByIdPendingAction,
    IGetCompanyByIdSuccessAction,
    IGetCompanyByIdFailureAction,
    ICreateCompanyPendingAction,
    ICreateCompanySuccessAction,
    ICreateCompanyFailureAction,
    IEditCompanyPendingAction,
    IEditCompanySuccessAction,
    IEditCompanyFailureAction,
    IGetCompanyNamesListPendingAction,
    IGetCompanyNamesListSuccessAction,
    IGetCompanyNamesListFailureAction,
    IDeleteCompanyPendingAction,
    IDeleteCompanySuccessAction,
    IDeleteCompanyFailureAction,
    ICompanyModel,
    ICompanyCreateModel,
    ICompanyEditModel,
    ICompanyNameModel,
    IGetCompanyListByCityPendingAction,
    IGetCompanyListByCityFailureAction,
    IGetCompanyListByCitySuccessAction,
    ICompanyByCityModel,
} from "./types";
import { formatSearchParams } from "services";
import { AxiosResponse, AxiosError } from "axios";

export const getCompanyList: ActionCreator<
  ThunkAction<
    Promise<IGetCompanyListSuccessAction | IGetCompanyListFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    ICompanyModel[],                  // The type for the data within the last action
    ISearchParams,                       // The type of the parameter for the nested function 
    IGetCompanyListSuccessAction | IGetCompanyListFailureAction            // The type of the last action to be dispatched
  >
> = ( searchParams: ISearchParams) => {
    return async (dispatch: Dispatch) => {
        
        const getCompanyListPendingAction: IGetCompanyListPendingAction = {
            type: 'GET_COMPANY_LIST_PENDING',
        };
        dispatch(getCompanyListPendingAction);

        try{
            const response: AxiosResponse<IGetListServerResponse<ICompanyModel>> = await Api.get(`/companies?${formatSearchParams(searchParams)}`);
            const payload: IGetListPayload<ICompanyModel> = {
                list: response.data.data.content,
                searchParams,
                pageble: {
                    currentPage: response.data.data.number,
                    totalPages: response.data.data.totalPages,
                    totalElements: response.data.data.totalElements
                }
            }
            const getCompanyListSuccessAction: IGetCompanyListSuccessAction = {
                payload,
                type: 'GET_COMPANY_LIST_SUCCESS',
            };
            return dispatch(getCompanyListSuccessAction);
        } catch (error) {
            const getCompanyListFailureAction: IGetCompanyListFailureAction = {
                type: 'GET_COMPANY_LIST_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(getCompanyListFailureAction);
        }
    };
};

export const getCompanyById: ActionCreator<
  ThunkAction<
    Promise<IGetCompanyByIdSuccessAction | IGetCompanyByIdFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    IGetByIdPayload<ICompanyModel> ,                  // The type for the data within the last action
    string,                       // The type of the parameter for the nested function 
    IGetCompanyByIdSuccessAction | IGetCompanyByIdFailureAction            // The type of the last action to be dispatched
  >
> = (id: string) => {
    return async (dispatch: Dispatch) => {
        
        const getCompanyByIdPendingAction: IGetCompanyByIdPendingAction = {
            type: 'GET_COMPANY_BY_ID_PENDING',
        };
        dispatch(getCompanyByIdPendingAction);
        // console.log('id',id)
        try{
            const response: AxiosResponse<IGetByIdServerResponse<ICompanyModel>> = await Api.get(`/company/${id}`);
            const payload: IGetByIdPayload<ICompanyModel> = {
                record: response.data.data,
                id
            } 
            const getCompanyByIdSuccessAction: IGetCompanyByIdSuccessAction = {
                payload,
                type: 'GET_COMPANY_BY_ID_SUCCESS',
            };
            return dispatch(getCompanyByIdSuccessAction);
        } catch (error) {
            const getCompanyByIdFailureAction: IGetCompanyByIdFailureAction = {
                type: 'GET_COMPANY_BY_ID_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(getCompanyByIdFailureAction);
        }
    };
};

export const getCompanyNamesList: ActionCreator<
  ThunkAction<
    Promise<IGetCompanyNamesListSuccessAction | IGetCompanyNamesListFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    ICompanyNameModel[],                  // The type for the data within the last action
    string,                       // The type of the parameter for the nested function 
    IGetCompanyNamesListSuccessAction | IGetCompanyNamesListFailureAction            // The type of the last action to be dispatched
  >
> = (namePart: string) => {
    return async (dispatch: Dispatch) => {
        
        const getCompanyNamesListPendingAction: IGetCompanyNamesListPendingAction = {
            type: 'GET_COMPANY_NAMES_LIST_PENDING',
        };
        dispatch(getCompanyNamesListPendingAction);

        try{
            const authDataResponse: AxiosResponse<IGetDataListServerResponse<ICompanyNameModel>> = await Api.get(`/company/names?namePart=${namePart}`);
            const list: ICompanyNameModel[] = authDataResponse.data.data; 
            const getCompanyNamesListSuccessAction: IGetCompanyNamesListSuccessAction = {
                list,
                type: 'GET_COMPANY_NAMES_LIST_SUCCESS',
            };
            return dispatch(getCompanyNamesListSuccessAction);
        } catch (error) {

            const getCompanyNamesListFailureAction: IGetCompanyNamesListFailureAction = {
                type: 'GET_COMPANY_NAMES_LIST_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(getCompanyNamesListFailureAction);
        }
    };
};


export const getCompanyListByCity: ActionCreator<
  ThunkAction<
    Promise<IGetCompanyListByCitySuccessAction | IGetCompanyListByCityFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    ICompanyNameModel[],                  // The type for the data within the last action
    ICompanyByCityModel,                       // The type of the parameter for the nested function 
    IGetCompanyListByCitySuccessAction | IGetCompanyListByCityFailureAction            // The type of the last action to be dispatched
  >
> = (data: ICompanyByCityModel) => {
    return async (dispatch: Dispatch) => {
        
        const getCompanyListByCityPendingAction: IGetCompanyListByCityPendingAction = {
            type: 'GET_COMPANY_LIST_BY_CITY_PENDING',
        };
        dispatch(getCompanyListByCityPendingAction);

        try{
            console.log('Try COMPANY BY CITY')
            const authDataResponse: AxiosResponse<IGetDataListServerResponse<ICompanyNameModel>> = await Api.get(`/warehouse/companies/city?namePart=${data.namePart}&city=${data.city}`);
            const list: ICompanyNameModel[] = authDataResponse.data.data; 
            const getCompanyListByCitySuccessAction: IGetCompanyListByCitySuccessAction = {
                list,
                type: 'GET_COMPANY_LIST_BY_CITY_SUCCESS',
            };
            return dispatch(getCompanyListByCitySuccessAction);
        } catch (error) {

            const getCompanyListByCityFailureAction: IGetCompanyListByCityFailureAction = {
                type: 'GET_COMPANY_LIST_BY_CITY_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(getCompanyListByCityFailureAction);
        }
    };
};


export const createCompany: ActionCreator<
  ThunkAction<
    Promise<ICreateCompanySuccessAction | ICreateCompanyFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    ICompanyCreateModel,                       // The type of the parameter for the nested function 
    ICreateCompanySuccessAction | ICreateCompanyFailureAction            // The type of the last action to be dispatched
  >
> = ( createdData: ICompanyCreateModel) => {
    return async (dispatch: Dispatch) => {
        
        const createCompanyPendingAction: ICreateCompanyPendingAction = {
            type: 'CREATE_COMPANY_PENDING',
        };
        dispatch(createCompanyPendingAction);

        try{
            const response: AxiosResponse<IDefaultServerResponse> = await Api.post(`/company`, { ...createdData });
            const createCompanySuccessAction: ICreateCompanySuccessAction = {
                type: 'CREATE_COMPANY_SUCCESS',
            };
            return dispatch(createCompanySuccessAction);
        } catch (error) {
            const createCompanyFailureAction: ICreateCompanyFailureAction = {
                type: 'CREATE_COMPANY_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(createCompanyFailureAction);
        }
    };
};

export const editCompany: ActionCreator<
  ThunkAction<
    Promise<IEditCompanySuccessAction | IEditCompanyFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    ICompanyEditModel,                       // The type of the parameter for the nested function 
    IEditCompanySuccessAction | IEditCompanyFailureAction            // The type of the last action to be dispatched
  >
> = ( editData: ICompanyEditModel) => {
    return async (dispatch: Dispatch) => {
        
        const editCompanyPendingAction: IEditCompanyPendingAction = {
            type: 'EDIT_COMPANY_PENDING',
        };
        dispatch(editCompanyPendingAction);

        try{
            const response: AxiosResponse<IDefaultServerResponse> = await Api.put(`/company`, { ...editData });
            const editCompanySuccessAction: IEditCompanySuccessAction = {
                type: 'EDIT_COMPANY_SUCCESS',
            };
            return dispatch(editCompanySuccessAction);
        } catch (error) {
            const editCompanyFailureAction: IEditCompanyFailureAction = {
                type: 'EDIT_COMPANY_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(editCompanyFailureAction);
        }
    };
};


export const deleteCompany: ActionCreator<
  ThunkAction<
    Promise<IDeleteCompanySuccessAction | IDeleteCompanyFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    string,                       // The type of the parameter for the nested function 
    IDeleteCompanySuccessAction | IDeleteCompanyFailureAction            // The type of the last action to be dispatched
  >
> = ( id: string) => {
    return async (dispatch: Dispatch) => {
        
        const deleteCompanyPendingAction: IDeleteCompanyPendingAction = {
            type: 'DELETE_COMPANY_PENDING',
        };
        dispatch(deleteCompanyPendingAction);

        try{
            const response: AxiosResponse<IDefaultServerResponse> = await Api.delete(`/company/${id}`);
            const deleteCompanySuccessAction: IDeleteCompanySuccessAction = {
                type: 'DELETE_COMPANY_SUCCESS',
            };
            return dispatch(deleteCompanySuccessAction);
        } catch (error) {
            const deleteCompanyFailureAction: IDeleteCompanyFailureAction = {
                type: 'DELETE_COMPANY_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(deleteCompanyFailureAction);
        }
    };
};