
import React, { Component } from 'react'
import { connect } from 'react-redux'


import './ErrorDialog.scss';
import { Link } from 'react-router-dom';
import { FormControlLabel, Checkbox, Dialog } from '@material-ui/core';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { IAppState } from 'store';
import { ILocaleState } from 'store/locale/types';
import { Button } from 'components/Button';
import { closeErrorDialog } from 'store/dialog/actions';
import { ICloseErrorDialogAction, IDialogState } from 'store/dialog/types';


interface IProps {
    // display: boolean;
    locale: ILocaleState;
    dialog: IDialogState;
    // closeDialog: () => void;
    // errorMessages?: string[];
    closeDialog: () => Promise<ICloseErrorDialogAction>;
}
interface IState {
    // display: boolean;

}


class ErrorDialog extends Component<IProps> {
    state: IState = {
        // display: false
    }


    closeDialog = () => {
        this.props.closeDialog();
    }


    render() {
        const { locale, dialog } = this.props;
        const { errorDialogMessages, errorDialogText } = dialog;
        const { currentLocaleJSON: loc } = locale;

        return (
            <Dialog
                open={this.props.dialog.errorDialogDisplay}
                onClose={this.closeDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                scroll={'body'}
            >
                <div className="error-dialog-window">
                    <span className="swal2-x-mark">
                        <span className="swal2-x-mark-line-left"></span>
                        <span className="swal2-x-mark-line-right"></span>
                    </span>
                    <p>{errorDialogText || loc['ERROR_Not Success Operation']}</p>
                    {
                        errorDialogMessages && errorDialogMessages.length?
                            errorDialogMessages.map((errorMsg, msgNum) => 
                                <p key={`${errorMsg}_${msgNum}`}>{`- ${errorMsg}`}</p>
                            )
                        :
                            null
                    }
                    <Button col={2} color="primary" onClick={this.closeDialog} padding>
                        {loc.Button_OK}
                    </Button>
                </div>
            </Dialog>
        )
    }
}



const mapStateToProps = (store: IAppState) => ({
    locale: store.locale,
    loc: store.locale.currentLocaleJSON,
    dialog: store.dialog
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
    closeDialog: () => dispatch(closeErrorDialog())
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorDialog)
