import { createStore, combineReducers, applyMiddleware, Store, Dispatch, AnyAction, MiddlewareAPI, Middleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import { IUserState } from "./user/types";
import { userReducer } from "./user/reducers";

import { ILocaleState } from "./locale/types";
import { localeReducer } from "./locale/reducers";

import { IStaffState } from "./staff/types";
import { staffReducer } from "./staff/reducers";

import { ICityState } from "./city/types";
import { cityReducer } from "./city/reducers";

import { INotificationState } from "./notification/types";
import { notificationReducer } from "./notification/reducers";

import { IOrderState } from "./order/types";
import { orderReducer } from "./order/reducers";

import { IActState } from "./act/types";
import { actReducer } from "./act/reducers";


import { ICompanyState } from "./company/types";
import { companyReducer } from "./company/reducers";

import { IWarehouseState } from "./warehouse/types";
import { warehouseReducer } from "./warehouse/reducers";

import { IVehicleState } from "./vehicle/types";
import { vehicleReducer } from "./vehicle/reducers";

import { ITariffState } from "./tariff/types";
import { tariffReducer } from "./tariff/reducers";

import { IFileState } from "./file/types";
import { fileReducer } from "./file/reducers";

import { IRouteState } from "./route/types";
import { routeReducer } from "./route/reducers";

import { IDialogState } from "./dialog/types";
import { dialogReducer } from "./dialog/reducers";
import { getUserStoredData, parseJwt, logoutUserFromSystem } from "services";
import { ACTIONS_WITHOUT_TOKEN } from "globalConstants";

const rootReducer = combineReducers({
  user: userReducer,
  locale: localeReducer,
  staff: staffReducer,
  city: cityReducer,
  notification: notificationReducer,
  company: companyReducer,
  warehouse: warehouseReducer,
  vehicle: vehicleReducer,
  tariff: tariffReducer,
  file: fileReducer,
  route: routeReducer,
  act: actReducer,
  order: orderReducer,
  dialog: dialogReducer
});

// export type AppState = ReturnType<typeof rootReducer>;
export interface IAppState {
  readonly user: IUserState;
  readonly locale: ILocaleState;
  readonly staff: IStaffState;
  readonly city: ICityState;
  readonly notification: INotificationState;
  readonly company: ICompanyState;
  readonly warehouse: IWarehouseState;
  readonly vehicle: IVehicleState;
  readonly tariff: ITariffState;
  readonly file: IFileState;
  readonly route: IRouteState;
  readonly order: IOrderState;
  readonly act: IActState;
  readonly dialog: IDialogState;
}

const checkTokenExpirationMiddleware: Middleware = (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
  const { token } = getUserStoredData();
  
  if(token){
    // console.log('with token',action)
    if (parseJwt(token).exp < Date.now() / 1000) {
      // next(action);
      // console.log('MIDDLEWARE FOR LOGIN IN 403 = ',parseJwt(token).exp, '<', Date.now() / 1000)
      console.log('Token expired');
      logoutUserFromSystem();
    } else {
      // console.log('no exp')
      return next(action);
    }
  } else {
    // console.log(action)
    if(ACTIONS_WITHOUT_TOKEN.indexOf(action.type) !== -1){
      return next(action);
    } else {
      console.log('Token is null and not have in SessionStorage');
      logoutUserFromSystem();
    }
    
  }
  
};

export default function configureStore(): Store<IAppState> {
  const middlewares = [thunkMiddleware, checkTokenExpirationMiddleware];
  const middleWareEnhancer = applyMiddleware(...middlewares);

  const store = createStore(
    rootReducer,
    composeWithDevTools(middleWareEnhancer)
  );

  return store;
}
