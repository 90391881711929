import { IErrorPayload } from "../types";

import { IFileState, FileActions } from "./types";

import { Reducer } from "redux";

const initialFileState: IFileState = {
  isFetching: false,
  file: null,
  entity: null
};

export const fileReducer: Reducer<IFileState, FileActions> = (
  state = initialFileState,
  action
) => {
  
  const defaultStateWithFetchingTrue = {
    ...state,
    isFetching: true,
  };

  const defaultStateWithFetchingFalse = {
      ...state,
      isFetching: false,
  };
  const defaultErrorState = (action: { payload: IErrorPayload }) => ({
    ...state,
    isFetching: false,
    errorInfo: {
        hasError: true,
        ...action.payload
    },
  });

  // const defaultErrorState = (action: { payload: IErrorPayload }) => ({
  //     ...state,
  //     isFetching: false,
  //     errorInfo: {
  //         hasError: true,
  //         ...action.payload
  //     },
  // });

  switch (action.type) {
    case "GET_FILE_PENDING": {
      return {
        ...defaultStateWithFetchingTrue
      };
    }
    case "GET_FILE_SUCCESS": {
      // console.log(parseJwt(action.authData.data.token))
      return {
        ...defaultStateWithFetchingFalse,
        file: action.payload.file,
        entity: action.payload.entity
      };
    }
    case "GET_FILE_FAILURE": {
      return { ...defaultErrorState(action) };
    }

    case "SEND_FILE_PENDING": {
      return { ...defaultStateWithFetchingTrue };
    }
    case "SEND_FILE_SUCCESS": {
      // console.log(parseJwt(action.authData.data.token))
      return { ...defaultStateWithFetchingFalse };
    }
    case "SEND_FILE_FAILURE": {
      return { ...defaultErrorState(action) };
    }
    default:
      neverReached(action); // when a new action is created, this helps us remember to handle it in the reducer
  }
  return state;
};

// tslint:disable-next-line:no-empty
const neverReached = (never: never) => {};
