import './App.scss';
import React, { Component } from 'react'
import { connect } from 'react-redux'

import GlobalRouter from './router/GlobalRouter';
import { ILocaleState, IInitLocalesSuccessAction, IInitLocalesFailureAction,  } from 'store/locale/types';
import { IAppState } from 'store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { initLocales } from 'store/locale/actions';
import config from 'locales/config.json';
import { getUserInfo, logoutUser } from 'store/user/actions';
import { IGetUserInfoSuccessAction, IGetUserInfoFailureAction, IUserState, ILogoutUserSuccessAction, ILogoutUserFailureAction } from 'store/user/types';
import { SS_AUTH_TOKEN } from 'store/types';
import { CircularProgress } from '@material-ui/core';
import { getUserStoredData } from 'services';

interface IProps {
    locale: ILocaleState;
    user: IUserState;
    initLocales: () => Promise<IInitLocalesSuccessAction | IInitLocalesFailureAction>;
    getUserInfo: () => Promise<IGetUserInfoSuccessAction | IGetUserInfoFailureAction>;
    logoutUser: () => Promise<ILogoutUserSuccessAction | ILogoutUserFailureAction>;
}

interface State {
  
}

export class App extends Component<IProps> {
    state: State = {}

    componentWillMount = () => {
        this.props.initLocales().then(() => {
            const { token } = getUserStoredData();
        
            console.log('sessionStorage.getItem(SS_AUTH_TOKEN)', token)
            if(token !== null){
                console.log('APP DID MOUTN',token)
                this.props.getUserInfo()
                    .then((data) => {
                        // this.props
                        if(data.type === "GET_USER_INFO_FAILURE"){
                            if(data.payload.error === "Forbidden"){
                                this.props.logoutUser();
                            }
                        }
                        console.log(this.props, data)
                    });
            }
        });
        
    }
    componentDidUpdate() {
        console.log('Up App')
    }
    

    render() {
        return (
            <>
            {
                !this.props.user.isFetchingUserInfo?
                    <GlobalRouter/>
                :
                <div className="spinner">
                    <CircularProgress />
                </div> 
            }
            </>
        )
    }
}

const mapStateToProps = (state: IAppState) => ({
    locale: state.locale,
    user: state.user
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
    initLocales: () => dispatch(initLocales(config)),
    getUserInfo: () => dispatch(getUserInfo()),
    logoutUser: () => dispatch(logoutUser()),
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
