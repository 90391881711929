import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { AppState } from "../store";
import ChangePasswordCard from 'components/ChangePasswordCard';

import './ChangePasswordScreen.scss';

interface Props {

}
interface State {

}




export default class ChangePasswordScreen extends Component<Props, State> {
    state = {expanded: false};

    handleExpandClick = () => {
        this.setState({expanded: !this.state.expanded})
        //setExpanded(!expanded);
    };

    render() {

        return (
            <div className='auth-screen screen-top'>
                {/* <LoginCard/> */}
                <ChangePasswordCard/>
            </div>
        )
    }
}
