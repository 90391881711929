import React, { Component } from 'react'
import { connect } from 'react-redux'
import NoData from 'components/NoData';
import { IAppState } from 'store';

interface Props {
    
}
interface State {
    
}

export class AnaliticScreen extends Component<Props, State> {
    state = {}

    render() {
        return (
            <NoData/>
        )
    }
}

const mapStateToProps = (state: IAppState) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(AnaliticScreen)
