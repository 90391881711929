import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { AppState } from "../store";
import ChangePasswordCard from 'components/ChangePasswordCard';

import './CreatePasswordScreen.scss';
import CreatePasswordCard from 'components/CreatePasswordCard/CreatePasswordCard';
import { match } from 'react-router-dom';

interface IRouterParams {
    token: string;
}

interface IProps {
    history: any;
    match: match<IRouterParams>;
}
interface State {
    
}




export default class CreatePasswordScreen extends Component<IProps> {
    state = {expanded: false}

    handleExpandClick = () => {
        this.setState({expanded: !this.state.expanded})
        //setExpanded(!expanded);
    }
    render() {

        return (
            <div className='auth-screen screen-center'>
                {/* <LoginCard/> */}
                <CreatePasswordCard history={this.props.history} token={this.props.match.params.token}/>
            </div>
        )
    }
}