import './WarehouseScreen.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ILocaleState, IInitLocalesSuccessAction, IInitLocalesFailureAction, IDictionary,  } from 'store/locale/types';
import { IAppState } from 'store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { initLocales } from 'store/locale/actions';
import config from 'locales/config.json';
import { SS_AUTH_TOKEN, ISearchParams } from 'store/types';
import PageTemplate from 'components/PageTemplate';
import { IGetWarehouseListSuccessAction, IGetWarehouseListFailureAction, ICreateWarehouseSuccessAction, ICreateWarehouseFailureAction, IWarehouseState, IDeleteWarehouseSuccessAction, IDeleteWarehouseFailureAction, IWarehouseModel, ILogistModel, IAdminModel, WarehouseStatusType, IWarehouseCreateModel, IWarehouseEditModel, IGetWarehouseByIdSuccessAction, IGetWarehouseByIdFailureAction, IEditWarehouseSuccessAction, IEditWarehouseFailureAction } from 'store/warehouse/types';
import { getWarehouseList, createWarehouse, deleteWarehouse, editWarehouse, getWarehouseById } from 'store/warehouse/actions';
import { Dialog, Icon } from '@material-ui/core';
import { Button } from 'components/Button';
import InputField from 'components/InputField';
import { ICompanyNameModel, ICompanyModel } from 'store/company/types';
import { ICityModel } from 'store/city/types';
import { FormBlock } from 'components/FormBlock/FormBlock';
import { IUserModel } from 'store/user/types';
import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import WarningDialog from 'components/WarningDialog/WarningDialog';
import { validateRequiredFields, getNewSort, getErrorsFromDictionary } from 'services';
import equal from 'deep-equal';
import { withPageBase, IWithPageBase } from 'hoc/withPageBase/withPageBase';

const formFieldsDefault: IFormFields = {
    address: '',
    coordinates: '',
    id: '',
    
    comment: '',
    name: '',
    pointCode: '',
    securityPointEmail: '',
    securityPointPhone: null,
    status: null,
    tonnage: null,
    uploadTime: '',
    workDays: '',
    workHours: '',
    adminFio: '', 
    adminPhone: null,
    admin2Fio: '',
    admin2Phone: null,
    cityName: '',
    companyName: '',
    logistFio: '',
    logistPhone: null
};

const autocompleteFormFieldsDefault:IAutocompleteFormFields = {
    admin: {
        fio: '',
        id: '',
        phone: null
    },
    admin2: {
        fio: '',
        id: '',
        phone: null
    },
    city: {
        name: '',
        region: ''
    },
    
    company: {
        name: '',
        id: ''
    },
    
    logist: {
        fio: '',
        id: '',
        phone: null
    },
};

interface IProps extends IWithPageBase {
    locale: ILocaleState;
    loc: IDictionary;
    warehouse: IWarehouseState;
    history: any;
    // initLocales: () => Promise<IInitLocalesSuccessAction | IInitLocalesFailureAction>;
    // getWarehouseInfo: () => Promise<IGetWarehouseInfoSuccessAction | IGetWarehouseInfoFailureAction>;
    getWarehouseList: (params: ISearchParams) => Promise<IGetWarehouseListSuccessAction|IGetWarehouseListFailureAction>;
    deleteWarehouse: (id: string) => Promise<IDeleteWarehouseSuccessAction|IDeleteWarehouseFailureAction>;
    getWarehouseById: (id: string) => Promise<IGetWarehouseByIdSuccessAction|IGetWarehouseByIdFailureAction>;
    createWarehouse: (data: IWarehouseCreateModel) => Promise<ICreateWarehouseSuccessAction|ICreateWarehouseFailureAction>;
    editWarehouse: (data: IWarehouseEditModel) => Promise<IEditWarehouseSuccessAction|IEditWarehouseFailureAction>;
}

interface IFormFields {
    address: string;
    comment: string;
    coordinates: string;
    id: string;
    name: string;
    pointCode: string;
    securityPointEmail: string;
    securityPointPhone: number | null;
    status: WarehouseStatusType | null;
    tonnage: number | null;
    uploadTime: string;
    workDays: string;
    workHours: string;
    adminFio: string;
    adminPhone: number | null;
    admin2Fio: string;
    admin2Phone: number | null;
    cityName: string;
    companyName: string;
    logistFio: string;
    logistPhone: number | null;
}

interface IAutocompleteFormFields {
    admin: IAdminModel;
    admin2: IAdminModel;
    city: ICityModel;
    logist: ILogistModel;
    company: ICompanyNameModel;
}

interface IState {
    page: number;
    q: any;
    sort: string;
    deleteDialogOpen: boolean;
    deleteRow: any | null;
    filters: {[key: string]: string};//{field: string, value: string}[];//{[key: string]: string};
    formFields: IFormFields,
    autocompleteFormFields: IAutocompleteFormFields;
    // beforeEdit: IWarehouseModel | null;
    formDialogOpen: boolean;
    formRow: IWarehouseModel | null;
    // displayErrorDialog: boolean;
    // displayWarningDialog: boolean;
    // errorMessages: string[];
    create: boolean;
    edit: boolean;
}

class WarehouseScreen extends Component<IProps> {
    state: IState = {
        q: {

        },
        page: 0,
        sort: '',
        deleteDialogOpen: false,
        deleteRow: null,
        filters: {},
        formFields: { ...formFieldsDefault },
        // formFields: {
        //     address: '',
        //     coordinates: '',
        //     id: '',
            
        //     comment: '',
        //     name: '',
        //     pointCode: '',
        //     securityPointEmail: '',
        //     securityPointPhone: null,
        //     status: null,
        //     tonnage: null,
        //     uploadTime: '',
        //     workDays: '',
        //     workHours: '',
        //     adminFio: '', 
        //     adminPhone: null,
        //     admin2Fio: '',
        //     admin2Phone: null,
        //     cityName: '',
        //     companyName: '',
        //     logistFio: '',
        //     logistPhone: null
        // },
        autocompleteFormFields: { ...autocompleteFormFieldsDefault },
        // autocompleteFormFields:{
        //     admin: {
        //         fio: '',
        //         id: '',
        //         phone: null
        //     },
        //     admin2: {
        //         fio: '',
        //         id: '',
        //         phone: null
        //     },
        //     city: {
        //         name: '',
        //         region: ''
        //     },
            
        //     company: {
        //         name: '',
        //         id: ''
        //     },
            
        //     logist: {
        //         fio: '',
        //         id: '',
        //         phone: null
        //     },
        // },
        // beforeEdit: null,
        formDialogOpen: false,
        formRow: null,
        // displayErrorDialog: false,
        // displayWarningDialog: false,
        // errorMessages: [],
        create: false,
        edit: false
    }

    beforeEdit = {};

    handleChangeCompanyField = this.props.handleChangeCompanyField.bind(this);
    handleChangeLogistField = this.props.handleChangeLogistField.bind(this);
    handleChangeAdminField = this.props.handleChangeAdminField.bind(this);
    handleChangeAdmin2Field = this.props.handleChangeAdmin2Field.bind(this);
    handleChangeCityField = this.props.handleChangeCityField.bind(this);

    handleChangeFilter = this.props.handleChangeFilter.bind(this);
    handleChangeCompanyFilter = this.props.handleChangeCompanyFilter.bind(this);
    handleChangeCityFilter = this.props.handleChangeCityFilter.bind(this);
    handleChangeLogistFilter = this.props.handleChangeLogistFilter.bind(this);
    handleChangeField = this.props.handleChangeFieldInForm.bind(this);

    static getDerivedStateFromProps = (nextProps: IProps, prevState: IState) => {
        if(prevState.formFields.cityName && prevState.formFields.cityName !== `${prevState.autocompleteFormFields.city.name} (${prevState.autocompleteFormFields.city.region})`){
            return ({
                autocompleteFormFields: {
                    ...prevState.autocompleteFormFields,
                    city: {
                        name: '', 
                        region: ''
                    }
                }
            })
        }

        if(prevState.formFields.companyName && prevState.autocompleteFormFields.company && prevState.autocompleteFormFields.company.name && prevState.formFields.companyName !== `${prevState.autocompleteFormFields.company.name}`){
            return ({
                autocompleteFormFields: {
                    ...prevState.autocompleteFormFields,
                    company: {
                        name: '',
                        id: ''
                    }
                }
            })
        }

        if(prevState.formFields.adminFio && prevState.autocompleteFormFields.admin && prevState.autocompleteFormFields.admin.fio && prevState.formFields.adminFio !== `${prevState.autocompleteFormFields.admin.fio}`){
            return ({
                formFields: {
                    ...prevState.formFields,
                    adminPhone: null,
                },
                autocompleteFormFields: {
                    ...prevState.autocompleteFormFields,
                    admin: {
                        phone: null,
                        fio: '',
                        id: ''
                    }
                }
            })
        }

        if(prevState.formFields.admin2Fio && prevState.autocompleteFormFields.admin2 && prevState.autocompleteFormFields.admin2.fio && prevState.formFields.admin2Fio !== `${prevState.autocompleteFormFields.admin2.fio}`){
            return ({
                formFields: {
                    ...prevState.formFields,
                    admin2Phone: null,
                },
                autocompleteFormFields: {
                    ...prevState.autocompleteFormFields,
                    admin2: {
                        phone: null,
                        fio: '',
                        id: ''
                    }
                }
            })
        }


        if(prevState.formFields.logistFio && prevState.autocompleteFormFields.logist && prevState.autocompleteFormFields.logist.fio && prevState.formFields.logistFio !== `${prevState.autocompleteFormFields.logist.fio}`){
            return ({
                formFields: {
                    ...prevState.formFields,
                    logistPhone: null,
                },
                autocompleteFormFields: {
                    ...prevState.autocompleteFormFields,
                    logist: {
                        phone: null,
                        fio: '',
                        id: ''
                    }
                }
            })
        }

        // console.log(newState)
        return null;
    }

    componentDidUpdate = (prevProps: IProps, prevState: IState) => {
        if(!this.state.formDialogOpen && prevState.formDialogOpen){
            this.setState({
                formFields: { ...formFieldsDefault },
                autocompleteFormFields: { ...autocompleteFormFieldsDefault }
            });
        }
    }

    componentDidMount = () => {
        this.props.getWarehouseList({page: this.state.page});
    }

    changePageNumber = (page: number) => {
        this.props.getWarehouseList({page, q: {...this.state.filters}, sort: this.state.sort})
            .then(data => {
                this.setState({page});
            });
    }

    handleAdd = () => {
        this.setState({
            formDialogOpen: true,
            create: true,
            edit: false 
        })
    }
    
    handleDeleteDialog = (row: any) => {
        const { loc } = this.props;
        this.setState({
            deleteRow: {...row}
        },
        () => {
            this.props.handleDeleteItem(
                `${loc.WarehouseScreen_TryToDelete} ${this.state.deleteRow !== null && this.state.deleteRow.name? this.state.deleteRow.name: "" }`,
                () => {
                    this.deleteRecord();
                }
            );
        });
    }

    deleteRecord = () => {
        this.props.deleteWarehouse(this.state.deleteRow !== null && this.state.deleteRow.id? this.state.deleteRow.id: "")
            .then(dataDelete => {
                if(dataDelete.type === 'DELETE_WAREHOUSE_SUCCESS'){
                    this.props.openSuccessDialog();
                    this.props.getWarehouseList({page: this.state.page, q: {...this.state.filters}, sort: this.state.sort});
                    // this.handleCloseDeleteDialog();
                } else {
                    this.props.openErrorDialog({});
                }
            })
    }

    handleRecordResult = (data: ICreateWarehouseSuccessAction | ICreateWarehouseFailureAction | IEditWarehouseSuccessAction | IEditWarehouseFailureAction, errorType: 'CREATE_WAREHOUSE_FAILURE' | 'EDIT_WAREHOUSE_FAILURE') => {
        if(data.type === errorType){
            // let error = this.props.locale.currentLocaleJSON[`ERROR_${data.payload.error}`]? this.props.locale.currentLocaleJSON[`ERROR_${data.payload.error}`]: this.props.locale.currentLocaleJSON[`ERROR_UnknownError`];
            // this.setState({displayErrorDialog: true, errorMessages: [error]});
            this.props.openErrorDialog(
                {
                    messages: getErrorsFromDictionary(this.props.locale.currentLocaleJSON, [data.payload.error])  
                }
            );
        } else {
            this.props.openSuccessDialog();
            this.props.getWarehouseList({page: this.state.page, q: {...this.state.filters}, sort: this.state.sort});
            //this.props.history.push(`/dashboard/administration/users`);
            this.setState({
                formDialogOpen: false,
                formFields: { ...formFieldsDefault },
                autocompleteFormFields: { ...autocompleteFormFieldsDefault }
            });
        }
        
    }

    handleCreateRecord = () => {
        let { cityName, logistFio, logistPhone, companyName, adminFio, adminPhone, admin2Fio, admin2Phone, id, ...restData } = this.state.formFields;
        this.props.createWarehouse({...restData, ...this.state.autocompleteFormFields })
            .then(data => this.handleRecordResult(data, 'CREATE_WAREHOUSE_FAILURE'));
    }

    handleEditRecord = () => {
        let { cityName, logistFio, logistPhone, companyName, adminFio, adminPhone, admin2Fio, admin2Phone, ...restData } = this.state.formFields;
        this.props.editWarehouse({...restData, ...this.state.autocompleteFormFields })
            .then(data => this.handleRecordResult(data, 'EDIT_WAREHOUSE_FAILURE'));
    }

    handleCloseDialog = () => {
        this.setState({displayErrorDialog: false});
    }

    handleCloseWarningDialog = () => {
        this.setState({displayWarningDialog: false});
    }

    handleCancel = () =>{
        // this.setState({formDialogOpen: false});
        this.props.handleCancelEditDialog.apply(this, [this.editIsValid()]);
    }

    handleEdit = (row: IWarehouseModel) => {
        // this.props.history.push(`/dashboard/warehouse/edit/${row.id}`)
        // this.props.getWarehouseById()
        this.beforeEdit = {...row};
        this.setState((prevState) => {
            return ({
                // beforeEdit: {...row},
                create: false,
                edit: true,
                formDialogOpen: true,
                formFields: {
                    address: row.address ? row.address : '',
                    coordinates: row.coordinates? row.coordinates: '',
                    id: row.id? row.id: '',
                    comment: row.comment? row.comment: '',
                    name: row.name? row.name: '',
                    pointCode: row.pointCode? row.pointCode: '',
                    securityPointEmail: row.securityPointEmail? row.securityPointEmail: '',
                    securityPointPhone: row.securityPointPhone? row.securityPointPhone: null,
                    status: row.status? row.status: '',
                    tonnage: row.tonnage? row.tonnage: null,
                    uploadTime: row.uploadTime? row.uploadTime: '',
                    workDays: row.workDays? row.workDays: '',
                    workHours: row.workHours? row.workHours: '',
                    adminFio: row.admin && row.admin.fio? row.admin.fio : '', 
                    adminPhone: row.admin && row.admin.phone? row.admin.phone: null,
                    admin2Fio: row.admin2 && row.admin2.fio? row.admin2.fio : '', 
                    admin2Phone: row.admin2 && row.admin2.phone? row.admin2.phone: null,
                    cityName: row.city && row.city.name && row.city.region? `${row.city.name} (${row.city.region})`: '',
                    companyName: row.company && row.company.name? row.company.name: '',
                    logistFio: row.logist && row.logist.fio? row.logist.fio: '',
                    logistPhone: row.logist && row.logist.phone? row.logist.phone: null,
                },
                autocompleteFormFields:{
                    admin: {
                        fio: row.admin && row.admin.fio? row.admin.fio : '',
                        id: row.admin && row.admin.id? row.admin.id: '',
                        phone: row.admin && row.admin.phone? row.admin.phone: null,
                    },
                    admin2: {
                        fio: row.admin2 && row.admin2.fio? row.admin2.fio : '',
                        id: row.admin2 && row.admin2.id? row.admin2.id: '',
                        phone: row.admin2 && row.admin2.phone? row.admin2.phone: null,
                    },
                    city: {
                        name: row.city && row.city.name ? row.city.name: '',
                        region: row.city && row.city.region? row.city.region: '',
                    },
                    
                    company: {
                        name: row.company && row.company.name? row.company.name: '',
                        id: row.company && row.company.id? row.company.id: '',
                    },
                    
                    logist: {
                        fio: row.logist && row.logist.fio? row.logist.fio: '',
                        id: row.logist && row.logist.id? row.logist.id: '',
                        phone: row.logist && row.logist.phone? row.logist.phone: null,
                    },
                },
            })
        })
    }

    handleSort = (sortString: string) => {
        const newSort = getNewSort(sortString, this.state);
        this.props.getWarehouseList({page: this.state.page, q: {...this.state.filters}, sort: newSort});
        this.setState({sort: newSort});
        
    }

    handleCloseFormDialog = () => {
        this.setState({formDialogOpen: false});
    }

    // handleCloseDeleteDialog = () => {
    //     this.setState({deleteDialogOpen: false});
    // }

    handleSearch = () => {
        this.props.getWarehouseList({page: 0, q: {...this.state.filters}, sort: this.state.sort});
            this.setState((prevState: IState) => ({
                page: 0
            }))
    }

    handleResetSearch = () => {
        this.props.getWarehouseList({page: this.state.page, q: {}, sort: this.state.sort});
        this.setState((prevState: IState) => ({
            filters: {},
            page: 0
        }))
    }

    // handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     const Event = event;
    //     const field = Event.target.name//.split('|');
    //     const value = Event.target.type == 'number'? +Event.target.value : Event.target.value;
    //     // console.log('filter value',value)
    //     this.setState((prevState: IState) => ({
    //         filters: {
    //             ...prevState.filters,
    //             [field]: value
    //         }
    //     }))
    // }

    // handleChangeCompanyFilter = (resultObject: ICompanyModel) => {
    //     // console.log(resultObject)
    //     this.setState((prevState: IState) => ({
    //         filters: {
    //             ...prevState.filters,
    //             'company.name|full': resultObject.shortName
    //         }
    //     }))
    // }

    // handleChangeLogistFilter = (resultObject: IUserModel) => {
    //     // console.log(resultObject)
    //     this.setState((prevState: IState) => ({
    //         filters: {
    //             ...prevState.filters,
    //             'company.name|full': resultObject.fio
    //         }
    //     }))
    // }

    // handleChangeCityFilter = (resultObject: ICityModel) => {
    //     // console.log(resultObject)
    //     this.setState((prevState: IState) => ({
    //         filters: {
    //             ...prevState.filters,
    //             'city.name|full': resultObject.name
    //         }
    //     }))
    // }



    // handleChangeCompanyField = (resultObject: ICompanyModel) => {
    //     // console.log(resultObject)
    //     this.setState((prevState: IState) => ({
    //         autocompleteFormFields:{
    //             ...prevState.autocompleteFormFields,
    //             company: {
    //                 name: resultObject.shortName,
    //                 id: resultObject.id
    //             }
    //         },
    //         formFields: {
    //             ...prevState.formFields,
    //             'companyName': resultObject.shortName
    //         }
    //     }))
    // }

    // handleChangeLogistField = (resultObject: IUserModel) => {
    //     // console.log(resultObject)
    //     this.setState((prevState: IState) => ({
    //         autocompleteFormFields:{
    //             ...prevState.autocompleteFormFields,
    //             logist: {
    //                 fio: resultObject.fio,
    //                 id: resultObject.id,
    //                 phone: resultObject.workPhone
    //             }
    //         },
    //         formFields: {
    //             ...prevState.formFields,
    //             'logistFio': resultObject.fio,
    //             'logistPhone': resultObject.workPhone
    //         }
    //     }))
    // }

    // handleChangeAdminField = (resultObject: IUserModel) => {
    //     // console.log(resultObject)
    //     this.setState((prevState: IState) => ({
    //         autocompleteFormFields:{
    //             ...prevState.autocompleteFormFields,
    //             admin: {
    //                 fio: resultObject.fio,
    //                 id: resultObject.id,
    //                 phone: resultObject.workPhone
    //             }
    //         },
    //         formFields: {
    //             ...prevState.formFields,
    //             'adminFio': resultObject.fio,
    //             'adminPhone': resultObject.workPhone
    //         }
    //     }))
    // }

    // handleChangeAdmin2Field = (resultObject: IUserModel) => {
    //     // console.log(resultObject)
    //     this.setState((prevState: IState) => ({
    //         autocompleteFormFields:{
    //             ...prevState.autocompleteFormFields,
    //             admin2: {
    //                 fio: resultObject.fio,
    //                 id: resultObject.id,
    //                 phone: resultObject.workPhone
    //             }
    //         },
    //         formFields: {
    //             ...prevState.formFields,
    //             'admin2Fio': resultObject.fio,
    //             'admin2Phone': resultObject.workPhone
    //         }
    //     }))
    // }

    // handleChangeCityField = (resultObject: ICityModel) => {
    //     // console.log(resultObject)
    //     this.setState((prevState: IState) => ({
    //         autocompleteFormFields:{
    //             ...prevState.autocompleteFormFields,
    //             city: {
    //                 name: resultObject.name,
    //                 region: resultObject.region
    //             }
    //         },
    //         formFields: {
    //             ...prevState.formFields,
    //             'cityName': `${resultObject.name} (${resultObject.region})`
    //         }
    //     }))
    // }

    
    // handleChangeField = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     // console.log(event.target, event.target.name, event.target.value);
    //     let name = event.target.name;
    //     let type = event.target.type
    //     let value = type === 'number'? +event.target.value : event.target.value;

    //         this.setState((prevState: IState) => 
    //             ({
    //                 formFields:{
    //                     ...prevState.formFields,
    //                     [name]: value
    //                 }
                    
    //             })
    //         );
    // }

    getCityCompanyAdditionalFilter = () => {
        let additionalData: {[key: string]: string} = {}
        if(this.state.autocompleteFormFields.city.name !== '' && this.state.autocompleteFormFields.city.region !== ''){
            additionalData['city.name|full'] = this.state.autocompleteFormFields.city.name;
            additionalData['city.region|full'] = this.state.autocompleteFormFields.city.region;
        }
        if(this.state.autocompleteFormFields.company.id !== '' && this.state.autocompleteFormFields.company.name !== ''){
            additionalData['company.id|full'] = this.state.autocompleteFormFields.company.id;
        }
        return additionalData;
    }

    additionIsValid = () => {
        let { cityName, logistFio, logistPhone, companyName, adminFio, adminPhone, admin2Fio, admin2Phone, id, ...restData } = this.state.formFields;
        
        return validateRequiredFields(this.props.warehouse.required? this.props.warehouse.required : [], {...restData, ...this.state.autocompleteFormFields });
    }

    editIsValid = (): boolean => {
        let { cityName, logistFio, logistPhone, companyName, adminFio, adminPhone, admin2Fio, admin2Phone, ...restData } = this.state.formFields;
        // let { id: idBefore, ...beforeUpdate } = this.beforeEdit//? this.beforeEdit: {id: ''};
        let afterUpdate = {...restData, ...this.state.autocompleteFormFields };
        // return equal(beforeUpdate, afterUpdate);
        return equal(this.beforeEdit, afterUpdate);
    }

    render() {
        const { loc } = this.props;
        console.log('warehouse valid',this.additionIsValid())
        return (
            <>
                <PageTemplate 
                    pageTitle={loc.ObjectScreen_PageTitle} 
                    pageIcon={'vpn_key'}
                    edit={true}
                    delete={true}
                    import
                    export
                    print
                    entity={'WAREHOUSE'}
                    tableHeaders={[
                        loc.ObjectScreen_CompanyColumn,
                        loc.ObjectScreen_StoreColumn,
                        loc.ObjectScreen_CityColumn,
                        loc.ObjectScreen_AddressColumn,
                        loc.ObjectScreen_LogistColumn,
                        loc.ObjectScreen_StoreCoordinatesColumn,
                        loc.ObjectScreen_PointCodeColumn
                    ]}
                    listData={this.props.warehouse.list}
                    dataModel={[
                        'company.name|struct',
                        'name',
                        'city.name|struct',
                        'address',
                        'logist.fio|struct',
                        'coordinates',
                        'pointCode'
                    ]}
                    isLoading={this.props.warehouse.isFetching}
                    pageble={this.props.warehouse.pageble? this.props.warehouse.pageble : {
                        currentPage: 0,
                        totalElements: 0,
                        totalPages: 0
                    }}
                    onChangePage={this.changePageNumber}
                    onDelete={this.handleDeleteDialog}
                    onEdit={this.handleEdit}
                    onSearch={this.handleSearch}
                    onResetSearch={this.handleResetSearch}
                    onSort={this.handleSort}
                    actualSort={this.state.sort}
                >
                    <InputField label={loc.ObjectScreen_CompanyFilter} type="company" onSelect={this.handleChangeCompanyFilter} onChange={this.handleChangeFilter} value={this.state.filters['company.name|full']? this.state.filters['company.name|full']: ""} name='company.name|full'/>
                    <InputField label={loc.ObjectScreen_StoreFilter} type="text" onChange={this.handleChangeFilter} value={this.state.filters['name|regex']? this.state.filters['name|regex']: ""} name='name|regex'/>
                    <InputField label={loc.ObjectScreen_CityFilter} type="city" onSelect={this.handleChangeCityFilter} onChange={this.handleChangeFilter} value={this.state.filters['city.name|full']? this.state.filters['city.name|full']: ""} name='city.name|full'/>
                    <InputField label={loc.ObjectScreen_AddressFilter} type="text" onChange={this.handleChangeFilter} value={this.state.filters['address|regex']? this.state.filters['address|regex']: ""} name='address|regex'/>
                    
                    
                    
                    <InputField label={loc.ObjectScreen_LogistFilter} type="logist" onSelect={this.handleChangeLogistFilter} onChange={this.handleChangeFilter} value={this.state.filters['logist.fio|full']? this.state.filters['logist.fio|full']: ""} name='logist.fio|full'/>
                    <InputField label={loc.ObjectScreen_StoreCoordinatesFilter} type="text" onChange={this.handleChangeFilter} value={this.state.filters['coordinates|regex']? this.state.filters['coordinates|regex']: ""} name='coordinates|regex'/>
                    <InputField label={loc.ObjectScreen_PointCodeFilter} type="text" onChange={this.handleChangeFilter} value={this.state.filters['pointCode|regex']? this.state.filters['pointCode|regex']: ""} name='pointCode|regex'/>
                </PageTemplate>
                {/* <Dialog
                    open={this.state.deleteDialogOpen}
                    onClose={this.handleCloseDeleteDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div className="dialog-window">
                        <p>{`${loc.WarehouseScreen_TryToDelete} ${this.state.deleteRow !== null && this.state.deleteRow.name? this.state.deleteRow.name: "" }`}</p>
                        <div className="btn-block">
                            <Button padding col={4} color={'primary'} onClick={() => {this.deleteRecord()}}>{loc.Button_Continue}</Button>
                            <Button padding col={4} onClick={() => {this.handleCloseDeleteDialog()}}>{loc.Button_Cancel}</Button>
                        </div>  
                        
                    </div>
                </Dialog> */}
                <Dialog
                    open={this.state.formDialogOpen}
                    onClose={this.handleCloseFormDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    scroll={'body'}
                >
                    
                    <div className="form-dialog-window">
                        <div className="form-dialog-header">
                            <p>{loc.ObjectBlock_ModalTitle}</p>
                            <button onClick={() => {this.handleCloseFormDialog()}}><Icon>close</Icon></button>
                        </div>
                        <div className="object-form-wrapper">
                            <div className="object-form-col left">
                                <FormBlock compactStyle hideBorder colLeft={5} colRight={7} title={loc.WarehouseBlock_StoreField}>
                                    <InputField required={true} padding={true} placeholder={loc.WarehouseBlock_StoreField} hideLabel={true} label={loc.WarehouseBlock_StoreField} type="text" onChange={this.handleChangeField} value={this.state.formFields.name? this.state.formFields.name: ""} name='name'/>
                                </FormBlock>
                                <FormBlock compactStyle hideBorder colLeft={5} colRight={7} title={loc.WarehouseBlock_CompanyField}>
                                    <InputField isValid={(this.state.autocompleteFormFields.company && this.state.autocompleteFormFields.company.name !== '' && this.state.autocompleteFormFields.company.id !== '')} required={true} padding={true} placeholder={loc.WarehouseBlock_CompanyField} hideLabel={true} label={loc.WarehouseBlock_CompanyField} autoSelect type="company" onSelect={this.handleChangeCompanyField} onChange={this.handleChangeField} value={this.state.formFields.companyName? this.state.formFields.companyName: ''} name='companyName'/>
                                </FormBlock>
                                <FormBlock compactStyle hideBorder colLeft={5} colRight={7} title={loc.WarehouseBlock_LifePointField}>
                                    <InputField isValid={(this.state.autocompleteFormFields.city && this.state.autocompleteFormFields.city.name !== '' && this.state.autocompleteFormFields.city.region !== '')} required={true} padding={true} placeholder={loc.WarehouseBlock_LifePointField} hideLabel={true} label={loc.WarehouseBlock_LifePointField} type="city" onSelect={this.handleChangeCityField} onChange={this.handleChangeField} value={this.state.formFields.cityName? this.state.formFields.cityName: ''} name='cityName'/>
                                </FormBlock>
                                <FormBlock compactStyle hideBorder colLeft={5} colRight={7} title={loc.WarehouseBlock_AddressField}>
                                    <InputField padding={true} placeholder={loc.WarehouseBlock_AddressField} hideLabel={true} label={loc.WarehouseBlock_AddressField} type="text" onChange={this.handleChangeField} value={this.state.formFields.address? this.state.formFields.address: ''} name='address'/>
                                </FormBlock>
                                <FormBlock compactStyle hideBorder colLeft={5} colRight={7} title={loc.WarehouseBlock_WorkTimeField}>
                                    <InputField padding={true} placeholder={loc.WarehouseBlock_WorkTimeField} hideLabel={true} label={loc.WarehouseBlock_WorkTimeField} type="text" onChange={this.handleChangeField} value={this.state.formFields.workHours? this.state.formFields.workHours: ''} name='workHours'/>
                                </FormBlock>
                                
                                <FormBlock compactStyle hideBorder colLeft={5} colRight={7} title={loc.WarehouseBlock_WorkDaysField}>
                                    <InputField  padding={true} placeholder={loc.WarehouseBlock_WorkDaysField} hideLabel={true} label={loc.WarehouseBlock_WorkDaysField} type="text" onChange={this.handleChangeField} value={this.state.formFields.workDays? this.state.formFields.workDays: ''} name='workDays'/>
                                </FormBlock>
                                <FormBlock compactStyle hideBorder colLeft={5} colRight={7} title={loc.WarehouseBlock_TimeToOutputField}>
                                    <InputField padding={true} placeholder={loc.WarehouseBlock_TimeToOutputField} hideLabel={true} label={loc.WarehouseBlock_TimeToOutputField} type="text" onChange={this.handleChangeField} value={this.state.formFields.uploadTime? this.state.formFields.uploadTime: ''} name='uploadTime'/>
                                </FormBlock>
                                <FormBlock compactStyle hideBorder colLeft={5} colRight={7} title={loc.WarehouseBlock_StoreCoordinatesField}>
                                    <InputField padding={true} placeholder={loc.WarehouseBlock_StoreCoordinatesField} hideLabel={true} label={loc.WarehouseBlock_StoreCoordinatesField} type="text" onChange={this.handleChangeField} value={this.state.formFields.coordinates? this.state.formFields.coordinates: ''} name='coordinates'/>
                                </FormBlock>
                                <FormBlock compactStyle hideBorder colLeft={5} colRight={7} title={loc.WarehouseBlock_PointCodeField}>
                                    <InputField padding={true} placeholder={loc.WarehouseBlock_PointCodeField} hideLabel={true} label={loc.WarehouseBlock_PointCodeField} type="text" onChange={this.handleChangeField} value={this.state.formFields.pointCode? this.state.formFields.pointCode: ''} name='pointCode'/>
                                </FormBlock>
                                <FormBlock compactStyle hideBorder colLeft={5} colRight={7} title={loc.WarehouseBlock_WeightAccessField}>
                                    <InputField hideLabel padding={true} label={loc.WarehouseBlock_WeightAccessField} type="select" onChange={this.handleChangeField} value={this.state.formFields.tonnage? this.state.formFields.tonnage: ''} name="tonnage"
                                        options={[
                                                {
                                                    value: 10,
                                                    label: '10',
                                                },
                                                {
                                                    value: 15,
                                                    label: '15',
                                                },
                                                {
                                                    value: 20,
                                                    label: '20',
                                                },
                                            ]}/>
                                </FormBlock>
                            </div>
                            <div className="object-form-col right">
                                <FormBlock compactStyle hideBorder colLeft={5} colRight={7} title={loc.WarehouseBlock_AdministratorField}>
                                    <InputField isValid={(this.state.autocompleteFormFields.admin && this.state.autocompleteFormFields.admin.fio !== '' && this.state.autocompleteFormFields.admin.id !== '')} required={true} padding={true} additionalFilters={this.getCityCompanyAdditionalFilter()} placeholder={loc.WarehouseBlock_AdministratorField} hideLabel={true} label={loc.UserBlock_FieldTitle_Fullname} autoSelect type="admin" onSelect={this.handleChangeAdminField} onChange={this.handleChangeField} value={this.state.formFields.adminFio? this.state.formFields.adminFio: ''} name='adminFio'/>
                                </FormBlock>
                                <FormBlock compactStyle colLeft={5} colRight={7} title={loc.WarehouseBlock_AdministratorPhoneField}>
                                    <InputField required={true} padding={true} placeholder={loc.WarehouseBlock_AdministratorPhoneField} hideLabel={true} label={loc.UserBlock_FieldTitle_Fullname} type="phone" onChange={this.handleChangeField} value={this.state.formFields.adminPhone? this.state.formFields.adminPhone: ''} name='adminPhone'/>
                                </FormBlock>

                                <FormBlock compactStyle hideBorder colLeft={5} colRight={7} title={loc.WarehouseBlock_Administrator2Field}>
                                    <InputField isValid={(this.state.autocompleteFormFields.admin2 && this.state.autocompleteFormFields.admin2.fio !== '' && this.state.autocompleteFormFields.admin2.id !== '')} required={true} padding={true} additionalFilters={this.getCityCompanyAdditionalFilter()} placeholder={loc.WarehouseBlock_Administrator2Field} hideLabel={true} label={loc.UserBlock_FieldTitle_Fullname} autoSelect type="admin" onSelect={this.handleChangeAdmin2Field} onChange={this.handleChangeField} value={this.state.formFields.admin2Fio? this.state.formFields.admin2Fio: ''} name='admin2Fio'/>
                                </FormBlock>
                                <FormBlock compactStyle colLeft={5} colRight={7} title={loc.WarehouseBlock_Administrator2PhoneField}>
                                    <InputField required={true} padding={true} placeholder={loc.WarehouseBlock_Administrator2PhoneField} hideLabel={true} label={loc.UserBlock_FieldTitle_Fullname} type="phone" onChange={this.handleChangeField} value={this.state.formFields.admin2Phone? this.state.formFields.admin2Phone: ''} name='admin2Phone'/>
                                </FormBlock>

                                <FormBlock compactStyle hideBorder colLeft={5} colRight={7} title={loc.WarehouseBlock_LogistField}>
                                    <InputField isValid={(this.state.autocompleteFormFields.logist && this.state.autocompleteFormFields.logist.fio !== '' && this.state.autocompleteFormFields.logist.id !== '')} required={true} padding={true} additionalFilters={this.getCityCompanyAdditionalFilter()} placeholder={loc.WarehouseBlock_LogistField} hideLabel={true} label={loc.UserBlock_FieldTitle_Fullname} autoSelect type="logist"  onSelect={this.handleChangeLogistField} onChange={this.handleChangeField} value={this.state.formFields.logistFio? this.state.formFields.logistFio: ''} name='logistFio'/>
                                </FormBlock>
                                <FormBlock compactStyle colLeft={5} colRight={7} title={loc.WarehouseBlock_LogistPhoneField}>
                                    <InputField required={true} padding={true} placeholder={loc.WarehouseBlock_LogistPhoneField} hideLabel={true} label={loc.UserBlock_FieldTitle_Fullname} type="phone" onChange={this.handleChangeField} value={this.state.formFields.logistPhone? this.state.formFields.logistPhone: ''} name='logistPhone'/>
                                </FormBlock>

                                <FormBlock compactStyle hideBorder colLeft={5} colRight={7} title={loc.WarehouseBlock_SecurityEmailField}>
                                    <InputField padding={true} placeholder={loc.WarehouseBlock_SecurityEmailField} hideLabel={true} label={loc.UserBlock_FieldTitle_Fullname} type="email" onChange={this.handleChangeField} value={this.state.formFields.securityPointEmail? this.state.formFields.securityPointEmail: ''} name='securityPointEmail'/>
                                </FormBlock>
                                <FormBlock compactStyle colLeft={5} colRight={7} title={loc.WarehouseBlock_SecurityPhoneField}>
                                    <InputField padding={true} placeholder={loc.WarehouseBlock_SecurityPhoneField} hideLabel={true} label={loc.UserBlock_FieldTitle_Fullname} type="phone" onChange={this.handleChangeField} value={this.state.formFields.securityPointPhone? this.state.formFields.securityPointPhone: null} name='securityPointPhone'/>
                                </FormBlock>

                                <FormBlock compactStyle hideBorder colLeft={0} colRight={12} title={""}>
                                    <InputField padding={true} placeholder={loc.WarehouseBlock_CommentTextField} label={loc.WarehouseBlock_CommentField} type="multiline" onChange={this.handleChangeField} value={this.state.formFields.comment? this.state.formFields.comment: ''} name='comment'/>
                                </FormBlock>
                               
                            </div>
                            <FormBlock compactStyle contentCenter hideBorder colLeft={0} colRight={12} title={loc.UserBlock_BlockTitle_UserRequisites}>
                                {
                                    (this.state.edit || this.state.create)?
                                        <>
                                            <Button onClick={this.handleCancel} padding col={4}>
                                                {loc.Button_Cancel}
                                            </Button>
                                            </>
                                    :
                                        null
                                }
                                {
                                    this.state.edit?
                                        <Button 
                                        col={4}
                                            disabled={
                                                this.editIsValid() || !this.additionIsValid() 
                                            }
                                            onClick={this.handleEditRecord} 
                                            padding 
                                            color="primary"
                                        >
                                            {loc.Button_Save}
                                        </Button>
                                    :
                                        null
                                }
                                {
                                    this.state.create?
                                        <Button 
                                            disabled={!this.additionIsValid() }
                                            onClick={this.handleCreateRecord} 
                                            padding 
                                            col={4} 
                                            color="primary"
                                        >
                                            {loc.Button_Add}
                                        </Button>
                                    :
                                        null
                                }

                                </FormBlock>
                        </div>
                    </div>
                </Dialog>
                {/* <WarningDialog display={this.state.displayWarningDialog} okAction={() => {this.props.history.goBack()}} closeDialog={this.handleCloseWarningDialog}/> */}
                {/* <ErrorDialog errorMessages={this.state.errorMessages} display={this.state.displayErrorDialog} closeDialog={this.handleCloseDialog}/> */}
                <button onClick={() => {this.handleAdd()}} className="add-button">
                    <Icon>add</Icon>
                </button>
            </>
        )
    }
}

const mapStateToProps = (state: IAppState) => ({
    locale: state.locale,
    warehouse: state.warehouse,
    loc: state.locale.currentLocaleJSON
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
    // initLocales: () => dispatch(initLocales(config)),
    // getWarehouseInfo: () => dispatch(getWarehouseInfo())
    getWarehouseList: (params: ISearchParams) => dispatch(getWarehouseList(params)),
    getWarehouseById: (id: string) => dispatch(getWarehouseById(id)),
    createWarehouse: (data: IWarehouseCreateModel) => dispatch(createWarehouse(data)),
    editWarehouse: (data: IWarehouseEditModel) => dispatch(editWarehouse(data)),
    deleteWarehouse: (id: string) => dispatch(deleteWarehouse(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(withPageBase(WarehouseScreen));
