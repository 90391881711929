import React, { Component } from 'react'
import { connect } from 'react-redux'
import { IAppState } from 'store';
import { FormControl, InputLabel, Input, InputAdornment, CircularProgress, FormHelperText } from '@material-ui/core';
import { IGetUserListFailureAction, IUserState, IGetUserListSuccessAction, IGetAdminListSuccessAction, IGetAdminListFailureAction, IUserModel } from 'store/user/types';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { getUserList, getAdminList } from 'store/user/actions';
import { ISearchParams } from 'store/types';
import equal from 'deep-equal';
import Icon from '@material-ui/core/Icon';

export interface IProps {
    label: string;
    name: string;
    value: string;
    // getAdminList: (params: ISearchParams) => Promise<IGetAdminListSuccessAction|IGetAdminListFailureAction>;
    // user: IUserState;
    // params: ISearchParams;
    updateFunction: () => Promise<{[key: string]: any}[]>;//<Success, Failure>(params: ISearchParams) => Promise<Success|Failure>;
    shouldUpdate: (props: IProps, prevProps: IProps) => boolean;
    listingParamName: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onSelect?: (resultObject: any) => void; 
    hideLabel?: boolean;
    placeholder?: string;
    disabled?: boolean;
    required?: boolean;
    error?: boolean;
    errorMsg?: string;
    additionalFilters?: {[key: string]: number | string};
    autoSelect?: boolean;
    cityName?: string;
}

interface IState {
    value: string;
    fieldsIsFocus: boolean;
    additionalFilters: {[key: string]: number | string};
    list: {[key: string]: any}[];
    isLoading: boolean;
    checked: boolean;
}



export class AutocompleteTemplate extends Component<IProps> {
    state: IState = {
        value: "",
        fieldsIsFocus: false,
        additionalFilters: {},
        list: [],
        isLoading: false,
        checked: false
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(this.props.onChange)
            this.props.onChange(event);

    }

    onBlur = () => {
        setTimeout(() => {
            this.setState({fieldsIsFocus: false});
        }, 300);
    }


    onFocus = () => {
        this.setState({fieldsIsFocus: true});
    }

    componentDidMount = () => {
        console.log('auto mount', this.props)
        if(this.props.autoSelect){
            // let additionalFilters = {};
            // if(this.props.additionalFilters){
            //     additionalFilters = { ...this.props.additionalFilters };
            // }
            
            this.setState({checked: false, list: this.props.updateFunction(), value: this.props.value, isLoading: false});

        } else {
            this.setState({checked: false});
        }
    }

    setNewList = () => {
        this.props.updateFunction()
            .then(list => {
                this.setState({list, value: this.props.value, isLoading: false, checked: false});
            })
        
    }
    
    componentDidUpdate = (prevProps: IProps) => {
        console.log('auto up', this.props.name, this.props)
        // let additionalFilters = {};
        //     if(this.props.additionalFilters){
        //         additionalFilters = { ...this.props.additionalFilters }
        //     }
        
        if(this.props.shouldUpdate(this.props, prevProps)) {
            // console.log('Have a symbol', nextProps.value)
            this.setNewList();
            
        }
    }



    static getDerivedStateFromProps = (nextProps: IProps, prevState: IState) => {
        if(prevState.value !== nextProps.value && Math.abs(prevState.value.length - nextProps.value.length) === 1 ) {
            if(!nextProps.autoSelect && nextProps.value.length < 2) {
                return ({isLoading: false, checked: false});
            } else {
                if(nextProps.value.length > 0) {
                    return ({isLoading: true, checked: false});
                } else {
                    return ({isLoading: false, checked: false});
                }
                
            }
        }
            
        return null;
    }

    handleChangeAutocomplete = (element: any) => {
        console.log('handleChangeAutocomplete', element)
        if(this.props.onSelect) {
            this.props.onSelect(element)
            this.setState({checked: true})
        }
    }

    render() {
        return (
            <FormControl error={this.props.error} fullWidth className={'field-wrapper'}>
                {
                    !this.props.hideLabel?
                        <InputLabel shrink htmlFor={`input_${this.props.label}`}>{this.props.label}</InputLabel>
                    :
                        null
                }
                <Input
                id = {`input_${this.props.label}`}
                name = {this.props.name? this.props.name : `input_${this.props.label}`}
                type={'text'}
                value={this.props.value}
                onBlur={this.onBlur}
                onFocus={this.onFocus}
                onChange={this.handleChange}
                disabled={this.props.disabled}
                placeholder={this.props.placeholder ? this.props.required? `${this.props.placeholder} *`: `${this.props.placeholder}` : ''}
                endAdornment={
                    <InputAdornment position="end">
                        {this.state.isLoading && <CircularProgress className={'autocomplete-progress'} />}
                        {/* {this.state.checked && <Icon>done</Icon>} */}
                    </InputAdornment>
                }
                />
                {this.props.error?
                    <FormHelperText id="component-error-text">{this.props.errorMsg}</FormHelperText>
                    : null
                }
                {(this.state.fieldsIsFocus && !this.state.isLoading && this.state.list.length && this.props.value.length > 1) || (this.props.autoSelect && this.state.fieldsIsFocus && !this.state.isLoading && this.state.list.length)?
                        <div className="autocomplete-dropdown">
                            {
                                this.state.list.map((elem, num) => {
                                    // console.log(elem[autocomleteValue])
                                    if(this.props.listingParamName === 'city'){
                                        return <p key={`${elem.name}(${elem.region})`} title={`${elem.name} (${elem.region})`} onClick={() => {this.handleChangeAutocomplete(elem)}}>{`${elem.name} (${elem.region})`}</p>
                                    } else {
                                        return <p key={`$(${elem[this.props.listingParamName]})`} title={`${elem[this.props.listingParamName]}`} onClick={() => {this.handleChangeAutocomplete(elem)}}>{`${elem[this.props.listingParamName]}`}</p>
                                    }
                                })
                            }
                        </div>
                    :
                        null
                }


            </FormControl>
        )
    }
}

// const mapStateToProps = (state: IAppState) => ({
//     // user: state.user
// })

// const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ( {
//     getAdminList: (params: ISearchParams) => dispatch(getAdminList(params))
// })

// export default connect(mapStateToProps, mapDispatchToProps)(AutocompleteAdmin);
