import Api from "api";
import { ISearchParams, IGetListServerResponse, IDefaultServerResponse, IGetListPayload } from "store/types";
import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { 
    IDoneDialogData,
    IOpenDoneDialogAction,
    ICloseDoneDialogAction,
    IOpenWarningDialogAction,
    IWarningDialogData,
    IOkWarningDialogAction,
    ICloseWarningDialogAction,
    IOpenErrorDialogAction,
    IErrorDialogData,
    ICloseErrorDialogAction
 } from "./types";
import { AxiosResponse } from "axios";
import { formatSearchParams } from "services";

export const openDoneDialog: ActionCreator<
  ThunkAction<
    Promise<IOpenDoneDialogAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    IDoneDialogData,                  // The type for the data within the last action
    IDoneDialogData,                       // The type of the parameter for the nested function 
    IOpenDoneDialogAction            // The type of the last action to be dispatched
  >
> = (data: IDoneDialogData) => {
    return async (dispatch: Dispatch) => {
        const openDoneDialogAction: IOpenDoneDialogAction = {
            type: 'OPEN_DONE_DIALOG',
            paylaod: {...data}
        };
        return dispatch(openDoneDialogAction);
    };
};


export const closeDoneDialog: ActionCreator<
  ThunkAction<
    Promise<ICloseDoneDialogAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    null,                       // The type of the parameter for the nested function 
    ICloseDoneDialogAction            // The type of the last action to be dispatched
  >
> = () => {
    return async (dispatch: Dispatch) => {
        const closeDoneDialogAction: ICloseDoneDialogAction = {
            type: 'CLOSE_DONE_DIALOG'
        };
        return dispatch(closeDoneDialogAction);
    };
};


export const openWarningDialog: ActionCreator<
  ThunkAction<
    Promise<IOpenWarningDialogAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    IWarningDialogData,                  // The type for the data within the last action
    IWarningDialogData,                       // The type of the parameter for the nested function 
    IOpenWarningDialogAction            // The type of the last action to be dispatched
  >
> = (data: IWarningDialogData) => {
    return async (dispatch: Dispatch) => {
        const openWarningDialogAction: IOpenWarningDialogAction = {
            type: 'OPEN_WARNING_DIALOG',
            paylaod: {...data}
        };
        return dispatch(openWarningDialogAction);
    };
};


export const okWarningDialog: ActionCreator<
  ThunkAction<
    Promise<IOkWarningDialogAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    () => void,                  // The type for the data within the last action
    () => void,                       // The type of the parameter for the nested function 
    IOkWarningDialogAction            // The type of the last action to be dispatched
  >
> = (callback: () => void) => {
    return async (dispatch: Dispatch) => {
        if(callback){
          callback();
        }
        const okWarningDialogAction: IOkWarningDialogAction = {
            type: 'OK_WARNING_DIALOG',
        };
        return dispatch(okWarningDialogAction);
    };
};


export const closeWarningDialog: ActionCreator<
  ThunkAction<
    Promise<ICloseWarningDialogAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    null,                       // The type of the parameter for the nested function 
    ICloseWarningDialogAction            // The type of the last action to be dispatched
  >
> = () => {
    return async (dispatch: Dispatch) => {
        const closeWarningDialogAction: ICloseWarningDialogAction = {
            type: 'CLOSE_WARNING_DIALOG'
        };
        return dispatch(closeWarningDialogAction);
    };
};

export const openErrorDialog: ActionCreator<
  ThunkAction<
    Promise<IOpenErrorDialogAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    IErrorDialogData,                  // The type for the data within the last action
    IErrorDialogData,                       // The type of the parameter for the nested function 
    IOpenErrorDialogAction            // The type of the last action to be dispatched
  >
> = (data: IErrorDialogData) => {
    return async (dispatch: Dispatch) => {
        const openErrorDialogAction: IOpenErrorDialogAction = {
            type: 'OPEN_ERROR_DIALOG',
            paylaod: {...data}
        };
        return dispatch(openErrorDialogAction);
    };
};


export const closeErrorDialog: ActionCreator<
  ThunkAction<
    Promise<ICloseErrorDialogAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    IErrorDialogData,                  // The type for the data within the last action
    IErrorDialogData,                       // The type of the parameter for the nested function 
    ICloseErrorDialogAction            // The type of the last action to be dispatched
  >
> = () => {
    return async (dispatch: Dispatch) => {
        const closeErrorDialogAction: ICloseErrorDialogAction = {
            type: 'CLOSE_ERROR_DIALOG'
        };
        return dispatch(closeErrorDialogAction);
    };
};
