import './MapScreen.scss';
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { ILocaleState, IInitLocalesSuccessAction, IInitLocalesFailureAction, IDictionary,  } from 'store/locale/types';
import { IAppState } from 'store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';




interface IProps {
    // locale: ILocaleState;
    // loc: IDictionary;
    // notification: INotificationState;
    // history: any;
    // sort: string;
    // initLocales: () => Promise<IInitLocalesSuccessAction | IInitLocalesFailureAction>;
    // getUserInfo: () => Promise<IGetUserInfoSuccessAction | IGetUserInfoFailureAction>;
    // getNotificationList: (params: ISearchParams) => Promise<IGetNotificationListSuccessAction|IGetNotificationListFailureAction>;
    // readCurrentNotification: (id: string) => Promise<IReadCurrentNotificationSuccessAction|IReadCurrentNotificationFailureAction>;
    // readAllNotifications: () => Promise<IReadAllNotificationsSuccessAction|IReadAllNotificationsFailureAction>;
}

interface IState {
    // page: number;
    // q: any;
    // readAllDialogOpen: boolean;
    // row: any | null;
    // sort: string;
    // filters: {[key: string]: string | number}
}

class MapScreen extends Component<IProps> {
    state: IState = {
        // q: '',
        // page: 0,
        // readAllDialogOpen: false,
        // row: null,
        // sort: "",
        // filters: {}
    }


    render() {
        // const { loc } = this.props;
        return (
            <>
                <iframe className="map-on-way" src="https://www.google.com/maps/d/u/0/embed?mid=1Ps9ErNN73Yt7yxLiIrSb-FIkYyVMR67S"></iframe>
            </>
        )
    }
}

const mapStateToProps = (state: IAppState) => ({
    // locale: state.locale,
    // notification: state.notification,
    // loc: state.locale.currentLocaleJSON
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
    // initLocales: () => dispatch(initLocales(config)),
    // getUserInfo: () => dispatch(getUserInfo())
    // getNotificationList: (params: ISearchParams) => dispatch(getNotificationList(params)),
    // readCurrentNotification: (id: string) => dispatch(readCurrentNotification(id)),
    // readAllNotifications: () => dispatch(readAllNotifications())
})

export default connect(mapStateToProps, mapDispatchToProps)(MapScreen)
