import { 
    REQUEST, 
    SUCCESS, 
    FAILURE 
} from '../types';
    
import {
    IDialogState,
    DialogActions,
} from "./types";

import { Reducer } from 'redux';

const initialDialogState: IDialogState = {
    doneDialogDisplay: false,
    doneDialogText: '',
    warningDialogDisplay: false,
    warningDialogText: '',
    warningDialogIsSmart: false,
    warningDialogIsDelete: false,
    warningDialogCallback: () => {},
    errorDialogDisplay: false,
    errorDialogText: '',
    errorDialogMessages: []
}

export const dialogReducer: Reducer<IDialogState, DialogActions> = (
    state = initialDialogState,
    action,
  ) => {

    const warningCleanDialogState = {
        ...state,
        warningDialogDisplay: false,
        warningDialogText: '',
        warningDialogSmart: false,
        warningDialogCallback: () => {}

    }

    switch (action.type) {
        case 'OPEN_DONE_DIALOG': {
            return {
                ...state,
                doneDialogDisplay: true,
                doneDialogText: action.paylaod.text? action.paylaod.text: ''
            };
        }
        case 'CLOSE_DONE_DIALOG': {
            // console.log(parseJwt(action.authData.data.token))
            return {
                ...state,
                doneDialogDisplay: false,
                doneDialogText: ''
            };
        }
        case 'OPEN_WARNING_DIALOG': {
            return {
                ...state,
                warningDialogDisplay: true,
                warningDialogText: action.paylaod.text? action.paylaod.text: '',
                warningDialogCallback: action.paylaod.callback? action.paylaod.callback: () => {},
                warningDialogIsSmart: !!action.paylaod.isSmart,
                warningDialogIsDelete: !!action.paylaod.isDelete
            };
        }
        case 'OK_WARNING_DIALOG': {
            return {
                ...warningCleanDialogState
            };
        }
        case 'CLOSE_WARNING_DIALOG': {
            // console.log(parseJwt(action.authData.data.token))
            return {
                ...warningCleanDialogState
            };
        }
        case 'OPEN_ERROR_DIALOG': {
            return {
                ...state,
                errorDialogDisplay: true,
                doneDialogText: action.paylaod.text? action.paylaod.text: '',
                errorDialogMessages: action.paylaod.messages? [ ...action.paylaod.messages ] : []
            };
        }
        case 'CLOSE_ERROR_DIALOG': {
            // console.log(parseJwt(action.authData.data.token))
            return {
                ...state,
                errorDialogDisplay: false,
                doneDialogText: '',
                errorDialogMessages: []
            };
        }

        default:
            neverReached(action); // when a new action is created, this helps us remember to handle it in the reducer
    }
    return state;
};
  
  // tslint:disable-next-line:no-empty
const neverReached = (never: never) => {};