
import React, { Component } from 'react'
import { connect } from 'react-redux'


import './WarningDialog.scss';
import { Link } from 'react-router-dom';
import { FormControlLabel, Checkbox, Dialog } from '@material-ui/core';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { IAppState } from 'store';
import { ILocaleState } from 'store/locale/types';
import { Button } from 'components/Button';
import { IDialogState, ICloseWarningDialogAction } from 'store/dialog/types';
import { closeWarningDialog } from 'store/dialog/actions';


interface IProps {
    // display: boolean;
    locale: ILocaleState;
    dialog: IDialogState;
    // warningText?: string; 
    // okAction: () => void;
    closeDialog: () => Promise<ICloseWarningDialogAction>;
    // smart?: boolean;

}
interface IState {
    display: boolean;

}


class WarningDialog extends Component<IProps> {
    state: IState = {
        display: false
    }

    okAction = () => {
        this.props.dialog.warningDialogCallback();
        this.props.closeDialog();
        // this.setState({display: false},()=>{
        //     setTimeout(() => {
        //         this.props.closeDialog();
        //     }, 300);
        // })
        // this.props.okAction();
    }
    
    closeDialog = () => {
        // this.setState({display: false},()=>{
        //     setTimeout(() => {
        //         this.props.closeDialog();
        //     }, 300);
        // })
        this.props.closeDialog();
        
    }

    componentDidUpdate = (nextProps: IProps, nextState: IState) => {
        // if(this.state.display && !nextProps.dialog.warningDialogDisplay){
        //     setTimeout(() => {
        //         this.setState({display: false});
        //     }, 300);
        // }
        // if(!this.state.display && nextProps.dialog.warningDialogDisplay){
        //     console.log('WarningDialog OPEN!!!!!')
        //     this.setState({display: true});
        // }

    }


    render() {
        const { locale } = this.props;
        const { currentLocaleJSON: loc } = locale;

        return (
            <Dialog
                open={this.props.dialog.warningDialogDisplay}
                // open={this.state.display}
                // onClose={this.closeDialog}
                // aria-labelledby="alert-dialog-title"
                // aria-describedby="alert-dialog-description"
                scroll={'body'}
                onExited={this.closeDialog}
            >
                {
                    this.props.dialog.warningDialogIsDelete?
                        <div className="dialog-window">
                            <p>{this.props.dialog.warningDialogText? this.props.dialog.warningDialogText : loc.Global_WarningPopup_AreYouSure}</p>
                            <div className="btn-block">
                                <Button padding col={4} color={'primary'} onClick={this.okAction}>{loc.Button_Continue}</Button>
                                <Button padding col={4} onClick={this.closeDialog}>{loc.Button_Cancel}</Button>
                            </div>  
                            
                        </div>
                    :
                    <div className="warning-dialog-window">
                        <span className="swal2-warning">
                            <span className="swal2-icon-text">!</span>
                        </span>
                        <p className={`default ${this.props.dialog.warningDialogIsSmart? 'smart': ''}`}>{this.props.dialog.warningDialogText? this.props.dialog.warningDialogText : loc.Global_WarningPopup_AreYouSure}</p>
                        <div className="btn-line">
                            {
                                this.props.dialog.warningDialogIsSmart?
                                    <Button col={2} color="primary" onClick={this.closeDialog} padding>
                                        {loc.Button_OK}
                                    </Button>
                                :
                                <>
                                    <Button col={4} color="primary" onClick={this.okAction} padding>
                                        {loc.Button_Continue}
                                    </Button>
                                    <Button col={4} color="grey" onClick={this.closeDialog} padding>
                                        {loc.Button_Cancel}
                                    </Button>
                                </>
                            }
                        </div>
                    </div>
                }
                
            </Dialog>
        )
    }
}



const mapStateToProps = (store: IAppState) => ({
    user: store.user,
    locale: store.locale,
    loc: store.locale.currentLocaleJSON,
    dialog: store.dialog
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
    closeDialog: () => dispatch(closeWarningDialog())
});

export default connect(mapStateToProps, mapDispatchToProps)(WarningDialog)
