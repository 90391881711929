import React, { Component } from 'react'
import { connect } from 'react-redux'
import { IAppState } from 'store';
import { ILocaleState, IDictionary } from 'store/locale/types';
import menuConfig from './menuConfig.json';
import profileMenuConfig from './profileMenuConfig.json';
import { Link } from 'react-router-dom';
import { Icon, Collapse } from '@material-ui/core';
import { checkAccess } from 'services';
import "./SidebarMenu.scss";
import { IUserState } from 'store/user/types.js';
import img from 'assets/images/anonymous.png';

interface IProps {
    locale: ILocaleState,
    loc: IDictionary,
    user: IUserState
}
interface IState {
    activeMenu: string;
    submenuOpen: {[key: string]: boolean}
}

export class SidebarMenu extends Component<IProps> {
    state: IState = {
        activeMenu: '',
        submenuOpen: {
            'sidebarProfile': false
        }
    }

    componentWillMount() {
        let submenuOpen: {[key: string]: boolean} = {};
        menuConfig.forEach( (menuItem, menuNumber) => {
            if(menuItem.submenu)
                submenuOpen[menuItem.label] = false;
        });
        this.setState({submenuOpen})
    }


    openSubmenu = (label: string) => {
        this.setState((prevState: IState) => ({
            submenuOpen: {
                ...prevState.submenuOpen,
                [label] : !prevState.submenuOpen[label]
            }
        }))
    }

    getLetterIconString = (label: string) => label? label.substr(0, 1).toUpperCase() : '';

    render() {
        const { loc, user } = this.props;
        const { userInfo } = user;
        const { company, fio } = userInfo;
        return (
            <div className='sidebar'>
                <div key={'sidebarProfile'} onClick={() => {this.openSubmenu('sidebarProfile')}} className={`avatar-bar`}>
                    <div className="avatar-wrapper">
                        <div className="avatar" style={{backgroundImage: `url(${img})`}}></div>
                        <div className="profile-info">
                            <p className='profile-name'>{fio}</p>
                            <span className='company-name'>{company && company.name? company.name : '' }</span>
                        </div>
                    </div>
                    <Icon className={`dropdown-carret${this.state.submenuOpen.sidebarProfile? ' open' : ''}`}>arrow_drop_down</Icon>
                </div>
                <div className="submenu">
                    <Collapse in={this.state.submenuOpen.sidebarProfile}>
                        {profileMenuConfig.map((profileMenuItem, profileMenuNumber) => 
                            <Link key={`${profileMenuItem.label}_${profileMenuNumber}`} className="menu-item" to={profileMenuItem.link}>
                                <span className='letter-icon'>{this.getLetterIconString(loc[profileMenuItem.label])}</span>
                                <p>{loc[profileMenuItem.label]}</p>
                            </Link>)
                        
                        }
                    </Collapse>
                </div>
                <div className="divider"></div>
                <div className="menu">
                    {
                        menuConfig.map( (menuItem, menuNumber) => 
                        
                            checkAccess(menuItem.rolesWithAccess, this.props.user.userInfo.authorities)?
                                menuItem.submenu?
                                    <React.Fragment key={`${menuItem.label}_${menuNumber}`}>
                                        <div onClick={() => {this.openSubmenu(menuItem.label)}} className={`menu-item`}>
                                            <Icon>{menuItem.icon}</Icon>
                                            <p>{loc[menuItem.label]}</p>
                                            <Icon className={`dropdown-carret${this.state.submenuOpen[menuItem.label]? ' open' : ''}`}>arrow_drop_down</Icon>
                                        </div>
                                        <div className="submenu">
                                            <Collapse in={this.state.submenuOpen[menuItem.label]}>
                                                {menuItem.submenu.map((submenuItem, submenuNumber) => 
                                                    checkAccess(submenuItem.rolesWithAccess, this.props.user.userInfo.authorities)?
                                                        <Link onClick={() => {this.setState({activeMenu: submenuItem.label})}} className={`menu-item${this.state.activeMenu === submenuItem.label? ' active': ''}`}  key={`${submenuItem.label}_${menuNumber}_1_${submenuNumber}`} to={submenuItem.link}>
                                                            <Icon></Icon>
                                                            <p>{loc[submenuItem.label]}</p>
                                                        </Link>
                                                    :
                                                        null
                                                )}
                                            </Collapse>
                                        </div>
                                    </React.Fragment>
                                :
                                    <Link onClick={() => {this.setState({activeMenu: menuItem.label})}} key={`${menuItem.label}_${menuNumber}`} className={`menu-item${this.state.activeMenu === menuItem.label? ' active': ''}`} to={menuItem.link}>
                                        <Icon>{menuItem.icon}</Icon>
                                        <p>{loc[menuItem.label]}</p>
                                    </Link>
                            :
                                null
                        )
                    
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: IAppState) => ({
    locale: state.locale,
    loc: state.locale.currentLocaleJSON,
    user: state.user
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu)
