import React, { Component } from 'react';
import { BrowserRouter, HashRouter, Router, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { AuthScreen } from 'pages/AuthScreen';
import { PageTemplate } from 'components/PageTemplate';
import DashboardScreen from 'pages/DashboardScreen';
import ResetPasswordScreen from 'pages/ResetPasswordSreen';
import { IAppState } from 'store';
import { IUserState } from 'store/user/types';
import PrivateRoute from './PrivateRoute';
import CreatePasswordScreen from 'pages/CreatePasswordScreen';
import SuccessDialog from 'components/SuccessDialog/SuccessDialog';
import ErrorDialog from 'components/ErrorDialog';
import WarningDialog from 'components/WarningDialog';
// import AuthScreen from ""


interface Props {
    user: IUserState
}


interface State {
    
}


export class GlobalRouter extends Component<Props, State> {
    state = {}

    render() {
        return (
            <BrowserRouter
                basename={'/'}
                forceRefresh={false}
                getUserConfirmation={() => {}}
                
                // keyLength={optionalNumber}
            >
                <div className="global-wrapper">
                    <Route exact  path="/" component={(AuthScreen)}/>
                    {   
                    }

                    <Route exact path="/password/email" component={(ResetPasswordScreen)}/>
                    <Route exact path="/password/create/:token" component={(CreatePasswordScreen)}/>
                    <Route exact path="/password/reset/:token" component={(CreatePasswordScreen)}/>
                    <PrivateRoute path="/dashboard" render={props => <DashboardScreen {...props} />} />
                </div>
                <SuccessDialog/>
                <ErrorDialog/>
                <WarningDialog/>
            </BrowserRouter>
        )
    }
}

const mapStateToProps = (state: IAppState) => ({
    user: state.user
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalRouter)
