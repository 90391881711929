import React, { Component } from 'react'
import { connect } from 'react-redux'
import { IAppState } from 'store';

import "./DashboardScreen.scss";
import SidebarMenu from 'components/SidebarMenu';
import { IconButton, Icon, Grow, Paper, ClickAwayListener, Popper } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { PageTemplate } from 'components/PageTemplate';
import StaffScreen from 'pages/StaffScreen';
import { Switch, Route, Link } from 'react-router-dom';
import PrivateRoute from 'router/PrivateRoute';
import CompanyBlock from 'pages/CompanyBlock';
import UserBlock from 'pages/UserBlock';
import CompanyScreen  from 'pages/CompanyScreen';
import NotificationScreen  from 'pages/NotificationScreen';
import UserScreen from 'pages/UserScreen';
import WarehouseScreen from 'pages/WarehouseScreen';
import VehicleScreen from 'pages/VehicleScreen';
import TariffScreen from 'pages/TariffScreen';
import ChangePasswordScreen from 'pages/ChangePasswordScreen';
import AnaliticScreen from 'pages/AnaliticScreen';
import MapScreen from 'pages/MapScreen/MapScreen';
import RouteScreen from 'pages/RouteScreen/RouteScreen';
import RouteBlock from 'pages/RouteBlock/RouteBlock';
import OrderScreen from 'pages/OrderScreen/OrderScreen';
import { ISearchParams } from 'store/types';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { readCurrentNotification, readAllNotifications, getUnreadNotificationList } from 'store/notification/actions';
import { IGetUnreadNotificationListSuccessAction, IGetUnreadNotificationListFailureAction, IReadCurrentNotificationSuccessAction, IReadAllNotificationsFailureAction, IReadAllNotificationsSuccessAction, IReadCurrentNotificationFailureAction, INotificationState } from 'store/notification/types';
import { IDictionary } from 'store/locale/types';
import OrderBlock from 'pages/OrderBlock/OrderBlock';
import ActBlock from 'pages/ActBlock';
import { IUserState } from 'store/user/types';

interface IProps {
    history: any;
    loc: IDictionary;
    user: IUserState;
    notification: INotificationState;
    getUnreadNotificationList: (params: ISearchParams) => Promise<IGetUnreadNotificationListSuccessAction|IGetUnreadNotificationListFailureAction>;
    readCurrentNotification: (id: string) => Promise<IReadCurrentNotificationSuccessAction|IReadCurrentNotificationFailureAction>;
    readAllNotifications: () => Promise<IReadAllNotificationsSuccessAction|IReadAllNotificationsFailureAction>;

}
interface IState {
    notificationListOpen: boolean;
}

export class DashboardScreen extends Component<IProps> {

    private myRef = React.createRef<HTMLDivElement>()

    state: IState = {
        notificationListOpen: false,

    }

    componentDidMount = () => {
        this.props.getUnreadNotificationList({page: 0, size: 100, q:{'userId|full': this.props.user.userInfo.id, 'read|full':'false'}, sort: "read,asc&sort=createdDate,desc"});
    }

    static getDerivedStateFromProps = (nextProps: IProps) => {
        // console.log(nextProps.history.location.pathname);
        if(nextProps.history.location.pathname === "/dashboard/notifications"){
            return ({notificationListOpen: false});
        }
        return null;
    }

    readCompactNotifiction = (id: string) => {
        this.props.readCurrentNotification(id)
            .then(data => {
                if(data.type === 'READ_CURRENT_NOTIFICATION_SUCCESS'){
                    this.props.getUnreadNotificationList({page: 0, size: 100, q:{'userId|full': this.props.user.userInfo.id, 'read|full':'false'}, sort: "read,asc&sort=createdDate,desc"});
                }
            });
    }

    openNotificationsList = () => {
        // console.log('####OEN COMPACT ###')
        this.setState( (prevState: IState) => ({notificationListOpen: !prevState.notificationListOpen}))
    }

    closeNotificationsList = (event: any) => {
        if (this.myRef.current && this.myRef.current.contains(event.target)) {
            return;
        }
        this.setState({
            notificationListOpen: false
        });
    }

    render() {
        const { loc } = this.props;
        return (
            <div className="dashboard-screen">
                <SidebarMenu/>
                <div className="dashboard">
                    <div className="dashboard-header">
                        <IconButton onClick={() => {this.props.history.goBack()}} aria-label="delete" className={'back-btn'}>

                            <Icon>arrow_back</Icon>
                        </IconButton>
                        <div ref={this.myRef} onClick={this.openNotificationsList} className="notification-btn">
                            {this.props.notification.listUnread.length? <span className="count">{this.props.notification.listUnread.length}</span> : null}
                            <Icon>notifications</Icon>
                        </div>
                        <Popper open={this.state.notificationListOpen} anchorEl={this.myRef.current} placement="bottom-end" transition disablePortal>
                            {({ TransitionProps, placement }) => (
                                <Grow
                                {...TransitionProps}
                                // style={{
                                //     transformOrigin: 'right top',
                                // }}
                                // timeout={350}
                                // in={this.state.notificationListOpen}
                                style={{ transformOrigin: '0 0 0' }}
                                {...(this.state.notificationListOpen ? { timeout: 1000 } : {})}
                                >
                                <ClickAwayListener onClickAway={this.closeNotificationsList}>
                                    <Paper elevation={4} className={'notification-list-wrapper'}>
                                        <div className="notification-compant">
                                            {
                                                this.props.notification.listUnread.slice(0, 5).map((notifEl, number) =>
                                                    <div key={`${notifEl.orderNumber}_${notifEl.id}_${number}`} className="notification-compant-item">
                                                        <Icon>
                                                            calendar_today
                                                        </Icon>
                                                        <p>{`${loc.NotificationCompact_Order} №${notifEl.orderNumber} ${loc[`Notification_Action_${notifEl.action}`]}`}</p>
                                                        <button onClick={() => {this.readCompactNotifiction(notifEl.id)}} className='read-compact'>
                                                            <Icon>
                                                                close
                                                            </Icon>
                                                        </button>

                                                    </div>
                                                )
                                            }

                                        </div>
                                        <div className="see-all">
                                            <Link to="/dashboard/notifications">{loc.NotificationCompact_SeeAll}</Link>
                                        </div>
                                        {/* <svg className={'svf'}>
                                        <polygon points="0,100 50,00, 100,100" />
                                        </svg> */}
                                    </Paper>
                                </ClickAwayListener>
                            </Grow>)}
                        </Popper>
                    </div>
                    <Switch>
                        <PrivateRoute exact path="/dashboard/staff" roles={['ROLE_TC_MANAGER']} render={props => <StaffScreen {...props}/>}/>
                        <PrivateRoute exact path="/dashboard/onway" roles={['ROLE_MANAGER_LOGIST', 'ROLE_LOGIST', 'ROLE_TC_MANAGER']} render={props => <MapScreen {...props}/>}/>

                        <PrivateRoute exact path="/dashboard/order/active" roles={['ROLE_MANAGER_LOGIST', 'ROLE_LOGIST', 'ROLE_TC_MANAGER']} render={props => <OrderScreen active {...props}/>}/>
                        <PrivateRoute exact path="/dashboard/order/acts" roles={['ROLE_MANAGER_LOGIST', 'ROLE_LOGIST', 'ROLE_TC_MANAGER']} render={props => <OrderScreen acts {...props}/>}/>
                        <PrivateRoute exact path="/dashboard/order/archive" roles={['ROLE_MANAGER_LOGIST', 'ROLE_LOGIST', 'ROLE_TC_MANAGER']} render={props => <OrderScreen archive {...props}/>}/>
                        <PrivateRoute exact path="/dashboard/order/input" roles={['ROLE_WAREHOUSE_MANAGER']} render={props => <OrderScreen input {...props}/>}/>
                        <PrivateRoute exact path="/dashboard/order/output" roles={['ROLE_WAREHOUSE_MANAGER']} render={props => <OrderScreen output {...props}/>}/>

                        <PrivateRoute exact path="/dashboard/act/create" roles={['ROLE_TC_MANAGER']} render={props => <ActBlock create {...props}/>}/>
                        <PrivateRoute exact path="/dashboard/act/edit/:id" roles={['ROLE_MANAGER_LOGIST', 'ROLE_LOGIST', 'ROLE_TC_MANAGER']} render={props => <ActBlock edit {...props}/>}/>

                        <PrivateRoute exact path="/dashboard/order/create/:type" roles={['ROLE_MANAGER_LOGIST', 'ROLE_LOGIST']} render={props => <OrderBlock create {...props}/>}/>
                        <PrivateRoute exact path="/dashboard/order/edit/:id/:token?" roles={['ROLE_MANAGER_LOGIST', 'ROLE_LOGIST', 'ROLE_TC_MANAGER']} render={props => <OrderBlock edit {...props}/>}/>
                        <PrivateRoute exact path="/dashboard/order/view/:id/:token?" roles={['ROLE_MANAGER_LOGIST', 'ROLE_LOGIST', 'ROLE_TC_MANAGER']} render={props => <OrderBlock view {...props}/>}/>

                        <PrivateRoute exact path="/dashboard/vehicles" roles={['ROLE_TC_MANAGER']} render={props => <VehicleScreen {...props}/>}/>
                        <PrivateRoute exact path="/dashboard/rates" roles={['ROLE_MANAGER_LOGIST','ROLE_TC_MANAGER']} render={props => <TariffScreen {...props}/>}/>
                        <PrivateRoute exact path="/dashboard/analitics" roles={['ROLE_MANAGER_LOGIST','ROLE_TC_MANAGER']} render={props => <AnaliticScreen {...props}/>}/>
                        <PrivateRoute exact path="/dashboard/routes" roles={['ROLE_MANAGER_LOGIST','ROLE_TC_MANAGER']} render={props => <RouteScreen {...props}/>}/>
                        <PrivateRoute exact path="/dashboard/route/create" roles={['ROLE_MANAGER_LOGIST','ROLE_TC_MANAGER']} render={props => <RouteBlock create {...props}/>}/>
                        <PrivateRoute exact path="/dashboard/route/edit/:id" roles={['ROLE_MANAGER_LOGIST','ROLE_TC_MANAGER']} render={props => <RouteBlock edit {...props}/>}/>
                        <PrivateRoute exact path="/dashboard/mycompany" roles={['ROLE_MANAGER_LOGIST', 'ROLE_LOGIST', 'ROLE_TC_MANAGER', 'ROLE_WAREHOUSE_MANAGER']} render={props => <CompanyBlock view {...props}/>}/>
                        <PrivateRoute exact path="/dashboard/password/change" roles={['ROLE_MANAGER_LOGIST', 'ROLE_LOGIST', 'ROLE_TC_MANAGER']} render={props => <ChangePasswordScreen {...props}/>}/>
                        <PrivateRoute exact path="/dashboard/company/edit/:id" roles={['ROLE_MANAGER_LOGIST']} render={props => <CompanyBlock edit {...props}/>}/>
                        <PrivateRoute exact path="/dashboard/company/create" roles={['ROLE_MANAGER_LOGIST']} render={props => <CompanyBlock create {...props}/>}/>
                        <PrivateRoute exact path="/dashboard/myprofile" roles={['ROLE_MANAGER_LOGIST', 'ROLE_LOGIST', 'ROLE_TC_MANAGER', 'ROLE_WAREHOUSE_MANAGER']} render={props => <UserBlock view {...props}/>}/>
                        <PrivateRoute exact path="/dashboard/user/edit/:id" roles={['ROLE_MANAGER_LOGIST']} render={props => <UserBlock edit {...props}/>}/>
                        <PrivateRoute exact path="/dashboard/user/create" roles={['ROLE_MANAGER_LOGIST']} render={props => <UserBlock create {...props}/>}/>
                        <PrivateRoute exact path="/dashboard/administration/companies" roles={['ROLE_MANAGER_LOGIST']} render={props => <CompanyScreen {...props}/>}/>
                        <PrivateRoute exact path="/dashboard/administration/users" roles={['ROLE_MANAGER_LOGIST']} render={props => <UserScreen {...props}/>}/>
                        <PrivateRoute exact path="/dashboard/administration/objects" roles={['ROLE_MANAGER_LOGIST']} render={props => <WarehouseScreen {...props}/>}/>
                        <PrivateRoute exact path="/dashboard/administration/staff" roles={['ROLE_MANAGER_LOGIST']} render={props => <StaffScreen administration {...props}/>}/>
                        <PrivateRoute exact path="/dashboard/administration/vehicles" roles={['ROLE_MANAGER_LOGIST']} render={props => <VehicleScreen administration {...props}/>}/>
                        <PrivateRoute exact path="/dashboard/notifications" roles={['ROLE_MANAGER_LOGIST', 'ROLE_LOGIST', 'ROLE_TC_MANAGER', 'ROLE_WAREHOUSE_MANAGER']} render={props => <NotificationScreen {...props}/>}/>
                        {/* <Route exact path="/dashboard/staff" component={StaffScreen}/> */}
                    </Switch>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: IAppState) => ({
    notification: state.notification,
    loc: state.locale.currentLocaleJSON,
    user: state.user
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
    getUnreadNotificationList: (params: ISearchParams) => dispatch(getUnreadNotificationList(params)),
    readCurrentNotification: (id: string) => dispatch(readCurrentNotification(id)),
    readAllNotifications: () => dispatch(readAllNotifications())
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardScreen)
