import './CompanyScreen.scss';
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { ILocaleState, IInitLocalesSuccessAction, IInitLocalesFailureAction, IDictionary,  } from 'store/locale/types';
import { IAppState } from 'store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { initLocales } from 'store/locale/actions';
import config from 'locales/config.json';
import { getUserInfo } from 'store/user/actions';
import { IGetUserInfoSuccessAction, IGetUserInfoFailureAction } from 'store/user/types';
import { SS_AUTH_TOKEN, ISearchParams } from 'store/types';
import PageTemplate from 'components/PageTemplate';
import { IGetCompanyListSuccessAction, IGetCompanyListFailureAction, ICreateCompanySuccessAction, ICreateCompanyFailureAction, ICompanyState, IDeleteCompanySuccessAction, IDeleteCompanyFailureAction, ICompanyNameModel, ICompanyModel } from 'store/company/types';
import { getCompanyList, createCompany, deleteCompany } from 'store/company/actions';
import { Dialog, Icon } from '@material-ui/core';
import { Button } from 'components/Button';
import InputField from 'components/InputField';
import { getNewSort } from 'services';
import { withPageBase, IWithPageBase } from 'hoc/withPageBase/withPageBase';



interface IProps extends IWithPageBase {
    locale: ILocaleState;
    loc: IDictionary;
    company: ICompanyState;
    history: any;
    // initLocales: () => Promise<IInitLocalesSuccessAction | IInitLocalesFailureAction>;
    // getUserInfo: () => Promise<IGetUserInfoSuccessAction | IGetUserInfoFailureAction>;
    getCompanyList: (params: ISearchParams) => Promise<IGetCompanyListSuccessAction|IGetCompanyListFailureAction>;
    deleteCompany: (id: string) => Promise<IDeleteCompanySuccessAction|IDeleteCompanyFailureAction>;
}

interface IState {
    page: number;
    q: any;
    sort: string;
    deleteDialogOpen: boolean;
    deleteRow: any | null;
    filters: {[key: string]: string};//{field: string, value: string}[];//{[key: string]: string};
}

export class CompanyScreen extends Component<IProps> {
    handleChangeFilter = this.props.handleChangeFilter.bind(this);
    handleChangeCompanyFilter = this.props.handleChangeCompanyFilter.bind(this);
    handleChangeCityFilter = this.props.handleChangeCityFilter.bind(this);

    state: IState = {
        q: {

        },
        page: 0,
        sort: '',
        deleteDialogOpen: false,
        deleteRow: null,
        filters: {}
    }

    componentWillMount = () => {
        this.props.getCompanyList({page: this.state.page});
    }

    changePageNumber = (page: number) => {
        this.props.getCompanyList({page, q: {...this.state.filters}, sort: this.state.sort})
            .then(data => {
                this.setState({page});
            });
    }

    // handleDeleteDialog = (row: any) => {
    //     this.setState({deleteDialogOpen: true, deleteRow: {...row}});
    // }
    handleDeleteDialog = (row: any) => {
        const { loc } = this.props;
        this.setState({
            deleteRow: {...row}
        },
        () => {
            this.props.handleDeleteItem(
                `${loc.CompanyScreen_TryToDelete} ${this.state.deleteRow !== null && this.state.deleteRow.shortName? this.state.deleteRow.shortName: "" }`,
                () => {
                    this.deleteRecord();
                }
            );
        });
    }

    deleteRecord = () => {
        this.props.deleteCompany(this.state.deleteRow !== null && this.state.deleteRow.id? this.state.deleteRow.id: "")
            .then(dataDelete => {
                if(dataDelete.type === 'DELETE_COMPANY_SUCCESS'){
                    this.props.openSuccessDialog();
                    this.props.getCompanyList({page: this.state.page, q: {...this.state.filters}, sort: this.state.sort});
                } else {
                    this.props.openErrorDialog({});
                }
                
                // this.handleCloseDeleteDialog();
            })
    }

    handleEdit = (row: any) => {
        this.props.history.push(`/dashboard/company/edit/${row.id}`)
    }

    handleAdd = () => {
        this.props.history.push(`/dashboard/company/create`)
    }

    handleSort = (sortString: string) => {
        const newSort = getNewSort(sortString, this.state);
        this.props.getCompanyList({page: this.state.page, q: {...this.state.filters}, sort: newSort});
        this.setState({sort: newSort});
        
    }

    // handleCloseDeleteDialog = () => {
    //     this.setState({deleteDialogOpen: false});
    // }

    handleSearch = () => {
        this.props.getCompanyList({page: 0, q: {...this.state.filters}, sort: this.state.sort})
            .then(() => {
                this.setState({page: 0});
            });
    }

    handleResetSearch = () => {
        this.props.getCompanyList({page: 0, q: {}, sort: this.state.sort})
            .then(() => {
                this.setState((prevState: IState) => ({
                    page: 0,
                    filters: {}
                }));
            });
        
    }

    // handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     const Event = event;
    //     const field = Event.target.name//.split('|');
    //     const value = Event.target.type == 'number'? +Event.target.value : Event.target.value;
    //     // console.log('filter value',value)
    //     this.setState((prevState: IState) => ({
    //         filters: {
    //             ...prevState.filters,
    //             [field]: value
    //         }
    //     }))
    // }

    // handleChangeCompanyFilter = (resultObject: ICompanyModel) => {
    //     // console.log(resultObject)
    //     this.setState((prevState: IState) => ({
    //         filters: {
    //             ...prevState.filters,
    //             'shortName|full': resultObject.shortName
    //         }
    //     }))
    // }

    render() {
        const { loc } = this.props;
        return (
            <>
                <PageTemplate 
                    pageTitle={loc.CompanyScreen_PageTitle} 
                    pageIcon={'monetization_on'}
                    edit={true}
                    delete={true}
                    import
                    export
                    print
                    entity={'COMPANY'}
                    tableHeaders={[loc.CompanyScreen_CodeColumn, loc.CompanyScreen_NameColumn, loc.CompanyScreen_TypeColumn, loc.CompanyScreen_ActNumberColumn, loc.CompanyScreen_TimeColumn]}
                    listData={this.props.company.list}
                    dataModel={['sap','shortName', 'type|type', 'contractNumber', 'contractDate|date']}
                    isLoading={this.props.company.isFetching}
                    pageble={this.props.company.pageble? this.props.company.pageble : {
                        currentPage: 0,
                        totalElements: 0,
                        totalPages: 0
                    }}
                    onChangePage={this.changePageNumber}
                    onDelete={this.handleDeleteDialog}
                    onEdit={this.handleEdit}
                    onSearch={this.handleSearch}
                    onResetSearch={this.handleResetSearch}
                    onSort={this.handleSort}
                    actualSort={this.state.sort}
                    typesDictionary={{
                        "type": {
                            "COMPANY": loc.Company_CompanyType_COMPANY,
                            "TRANSPORT_COMPANY": loc.Company_CompanyType_TRANSPORT_COMPANY
                        }
                    }}
                >
                    <InputField padding={true} placeholder={loc.CompanyScreen_CodeFilter} label={loc.CompanyScreen_CodeFilter} type="text" onChange={this.handleChangeFilter} value={this.state.filters['sap|regex']? this.state.filters['sap|regex']: ""} name='sap|regex'/>
                    <InputField padding={true} placeholder={loc.CompanyScreen_NameFilter} label={loc.CompanyScreen_NameFilter} type="company" onSelect={this.handleChangeCompanyFilter} onChange={this.handleChangeFilter} value={this.state.filters['shortName|full']? this.state.filters['shortName|full']: ""} name='shortName|full'/>
                    <InputField padding={true} placeholder={loc.CompanyScreen_TypeFilter} type="select" onChange={this.handleChangeFilter} name="type|full" value={this.state.filters['type|full']? this.state.filters['type|full']: ""} label={loc.CompanyScreen_TypeFilter}
                            options={[
                                    {
                                        value: 'COMPANY',
                                        label: loc.Company_CompanyType_COMPANY,
                                    },
                                    {
                                        value: 'TRANSPORT_COMPANY',
                                        label: loc.Company_CompanyType_TRANSPORT_COMPANY,
                                    }
                                ]}/>
                    <InputField padding={true} placeholder={loc.CompanyScreen_ActNumberFilter} label={loc.CompanyScreen_ActNumberFilter} type="text" onChange={this.handleChangeFilter} value={this.state.filters['contractNumber|regex']? `${this.state.filters['contractNumber|regex']}`: ""} name='contractNumber|regex'/>
                </PageTemplate>
                {/* <Dialog
                    open={this.state.deleteDialogOpen}
                    onClose={this.handleCloseDeleteDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div className="dialog-window">
                        <p>{`${loc.CompanyScreen_TryToDelete} ${this.state.deleteRow !== null && this.state.deleteRow.shortName? this.state.deleteRow.shortName: "" }`}</p>
                        <div className="btn-block">
                            <Button padding col={4} color={'primary'} onClick={() => {this.deleteRecord()}}>{loc.Button_Continue}</Button>
                            <Button padding col={4} onClick={() => {this.handleCloseDeleteDialog()}}>{loc.Button_Cancel}</Button>
                        </div>  
                        
                    </div>
                </Dialog> */}
                <button onClick={() => {this.handleAdd()}} className="add-button">
                    <Icon>add</Icon>
                </button>
            </>
        )
    }
}

const mapStateToProps = (state: IAppState) => ({
    locale: state.locale,
    company: state.company,
    loc: state.locale.currentLocaleJSON
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
    // initLocales: () => dispatch(initLocales(config)),
    // getUserInfo: () => dispatch(getUserInfo())
    getCompanyList: (params: ISearchParams) => dispatch(getCompanyList(params)),
    deleteCompany: (id: string) => dispatch(deleteCompany(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(withPageBase(CompanyScreen));
