import { Interface } from "readline";

export const SS_AUTH_TOKEN = 'authToken';
export const SS_USER_INFO = 'userInfo';
export const SS_ROLES = 'userRoles';
export const SS_SESSION_ID = 'sessionId';

export const LS_AUTH_TOKEN = 'authToken';
export const LS_USER_INFO = 'userInfo';
export const LS_ROLES = 'userRoles';
export const LS_SESSION_ID = 'sessionId';


/**
 * Appends REQUEST asyc action type
 */

export const REQUEST = (actionType:string) => `${actionType}_PENDING`;

/**
 * Appends SUCCESS asyc action type
 */

export const SUCCESS = (actionType:string) => `${actionType}_FULFILLED`;

/**
 * Appends FAILURE asyc action type
 */

export const FAILURE = (actionType:string) => `${actionType}_REJECTED`;

export const DEFAULT_PAGE_NUMBER = 0;

export const DEFAULT_PAGE_SIZE = 10;

export interface ISearchParams {
    page?: number;
    size?: number;
    sort?: string;
    q?: any;
    additional?: {[key: string]: string}
}

export interface IDecodedToken{
    auth: string;
    sub: string;
    exp: number;
}

export type ResponseStatusType  = 'ERROR' | 'OK';

export interface IErrorResponse {
    error?: string,
    fieldErrors?: {
        field: string,
        message: string,
        objectName: string,
        property: string,
        value: {}
    }[],
}

export interface IGetUnicListServerResponse<T> extends IErrorResponse{
    data: T[] | [];
    status: ResponseStatusType;
}

export interface IGetListServerResponse<T> extends IErrorResponse{
    data: {
        content: T[] | [],
        empty: boolean;
        first: boolean;
        last: boolean;
        number: number;
        numberOfElements: number;
        pageable: {
            offset: number;
            pageNumber: number;
            pageSize: number;
            paged: boolean;
            sort: {
                empty: boolean;
                sorted: boolean;
                unsorted: boolean;
            },
            unpaged: boolean;
        };
        size: number;
        sort: {
            empty: boolean;
            sorted: boolean;
            unsorted: boolean;
        };
        totalElements: number;
        totalPages: number;
    };
    status: ResponseStatusType;
}

export interface IGetDataListServerResponse<T> extends IErrorResponse{
    data: T[] | [];
    status: ResponseStatusType;
}

export interface IGetByIdServerResponse<T> extends IErrorResponse{
    data: T;
    status: ResponseStatusType;
}

export interface IDefaultServerResponse extends IErrorResponse{
    data: {} | any;
    status: ResponseStatusType;
}

export interface IErrorServerResponse extends IErrorResponse{
    status: ResponseStatusType;
}

export interface IGetUnicListPayload<Model> {
    list: Model[] | [];
}

export interface IGetListPayload<Model> {
    list: Model[] | [];
    searchParams: ISearchParams;
    pageble: IPagable;
}


export interface IGetByIdPayload<Model> {
    record: Model;
    id: string;
}

export interface IErrorPayload {
    error: string;
    fieldErrors: IFieldErrors[];
}

export interface IFieldErrors { 
    objectName: string;
    field: string;
    message: string;
}

export interface IDefaultStateWithoutPageble {
    readonly errorInfo?: IError;
    readonly errorFieldsInfo?: IErrorFieldsInfo;
    readonly isFetching: boolean;
}

export interface IDefaultState {
    readonly pageble?: IPagable;
    readonly errorInfo?: IError;
    readonly errorFieldsInfo?: IErrorFieldsInfo;
    readonly isFetching: boolean;
    readonly required?: string[];
}

export interface IPagable {
    currentPage: number;
    totalElements: number;
    totalPages: number;
}

export interface IErrorFieldsInfo{
    [key: string]: string;
}

export interface IError {
    hasError: boolean;
    error: string;
    fieldErrors: IFieldErrors[] | [];
}