import { REQUEST, SUCCESS, FAILURE, IErrorPayload } from "../types";

import { INotificationState, NotificationActions } from "./types";

import { Reducer } from "redux";

const initialUserState: INotificationState = {
  isFetching: false,
  list: [],
  listUnread: [
    {
      action: "NEW_COMMENT",
      company: {
        id: "",
        name: ""
      },
      createdDate: 1568749926076,
      id: "",
      orderNumber: 0,
      orderStatus: "FORMED",
      read: true,
      sender: "",
      userId: ""
    }
  ],
  searchParams: {}
};

export const notificationReducer: Reducer<
  INotificationState,
  NotificationActions
> = (state = initialUserState, action) => {
  const defaultStateWithFetchingTrue = {
    ...state,
    isFetching: true
  };

  const defaultStateWithFetchingFalse = {
    ...state,
    isFetching: false
  };

  const defaultErrorState = (action: { payload: IErrorPayload }) => ({
		...state,
		isFetching: false,
		errorInfo: {
			hasError: true,
			...action.payload
		},
	});
//   const defaultErrorState = (action: { payload: IErrorPayload }) => ({
//     ...state,
//     isFetching: false,
//     errorInfo: {
//       hasError: true,
//       ...action.payload
//     }
//   });

  switch (action.type) {
    case "GET_NOTIFICATION_LIST_PENDING": {
      return {
        ...state,
        isFetching: true
      };
    }
    case "GET_NOTIFICATION_LIST_SUCCESS": {
      // console.log(parseJwt(action.authData.data.token))
      return {
        ...state,
        searchParams: { ...action.payload.searchParams },
        list: [...action.payload.list],
        isFetching: false
      };
    }
    case "GET_NOTIFICATION_LIST_FAILURE": {
      return { ...defaultErrorState(action) };
    }

    case "GET_UNREAD_NOTIFICATION_LIST_PENDING": {
      return { ...defaultStateWithFetchingTrue };
    }
    case "GET_UNREAD_NOTIFICATION_LIST_SUCCESS": {
      // console.log(parseJwt(action.authData.data.token))
      return {
        ...state,
        // searchParams: {...action.payload.searchParams},
        listUnread: [...action.payload.list],
        isFetching: false
      };
    }
    case "GET_UNREAD_NOTIFICATION_LIST_FAILURE": {
      return { ...defaultErrorState(action) };
    }

    case "READ_CURRENT_NOTIFICATION_PENDING": {
      return { ...defaultStateWithFetchingTrue };
    }
    case "READ_CURRENT_NOTIFICATION_SUCCESS": {
      // console.log(parseJwt(action.authData.data.token))
      return { ...defaultStateWithFetchingFalse };
    }
    case "READ_CURRENT_NOTIFICATION_FAILURE": {
      return { ...defaultErrorState(action) };
    }

    case "READ_ALL_NOTIFICATIONS_PENDING": {
      return { ...defaultStateWithFetchingTrue };
    }
    case "READ_ALL_NOTIFICATIONS_SUCCESS": {
      // console.log(parseJwt(action.authData.data.token))
      return { ...defaultStateWithFetchingFalse };
    }
    case "READ_ALL_NOTIFICATIONS_FAILURE": {
      return { ...defaultErrorState(action) };
    }

    default:
      neverReached(action); // when a new action is created, this helps us remember to handle it in the reducer
  }
  return state;
};

// tslint:disable-next-line:no-empty
const neverReached = (never: never) => {};
