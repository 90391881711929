import React from 'react'
import { Icon, Grid, IconButton } from '@material-ui/core';
import "./PageHeader.scss";
import { Button } from 'components/Button/Button';

interface IProps {
    pageIcon: string;
    pageTitle: string;
    // className: string | null;
    printSection?: boolean;
    import?: boolean;
    export?: boolean;
    print?: boolean;
    importAction?: (fileList: FileList) => void;
    exportAction?: () => void;
    printAction?: () => void;
    titleBtnText?: string;
    titleBtnAction?: () => void;
    titleBtnIcon?: string;
    btnVisible?: boolean;
    rightContent?: JSX.Element;
}



export const PageHeader: React.FC<IProps> = props => {

    let fileInput = React.createRef<HTMLInputElement>()

    function handleImport() {
        const node = fileInput.current!;
        node.click()


        // props.importAction(file);
    }

    function handleExport() {
        if (props.exportAction)
            props.exportAction();
    }

    function handlePrint() {
        if (props.printAction)
            props.printAction();
    }

    function handleChange(selectorFiles: FileList | null) {
        if (props.importAction) {
            props.importAction(selectorFiles!)
        }
        // console.log(selectorFiles);
    }

    return (
        <Grid style={{ padding: '0 30px', marginBottom: '24px' }} container>
            <Grid item xs={9} >
                <div className="pmu-request-list-title">
                    <div className="title-icon">
                        <Icon>{props.pageIcon}</Icon>
                    </div>
                    <div className="title-body">{props.pageTitle}</div>
                    {props.children}
                </div>

                {/* <Paper className={classes.paper}>xs=12</Paper> */}
            </Grid>
            <input style={{ visibility: 'hidden', width: '0px' }} ref={fileInput} type="file" onChange={(e) => handleChange(e.target.files)} />

            <Grid container className='content-right' justify="flex-end" alignItems="center" item xs={3} >
                {props.rightContent ?
                    props.rightContent
                    :
                    null
                }

                {props.import ?
                    <IconButton onClick={() => { handleImport() }} aria-label="import" className="grey-btn">
                        <Icon>publish</Icon>
                    </IconButton>
                    :
                    null
                }
                {props.print ?
                    <IconButton onClick={() => { handlePrint() }} aria-label="print" className="grey-btn">
                        <Icon>print</Icon>
                    </IconButton>
                    :
                    null
                }
                {props.export ?
                    <IconButton onClick={() => { handleExport() }} aria-label="export" className="grey-btn upside-down">
                        <Icon>publish</Icon>
                    </IconButton>
                    :
                    null
                }
            </Grid>
        </Grid>
    )
}
