import React from 'react'
import { Icon, Button as Btn } from '@material-ui/core';
import "./Button.scss";

interface IProps {
    icon?: string;
    className?: string | null;
    // width: string;
    col?: number;
    color?: 'primary' | 'red' | 'grey' | 'green';
    padding?: boolean;
    disabled?: boolean;
    onClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
    small?: boolean;
}




export const Button: React.FC<IProps> = props => {
    const returnClassName = (): string => {
        let color = `${props.color? `${props.color}-btn-color`: 'grey-btn-color'}`;
        let className =  `${props.className? ` ${props.className}`: ``}`;
        let small = `${props.small? ` ${'small'}`: ``}`;
        return `${color}${className}${small}`;
    }


    return (
            <Btn disabled={props.disabled} onClick={props.onClick? props.onClick : () => {}} style={{margin: props.padding? "0 15px": "0px", width: `${props.col? props.padding? `calc(${(100 / 12 * props.col)}% - 30px)`: `${(100 / 12 * props.col)}%` : '100%'}`}} variant="contained" className={returnClassName()}>
                {props.children? props.children : ''}
            </Btn>
    )
}
