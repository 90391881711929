import './NotificationScreen.scss';
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { ILocaleState, IInitLocalesSuccessAction, IInitLocalesFailureAction, IDictionary,  } from 'store/locale/types';
import { IAppState } from 'store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { initLocales } from 'store/locale/actions';
import config from 'locales/config.json';
import { getUserInfo } from 'store/user/actions';
import { IGetUserInfoSuccessAction, IGetUserInfoFailureAction, IUserModel, IUserState } from 'store/user/types';
import { SS_AUTH_TOKEN, ISearchParams } from 'store/types';
import PageTemplate from 'components/PageTemplate';
import { 
    IGetNotificationListSuccessAction,
    IGetNotificationListFailureAction,
    IReadAllNotificationsSuccessAction,
    IReadAllNotificationsFailureAction,
    INotificationState,
    IReadCurrentNotificationSuccessAction,
    IReadCurrentNotificationFailureAction
} from 'store/notification/types';
import { 
    getNotificationList,
    readCurrentNotification,
    readAllNotifications } from 'store/notification/actions';
import { Dialog, Icon } from '@material-ui/core';
import { Button } from 'components/Button';
import InputField from 'components/InputField';
import { ICompanyNameModel, ICompanyModel } from 'store/company/types';
import { ICityModel } from 'store/city/types';
import moment from 'moment';
import { formatDate, getNewSort, getErrorsFromDictionary } from 'services';
import { IWithPageBase, withPageBase } from 'hoc/withPageBase/withPageBase';



interface IProps extends IWithPageBase {
    locale: ILocaleState;
    loc: IDictionary;
    notification: INotificationState;
    history: any;
    sort: string;
    user: IUserState;
    // initLocales: () => Promise<IInitLocalesSuccessAction | IInitLocalesFailureAction>;
    // getUserInfo: () => Promise<IGetUserInfoSuccessAction | IGetUserInfoFailureAction>;
    getNotificationList: (params: ISearchParams) => Promise<IGetNotificationListSuccessAction|IGetNotificationListFailureAction>;
    readCurrentNotification: (id: string) => Promise<IReadCurrentNotificationSuccessAction|IReadCurrentNotificationFailureAction>;
    readAllNotifications: () => Promise<IReadAllNotificationsSuccessAction|IReadAllNotificationsFailureAction>;
}

interface IState {
    page: number;
    q: any;
    readAllDialogOpen: boolean;
    row: any | null;
    sort: string;
    filters: {[key: string]: string | number}
}

class NotificationScreen extends Component<IProps> {
    state: IState = {
        q: '',
        page: 0,
        readAllDialogOpen: false,
        row: null,
        sort: "read,asc&sort=createdDate,desc",
        filters: {
            'userId|full': this.props.user.userInfo.id
        }
    }

    handleChangeFilter = this.props.handleChangeFilter.bind(this);
    handleChangeCompanyFilter = this.props.handleChangeCompanyFilter.bind(this);
    handleChangeCityFilter = this.props.handleChangeCityFilter.bind(this);
    handleChangePersonFilter = this.props.handleChangePersonFilter.bind(this);
    handleChangeDateFilter = this.props.handleChangeDateFilter.bind(this);

    componentDidMount = () => {
        this.props.getNotificationList({page: this.state.page, q: { ...this.state.filters }, sort: this.state.sort});
    }

    static getDerivedStateFromProps = (nextProps: IProps, prevState: IState) => {
        
        if(prevState.filters['createdDate|from'] && prevState.filters['createdDate|to']){
            if(moment(prevState.filters['createdDate|from']).isAfter(moment(prevState.filters['createdDate|to']))){
                return ({
                    filters: {
                        ...prevState.filters,
                        ['createdDate|to']: null,
                    }
                })
            }

        }
        return null;
    }

    changePageNumber = (page: number) => {
        this.props.getNotificationList({page, q: {...this.state.filters}, sort: this.state.sort})
            .then(data => {
                this.setState({page});
            });
    }

    readAllDialog = () => {
        this.setState({readAllDialogOpen: true});
    }


    handleView = (row: any) => {
        this.props.readCurrentNotification(row.id)
            .then(readData => {
                if(readData.type === 'READ_CURRENT_NOTIFICATION_SUCCESS'){
                    this.props.getNotificationList({page: this.state.page, q: {...this.state.filters}, sort: this.state.sort});
                } else {
                    this.props.openErrorDialog(
                        {
                            messages: getErrorsFromDictionary(this.props.locale.currentLocaleJSON, [readData.payload.error]) 
                        }
                    );
                }
                
            });
        // this.props.history.push(`/dashboard/Notification/edit/${row.id}`)
    }

    handleCloseReadAllDialog = () => {
        this.setState({readAllDialogOpen: false});
    }


    handleSearch = () => {
        this.props.getNotificationList({page: 0, q: {...this.state.filters}, sort: this.state.sort})
            .then(() => {
                this.setState({page: 0});
            });
    }


    handleResetSearch = () => {
        this.props.getNotificationList({page: 0, q: {}, sort: this.state.sort});
            this.setState((prevState: IState) => ({
                page: 0,
                filters: {}
            }))
    }


    handleSort = (sortString: string) => {
        const newSort = getNewSort(sortString, this.state);
        this.props.getNotificationList({page: this.state.page, q: {...this.state.filters}, sort: newSort});
        this.setState({sort: newSort});
        
    }

    // handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     const Event = event;
    //     const field = Event.target.name//.split('|');
    //     const value = Event.target.type == 'number'? +Event.target.value : Event.target.value;
    //     // console.log('filter value',value)
    //     this.setState((prevState: IState) => ({
    //         filters: {
    //             ...prevState.filters,
    //             [field]: value
    //         }
    //     }))
    // }


    // handleChangeCompanyFilter = (resultObject: ICompanyModel) => {
    //     // console.log(resultObject)
    //     this.setState((prevState: IState) => ({
    //         filters: {
    //             ...prevState.filters,
    //             'company.name|full': resultObject.shortName
    //         }
    //     }))
    // }

    // handleChangeCityFilter = (resultObject: ICityModel) => {
    //     // console.log(resultObject)
    //     this.setState((prevState: IState) => ({
    //         filters: {
    //             ...prevState.filters,
    //             'city.name|full': resultObject.name
    //         }
    //     }))
    // }

    // handleChangePersonFilter = (resultObject: IUserModel) => {
    //     // console.log(resultObject)
    //     this.setState((prevState: IState) => ({
    //         filters: {
    //             ...prevState.filters,
    //             'sender|full': resultObject.fio
    //         }
    //     }))
    // }

    // handleChangeDateFilter = (name: string, value: string | moment.Moment) => {

    //     this.setState((prevState: IState) => 
    //         ({
    //             filters: {
    //                 ...prevState.filters,
    //                 [name]: value.valueOf(),
    //             }
    //         })
    //     );

    // }
    readAllNotifications = () => {
        this.props.readAllNotifications()
            .then(readAllData => {
                if(readAllData.type === 'READ_ALL_NOTIFICATIONS_SUCCESS'){
                    this.props.openSuccessDialog();
                    this.props.getNotificationList({page: this.state.page, q: {...this.state.filters}, sort: this.state.sort});
                } else {
                    this.props.openErrorDialog(
                        {
                            messages: getErrorsFromDictionary(this.props.locale.currentLocaleJSON, [readAllData.payload.error]) 
                        }
                    );
                }
                this.handleCloseReadAllDialog()
            })
        
    }

    validSecondDate = (current: any) => {
        const selectedDate = this.state.filters['createdDate|from'] ? moment(this.state.filters['createdDate|from']) : moment(0) //Datetime.moment().subtract( 1, 'day' );
        
        return current.isAfter( selectedDate );
    }

    render() {
        const { loc } = this.props;
        return (
            <>
                <PageTemplate 
                    pageTitle={loc.NotificationScreen_PageTitle} 
                    pageIcon={'notifications'}
                    view={true}
                    viewTriggerKey={"read"}
                    tableHeaders={[
                        loc.NotificationScreen_ActNamberColumn,
                        loc.NotificationScreen_DateColumn,
                        loc.NotificationScreen_TimeColumn,
                        loc.NotificationScreen_FromColumn,
                        loc.NotificationScreen_CompanyColumn,
                        loc.NotificationScreen_ActStatusColumn,
                        loc.NotificationScreen_EventColumn
                    ]}
                    listData={this.props.notification.list}
                    // listData={
                    //     [
                    //         {
                    //             "action": "NEW_COMMENT",
                    //             "company": {
                    //                 "id": "companyId",
                    //                 "name": "companyName"
                    //             },
                    //             "createdDate": 1568749926076,
                    //             "id": "NotificationId",
                    //             "orderNumber": 67765,
                    //             "orderStatus": "FORMED",
                    //             "read": true,
                    //             "sender": "sender",
                    //             "userId": "userId"
                    //         },
                    //         {
                    //             "action": "NEW_COMMENT",
                    //             "company": {
                    //                 "id": "companyId",
                    //                 "name": "companyName"
                    //             },
                    //             "createdDate": 1568749926076,
                    //             "id": "NotificationId",
                    //             "orderNumber": 67765,
                    //             "orderStatus": "FORMED",
                    //             "read": true,
                    //             "sender": "sender",
                    //             "userId": "userId"
                    //         },
                    //         {
                    //             "action": "NEW_COMMENT",
                    //             "company": {
                    //                 "id": "companyId",
                    //                 "name": "companyName"
                    //             },
                    //             "createdDate": 1568749926076,
                    //             "id": "NotificationId",
                    //             "orderNumber": 67765,
                    //             "orderStatus": "FORMED",
                    //             "read": false,
                    //             "sender": "sender",
                    //             "userId": "userId"
                    //         },
                    //         {
                    //             "action": "NEW_COMMENT",
                    //             "company": {
                    //                 "id": "companyId",
                    //                 "name": "companyName"
                    //             },
                    //             "createdDate": 1568749926076,
                    //             "id": "NotificationId",
                    //             "orderNumber": 67765,
                    //             "orderStatus": "FORMED",
                    //             "read": false,
                    //             "sender": "sender",
                    //             "userId": "userId"
                    //         }
                    //     ]
                    // }
                    dataModel={[
                        "orderNumber|link",
                        "createdDate|date",
                        "createdDate|time",
                        "sender",
                        "company.name|struct",
                        "orderStatus|type",
                        "action|type"
                    ]}
                    isLoading={this.props.notification.isFetching}
                    pageble={this.props.notification.pageble? this.props.notification.pageble : {
                        currentPage: 0,
                        totalElements: 0,
                        totalPages: 0
                    }}
                    onChangePage={this.changePageNumber}
                    typesDictionary={{
                        "orderStatus": {
                            "FORMED": loc.Notification_OrderStatus_FORMED,
                            "UNDER_CONSIDERATION": loc.Notification_OrderStatus_UNDER_CONSIDERATION,
                            "REJECTED": loc.Notification_OrderStatus_REJECTED,
                            "AGREED": loc.Notification_OrderStatus_AGREED,
                            "ON_ROUTE": loc.Notification_OrderStatus_ON_ROUTE,
                            "COMPLETED": loc.Notification_OrderStatus_COMPLETED,
                            "ARCHIEVED": loc.Notification_OrderStatus_ARCHIEVED,
                            "CHANGED": loc.Notification_OrderStatus_CHANGED
                        },
                        "action": {
                            "NEW_COMMENT": loc.Notification_Action_NEW_COMMENT,
                            "STATUS_CHANGED": loc.Notification_Action_STATUS_CHANGED,
                            "ORDER_CHANGED": loc.Notification_Action_ORDER_CHANGED
                        }

                    }}
                    linksDictionary={{
                        "orderNumber": "order/edit/"
                    }}
                    onSearch={this.handleSearch}
                    onResetSearch={this.handleResetSearch}
                    onSort={this.handleSort}
                    actualSort={this.state.sort}
                    boldFromKey={"read"}
                    onView={this.handleView}
                >
                    <InputField label={loc.NotificationScreen_ActNamberFilter} type="number" onChange={this.handleChangeFilter} value={this.state.filters['orderNumber|full']? this.state.filters['orderNumber|full']: null} name='orderNumber|full'/>
                    <InputField label={loc.NotificationScreen_Date1Filter} type="date" changeDate={this.handleChangeDateFilter} value={this.state.filters['createdDate|from'] && typeof this.state.filters['createdDate|from'] === 'number'? formatDate(this.state.filters['createdDate|from']): ''} name='createdDate|from'/>
                    <InputField isValidDate={this.validSecondDate} label={loc.NotificationScreen_Date2Filter} type="date" changeDate={this.handleChangeDateFilter} value={this.state.filters['createdDate|to']  && typeof this.state.filters['createdDate|to'] === 'number'? formatDate(this.state.filters['createdDate|to']): ''} name='createdDate|to'/>
                    <InputField label={loc.NotificationScreen_FromFilter} additionalFilters={{'authorities|full': 'ROLE_WAREHOUSE_MANAGER'}} type="person" onSelect={this.handleChangePersonFilter} onChange={this.handleChangeFilter} value={this.state.filters['sender|full']? this.state.filters['sender|full']: ""} name='sender|full'/>
                    <InputField label={loc.NotificationScreen_CompanyFilter} type="company" onSelect={this.handleChangeCompanyFilter} onChange={this.handleChangeFilter} value={this.state.filters['company.name|full']? this.state.filters['company.name|full']: ""} name='company.name|full'/>
                    <InputField label={loc.NotificationScreen_ActStatusFilter} type="select" onChange={this.handleChangeFilter} value={this.state.filters['orderStatus|full']? this.state.filters['orderStatus|full']: null} name="orderStatus|full"
                        options={[
                                {
                                    value: 'FORMED',
                                    label: loc.Notification_OrderStatus_FORMED
                                },
                                {
                                    value: 'UNDER_CONSIDERATION',
                                    label: loc.Notification_OrderStatus_UNDER_CONSIDERATION
                                },
                                {
                                    value: 'REJECTED',
                                    label: loc.Notification_OrderStatus_REJECTED
                                },
                                {
                                    value: 'AGREED',
                                    label: loc.Notification_OrderStatus_AGREED
                                },
                                {
                                    value: 'ON_ROUTE',
                                    label: loc.Notification_OrderStatus_ON_ROUTE
                                },
                                {
                                    value: 'COMPLETED',
                                    label: loc.Notification_OrderStatus_COMPLETED
                                },
                                {
                                    value: 'ARCHIEVED',
                                    label: loc.Notification_OrderStatus_ARCHIEVED
                                },
                                {
                                    value: 'CHANGED',
                                    label: loc.Notification_OrderStatus_CHANGED
                                }
                                
                            ]}/>
        
                    <InputField label={loc.NotificationScreen_EventFilter} type="select" onChange={this.handleChangeFilter} value={this.state.filters['action|full']? this.state.filters['action|full']: null} name="action|full"
                        options={[
                                {
                                    value: "NEW_COMMENT",
                                    label: loc.Notification_Action_NEW_COMMENT
                                },
                                {
                                    value: "STATUS_CHANGED",
                                    label: loc.Notification_Action_STATUS_CHANGED,
                                },
                                {
                                    value: "ORDER_CHANGED",
                                    label: loc.Notification_Action_ORDER_CHANGED,
                                },
                            ]}/>
                </PageTemplate>
                <Dialog
                    open={this.state.readAllDialogOpen}
                    onClose={this.handleCloseReadAllDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div className="dialog-window">
                        <p>{`${loc.NotificationScreen_Modal_ReadAll}`}</p>
                        <div className="btn-block">
                            <Button padding col={4} color={'primary'} onClick={this.readAllNotifications}>{loc.Button_Continue}</Button>
                            <Button padding col={4} onClick={() => {this.handleCloseReadAllDialog()}}>{loc.Button_Cancel}</Button>
                        </div>  
                        
                    </div>
                </Dialog>
                <button onClick={() => {this.readAllDialog()}} className="add-button">
                    <Icon>remove_red_eye</Icon>
                </button>
            </>
        )
    }
}

const mapStateToProps = (state: IAppState) => ({
    locale: state.locale,
    notification: state.notification,
    loc: state.locale.currentLocaleJSON,
    user: state.user
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
    // initLocales: () => dispatch(initLocales(config)),
    // getUserInfo: () => dispatch(getUserInfo())
    getNotificationList: (params: ISearchParams) => dispatch(getNotificationList(params)),
    readCurrentNotification: (id: string) => dispatch(readCurrentNotification(id)),
    readAllNotifications: () => dispatch(readAllNotifications())
})

export default connect(mapStateToProps, mapDispatchToProps)(withPageBase(NotificationScreen));
