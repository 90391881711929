import React, { Component } from 'react';
import { BrowserRouter, HashRouter, Router, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { AuthScreen } from 'pages/AuthScreen';
import { PageTemplate } from 'components/PageTemplate';
import DashboardScreen from 'pages/DashboardScreen';
import ResetPasswordScreen from 'pages/ResetPasswordSreen';
import { IAppState } from 'store';
import { IUserState } from 'store/user/types';
import { checkAccess, getUserStoredData } from 'services';
import { SS_AUTH_TOKEN, SS_ROLES } from 'store/types';

// import AuthScreen from ""

interface IProps {
    user: IUserState;
    path: string;
    render: (props: any) => JSX.Element | Element;
    roles?: string[];
    exact?: boolean;
}
interface State {
    
}

// function PrivateRoute({ component: Component, ...rest }) {
//     return (
//       <Route
//         {...rest}
//         render={props =>
//           fakeAuth.isAuthenticated ? (
//             <Component {...props} />
//           ) : (
//             <Redirect
//               to={{
//                 pathname: "/login",
//                 state: { from: props.location }
//               }}
//             />
//           )
//         }
//       />
//     );
//   }


export class PrivateRoute extends Component<IProps> {
    state = {}

    getRoles = (storedRoles: string | null): string[] => {
        let roles: string[] | [] = [];
        if(storedRoles){
            const rolesString: string | null = storedRoles;
            roles = rolesString !== null? JSON.parse(rolesString) : [];
        }
        return roles;
    }


    render() {
        const { token, roles } = getUserStoredData();
        return (
            <Route
                exact={this.props.exact}
                path={this.props.path}
                render={props =>
                    !token?//!this.props.user.token?
                    ( 
                        <Redirect to={{ pathname: '/' }} />
                    ) : (
                        this.props.roles && this.props.roles.length?
                        
                            
                            roles && checkAccess(this.props.roles, this.getRoles(roles))?
                                
                                    this.props.render(props)
                                :
                            // this.props.render(props)
                                    <Redirect to={{ pathname: '/dashboard' }} />
                            :
                                this.props.render(props)
                            // authorised so return component
                    )
                }
            />
        )
    }
}

const mapStateToProps = (state: IAppState) => ({
    user: state.user
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
