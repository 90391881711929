import './OrderScreen.scss';
import React, {Component} from 'react'
import {connect} from 'react-redux'

import {IDictionary, ILocaleState,} from 'store/locale/types';
import {IAppState} from 'store';
import {ThunkDispatch} from 'redux-thunk';
import {AnyAction} from 'redux';
import {IUserModel, IUserState} from 'store/user/types';
import {ISearchParams} from 'store/types';
import PageTemplate from 'components/PageTemplate';
import {
    IGetOrderListFailureAction,
    IGetOrderListSuccessAction,
    IGetOrderUncompleteListFailureAction,
    IGetOrderUncompleteListSuccessAction,
    IOrderModel,
    IOrderState,
} from 'store/order/types';
import {getOrderList, getOrderUncompleteList} from 'store/order/actions';
import {Dialog, Icon} from '@material-ui/core';
import {Button} from 'components/Button';
import InputField from 'components/InputField';
import {ICompanyModel} from 'store/company/types';
import {ICityModel} from 'store/city/types';
import moment from 'moment';
import {formatDate, getNewSort} from 'services';
import RoleControl from 'components/RoleControl/RoleControl';
import { IActState, IGetActListSuccessAction, IGetActListFailureAction } from 'store/act/types';
import { getActList } from 'store/act/actions';
import { IWarehouseModel } from 'store/warehouse/types';
import { IStaffModel } from 'store/staff/types';


interface IProps {
    locale: ILocaleState;
    loc: IDictionary;
    order: IOrderState;
    act: IActState;
    history: any;
    sort: string;
    active?: boolean;
    acts?: boolean;
    archive?: boolean;
    input?: boolean;
    output?: boolean;
    isLogist: boolean;
    isManager: boolean;
    user: IUserState;
    getActList: (params: ISearchParams) => Promise<IGetActListSuccessAction|IGetActListFailureAction>;
    // initLocales: () => Promise<IInitLocalesSuccessAction | IInitLocalesFailureAction>;
    // getUserInfo: () => Promise<IGetUserInfoSuccessAction | IGetUserInfoFailureAction>;
    getOrderList: (params: ISearchParams) => Promise<IGetOrderListSuccessAction | IGetOrderListFailureAction>;
    getOrderUncompleteList: (params: ISearchParams) => Promise<IGetOrderUncompleteListSuccessAction | IGetOrderUncompleteListFailureAction>;
    
}

interface IState {
    page: number;
    q: any;
    orderWithoutDialog: boolean;
    row: any | null;
    sort: string;
    filters: { [key: string]: string | number },
    additional: { [key: string]: string },
    uncomplete: boolean;
}

interface IKeysStringObject {
    [key: string]: any;
}

class OrderScreen extends Component<IProps> {
    state: IState = {
        q: '',
        page: 0,
        orderWithoutDialog: false,
        row: null,
        sort: "",
        filters: {},
        additional: {},
        uncomplete: false
    };



    defaultFilters = ():IKeysStringObject => {
        // console.log(this.props.user.userInfo.authorities[0])
        if(this.props.acts){
            // switch (this.props.user.userInfo.authorities[0]) {
                // case 'ROLE_MANAGER_LOGIST':
                //     return {};
                    

                // case 'ROLE_TC_MANAGER':
                //     return {};

            
                // default:
            return {};
            // }
        } else {
            switch (this.props.user.userInfo.authorities[0]) {
                case 'ROLE_MANAGER_LOGIST':
                    if(this.props.archive){
                        return {'status|or': ['ARCHIEVED']};
                    } else {
                        return {'status|or': ['FORMED', 'UNDER_CONSIDERATION' , 'REJECTED' , 'AGREED' , 'ON_ROUTE' , 'COMPLETED' , 'CHANGED']};
                    }
                    

                case 'ROLE_TC_MANAGER':
                    if(this.props.archive){
                        return {'status|or': ['ARCHIEVED'], 'tariff.company.id|full': this.props.user.userInfo.company.id};
                    } else {
                        return {'status|or': ['UNDER_CONSIDERATION' , 'REJECTED' , 'AGREED' , 'ON_ROUTE' , 'COMPLETED' , 'CHANGED'], 'tariff.company.id|full': this.props.user.userInfo.company.id};
                    }
                case 'ROLE_WAREHOUSE_MANAGER':
                    if(this.props.input){
                        return {'status|or': ['ON_ROUTE'], 'tariff.company.id|full': this.props.user.userInfo.company.id};
                    }
                    if(this.props.output){
                        return {'status|or': ['AGREED' , 'ON_ROUTE'], 'tariff.company.id|full': this.props.user.userInfo.company.id};
                    }
                default:
                    return {};
            }
        }
    }

    getPageTitle = (): string => {
        const { loc } = this.props;
        if(this.props.active) return loc.OrderScreen_ActivePageTitle;
        if(this.props.acts) return loc.OrderScreen_ActPageTitle;
        if(this.props.archive) return loc.OrderScreen_ArchivePageTitle;
        if(this.props.input) return loc.OrderScreen_InputPageTitle;
        if(this.props.output) return loc.OrderScreen_OutpuPageTitle;
        return "";
        
    }

    static getDerivedStateFromProps = (nextProps: IProps, prevState: IState) => {
        

        if (prevState.filters['createdDate|from'] && prevState.filters['createdDate|to']) {
            if (moment(prevState.filters['createdDate|from']).isAfter(moment(prevState.filters['createdDate|to']))) {
                return ({
                    filters: {
                        ...prevState.filters,
                        ['createdDate|to']: null,
                    }
                })
            }

        }
        return null;
    };

    handlingOrderUncompleteList = (data:  IGetOrderUncompleteListSuccessAction | IGetOrderUncompleteListFailureAction) => {
        if (data.type === 'GET_ORDER_UNCOMPLETE_LIST_SUCCESS') {
            if (data.payload.list.length) {
                this.setState({orderWithoutDialog: true, additional: {'uncomplete': 'true'}});
            }
        }
    }

    searchAdapter = (searchData: ISearchParams) => {

        if(this.props.acts){
            this.props.getActList(searchData)
                .then(data => {
                    // if (data.type === "GET_ACT_LIST_SUCCESS") {
                        
                    // }
                });
        } else {
            if(this.state.uncomplete){
                this.props.getOrderUncompleteList(searchData)
                    .then(this.handlingOrderUncompleteList);
            } else {
                this.props.getOrderList(searchData)
                    .then(data => {
                        if (data.type === "GET_ORDER_LIST_SUCCESS") {
                            if(this.props.isLogist && this.props.active){
                                this.props.getOrderUncompleteList(searchData)
                                    .then(this.handlingOrderUncompleteList);
                            }
                        }
                    });
            }
        }
    }

    componentDidMount = () => {
        this.setState({
            filters: this.defaultFilters(),
        }, () => {
            this.searchAdapter({page: this.state.page, q: {...this.state.filters}});
        });
        // console.log('this.props.act',this.props.act)
        // if(this.props.acts){
        //     this.props.getActList({page: this.state.page, q: {}})
        //         .then(data => {
        //             if (data.type === "GET_ACT_LIST_SUCCESS") {

        //             }
        //         });
        // } else {
        //     this.setState({
        //         filters: this.defaultFilters(),
        //     }, () => {
        //         console.log('----AFTER UPDATE',this.state)
        //         this.props.getOrderList({page: this.state.page, q: {...this.state.filters}})
        //             .then(data => {
        //                 if (data.type === "GET_ORDER_LIST_SUCCESS") {
        //                     if(this.props.isLogist && this.props.active){
        //                         this.props.getOrderUncompleteList({page: 0, q: {...this.state.filters}})
        //                             .then(data => {
        //                                 if (data.type === 'GET_ORDER_UNCOMPLETE_LIST_SUCCESS') {
        //                                     if (data.payload.list.length) {
        //                                         this.setState({orderWithoutDialog: true, additional: {'uncomplete': 'true'}});
        //                                     }
        //                                 }
        //                             });
        //                     }
        //                 }
        //             });
        //     });
        // }


    };

    componentDidUpdate = (prevProps: IProps, prevState: IState) => {
        if(prevProps.archive !== this.props.archive || 
            prevProps.acts !== this.props.acts ||
            prevProps.active !== this.props.active || 
            prevProps.input !== this.props.input ||
            prevProps.output !== this.props.output ){
            // console.log("=====CHANGE ARCHIVE");
            this.setState({
                filters: this.defaultFilters(),
            }, () => {
                this.searchAdapter({page: this.state.page, q: {...this.state.filters}});
            });
            // this.setState({
            //     filters: this.defaultFilters(),
            // }, () => {
            //     if(this.props.acts){
            //         this.props.getActList({page: 0, q: {}})
            //             .then(data => {
            //                 if (data.type === "GET_ACT_LIST_SUCCESS") {
                                
            //                 }
            //             });
            //     } else {
            //         this.props.getOrderList({page: 0, q: {...this.state.filters}})
            //             .then(data => {
            //                 if (data.type === "GET_ORDER_LIST_SUCCESS") {
            //                     if(this.props.isLogist && this.props.active){
            //                         this.props.getOrderUncompleteList({page: 0, q: {...this.state.filters}})
            //                             .then(data => {
            //                                 if (data.type === 'GET_ORDER_UNCOMPLETE_LIST_SUCCESS') {
            //                                     if (data.payload.list.length) {
            //                                         this.setState({orderWithoutDialog: true, additional: {'uncomplete': 'true'}});
            //                                     }
            //                                 }
            //                             });
            //                     }
            //                 }
            //             });
            //     }
            // });
        }
    }


    handleViewUncompleteList = () => {
        this.setState({
            page: 0,
            filters: this.defaultFilters(),
            uncomplete: true
        }, () => {
            this.props.getOrderList({page: 0, q: {...this.state.filters}, additional: {'uncomplete': 'true'}})
                .then(data => {
                    // this.setState({page: 0});
                });
            this.handleCloseWithoutDialog()
        })
    };


    changePageNumber = (page: number) => {
        this.props.getOrderList({page, q: {...this.state.filters}, sort: this.state.sort})
            .then(data => {
                this.setState({page});
            });
    };

    readAllDialog = () => {
        this.setState({orderWithoutDialog: true});
    };


    handleCloseWithoutDialog = () => {
        this.setState({orderWithoutDialog: false});
    };


    handleSearch = () => {
        this.searchAdapter({page: this.state.page, q: {...this.state.filters}});
        // this.props.getOrderList({page: 0, q: {...this.state.filters}, sort: this.state.sort})
        //     .then(() => {
        //         this.setState({page: 0});
        //     });
    };


    handleResetSearch = () => {
        this.setState({
            page: 0,
            filters: this.defaultFilters(),
            additional: {},
            uncomplete: false
        }, () => {
            this.searchAdapter({page: this.state.page, q: {...this.state.filters}});
        });
        // this.setState((prevState: IState) => ({
        //     page: 0,
        //     filters: {...this.defaultFilters()},
        //     additional: {}
        // }),
        // () => {
        //     this.props.getOrderList({page: 0, q: {...this.state.filters}, sort: this.state.sort});
        // });
    };


    handleSort = (sortString: string) => {
        const newSort = getNewSort(sortString, this.state);
        this.props.getOrderList({page: this.state.page, q: {...this.state.filters}, sort: newSort});
        this.setState({sort: newSort});

    };

    handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        const Event = event;
        const field = Event.target.name;//.split('|');
        const value = Event.target.type == 'number' ? +Event.target.value : Event.target.value;
        // console.log('filter value',value)
        this.setState((prevState: IState) => ({
            filters: {
                ...prevState.filters,
                [field]: value
            }
        }))
    };


    handleChangeCompanyFilter = (resultObject: ICompanyModel, name: string) => {
        // console.log(resultObject)
        this.setState((prevState: IState) => ({
            filters: {
                ...prevState.filters,
                [name]: resultObject.shortName
            }
        }))
    };

    handleChangeCityFilter = (resultObject: ICityModel, name: string) => {
        // console.log(resultObject)
        this.setNewFilterName(resultObject, name);
        // this.setState((prevState: IState) => ({
        //     filters: {
        //         ...prevState.filters,
        //         [name]: resultObject.name
        //     }
        // }))
    };

    setNewFilterName = (resultObject: ICityModel | IWarehouseModel, name: string) => {
        this.setState((prevState: IState) => ({
            filters: {
                ...prevState.filters,
                [name]: resultObject.name
            }
        }))
    }


    setNewFilterFio = (resultObject: IStaffModel | IUserModel, name: string) => {
        this.setState((prevState: IState) => ({
            filters: {
                ...prevState.filters,
                [name]: resultObject.fio
            }
        }))
    }

    handleChangeWarehouseFilter = (resultObject: IWarehouseModel, name: string) => {
        // console.log(resultObject)
        this.setNewFilterName(resultObject, name);
    };

    handleChangeStaffFilter = (resultObject: IStaffModel, name: string) => {
        // console.log(resultObject)
        this.setNewFilterFio(resultObject, name);
    };

    handleChangePersonFilter = (resultObject: IUserModel, name: string) => {
        // console.log(resultObject)
        this.setNewFilterFio(resultObject, name);
    };

    handleChangeDateFilter = (name: string, value: string | moment.Moment) => {

        this.setState((prevState: IState) =>
            ({
                filters: {
                    ...prevState.filters,
                    [name]: value.valueOf(),
                }
            })
        );

    };

    validSecondDate = (current: any) => {
        const selectedDate = this.state.filters['createdDate|from'] ? moment(this.state.filters['createdDate|from']) : moment(0); //Datetime.moment().subtract( 1, 'day' );

        return current.isAfter(selectedDate);
    };

    getDataHeaders = () => {
        const { loc } = this.props;
        if (this.props.active) {
            return [
                loc.OrderScreen_NuberColumn,
                loc.OrderScreen_StatusColumn,
                loc.OrderScreen_CityColumn,
                loc.OrderScreen_WarehouseColumn,
                loc.OrderScreen_RouteColumn,
                loc.OrderScreen_DateColumn,
                loc.OrderScreen_CompanyColumn,
                loc.OrderScreen_LogistColumn
            ]
        }
        if (this.props.acts) {
            return [
                loc.OrderScreen_ActNumberColumn,
                loc.OrderScreen_ActDateColumn,
                loc.OrderScreen_ActValueColumn,
                loc.OrderScreen_ActTransferColumn,
                loc.OrderScreen_ActStatusColumn,
                loc.OrderScreen_ActManagerColumn,
            ];
        }
        if (this.props.archive) {
            return [
                loc.OrderScreen_NuberColumn,
                loc.OrderScreen_CityColumn,
                loc.OrderScreen_WarehouseColumn,
                loc.OrderScreen_RouteColumn,
                loc.OrderScreen_DateColumn,
                loc.OrderScreen_ValueColumn
            ]
        }
        if (this.props.input) {
            //Номер ТТН	Точка выгрузки	Расч. дата прибытия	Расч. время прибытия	Статус	Количество коробов, шт.	Номер ТС	Водитель
            return [
                loc.OrderScreen_InTTNColumn,
                loc.OrderScreen_InPointColumn,
                loc.OrderScreen_InDateColumn,
                loc.OrderScreen_InTimeColumn,
                loc.OrderScreen_InStatusColumn,
                loc.OrderScreen_InBoxesColumn,
                // loc.OrderScreen_InTCNumberColumn,
                // loc.OrderScreen_InDriverColumn
            ];
        }
        if (this.props.output) {
            ///Номер	Дата загрузки	Время загрузки	Маршрутinfo	Перевозчик	Водитель	Гос. номер ТС	Статус
            return [
                loc.OrderScreen_OutNumberColumn,
                loc.OrderScreen_OutDateColumn,
                loc.OrderScreen_OutTimeColumn,
                loc.OrderScreen_OutRouteColumn,
                loc.OrderScreen_OutTransferColumn,
                // loc.OrderScreen_OutDriverColumn,
                // loc.OrderScreen_OutGovementNumberColumn,
                loc.OrderScreen_OutStatusColumn,
            ];
        }
        return [];
    };


    getDataModel = () => {
        if (this.props.active) {
            return [
                'id',
                'status|type',
                'loadInPoint.city.name|struct',
                'loadInPoint.warehouse.name|struct',
                'route|route',
                'loadInPoint.uploadDate|structDate',//&
                'tariff.company.name|struct',
                'author.fio|struct'
            ];
        }
        if (this.props.acts) {
            //Номер акта	Дата составления	Общая сумма, грн.	Перевозчик	Статус	Менеджер
            return [
                'number',
                'createdDate|date',
                'total',
                'company.name|struct',
                'status|type',
                'author.fio|struct'
            ];
        }
        if (this.props.archive) {
            return [
                'id',
                // 'status|type',
                'loadInPoint.city.name|struct',
                'loadInPoint.warehouse.name|struct',
                'route|route',
                'createdDate|date',//&
                // 'transport.company.name',
                'price',
                // ''
            ];
        }
        if (this.props.input) {
            //Номер ТТН	Точка выгрузки	Расч. дата прибытия	Расч. время прибытия	Статус	Количество коробов, шт.	Номер ТС	Водитель
            return [
                'loadOutPoints.0.ttn|struct',
                'loadOutPoints.0.warehouse.name|struct',
                'loadOutPoints.0.downloadDate|structDate',
                'loadOutPoints.0.downloadDate|structTime',
                'status|type',
                '', //Количество коробов, шт.
                // 'transport.car.registrationNumber|struct',
                // 'transport.driver.fio|struct'
            ];
        }
        if (this.props.output) {
            ///Номер	Дата загрузки	Время загрузки	Маршрутinfo	Перевозчик	Водитель	Гос. номер ТС	Статус
            return [
                'id',
                'loadInPoint.uploadDate|structDate',
                'loadInPoint.uploadDate|structTime',
                'route|route',
                'loadInPoint.company.name|struct',
                // 'transport.driver.fio|struct',
                // 'transport.car.registrationNumber|struct',
                'status|type',
            ];
        }
        return [];
    };

    getFilters = () => {
        const {loc} = this.props;
        if (this.props.active) {
            return <>
                    <InputField label={loc.OrderScreen_NumberFilter} type="number" onChange={this.handleChangeFilter}
                                value={this.state.filters['id|full'] ? this.state.filters['id|full'] : null}
                                name='id|full'/>
                    <InputField label={loc.OrderScreen_StatusFilter} type="select" onChange={this.handleChangeFilter}
                                value={this.state.filters['status|full'] ? this.state.filters['status|full'] : null}
                                name="status|full"
                                options={[
                                    {
                                        value: 'FORMED',
                                        label: loc.Order_OrderStatus_FORMED
                                    },
                                    {
                                        value: 'UNDER_CONSIDERATION',
                                        label: loc.Order_OrderStatus_UNDER_CONSIDERATION
                                    },
                                    {
                                        value: 'REJECTED',
                                        label: loc.Order_OrderStatus_REJECTED
                                    },
                                    {
                                        value: 'AGREED',
                                        label: loc.Order_OrderStatus_AGREED
                                    },
                                    {
                                        value: 'ON_ROUTE',
                                        label: loc.Order_OrderStatus_ON_ROUTE
                                    },
                                    {
                                        value: 'COMPLETED',
                                        label: loc.Order_OrderStatus_COMPLETED
                                    },
                                    // {
                                    //     value: 'ARCHIEVED',
                                    //     label: loc.Order_OrderStatus_ARCHIEVED
                                    // },
                                    // {
                                    //     value: 'CHANGED',
                                    //     label: loc.Order_OrderStatus_CHANGED
                                    // }

                                ]}/>
                    <InputField label={loc.OrderScreen_CityFilter} type="city"
                                onSelect={(row) => {this.handleChangeCityFilter(row, 'loadInPoint.city.name|full')}} onChange={this.handleChangeFilter}
                                value={this.state.filters['loadInPoint.city.name|full'] ? this.state.filters['loadInPoint.city.name|full'] : ""}
                                name='loadInPoint.city.name|full'/>

                    <InputField label={loc.OrderScreen_WarehouseFilter} type="warehouse"
                                disabled={!this.state.filters['loadInPoint.city.name|full'] || this.state.filters['loadInPoint.city.name|full'] === ''}
                                additionalFilters={{'city.name|full': this.state.filters['loadInPoint.city.name|full']}}
                                onSelect={(row) => {this.handleChangeWarehouseFilter(row, 'loadInPoint.warehouse.name|full')}} onChange={this.handleChangeFilter}
                                value={this.state.filters['loadInPoint.warehouse.name|full'] ? this.state.filters['loadInPoint.warehouse.name|full'] : ""}
                                name='loadInPoint.warehouse.name|full'/>

                    <InputField label={loc.OrderScreen_DateFilter} type="date" changeDate={this.handleChangeDateFilter}
                                value={this.state.filters['loadInPoint.uploadDate|from'] && typeof this.state.filters['loadInPoint.uploadDate|from'] === 'number' ? formatDate(this.state.filters['loadInPoint.uploadDate|from']) : ''}
                                name='loadInPoint.uploadDate|from'/>
                    <InputField isValidDate={this.validSecondDate} label={loc.OrderScreen_Date2Filter} type="date"
                                changeDate={this.handleChangeDateFilter}
                                value={this.state.filters['loadInPoint.uploadDate|to'] && typeof this.state.filters['loadInPoint.uploadDate|to'] === 'number' ? formatDate(this.state.filters['loadInPoint.uploadDate|to']) : ''}
                                name='loadInPoint.uploadDate|to'/>

                     <InputField label={loc.OrderScreen_CompanyFilter} type="company"
                                onSelect={(row) => {this.handleChangeCompanyFilter(row, 'tariff.company.name|full')}} onChange={this.handleChangeFilter}
                                value={this.state.filters['tariff.company.name|full'] ? this.state.filters['tariff.company.name|full'] : ""}
                                name='tariff.company.name|full'/>
                    
                    <InputField label={loc.OrderScreen_LogistFilter} type="logist"
                                onSelect={(row) => {this.handleChangePersonFilter(row, 'author.fio|full')}} onChange={this.handleChangeFilter}
                                value={this.state.filters['author.fio|full'] ? this.state.filters['author.fio|full'] : ""}
                                name='author.fio|full'/>
                   


                    {/* <InputField label={loc.OrderScreen_EventFilter} type="select" onChange={this.handleChangeFilter}
                                value={this.state.filters['action|full'] ? this.state.filters['action|full'] : null}
                                name="action|full"
                                options={[
                                    {
                                        value: "NEW_COMMENT",
                                        label: loc.Order_Action_NEW_COMMENT
                                    },
                                    {
                                        value: "STATUS_CHANGED",
                                        label: loc.Order_Action_STATUS_CHANGED,
                                    },
                                    {
                                        value: "ORDER_CHANGED",
                                        label: loc.Order_Action_ORDER_CHANGED,
                                    },
                                ]}/> */}
            </>;
        }
        if (this.props.acts) {
            return <>
            
                <InputField label={loc.OrderScreen_ActNumberFilter} type="number" onChange={this.handleChangeFilter}
                    value={this.state.filters['number|full'] ? this.state.filters['number|full'] : null}
                    name='number|full'/>

                <InputField label={loc.OrderScreen_ActDateFilter} type="date" changeDate={this.handleChangeDateFilter}
                    value={this.state.filters['createdDate|from'] && typeof this.state.filters['createdDate|from'] === 'number' ? formatDate(this.state.filters['createdDate|from']) : ''}
                    name='createdDate|from'/>
                <InputField isValidDate={this.validSecondDate} label={loc.OrderScreen_ActDate2Filter} type="date"
                    changeDate={this.handleChangeDateFilter}
                    value={this.state.filters['createdDate|to'] && typeof this.state.filters['createdDate|to'] === 'number' ? formatDate(this.state.filters['createdDate|to']) : ''}
                        name='createdDate|to'/>
                
                   
                <InputField label={loc.OrderScreen_ActStatusFilter} type="select" onChange={this.handleChangeFilter}
                    value={this.state.filters['status|full'] ? this.state.filters['status|full'] : null}
                    name="status|full"
                    options={[
                        {
                            value: "FOR_PAY",
                            label: loc.Act_Status_FOR_PAY
                        },
                        {
                            value: "FOR_EDIT",
                            label: loc.Act_Status_FOR_EDIT,
                        },
                        {
                            value: "COMPLETED",
                            label: loc.Act_Status_COMPLETED,
                        },
                    ]}/>
                <InputField label={loc.OrderScreen_ActManagerFilter} type="person"
                    additionalFilters={{'authorities|full': 'ROLE_TC_MANAGER',}}
                    onSelect={(row) => { this.handleChangePersonFilter(row, 'author.fio|full')}} onChange={this.handleChangeFilter}
                    value={this.state.filters['author.fio|full'] ? this.state.filters['author.fio|full'] : ""}
                    name='author.fio|full'/>
            </>;
        }
        if (this.props.archive) {
            return <>
                    <InputField label={loc.OrderScreen_NumberFilter} type="number" onChange={this.handleChangeFilter}
                                value={this.state.filters['id|full'] ? this.state.filters['id|full'] : null}
                                name='id|full'/>

                    <InputField label={loc.OrderScreen_CityFilter} type="city"
                                onSelect={row => {this.handleChangeCityFilter(row, 'loadInPoint.city.name|full')}} onChange={this.handleChangeFilter}
                                value={this.state.filters['loadInPoint.city.name|full'] ? this.state.filters['loadInPoint.city.name|full'] : ""}
                                name='loadInPoint.city.name|full'/>

                    <InputField label={loc.OrderScreen_WarehouseFilter} type="warehouse"
                                disabled={!this.state.filters['loadInPoint.city.name|full'] || this.state.filters['loadInPoint.city.name|full'] === ''}
                                additionalFilters={{'city.name|full': this.state.filters['loadInPoint.city.name|full']}}
                                onSelect={row => {this.handleChangeWarehouseFilter(row, 'loadInPoint.warehouse.name|full')}} onChange={this.handleChangeFilter}
                                value={this.state.filters['loadInPoint.warehouse.name|full'] ? this.state.filters['loadInPoint.warehouse.name|full'] : ""}
                                name='loadInPoint.warehouse.name|full'/>

                    <InputField label={loc.OrderScreen_DateFilter} type="date" changeDate={this.handleChangeDateFilter}
                                value={this.state.filters['loadInPoint.uploadDate|from'] && typeof this.state.filters['loadInPoint.uploadDate|from'] === 'number' ? formatDate(this.state.filters['loadInPoint.uploadDate|from']) : ''}
                                name='loadInPoint.uploadDate|from'/>

                    <InputField isValidDate={this.validSecondDate} label={loc.OrderScreen_Date2Filter} type="date"
                                changeDate={this.handleChangeDateFilter}
                                value={this.state.filters['loadInPoint.uploadDate|to'] && typeof this.state.filters['loadInPoint.uploadDate|to'] === 'number' ? formatDate(this.state.filters['loadInPoint.uploadDate|to']) : ''}
                                name='loadInPoint.uploadDate|to'/>
            </>;
        }
        if (this.props.input) {
            //Номер ТТН	Точка выгрузки	Расч. дата прибытия	Расч. время прибытия	Статус	Количество коробов, шт.	Номер ТС	Водитель
            return <>
                    <InputField label={loc.OrderScreen_InTTNFilter} type="text"
                                
                                // additionalFilters={{'city.name|full': this.state.filters['loadInPoint.city.name|full']}}
                                // onSelect={row => {this.handleChangeCompanyFilter(row, 'loadOutPoint.ttn|full')}} 
                                onChange={this.handleChangeFilter}
                                value={this.state.filters['loadOutPoint.ttn|regex'] ? this.state.filters['loadOutPoint.ttn|regex'] : ""}
                                name='loadOutPoint.ttn|regex'/>

                    <InputField label={loc.OrderScreen_InDateFilter} type="date" changeDate={this.handleChangeDateFilter}
                                value={this.state.filters['loadInPoint.uploadDate|from'] && typeof this.state.filters['loadInPoint.uploadDate|from'] === 'number' ? formatDate(this.state.filters['loadInPoint.uploadDate|from']) : ''}
                                name='loadInPoint.uploadDate|from'/>
                    <InputField isValidDate={this.validSecondDate} label={loc.OrderScreen_InDate2Filter} type="date"
                                changeDate={this.handleChangeDateFilter}
                                value={this.state.filters['loadInPoint.uploadDate|to'] && typeof this.state.filters['loadInPoint.uploadDate|to'] === 'number' ? formatDate(this.state.filters['loadInPoint.uploadDate|to']) : ''}
                                name='loadInPoint.uploadDate|to'/>
            </>;
        }
        if (this.props.output) {
            ///Номер	Дата загрузки	Время загрузки	Маршрутinfo	Перевозчик	Водитель	Гос. номер ТС	Статус
            return <> 
                    <InputField label={loc.OrderScreen_OutNumberFilter} type="number" onChange={this.handleChangeFilter}
                                value={this.state.filters['id|full'] ? this.state.filters['id|full'] : null}
                                name='id|full'/>
                    <InputField label={loc.OrderScreen_OutDateFilter} type="date" changeDate={this.handleChangeDateFilter}
                                value={this.state.filters['loadInPoint.uploadDate|from'] && typeof this.state.filters['loadInPoint.uploadDate|from'] === 'number' ? formatDate(this.state.filters['loadInPoint.uploadDate|from']) : ''}
                                name='loadInPoint.uploadDate|from'/>
                    <InputField isValidDate={this.validSecondDate} label={loc.OrderScreen_OutDate2Filter} type="date"
                                changeDate={this.handleChangeDateFilter}
                                value={this.state.filters['loadInPoint.uploadDate|to'] && typeof this.state.filters['loadInPoint.uploadDate|to'] === 'number' ? formatDate(this.state.filters['loadInPoint.uploadDate|to']) : ''}
                                name='loadInPoint.uploadDate|to'/>
                    <InputField label={loc.OrderScreen_OutCompanyFilter} type="company"
                                onSelect={(row) => {this.handleChangeCompanyFilter(row, 'tariff.company.name|full')}} onChange={this.handleChangeFilter}
                                value={this.state.filters['tariff.company.name|full'] ? this.state.filters['tariff.company.name|full'] : ""}
                                name='tariff.company.name|full'/>
                    <InputField label={loc.OrderScreen_OutDriverFilter} type="staff"
                                // disabled={!this.state.filters['loadInPoint.city.name|full'] || this.state.filters['loadInPoint.city.name|full'] === ''}
                                additionalFilters={{'staffType|full': 'DRIVER'}}
                                onSelect={(row) => {this.handleChangeStaffFilter(row, 'transport.driver.fio|full')}} onChange={this.handleChangeFilter}
                                value={this.state.filters['transport.driver.fio|full'] ? this.state.filters['transport.driver.fio|full'] : ""}
                                name='transport.driver.fio|full'/>

                    
                    <InputField label={loc.OrderScreen_OutStatusFilter} type="select" onChange={this.handleChangeFilter}
                                value={this.state.filters['status|full'] ? this.state.filters['status|full'] : null}
                                name="status|full"
                                options={[
                                    {
                                        value: 'AGREED',
                                        label: loc.Order_OrderStatus_AGREED
                                    },
                                    {
                                        value: 'ON_ROUTE',
                                        label: loc.Order_OrderStatus_ON_ROUTE
                                    },

                                    // {
                                    //     value: 'ARCHIEVED',
                                    //     label: loc.Order_OrderStatus_ARCHIEVED
                                    // },
                                    // {
                                    //     value: 'CHANGED',
                                    //     label: loc.Order_OrderStatus_CHANGED
                                    // }

                                ]}/>



                    </>;
        }
    }

    handleEdit = (row: IOrderModel) => {
        // console.log('Open Edit Order!+!+!+!+!+!+!+!++!+!+!+!+!+');
        if(this.props.acts){
            this.props.history.push(`/dashboard/act/edit/${row.id}`);
        } else {
            this.props.history.push(`/dashboard/order/edit/${row.id}`);
        }
        
    };

    handleView = (row: IOrderModel) => {
        // console.log('Open Edit Order!+!+!+!+!+!+!+!++!+!+!+!+!+');
        if(this.props.acts){
            this.props.history.push(`/dashboard/act/view/${row.id}`);
        } else {
            this.props.history.push(`/dashboard/order/view/${row.id}`);
        }
        
    };

    handleAddAct = () => {
        this.props.history.push(`/dashboard/act/create`);
    }

    render() {
        const {loc} = this.props;
        return (
            <>
                <PageTemplate
                    isFetching={this.props.order.isFetching}
                    pageTitle={this.getPageTitle()}
                    pageIcon={'assignment'}
                    view={this.props.archive}
                    edit={!this.props.archive}
                    // create
                    warningFilter={this.props.order.uncompleteList && this.props.active && this.props.isLogist ? this.props.order.uncompleteList.length > 0 : false}
                    warningSearch={this.handleViewUncompleteList}
                    print
                    viewTriggerKey={"read"}
                    tableHeaders={this.getDataHeaders()}
                    listData={this.props.acts? this.props.act.list : this.state.uncomplete? this.props.order.uncompleteList : this.props.order.list}
                    dataModel={this.getDataModel()}
                    isLoading={this.props.order.isFetching}
                    pageble={this.props.order.pageble ? this.props.order.pageble : {
                        currentPage: 0,
                        totalElements: 0,
                        totalPages: 0
                    }}
                    onChangePage={this.changePageNumber}
                    typesDictionary={{
                        "status": {
                            "FORMED": this.props.loc.Order_OrderStatus_FORMED,
                            "UNDER_CONSIDERATION": this.props.loc.Order_OrderStatus_UNDER_CONSIDERATION,
                            "REJECTED": this.props.loc.Order_OrderStatus_REJECTED,
                            "AGREED": this.props.loc.Order_OrderStatus_AGREED,
                            "ON_ROUTE": this.props.loc.Order_OrderStatus_ON_ROUTE,
                            "COMPLETED": this.props.loc.Order_OrderStatus_COMPLETED,
                            "ARCHIEVED": this.props.loc.Order_OrderStatus_ARCHIEVED,
                            "CHANGED": this.props.loc.Order_OrderStatus_CHANGED,
                            "FOR_PAY": this.props.loc.Act_Status_FOR_PAY,
                            "FOR_EDIT": this.props.loc.Act_Status_FOR_EDIT,
                            // "COMPLETED": this.props.loc.Act_Status_COMPLETED,
                        },
                        "action": {
                            "NEW_COMMENT": loc.Order_Action_NEW_COMMENT,
                            "STATUS_CHANGED": loc.Order_Action_STATUS_CHANGED,
                            "ORDER_CHANGED": loc.Order_Action_ORDER_CHANGED
                        }

                    }}
                    linksDictionary={{
                        "orderNumber": "order/"
                    }}
                    onSearch={this.handleSearch}
                    onResetSearch={this.handleResetSearch}
                    onSort={this.handleSort}
                    actualSort={this.state.sort}
                    // boldFromKey={"read"}
                    onEdit={this.handleEdit}
                    onView={this.handleView}
                >
                    {this.getFilters()}
                </PageTemplate>
                <Dialog
                    open={this.state.orderWithoutDialog}
                    onClose={this.handleCloseWithoutDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div className="dialog-window">
                        <h4>{loc.Title_Attention}</h4>
                        <p className="small-text">{`${loc.WARNING_OrderWithoutSTOandTTH}`}</p>
                        <div className="btn-block">
                            <Button padding col={4} color={'primary'} onClick={() => {
                                this.handleViewUncompleteList()
                            }}>{loc.Button_View}</Button>
                            <Button padding col={4} onClick={() => {
                                this.handleCloseWithoutDialog()
                            }}>{loc.Button_Cancel}</Button>
                        </div>

                    </div>
                </Dialog>
                {
                    this.props.active?
                        <RoleControl roles={['ROLE_MANAGER_LOGIST', 'ROLE_LOGIST']}>
                            <div className="add-btn-block">
                                <div onClick={() => {
                                    this.props.history.push('/dashboard/order/create/distance')
                                }} className="btn-block-item">
                                    {loc.OrderAddBtn_Distance}
                                </div>
                                <div onClick={() => {
                                    this.props.history.push('/dashboard/order/create/time')
                                }} className="btn-block-item">
                                    {loc.OrderAddBtn_Time}
                                </div>
                                <div onClick={() => {
                                    this.props.history.push('/dashboard/order/create/pickup')
                                }} className="btn-block-item">
                                    {loc.OrderAddBtn_Pickup}
                                </div>
                                <button onClick={() => {
                                }} className="add-button">
                                    <Icon>add</Icon>
                                </button>
                            </div>
                            <div className="add-btn-bg"></div>
                        </RoleControl>
                    :
                        null
                }

                {
                    this.props.acts?
                        <RoleControl roles={['ROLE_TC_MANAGER']}>
                            <button onClick={() => {this.handleAddAct()}} className="add-button">
                                <Icon>add</Icon>
                            </button>
                        </RoleControl>
                    :
                        null
                }


            </>
        )
    }
}

const mapStateToProps = (state: IAppState) => ({
    locale: state.locale,
    order: state.order,
    loc: state.locale.currentLocaleJSON,
    user: state.user,
    act: state.act,
    isLogist: state.user.userInfo.authorities[0] === 'ROLE_MANAGER_LOGIST',
    isManager: state.user.userInfo.authorities[0] === 'ROLE_TC_MANAGER'
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
    // initLocales: () => dispatch(initLocales(config)),
    // getUserInfo: () => dispatch(getUserInfo())
    getActList: (params: ISearchParams) => dispatch(getActList(params)),
    getOrderList: (params: ISearchParams) => dispatch(getOrderList(params)),
    getOrderUncompleteList: (params: ISearchParams) => dispatch(getOrderUncompleteList(params)),
    // readCurrentOrder: (id: string) => dispatch(readCurrentOrder(id)),
    // readAllOrders: () => dispatch(readAllOrders())
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderScreen)
/*
"OrderScreen_NumberFilter": "Номер",
    "OrderScreen_StatusNameFilter": "Статус",
    "OrderScreen_CityNameFilter": "Город загрузки",
    "OrderScreen_Date1Filter": "Дата загрузки 1",
    "OrderScreen_Date2Filter": "Дата загрузки 2",
    "OrderScreen_TransComapnyFilter": "Перевозчик",
    "OrderScreen_LogistFilter": "Логист ФМ",
    "OrderScreen_Filter": "",
    "OrderScreen_Filter": "",
    "OrderScreen_Filter": "",
    
    "OrderScreen_Filter": "",
    "OrderScreen_Filter": "",
    "OrderScreen_Filter": "",
    "OrderScreen_Filter": "",
    "OrderScreen_Filter": "",
    "OrderScreen_Filter": "",
    */