import './ActBlock.scss';
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { ILocaleState, IInitLocalesSuccessAction, IInitLocalesFailureAction, IDictionary  } from 'store/locale/types';
import { IAppState } from 'store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { initLocales } from 'store/locale/actions';
import config from 'locales/config.json';
import { getUserInfo } from 'store/user/actions';
import { IGetUserInfoSuccessAction, IGetUserInfoFailureAction, IUserModel } from 'store/user/types';
import { SS_AUTH_TOKEN, ISearchParams } from 'store/types';
import PageTemplate from 'components/PageTemplate';
import { 
    IGetActListSuccessAction,
    IGetActListFailureAction,
    IActState,
    IGetActCandidatesSuccessAction,
    IGetActCandidatesFailureAction,
    IActComment,
    IActChangeStatusModel,
    IActCreateModel,
    IActEditModel,
    IActCreateCommentModel,
    ICreateActSuccessAction,
    ICreateActFailureAction,
    IAddActCommentSuccessAction,
    IAddActCommentFailureAction,
    IEditActSuccessAction,
    IEditActFailureAction,
    IChangeActStatusByLogistSuccessAction,
    IChangeActStatusByLogistFailureAction,
    IChangeActStatusByManagerSuccessAction,
    IChangeActStatusByManagerFailureAction,
    IGetActByIdSuccessAction,
    IGetActByIdFailureAction,
    ActStatus,
} from 'store/act/types';
import { 
    getActList, getActCandidates, changeActStatusByLogist, changeActStatusByManager, getActById, createAct, editAct, addActComment } from 'store/act/actions';
import { Dialog, Icon } from '@material-ui/core';
import { Button } from 'components/Button';
import InputField from 'components/InputField';
import { ICompanyNameModel, ICompanyModel } from 'store/company/types';
import { ICityModel } from 'store/city/types';
import moment from 'moment';
import { formatDate, formatTime, getNewSort } from 'services';
import { IOrderModel, IOrderState, IGetOrderListSuccessAction, IGetOrderListFailureAction } from 'store/order/types';
import { Section } from 'components/Section/Section';
import { match } from 'react-router';
import { openDoneDialog } from 'store/dialog/actions';
import { IDoneDialogData, IOpenDoneDialogAction } from 'store/dialog/types';
import { getOrderList } from 'store/order/actions';


interface IActParams {
    id?: string;
}

interface IProps {
    locale: ILocaleState;
    loc: IDictionary;
    act: IActState;
    // history: any;
    isLogist: boolean;
    isManager: boolean;
    create?: boolean;
    edit?: boolean;
    order: IOrderState;
    // sort: string;
    // initLocales: () => Promise<IInitLocalesSuccessAction | IInitLocalesFailureAction>;
    // getUserInfo: () => Promise<IGetUserInfoSuccessAction | IGetUserInfoFailureAction>;
    getOrderList: (params: ISearchParams) => Promise<IGetOrderListSuccessAction | IGetOrderListFailureAction>;
    openSuccessDialog: (data: IDoneDialogData) => Promise<IOpenDoneDialogAction>; 
    getActList: (params: ISearchParams) => Promise<IGetActListSuccessAction|IGetActListFailureAction>;
    getActCandidates: (params: ISearchParams) => Promise<IGetActCandidatesSuccessAction|IGetActCandidatesFailureAction>;
    createAct: (orderData: IActCreateModel) => Promise<ICreateActSuccessAction | ICreateActFailureAction>;
    createActComment: (orderData: IActCreateCommentModel) => Promise<IAddActCommentSuccessAction | IAddActCommentFailureAction>;
    editAct: (orderData: IActEditModel) => Promise<IEditActSuccessAction | IEditActFailureAction>;
    changeActStatusByLogist: (statusData: IActChangeStatusModel) => Promise<IChangeActStatusByLogistSuccessAction | IChangeActStatusByLogistFailureAction>;
    changeActStatusByManager: (statusData: IActChangeStatusModel) => Promise<IChangeActStatusByManagerSuccessAction | IChangeActStatusByManagerFailureAction>;
    getActById: (id: string) => Promise<IGetActByIdSuccessAction | IGetActByIdFailureAction>;
    // readCurrentAct: (id: string) => Promise<IReadCurrentActSuccessAction|IReadCurrentActFailureAction>;
    // readAllActs: () => Promise<IReadAllActsSuccessAction|IReadAllActsFailureAction>;
    match: match<IActParams>;
    history: any;
}

interface IState {
    page: number;
    q: any;
    readAllDialogOpen: boolean;
    row: any | null;
    sort: string;
    filters: {[key: string]: string | number},
    formData: {
        nds: number;
        valueWithNDS: number;
        valueWithoutNDS: number;
        number: number|null;
        comment: string,
        comments: {[key: string]: IActComment},
        status: ActStatus;
        id: string;
        ordersId: number[];
        ordersPrice: number[];
    }
}

export class ActBlock extends Component<IProps> {
    state: IState = {
        q: '',
        page: 0,
        readAllDialogOpen: false,
        row: null,
        sort: "",
        filters: {},
        formData: {
            nds: 0,
            valueWithNDS: 0,
            valueWithoutNDS: 0,
            number: null,
            comment: '',
            status: 'FOR_PAY',
            // actNumber: null,
            id: '',
            ordersId: [],
            ordersPrice: [],
            comments: {
            
            }
        }
    }

    comment_icon = 'sms';

    getUpdatedAct = (data:  IGetActByIdSuccessAction | IGetActByIdFailureAction) => {
        if(data.type === 'GET_ACT_BY_ID_SUCCESS'){
            this.props.getOrderList({page: 0, q:{'id|or': data.payload.record.ordersId}})
            let coms: { [key: string]: any } = {};
            data.payload.record.comments && data.payload.record.comments.forEach((el, num) => {
                coms[`comment_${num}`] = {
                    ...el
                };
            });
            this.setState({
                formData: {
                    nds: data.payload.record.total * .2,
                    valueWithNDS: data.payload.record.total + data.payload.record.total * .2,
                    valueWithoutNDS: data.payload.record.total,
                    number: data.payload.record.number,
                    comment: '',
                    status: data.payload.record.status,
                    // actNumber: null,
                    id: this.props.match.params.id? this.props.match.params.id.toString(): '',
                    ordersId: data.payload.record.ordersId,
                    ordersPrice: [],
                    comments: { ...coms }
                }
            })
        }
    }

    componentDidMount = () => {
        if(this.props.create){
            this.props.getActCandidates({page: this.state.page});
            this.setState((prevState: IState) => ({
                formData: {
                    ...prevState.formData,
                    status: ''
                }
            }));
        }

        if(this.props.edit){
            this.props.getActById(this.props.match.params.id? this.props.match.params.id.toString(): '')
                .then(this.getUpdatedAct);
        }
        
    }

    // static getDerivedStateFromProps = (nextProps: IProps, prevState: IState) => {
        
        // if(prevState.filters['createdDate|from'] && prevState.filters['createdDate|to']){
        //     if(moment(prevState.filters['createdDate|from']).isAfter(moment(prevState.filters['createdDate|to']))){
        //         return ({
        //             filters: {
        //                 ...prevState.filters,
        //                 ['createdDate|to']: null,
        //             }
        //         })
        //     }

        // }
    //     return null;
    // }

    changePageNumber = (page: number) => {
        this.props.getActList({page, q: {...this.state.filters}, sort: this.state.sort})
            .then(data => {
                this.setState({page});
            });
    }

    readAllDialog = () => {
        this.setState({readAllDialogOpen: true});
    }


    handleView = (row: any) => {
        // this.props.readCurrentAct(row.id);
        // this.props.history.push(`/dashboard/Act/edit/${row.id}`)
    }

    handleCloseReadAllDialog = () => {
        this.setState({readAllDialogOpen: false});
    }


    handleSearch = () => {
        this.props.getActList({page: 0, q: {...this.state.filters}, sort: this.state.sort})
            .then(() => {
                this.setState({page: 0});
            });
    }


    handleResetSearch = () => {
        this.props.getActList({page: 0, q: {}, sort: this.state.sort});
            this.setState((prevState: IState) => ({
                page: 0,
                filters: {}
            }))
    }


    handleSort = (sortString: string) => {
        const newSort = getNewSort(sortString, this.state);
        this.props.getActList({page: this.state.page, q: {...this.state.filters}, sort: newSort});
        this.setState({sort: newSort});
        
    }

    handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        const Event = event;
        const field = Event.target.name//.split('|');
        const value = Event.target.type == 'number'? +Event.target.value : Event.target.value;
        // console.log('filter value',value)
        this.setState((prevState: IState) => ({
            filters: {
                ...prevState.filters,
                [field]: value
            }
        }))
    }

    handleChangeFormField = (event: React.ChangeEvent<HTMLInputElement>) => {
        const Event = event;
        const field = Event.target.name//.split('|');
        const value = Event.target.type == 'number'? +Event.target.value : Event.target.value;
        // console.log('filter value',value)
        this.setState((prevState: IState) => ({
            formData: {
                ...prevState.formData,
                [field]: value
            }
        }))
    }


    handleChangeCompanyFilter = (resultObject: ICompanyModel) => {
        // console.log(resultObject)
        this.setState((prevState: IState) => ({
            filters: {
                ...prevState.filters,
                'company.name|full': resultObject.shortName
            }
        }))
    }

    handleChangeCityFilter = (resultObject: ICityModel) => {
        // console.log(resultObject)
        this.setState((prevState: IState) => ({
            filters: {
                ...prevState.filters,
                'city.name|full': resultObject.name
            }
        }))
    }

    handleChangePersonFilter = (resultObject: IUserModel) => {
        // console.log(resultObject)
        this.setState((prevState: IState) => ({
            filters: {
                ...prevState.filters,
                'sender|full': resultObject.fio
            }
        }))
    }

    handleChangeDateFilter = (name: string, value: string | moment.Moment) => {

        this.setState((prevState: IState) => 
            ({
                filters: {
                    ...prevState.filters,
                    [name]: value.valueOf(),
                }
            })
        );

    }

    validSecondDate = (current: any) => {
        const selectedDate = this.state.filters['createdDate|from'] ? moment(this.state.filters['createdDate|from']) : moment(0) //Datetime.moment().subtract( 1, 'day' );
        
        return current.isAfter( selectedDate );
    }

    onCheckInOrders = (orders: IOrderModel[]) => {
        let ordersId:number[] = [];
        let ordersPrice:number[] = [];
        let valueWithoutNDS = 0;
        orders.forEach(e => {
            if(e.id !== null && e.price !== null){
                ordersId.push(e.id);
                ordersPrice.push(e.price);
            }
            if(e.price){
                valueWithoutNDS += e.price;
            }
        });
        this.setState((pervState: IState) => ({
            formData:{
                ...pervState.formData,
                ordersPrice,
                ordersId,
                valueWithoutNDS,
                nds: valueWithoutNDS * .2,
                valueWithNDS: valueWithoutNDS + valueWithoutNDS * .2
            }
        }));
    }

    handleChangeActStatusByLogist = (status: ActStatus) => {
        this.props.changeActStatusByLogist({status, id: this.state.formData.id? this.state.formData.id.toString(): ''})
            .then(data => {
                if (data.type === 'CHANGE_ACT_STATUS_BY_LOGIST_SUCCESS') {
                    this.props.openSuccessDialog({});
                    this.props.history.push('/dashboard/order/acts');
                }
            });
    } 

    onPaymentByManager = () => {
        this.handleChangeActStatusByLogist("COMPLETED");
    }

    agreeActByLogist = () => {
        this.handleChangeActStatusByLogist("COMPLETED");
    }

    rejectActByLogist = () => {
        this.handleChangeActStatusByLogist("FOR_EDIT");
    }


    formatToCreate = () => {
        const { ordersId, number, valueWithoutNDS } = this.state.formData;
        this.props.createAct({
            ordersId,
            total: valueWithoutNDS,
            number
        })
        .then((createData:  ICreateActSuccessAction | ICreateActFailureAction) => {
            
            if(createData.type === 'CREATE_ACT_SUCCESS'){
                this.props.openSuccessDialog({});
                this.props.history.push('/dashboard/order/acts');
            }
        })
    }

    formatToEdit = () => {
        const { ordersId, number, valueWithoutNDS } = this.state.formData;
        this.props.editAct({
            id: this.state.formData.id? this.state.formData.id.toString(): '',
            ordersId,
            total: valueWithoutNDS,
            number
        })
        .then(editData => {
            if(editData.type === 'EDIT_ACT_SUCCESS'){
                this.props.openSuccessDialog({});
                this.props.history.push('/dashboard/order/acts');
            }
        })
    }

    writeComment = () => {
        this.props.createActComment({id: this.props.match.params.id? this.props.match.params.id: '', comment: this.state.formData.comment})
            .then(data => {
                if(data.type === 'GET_ADD_ACT_COMMENT_SUCCESS'){
                    this.props.getActById(this.props.match.params.id? this.props.match.params.id.toString(): '')
                    .then(this.getUpdatedAct);
                }
            });
    };



    bottomPart = (): JSX.Element => {
        return <> 
            <Section withoutHeader>
                <div className="price-act-result-block">
                    <div className="act-price-nds-block">
                        <p>{this.props.loc.ActBlock_NDSTitle}</p>
                        <p>{this.state.formData.nds}</p>
                    </div>
                    <div className="act-price-result-block">
                        <p>{this.props.loc.ActBlock_ResultWithNDS}</p>
                        <p>{this.state.formData.valueWithNDS}</p>
                    </div>
                </div>
            </Section>
            <Section withoutHeader>
                <InputField disabled={this.state.formData.status === 'FOR_PAY' || this.state.formData.status === 'COMPLETED' || this.props.isLogist} padding label={this.props.loc.ActBlock_ResultActLabel} type="number" onChange={this.handleChangeFormField} value={this.state.formData.number? this.state.formData.number: null} name='number'/>
            </Section>
            <div className="act-btn-block">
            {
                (this.props.create && this.props.isManager) || (this.state.formData.status === 'FOR_EDIT' && this.props.isManager) ?
                    // <div className="act-btn-block">
                        <Button padding disabled={!(this.state.formData.number !== null && this.state.formData.ordersId.length && this.state.formData.ordersPrice.indexOf(0) === -1 && this.state.formData.valueWithoutNDS > 0)} col={3} color={'primary'} onClick={()=> {this.formatToCreate()}} >{this.props.loc.ActBlock_ForPay}</Button>
                    // </div>
                :
                    null
            }

            {
                (this.state.formData.status === 'FOR_EDIT' &&  this.props.isManager)?
                    // <div className="act-btn-block">
                        <Button padding disabled={!(this.state.formData.number !== null && this.state.formData.ordersId.length && this.state.formData.ordersPrice.indexOf(0) === -1 && this.state.formData.valueWithoutNDS > 0)} col={3} color={'primary'} onClick={()=> {this.formatToEdit()}} >{this.props.loc.Button_Save}</Button>
                    // {/* </div> */}
                :
                    null
            }
            </div>
            
            {
                this.props.edit && this.state.formData.status === "FOR_PAY" && this.props.isLogist && !this.props.act.isFetching?
                    <div className="act-btn-block">
                        <Button col={2.5} padding color={'green'} onClick={()=> {this.agreeActByLogist()}} >{this.props.loc.ActBlock_AcceptActBtn}</Button>
                        <Button col={2.5} padding color={'red'} onClick={()=> {this.rejectActByLogist()}} >{this.props.loc.ActBlock_RejectActBtn}</Button>
                    </div>
                :
                    null
            }
            {
                this.props.edit ?
                    <Section centerTitle title={this.props.loc.OrderBlock_CommentsTitle}>
                            <div className={'wright-comment-block'}>
                                <Icon>{this.comment_icon}</Icon>
                                <div className={'comment-wright-field'}>
                                <InputField hideLabel col={12} padding={false} placeholder={this.props.loc.OderBlock_CommentSectionTitle}
                                            label={this.props.loc.OderBlock_CommentSectionTitle} type="multiline" onChange={(e) => {
                                    this.handleChangeFormField(e)
                                }} value={this.state.formData.comment} name='comment'/>
                                </div>
                                <div className="btn-wright-wrapper">
                                    <Button col={4} onClick={this.writeComment}>{this.props.loc.OrderBlock_SendBtn}</Button>
                                </div>
                                
                            </div>
                            {
                                    Object.keys(this.state.formData.comments).length?
                                        Object.keys(this.state.formData.comments).map((elem, num) =>
                                            <div key={elem + num} className={`event-list without-lines`}>
                                                <div className={'time'}>
                                                    <p>{formatDate(this.state.formData.comments[elem].createdDate)}</p>
                                                    <p>{formatTime(this.state.formData.comments[elem].createdDate)}</p>
                                                </div>
                                                <div className={'status'}>
                                                    <Icon>{this.comment_icon}</Icon>
                                                    <p>{this.state.formData.comments[elem].text}</p>
                                                </div>
                                                <p>{this.state.formData.comments[elem].author}</p>
                                            </div>
                                        )
                                    :
                                    <p className={'msg'}>{this.props.loc.OrderBlock_CommentsNull}</p>

                            }
                    </Section>
                :
                    null
            }
        </>
    }

    render() {
        const { loc } = this.props;
        return (
            <>
                <PageTemplate 
                    pageTitle={loc.ActBlock_PageTitle} 
                    pageIcon={'assignment'}
                    check
                    onCheck={this.onCheckInOrders}
                    // view={true}
                    // viewTriggerKey={"read"}
                    // onCheck={this.onCheckInOrders}
                    tableHeaders={[
                        loc.ActBlock_OrderNumberColumn,
                        loc.ActBlock_UploadDateColumn,
                        loc.ActBlock_RouteColumn,
                        loc.ActBlock_TCNumberColumn,
                        loc.ActBlock_TCMarkColumn,
                        loc.ActBlock_CountColumn,
                        loc.ActBlock_TariffColumn,
                        loc.ActBlock_ValueColumn
                    ]}
                    checkedAll= {this.props.edit}
                    listData={ this.props.create? this.props.act.candidatesList : this.props.order.list}
                    // listData={
                    //     [
                    //         {
                    //             "action": "NEW_COMMENT",
                    //             "company": {
                    //                 "id": "companyId",
                    //                 "name": "companyName"
                    //             },
                    //             "createdDate": 1568749926076,
                    //             "id": "ActId",
                    //             "orderNumber": 67765,
                    //             "orderStatus": "FORMED",
                    //             "read": true,
                    //             "sender": "sender",
                    //             "userId": "userId"
                    //         },
                    //         {
                    //             "action": "NEW_COMMENT",
                    //             "company": {
                    //                 "id": "companyId",
                    //                 "name": "companyName"
                    //             },
                    //             "createdDate": 1568749926076,
                    //             "id": "ActId",
                    //             "orderNumber": 67765,
                    //             "orderStatus": "FORMED",
                    //             "read": true,
                    //             "sender": "sender",
                    //             "userId": "userId"
                    //         },
                    //         {
                    //             "action": "NEW_COMMENT",
                    //             "company": {
                    //                 "id": "companyId",
                    //                 "name": "companyName"
                    //             },
                    //             "createdDate": 1568749926076,
                    //             "id": "ActId",
                    //             "orderNumber": 67765,
                    //             "orderStatus": "FORMED",
                    //             "read": false,
                    //             "sender": "sender",
                    //             "userId": "userId"
                    //         },
                    //         {
                    //             "action": "NEW_COMMENT",
                    //             "company": {
                    //                 "id": "companyId",
                    //                 "name": "companyName"
                    //             },
                    //             "createdDate": 1568749926076,
                    //             "id": "ActId",
                    //             "orderNumber": 67765,
                    //             "orderStatus": "FORMED",
                    //             "read": false,
                    //             "sender": "sender",
                    //             "userId": "userId"
                    //         }
                    //     ]
                    // }
                    dataModel={[
                        "id|link",
                        "loadInPoint.uploadDate|structDate",
                        "route.name|struct",
                        "transport.car.registerNumber|struct",
                        "transport.car.mark|struct",
                        "tariff.time|struct",
                        "tariff.value|struct",
                        "price"
                        // "company.name|struct",
                        // "orderStatus|type",
                        // "action|type"
                    ]}
                    isLoading={this.props.act.isFetching}
                    pageble={this.props.act.pageble? this.props.act.pageble : {
                        currentPage: 0,
                        totalElements: 0,
                        totalPages: 0
                    }}
                    onChangePage={this.changePageNumber}
                    typesDictionary={{
                        "orderStatus": {
                            "FORMED": loc.Act_OrderStatus_FORMED,
                            "UNDER_CONSIDERATION": loc.Act_OrderStatus_UNDER_CONSIDERATION,
                            "REJECTED": loc.Act_OrderStatus_REJECTED,
                            "AGREED": loc.Act_OrderStatus_AGREED,
                            "ON_ROUTE": loc.Act_OrderStatus_ON_ROUTE,
                            "COMPLETED": loc.Act_OrderStatus_COMPLETED,
                            "ARCHIEVED": loc.Act_OrderStatus_ARCHIEVED,
                            "CHANGED": loc.Act_OrderStatus_CHANGED
                        },
                        "action": {
                            "NEW_COMMENT": loc.Act_Action_NEW_COMMENT,
                            "STATUS_CHANGED": loc.Act_Action_STATUS_CHANGED,
                            "ORDER_CHANGED": loc.Act_Action_ORDER_CHANGED
                        }

                    }}
                    linksDictionary={{
                        "id": "/dashboard/order/edit/"
                    }}
                    onSearch={this.handleSearch}
                    onResetSearch={this.handleResetSearch}
                    onSort={this.handleSort}
                    actualSort={this.state.sort}
                    // boldFromKey={"read"}
                    onView={this.handleView}
                    insertBottom={this.bottomPart}
                >
                    <InputField label={loc.ActBlock_OrderNumberFilter} type="number" onChange={this.handleChangeFilter} value={this.state.filters['orderNumber|full']? this.state.filters['orderNumber|full']: null} name='orderNumber|full'/>
                    <InputField label={loc.ActBlock_DateFilter} type="date" changeDate={this.handleChangeDateFilter} value={this.state.filters['createdDate|from'] && typeof this.state.filters['createdDate|from'] === 'number'? formatDate(this.state.filters['createdDate|from']): ''} name='createdDate|from'/>
                    <InputField isValidDate={this.validSecondDate} label={loc.ActBlock_Date2Filter} type="date" changeDate={this.handleChangeDateFilter} value={this.state.filters['createdDate|to']  && typeof this.state.filters['createdDate|to'] === 'number'? formatDate(this.state.filters['createdDate|to']): ''} name='createdDate|to'/>
                    <InputField label={loc.ActBlock_TCNumberFilter} type="person" onSelect={this.handleChangePersonFilter} onChange={this.handleChangeFilter} value={this.state.filters['sender|full']? this.state.filters['sender|full']: ""} name='sender|full'/>
                    <InputField label={loc.ActBlock_TCMarkFilter} type="company" onSelect={this.handleChangeCompanyFilter} onChange={this.handleChangeFilter} value={this.state.filters['company.name|full']? this.state.filters['company.name|full']: ""} name='company.name|full'/>
                    
                </PageTemplate>

                <Dialog
                    open={this.state.readAllDialogOpen}
                    onClose={this.handleCloseReadAllDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div className="dialog-window">
                        <p>{`${loc.ActBlock_Modal_ReadAll}`}</p>
                        <div className="btn-block">
                            {/* <Button padding col={4} color={'primary'} onClick={() => {this.props.readAllActs();this.handleCloseReadAllDialog()}}>{loc.Button_Continue}</Button> */}
                            <Button padding col={4} onClick={() => {this.handleCloseReadAllDialog()}}>{loc.Button_Cancel}</Button>
                        </div>  
                        
                    </div>
                </Dialog>
            </>
        )
    }
}

const mapStateToProps = (state: IAppState) => ({
    locale: state.locale,
    act: state.act,
    loc: state.locale.currentLocaleJSON,
    order: state.order,
    isLogist: state.user.userInfo.authorities[0] === 'ROLE_MANAGER_LOGIST',
    isManager: state.user.userInfo.authorities[0] === 'ROLE_TC_MANAGER'
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
    // initLocales: () => dispatch(initLocales(config)),
    // getUserInfo: () => dispatch(getUserInfo())
    getActList: (params: ISearchParams) => dispatch(getActList(params)),
    getActCandidates: (params: ISearchParams) => dispatch(getActCandidates(params)),
    // readCurrentAct: (id: string) => dispatch(readCurrentAct(id)),
    getOrderList: (params: ISearchParams) => dispatch(getOrderList(params)),
    openSuccessDialog: (data: IDoneDialogData) => dispatch(openDoneDialog(data)),
    // readAllActs: () => dispatch(readAllActs())
    changeActStatusByLogist: (statusData: IActChangeStatusModel) => dispatch(changeActStatusByLogist(statusData)),
    changeActStatusByManager: (statusData: IActChangeStatusModel) => dispatch(changeActStatusByManager(statusData)),
    getActById: (id: string) => dispatch(getActById(id)),
    createAct: (orderData: IActCreateModel) => dispatch(createAct(orderData)),
    createActComment: (orderData: IActCreateCommentModel) => dispatch(addActComment(orderData)),
    editAct: (orderData: IActEditModel) => dispatch(editAct(orderData)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ActBlock)
