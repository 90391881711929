import { 
    REQUEST, 
    SUCCESS, 
    FAILURE, 
    IErrorPayload
} from '../types';
    
import {
    ITariffState,
    TariffActions,
} from "./types";

import { Reducer } from 'redux';

const initialTariffState: ITariffState = {
    isFetching: false,
    list: [],
    searchParams: {},
    currentTariff: {},
    currentTariffId: '',
    required: [
        'city',
        'company',
        'value',
        'startDate',
        'documentNumber',
        'comeBack',
        'tonnage',
        'type',
        'withSecurity',
    ]
}

export const tariffReducer: Reducer<ITariffState, TariffActions> = (
    state = initialTariffState,
    action,
  ) => {

    const defaultStateWithFetchingTrue = {
        ...state,
        isFetching: true,
    };

    const defaultStateWithFetchingFalse = {
        ...state,
        isFetching: false,
    };

    const defaultErrorState = (action: { payload: IErrorPayload }) => ({
        ...state,
        isFetching: false,
        errorInfo: {
            hasError: true,
            ...action.payload
        },
    });

    switch (action.type) {

        // GET FULL LIST
        case 'GET_TARIFF_LIST_PENDING': {
            return { ...defaultStateWithFetchingTrue };
        }
        case 'GET_TARIFF_LIST_SUCCESS': {
            // console.log(parseJwt(action.authData.data.token))
            return {
                ...state,
                list: [...action.payload.list],
                pageble: {...action.payload.pageble},
                isFetching: false,
            };
        }
        case 'GET_TARIFF_LIST_FAILURE': {
            return {
                ...state,
                isFetching: false,
                errorInfo: {
                    hasError: true,
                    ...action.payload
                },
            };
        }

        // GET BY ID
        case 'GET_TARIFF_BY_ID_PENDING': {
            return { ...defaultStateWithFetchingTrue };
        }
        case 'GET_TARIFF_BY_ID_SUCCESS': {
            // console.log(parseJwt(action.authData.data.token))
            return {
                ...state,
                currentTariff: {...action.payload.record},
                currentTariffId: action.payload.id,
                isFetching: false,
            };
        }
        case 'GET_TARIFF_BY_ID_FAILURE': {
            return { ...defaultErrorState(action) };
        }

        // CREATE 
        case 'CREATE_TARIFF_PENDING': {
            return { ...defaultStateWithFetchingTrue };
        }
        case 'CREATE_TARIFF_SUCCESS': {
            // console.log(parseJwt(action.authData.data.token))
            return {
                ...state,
                isFetching: false,
            };
        }
        case 'CREATE_TARIFF_FAILURE': {
            return { ...defaultErrorState(action) };
        }


        // EDIT
        case 'EDIT_TARIFF_PENDING': {
            return { ...defaultStateWithFetchingTrue };
        }
        case 'EDIT_TARIFF_SUCCESS': {
            // console.log(parseJwt(action.authData.data.token))
            return { ...defaultStateWithFetchingFalse };
        }
        case 'EDIT_TARIFF_FAILURE': {
            return { ...defaultErrorState(action) };
        }


        default:
        neverReached(action); // when a new action is created, this helps us remember to handle it in the reducer
    }
    return state;
};
  
  // tslint:disable-next-line:no-empty
const neverReached = (never: never) => {};