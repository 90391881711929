import Api from "api";
import { ISearchParams, IGetListServerResponse, IDefaultServerResponse, IGetListPayload, IErrorServerResponse, IGetDataListServerResponse, IGetByIdServerResponse, IGetByIdPayload } from "store/types";
import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { 
    IGetTariffListPendingAction,
    IGetTariffListSuccessAction,
    IGetTariffListFailureAction,
    IGetTariffByIdPendingAction,
    IGetTariffByIdSuccessAction,
    IGetTariffByIdFailureAction,
    ICreateTariffPendingAction,
    ICreateTariffSuccessAction,
    ICreateTariffFailureAction,
    IEditTariffPendingAction,
    IEditTariffSuccessAction,
    IEditTariffFailureAction,
    ITariffModel,
    ITariffCreateModel,
    ITariffEditModel,
} from "./types";
import { formatSearchParams } from "services";
import { AxiosResponse, AxiosError } from "axios";

export const getTariffList: ActionCreator<
  ThunkAction<
    Promise<IGetTariffListSuccessAction | IGetTariffListFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    ITariffModel[],                  // The type for the data within the last action
    ISearchParams,                       // The type of the parameter for the nested function 
    IGetTariffListSuccessAction | IGetTariffListFailureAction            // The type of the last action to be dispatched
  >
> = ( searchParams: ISearchParams) => {
    return async (dispatch: Dispatch) => {
        
        const getTariffListPendingAction: IGetTariffListPendingAction = {
            type: 'GET_TARIFF_LIST_PENDING',
        };
        dispatch(getTariffListPendingAction);

        try{
            const response: AxiosResponse<IGetListServerResponse<ITariffModel>> = await Api.get(`/tariffs?${formatSearchParams(searchParams)}`);
            const payload: IGetListPayload<ITariffModel> = {
                list: response.data.data.content,
                searchParams,
                pageble: {
                    currentPage: response.data.data.number,
                    totalPages: response.data.data.totalPages,
                    totalElements: response.data.data.totalElements
                }
            }
            const getTariffListSuccessAction: IGetTariffListSuccessAction = {
                payload,
                type: 'GET_TARIFF_LIST_SUCCESS',
            };
            return dispatch(getTariffListSuccessAction);
        } catch (error) {
            const getTariffListFailureAction: IGetTariffListFailureAction = {
                type: 'GET_TARIFF_LIST_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(getTariffListFailureAction);
        }
    };
};

export const getTariffById: ActionCreator<
  ThunkAction<
    Promise<IGetTariffByIdSuccessAction | IGetTariffByIdFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    IGetByIdPayload<ITariffModel> ,                  // The type for the data within the last action
    string,                       // The type of the parameter for the nested function 
    IGetTariffByIdSuccessAction | IGetTariffByIdFailureAction            // The type of the last action to be dispatched
  >
> = (id: string) => {
    return async (dispatch: Dispatch) => {
        
        const getTariffByIdPendingAction: IGetTariffByIdPendingAction = {
            type: 'GET_TARIFF_BY_ID_PENDING',
        };
        dispatch(getTariffByIdPendingAction);
        console.log('id',id)
        try{
            const response: AxiosResponse<IGetByIdServerResponse<ITariffModel>> = await Api.get(`/tariff/${id}`);
            const payload: IGetByIdPayload<ITariffModel> = {
                record: response.data.data,
                id
            } 
            const getTariffByIdSuccessAction: IGetTariffByIdSuccessAction = {
                payload,
                type: 'GET_TARIFF_BY_ID_SUCCESS',
            };
            return dispatch(getTariffByIdSuccessAction);
        } catch (error) {
            const getTariffByIdFailureAction: IGetTariffByIdFailureAction = {
                type: 'GET_TARIFF_BY_ID_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(getTariffByIdFailureAction);
        }
    };
};


export const createTariff: ActionCreator<
  ThunkAction<
    Promise<ICreateTariffSuccessAction | ICreateTariffFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    ITariffCreateModel,                       // The type of the parameter for the nested function 
    ICreateTariffSuccessAction | ICreateTariffFailureAction            // The type of the last action to be dispatched
  >
> = ( createdData: ITariffCreateModel) => {
    return async (dispatch: Dispatch) => {
        
        const createTariffPendingAction: ICreateTariffPendingAction = {
            type: 'CREATE_TARIFF_PENDING',
        };
        dispatch(createTariffPendingAction);

        try{
            const response: AxiosResponse<IDefaultServerResponse> = await Api.post(`/tariff`, { ...createdData });
            const createTariffSuccessAction: ICreateTariffSuccessAction = {
                type: 'CREATE_TARIFF_SUCCESS',
            };
            return dispatch(createTariffSuccessAction);
        } catch (error) {
            const createTariffFailureAction: ICreateTariffFailureAction = {
                type: 'CREATE_TARIFF_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(createTariffFailureAction);
        }
    };
};

export const editTariff: ActionCreator<
  ThunkAction<
    Promise<IEditTariffSuccessAction | IEditTariffFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    ITariffEditModel,                       // The type of the parameter for the nested function 
    IEditTariffSuccessAction | IEditTariffFailureAction            // The type of the last action to be dispatched
  >
> = ( editData: ITariffEditModel) => {
    return async (dispatch: Dispatch) => {
        
        const editTariffPendingAction: IEditTariffPendingAction = {
            type: 'EDIT_TARIFF_PENDING',
        };
        dispatch(editTariffPendingAction);

        try{
            const response: AxiosResponse<IDefaultServerResponse> = await Api.put(`/tariff`, { ...editData });
            const editTariffSuccessAction: IEditTariffSuccessAction = {
                type: 'EDIT_TARIFF_SUCCESS',
            };
            return dispatch(editTariffSuccessAction);
        } catch (error) {
            const editTariffFailureAction: IEditTariffFailureAction = {
                type: 'EDIT_TARIFF_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(editTariffFailureAction);
        }
    };
};