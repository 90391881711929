import {
    ILocaleState,
    LocaleActions,
    ILocalesStore
} from "./types";

import { Reducer } from 'redux';
// import locales from 'locales';

// type Dictionary = { [index: string]: string }

// type localeKeys = typeof Object.keys(locales.mainLocale);

// const mainLocaleJSON:Dictionary = locales.localeFiles[locales.mainLocale];



const initialUserState: ILocaleState = {
    mainLocale: "",
    currentLocale: "",
    localeDictionaries: {},
    localesList: [],
    currentLocaleJSON: {},
    mainLocaleJSON: {}
    // mainLocaleJSON: getCurrentLocale(locales.localeFiles, locales.mainLocale),//locales.localeFiles[locales.mainLocale],
    // currentLocale: locales.mainLocale,
    // localeModules: locales.localeFiles,
    // currentLocaleJSON: getCurrentLocale(locales.localeFiles, locales.mainLocale)//locales.localeFiles[locales.mainLocale] 
}

export const localeReducer: Reducer<ILocaleState, LocaleActions> = (
    state = initialUserState,
    action,
  ) => {

    switch (action.type) {
        case 'INIT_LOCALES_PENDING':{

            return {
                ...state,
            }
        }
        case 'INIT_LOCALES_SUCCESS':{
            console.log('INIT LOCALS Done')
            return {
                ...state,
                ...action.adaptedLocales
            }
        }
        case 'INIT_LOCALES_FAILURE':{
            return {
                ...state
            }
        }
        case 'SET_LOCALE_PENDING': {

            return {
                ...state
            };
        }
        case 'SET_LOCALE_SUCCESS': {
            console.log('LOCALS Done', action.lang)
            return {
                ...state,
                currentLocale: action.lang,
                currentLocaleJSON: {
                    ...state.localeDictionaries[state.mainLocale],
                    ...state.localeDictionaries[action.lang]
                }
            };
        }
        case 'SET_LOCALE_FAILURE': {

            return {
                ...state,
            };
        }
        default:
            neverReached(action); // when a new action is created, this helps us remember to handle it in the reducer
    }
    return state;
};
  
  // tslint:disable-next-line:no-empty
const neverReached = (never: never) => {};
  