import { Action, ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
// import { sendMessage } from "./store/chat/actions";
// import { AppState } from "../../store";

import {
    REQUEST,
    SUCCESS,
    FAILURE,
    SS_AUTH_TOKEN,
    SS_ROLES,
    ISearchParams,
    IGetListPayload,
    IGetListServerResponse,
    IGetByIdPayload,
    IGetByIdServerResponse,
    IDefaultServerResponse
} from "../types";

import {
    IUserState,
    ILoginData,
    ILoginUserPendingAction,
    ILoginUserSuccessAction,
    ILoginUserFailureAction,
    IAuthUserData,
    IGetUserInfoSuccessAction,
    IGetUserInfoFailureAction,
    IGetUserInfoPendingAction,
    // IUserModel,
    ILogoutUserSuccessAction,
    ILogoutUserFailureAction,
    ILogoutUserPendingAction,
    IGetUserListFailureAction,
    IGetUserListSuccessAction,
    IGetUserListPendingAction,
    IGetUserByIdSuccessAction,
    IGetUserByIdFailureAction,
    IGetUserByIdPendingAction,
    ICreateUserSuccessAction,
    ICreateUserFailureAction,
    ICreateUserPendingAction,
    IEditUserFailureAction,
    IEditUserSuccessAction,
    IEditUserPendingAction,
    IDeleteUserSuccessAction,
    IDeleteUserFailureAction,
    IDeleteUserPendingAction,
    IUserModel,
    IUserCreateModel,
    IUserEditModel,
    IChangeUserStatusFailureAction,
    IChangeUserStatusSuccessAction,
    IChangeUserStatusPendingAction,
    IEditCurrentUserFailureAction,
    IEditCurrentUserSuccessAction,
    IEditCurrentUserPendingAction,
    IEditUserPasswordPendingAction,
    IEditUserPasswordSuccessAction,
    IEditUserPasswordFailureAction,
    IChangeUserPasswordModel,
    ICreateUserPasswordSuccessAction,
    ICreateUserPasswordPendingAction,
    ICreateUserPasswordFailureAction,
    IResetPasswodByEmailPendingAction,
    IResetPasswodByEmailSuccessAction,
    IResetPasswodByEmailFailureAction,
    IChangeUserStatusModel,
    IGetLogistListPendingAction,
    IGetLogistListFailureAction,
    IGetLogistListSuccessAction,
    IGetAdminListPendingAction,
    IGetAdminListFailureAction,
    IGetAdminListSuccessAction,
    ICurrentUserEditModel,
    IGetPersonListPendingAction,
    IGetPersonListSuccessAction,
    IGetPersonListFailureAction,
    ICreateUserPasswordModel
} from "./types";

import Api from "api";
import { parseJwt, formatSearchParams, loginUserToSystem, logoutUserFromSystem } from "services";
import { AxiosResponse } from "axios";

export const loginUser: ActionCreator<
  ThunkAction<
    Promise<ILoginUserSuccessAction | ILoginUserFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    IAuthUserData,                  // The type for the data within the last action
    ILoginData,                       // The type of the parameter for the nested function 
    ILoginUserSuccessAction | ILoginUserFailureAction            // The type of the last action to be dispatched
  >
> = (data: ILoginData) => {
    return async (dispatch: Dispatch) => {
        
        const loginUserPendingAction: ILoginUserPendingAction = {
            type: 'LOGIN_USER_PENDING',
        };
        dispatch(loginUserPendingAction);

        try{
            const authDataResponse = await Api.post('/authenticate', JSON.stringify({email: data.email, password: data.password}));
            const authData: IAuthUserData = authDataResponse.data; 
            console.log(authData)
            const loginUserSuccessAction: ILoginUserSuccessAction = {
                authData,
                type: 'LOGIN_USER_SUCCESS',
            };
            Api.defaults.headers['Authorization'] = authData.data.token;
            // console.log("Api ",Api.defaults.headers)
            // console.log('adasdasdas', data)
            loginUserToSystem(authData.data.token, data.saveMe);
            // sessionStorage.setItem(SS_AUTH_TOKEN, authData.data.token);
            // console.log('parse token',parseJwt(authData.data.token))
            // sessionStorage.setItem(SS_ROLES, JSON.stringify([parseJwt(authData.data.token).auth]));
console.log('1 line before dispatch')
            return dispatch(loginUserSuccessAction);
        } catch (error) {
            const loginUserFailureAction: ILoginUserFailureAction = {
                type: 'LOGIN_USER_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(loginUserFailureAction);
        }
    };
};

export const getUserInfo: ActionCreator<
  ThunkAction<
    Promise<IGetUserInfoSuccessAction | IGetUserInfoFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    IUserModel,                  // The type for the data within the last action
    null,                       // The type of the parameter for the nested function 
    IGetUserInfoSuccessAction | IGetUserInfoFailureAction            // The type of the last action to be dispatched
  >
> = () => {
    return async (dispatch: Dispatch) => {
        
        const getUserInfoPendingAction: IGetUserInfoPendingAction = {
            type: 'GET_USER_INFO_PENDING',
        };
        dispatch(getUserInfoPendingAction);

        try{
            const response: AxiosResponse<IDefaultServerResponse> = await Api.get('/user');
            const userInfo: IUserModel = response.data.data; 
            console.log(userInfo)
            const getUserInfoSuccessAction: IGetUserInfoSuccessAction = {
                userInfo,
                type: 'GET_USER_INFO_SUCCESS',
            };
            return dispatch(getUserInfoSuccessAction);
        } catch (error) {
            const getUserInfoFailureAction: IGetUserInfoFailureAction = {
                type: 'GET_USER_INFO_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(getUserInfoFailureAction);
        }
    };
};


export const getUserList: ActionCreator<
  ThunkAction<
    Promise<IGetUserListSuccessAction | IGetUserListFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    IUserModel[],                  // The type for the data within the last action
    ISearchParams,                       // The type of the parameter for the nested function 
    IGetUserListSuccessAction | IGetUserListFailureAction            // The type of the last action to be dispatched
  >
> = ( searchParams: ISearchParams) => {
    return async (dispatch: Dispatch) => {
        
        const getUserListPendingAction: IGetUserListPendingAction = {
            type: 'GET_USER_LIST_PENDING',
        };
        dispatch(getUserListPendingAction);

        try{
            const response: AxiosResponse<IGetListServerResponse<IUserModel>> = await Api.get(`/users?${formatSearchParams(searchParams)}`);
            const payload: IGetListPayload<IUserModel> = {
                list: response.data.data.content,
                searchParams,
                pageble: {
                    currentPage: response.data.data.number,
                    totalPages: response.data.data.totalPages,
                    totalElements: response.data.data.totalElements
                }
            }
            const getUserListSuccessAction: IGetUserListSuccessAction = {
                payload,
                type: 'GET_USER_LIST_SUCCESS',
            };
            return dispatch(getUserListSuccessAction);
        } catch (error) {
            const getUserListFailureAction: IGetUserListFailureAction = {
                type: 'GET_USER_LIST_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(getUserListFailureAction);
        }
    };
};


export const getLogistList: ActionCreator<
  ThunkAction<
    Promise<IGetLogistListSuccessAction | IGetLogistListFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    IUserModel[],                  // The type for the data within the last action
    ISearchParams,                       // The type of the parameter for the nested function 
    IGetLogistListSuccessAction | IGetLogistListFailureAction            // The type of the last action to be dispatched
  >
> = ( searchParams: ISearchParams) => {
    return async (dispatch: Dispatch) => {
        
        const getLogistListPendingAction: IGetLogistListPendingAction = {
            type: 'GET_LOGIST_LIST_PENDING',
        };
        dispatch(getLogistListPendingAction);

        try{
            const response: AxiosResponse<IGetListServerResponse<IUserModel>> = await Api.get(`/users?${formatSearchParams(searchParams)}`);
            const payload: IGetListPayload<IUserModel> = {
                list: response.data.data.content,
                searchParams,
                pageble: {
                    currentPage: response.data.data.number,
                    totalPages: response.data.data.totalPages,
                    totalElements: response.data.data.totalElements
                }
            }
            const getLogistListSuccessAction: IGetLogistListSuccessAction = {
                payload,
                type: 'GET_LOGIST_LIST_SUCCESS',
            };
            return dispatch(getLogistListSuccessAction);
        } catch (error) {
            const getLogistListFailureAction: IGetLogistListFailureAction = {
                type: 'GET_LOGIST_LIST_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(getLogistListFailureAction);
        }
    };
};


export const getPersonList: ActionCreator<
  ThunkAction<
    Promise<IGetPersonListSuccessAction | IGetPersonListFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    IUserModel[],                  // The type for the data within the last action
    ISearchParams,                       // The type of the parameter for the nested function 
    IGetPersonListSuccessAction | IGetPersonListFailureAction            // The type of the last action to be dispatched
  >
> = ( searchParams: ISearchParams) => {
    return async (dispatch: Dispatch) => {
        
        const getPersonListPendingAction: IGetPersonListPendingAction = {
            type: 'GET_PERSON_LIST_PENDING',
        };
        dispatch(getPersonListPendingAction);

        try{
            const response: AxiosResponse<IGetListServerResponse<IUserModel>> = await Api.get(`/users?${formatSearchParams(searchParams)}`);
            const payload: IGetListPayload<IUserModel> = {
                list: response.data.data.content,
                searchParams,
                pageble: {
                    currentPage: response.data.data.number,
                    totalPages: response.data.data.totalPages,
                    totalElements: response.data.data.totalElements
                }
            }
            const getPersonListSuccessAction: IGetPersonListSuccessAction = {
                payload,
                type: 'GET_PERSON_LIST_SUCCESS',
            };
            return dispatch(getPersonListSuccessAction);
        } catch (error) {
            const getPersonListFailureAction: IGetPersonListFailureAction = {
                type: 'GET_PERSON_LIST_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(getPersonListFailureAction);
        }
    };
};

export const getAdminList: ActionCreator<
  ThunkAction<
    Promise<IGetAdminListSuccessAction | IGetAdminListFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    IUserModel[],                  // The type for the data within the last action
    ISearchParams,                       // The type of the parameter for the nested function 
    IGetAdminListSuccessAction | IGetAdminListFailureAction            // The type of the last action to be dispatched
  >
> = ( searchParams: ISearchParams) => {
    return async (dispatch: Dispatch) => {
        
        const getAdminListPendingAction: IGetAdminListPendingAction = {
            type: 'GET_ADMIN_LIST_PENDING',
        };
        dispatch(getAdminListPendingAction);

        try{
            const response: AxiosResponse<IGetListServerResponse<IUserModel>> = await Api.get(`/users?${formatSearchParams(searchParams)}`);
            const payload: IGetListPayload<IUserModel> = {
                list: response.data.data.content,
                searchParams,
                pageble: {
                    currentPage: response.data.data.number,
                    totalPages: response.data.data.totalPages,
                    totalElements: response.data.data.totalElements
                }
            }
            const getAdminListSuccessAction: IGetAdminListSuccessAction = {
                payload,
                type: 'GET_ADMIN_LIST_SUCCESS',
            };
            return dispatch(getAdminListSuccessAction);
        } catch (error) {
            const getAdminListFailureAction: IGetAdminListFailureAction = {
                type: 'GET_ADMIN_LIST_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(getAdminListFailureAction);
        }
    };
};

export const getUserById: ActionCreator<
  ThunkAction<
    Promise<IGetUserByIdSuccessAction | IGetUserByIdFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    IGetByIdPayload<IUserModel> ,                  // The type for the data within the last action
    string,                       // The type of the parameter for the nested function 
    IGetUserByIdSuccessAction | IGetUserByIdFailureAction            // The type of the last action to be dispatched
  >
> = (id: string) => {
    return async (dispatch: Dispatch) => {
        
        const getUserByIdPendingAction: IGetUserByIdPendingAction = {
            type: 'GET_USER_BY_ID_PENDING',
        };
        dispatch(getUserByIdPendingAction);
        try{
            const response: AxiosResponse<IGetByIdServerResponse<IUserModel>> = await Api.get(`/user/${id}`);
            const payload: IGetByIdPayload<IUserModel> = {
                record: response.data.data,
                id
            } 
            const getUserByIdSuccessAction: IGetUserByIdSuccessAction = {
                payload,
                type: 'GET_USER_BY_ID_SUCCESS',
            };
            return dispatch(getUserByIdSuccessAction);
        } catch (error) {
            const getUserByIdFailureAction: IGetUserByIdFailureAction = {
                type: 'GET_USER_BY_ID_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(getUserByIdFailureAction);
        }
    };
};


export const createUser: ActionCreator<
  ThunkAction<
    Promise<ICreateUserSuccessAction | ICreateUserFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    IUserCreateModel,                       // The type of the parameter for the nested function 
    ICreateUserSuccessAction | ICreateUserFailureAction            // The type of the last action to be dispatched
  >
> = ( createdData: IUserCreateModel) => {
    return async (dispatch: Dispatch) => {
        
        const createUserPendingAction: ICreateUserPendingAction = {
            type: 'CREATE_USER_PENDING',
        };
        dispatch(createUserPendingAction);

        try{
            const response: AxiosResponse<IDefaultServerResponse> = await Api.post(`/user`, { ...createdData, "status": "ACTIVE" });
            const createUserSuccessAction: ICreateUserSuccessAction = {
                type: 'CREATE_USER_SUCCESS',
            };
            return dispatch(createUserSuccessAction);
        } catch (error) {
            const createUserFailureAction: ICreateUserFailureAction = {
                type: 'CREATE_USER_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(createUserFailureAction);
        }
    };
};

export const editUser: ActionCreator<
  ThunkAction<
    Promise<IEditUserSuccessAction | IEditUserFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    IUserEditModel,                       // The type of the parameter for the nested function 
    IEditUserSuccessAction | IEditUserFailureAction            // The type of the last action to be dispatched
  >
> = ( editData: IUserEditModel) => {
    return async (dispatch: Dispatch) => {
        
        const editUserPendingAction: IEditUserPendingAction = {
            type: 'EDIT_USER_PENDING',
        };
        dispatch(editUserPendingAction);

        try{
            const response: AxiosResponse<IDefaultServerResponse> = await Api.put(`/user`, { ...editData });
            const editUserSuccessAction: IEditUserSuccessAction = {
                type: 'EDIT_USER_SUCCESS',
            };
            return dispatch(editUserSuccessAction);
        } catch (error) {
            const editUserFailureAction: IEditUserFailureAction = {
                type: 'EDIT_USER_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(editUserFailureAction);
        }
    };
};

export const createUserPassword: ActionCreator<
  ThunkAction<
    Promise<ICreateUserPasswordSuccessAction | ICreateUserPasswordFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    ICreateUserPasswordModel,                       // The type of the parameter for the nested function 
    ICreateUserPasswordSuccessAction | ICreateUserPasswordFailureAction            // The type of the last action to be dispatched
  >
> = ( passwordData: ICreateUserPasswordModel) => {
    return async (dispatch: Dispatch) => {
        
        const createUserPasswordPendingAction: ICreateUserPasswordPendingAction = {
            type: 'CREATE_USER_PASSWORD_PENDING',
        };
        dispatch(createUserPasswordPendingAction);

        try{
            // Api.defaults.headers['Authorization'] = authData.data.token;
            const response: AxiosResponse<IDefaultServerResponse> = await Api.post(`/user/password`, { newPassword: passwordData.newPassword }, { headers: { 'Authorization': passwordData.token } });
            const createUserPasswordSuccessAction: ICreateUserPasswordSuccessAction = {
                type: 'CREATE_USER_PASSWORD_SUCCESS',
            };
            return dispatch(createUserPasswordSuccessAction);
        } catch (error) {
            const createUserPasswordFailureAction: ICreateUserPasswordFailureAction = {
                type: 'CREATE_USER_PASSWORD_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(createUserPasswordFailureAction);
        }
    };
};


export const editUserPassword: ActionCreator<
  ThunkAction<
    Promise<IEditUserPasswordSuccessAction | IEditUserPasswordFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    IChangeUserPasswordModel,                       // The type of the parameter for the nested function 
    IEditUserPasswordSuccessAction | IEditUserPasswordFailureAction            // The type of the last action to be dispatched
  >
> = ( passwordData: IChangeUserPasswordModel) => {
    return async (dispatch: Dispatch) => {
        
        const editUserPasswordPendingAction: IEditUserPasswordPendingAction = {
            type: 'EDIT_USER_PASSWORD_PENDING',
        };
        dispatch(editUserPasswordPendingAction);

        try{
            const response: AxiosResponse<IDefaultServerResponse> = await Api.put(`/user/password`, { ...passwordData });
            const editUserPasswordSuccessAction: IEditUserPasswordSuccessAction = {
                type: 'EDIT_USER_PASSWORD_SUCCESS',
            };
            return dispatch(editUserPasswordSuccessAction);
        } catch (error) {
            const editUserPasswordFailureAction: IEditUserPasswordFailureAction = {
                type: 'EDIT_USER_PASSWORD_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(editUserPasswordFailureAction);
        }
    };
};

export const resetPasswodByEmailPassword: ActionCreator<
  ThunkAction<
    Promise<IResetPasswodByEmailSuccessAction | IResetPasswodByEmailFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    string,                       // The type of the parameter for the nested function 
    IResetPasswodByEmailSuccessAction | IResetPasswodByEmailFailureAction            // The type of the last action to be dispatched
  >
> = ( email: string) => {
    return async (dispatch: Dispatch) => {
        
        const resetPasswodByEmailPendingAction: IResetPasswodByEmailPendingAction = {
            type: 'RESET_PASSWORD_BY_EMAIL_PENDING',
        };
        dispatch(resetPasswodByEmailPendingAction);

        try{
            const response: AxiosResponse<IDefaultServerResponse> = await Api.post(`/user/password/${email}`, {email});
            const resetPasswodByEmailSuccessAction: IResetPasswodByEmailSuccessAction = {
                type: 'RESET_PASSWORD_BY_EMAIL_SUCCESS',
            };
            return dispatch(resetPasswodByEmailSuccessAction);
        } catch (error) {
            const resetPasswodByEmailFailureAction: IResetPasswodByEmailFailureAction = {
                type: 'RESET_PASSWORD_BY_EMAIL_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(resetPasswodByEmailFailureAction);
        }
    };
};

export const editCurrentUser: ActionCreator<
  ThunkAction<
    Promise<IEditCurrentUserSuccessAction | IEditCurrentUserFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    ICurrentUserEditModel,                       // The type of the parameter for the nested function 
    IEditCurrentUserSuccessAction | IEditCurrentUserFailureAction            // The type of the last action to be dispatched
  >
> = ( editData: ICurrentUserEditModel) => {
    return async (dispatch: Dispatch) => {
        
        const editCurrentUserPendingAction: IEditCurrentUserPendingAction = {
            type: 'EDIT_CURRENT_USER_PENDING',
        };
        dispatch(editCurrentUserPendingAction);

        try{
            const response: AxiosResponse<IDefaultServerResponse> = await Api.put(`/user/self`, { ...editData });
            const editCurrentUserSuccessAction: IEditCurrentUserSuccessAction = {
                type: 'EDIT_CURRENT_USER_SUCCESS',
            };
            return dispatch(editCurrentUserSuccessAction);
        } catch (error) {
            const editCurrentUserFailureAction: IEditCurrentUserFailureAction = {
                type: 'EDIT_CURRENT_USER_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(editCurrentUserFailureAction);
        }
    };
};

export const changeUserStatus: ActionCreator<
  ThunkAction<
    Promise<IChangeUserStatusSuccessAction | IChangeUserStatusFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    IChangeUserStatusModel,                       // The type of the parameter for the nested function 
    IChangeUserStatusSuccessAction | IChangeUserStatusFailureAction            // The type of the last action to be dispatched
  >
> = ( data: IChangeUserStatusModel) => {
    return async (dispatch: Dispatch) => {
        
        const changeUserStatusPendingAction: IChangeUserStatusPendingAction = {
            type: 'CHANGE_USER_STATUS_PENDING',
        };
        dispatch(changeUserStatusPendingAction);

        try{
            const response: AxiosResponse<IDefaultServerResponse> = await Api.put(`/user/status/${data.id}/${data.status}`);
            const changeUserStatusSuccessAction: IChangeUserStatusSuccessAction = {
                type: 'CHANGE_USER_STATUS_SUCCESS',
            };
            return dispatch(changeUserStatusSuccessAction);
        } catch (error) {
            const changeUserStatusFailureAction: IChangeUserStatusFailureAction = {
                type: 'CHANGE_USER_STATUS_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(changeUserStatusFailureAction);
        }
    };
};

export const deleteUser: ActionCreator<
  ThunkAction<
    Promise<IDeleteUserSuccessAction | IDeleteUserFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    string,                       // The type of the parameter for the nested function 
    IDeleteUserSuccessAction | IDeleteUserFailureAction            // The type of the last action to be dispatched
  >
> = ( id: string) => {
    return async (dispatch: Dispatch) => {
        
        const deleteUserPendingAction: IDeleteUserPendingAction = {
            type: 'DELETE_USER_PENDING',
        };
        dispatch(deleteUserPendingAction);

        try{
            const response: AxiosResponse<IDefaultServerResponse> = await Api.delete(`/user/${id}`);
            const deleteUserSuccessAction: IDeleteUserSuccessAction = {
                type: 'DELETE_USER_SUCCESS',
            };
            return dispatch(deleteUserSuccessAction);
        } catch (error) {
            const deleteUserFailureAction: IDeleteUserFailureAction = {
                type: 'DELETE_USER_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(deleteUserFailureAction);
        }
    };
};



export const logoutUser: ActionCreator<
  ThunkAction<
    Promise<ILogoutUserSuccessAction | ILogoutUserFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    null,                       // The type of the parameter for the nested function 
    ILogoutUserSuccessAction | ILogoutUserFailureAction            // The type of the last action to be dispatched
  >
> = () => {
    return async (dispatch: Dispatch) => {
        
        const logoutUserPendingAction: ILogoutUserPendingAction = {
            type: 'LOGOUT_USER_PENDING',
        };
        dispatch(logoutUserPendingAction);

        try{
            
            const logoutUserSuccessAction: ILogoutUserSuccessAction = {
                type: 'LOGOUT_USER_SUCCESS',
            };
            // sessionStorage.removeItem(SS_AUTH_TOKEN);
            // console.log('parse token',parseJwt(authData.data.token))
            // sessionStorage.removeItem(SS_ROLES);
            logoutUserFromSystem();
            return dispatch(logoutUserSuccessAction);
        } catch (e) {
            const logoutUserFailureAction: ILogoutUserFailureAction = {
                type: 'LOGOUT_USER_FAILURE',
            };

            return dispatch(logoutUserFailureAction);
        }
    };
};