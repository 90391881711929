import Api from "api";
import { ISearchParams, IGetListServerResponse, IDefaultServerResponse, IGetListPayload } from "store/types";
import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { 
    IGetNotificationListPendingAction,
    IGetNotificationListSuccessAction,
    IGetNotificationListFailureAction,
    IReadCurrentNotificationPendingAction,
    IReadCurrentNotificationSuccessAction,
    IReadCurrentNotificationFailureAction,
    IReadAllNotificationsPendingAction,
    IReadAllNotificationsSuccessAction,
    IReadAllNotificationsFailureAction, 
    INotificationModel,
    IGetUnreadNotificationListSuccessAction,
    IGetUnreadNotificationListFailureAction,
    IGetUnreadNotificationListPendingAction
 } from "./types";
import { AxiosResponse } from "axios";
import { formatSearchParams } from "services";

export const getNotificationList: ActionCreator<
  ThunkAction<
    Promise<IGetNotificationListSuccessAction | IGetNotificationListFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    IGetListPayload<INotificationModel>,                  // The type for the data within the last action
    ISearchParams,                       // The type of the parameter for the nested function 
    IGetNotificationListSuccessAction | IGetNotificationListFailureAction            // The type of the last action to be dispatched
  >
> = (searchParams: ISearchParams) => {
    return async (dispatch: Dispatch) => {
        
        const getNotificationListPendingAction: IGetNotificationListPendingAction = {
            type: 'GET_NOTIFICATION_LIST_PENDING',
        };
        dispatch(getNotificationListPendingAction);

        try{
            const response: AxiosResponse<IGetListServerResponse<INotificationModel>> = await Api.get(`/notification?${formatSearchParams(searchParams)}`);
            const payload: IGetListPayload<INotificationModel> = {
                list: response.data.data.content,
                searchParams,
                pageble: {
                    currentPage: response.data.data.number,
                    totalPages: response.data.data.totalPages,
                    totalElements: response.data.data.totalElements
                }
            } 
            const getNotificationListSuccessAction: IGetNotificationListSuccessAction = {
                payload,
                type: 'GET_NOTIFICATION_LIST_SUCCESS',
            };
            return dispatch(getNotificationListSuccessAction);
        } catch (error) {
            const getNotificationListFailureAction: IGetNotificationListFailureAction = {
                type: 'GET_NOTIFICATION_LIST_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(getNotificationListFailureAction);
        }
    };
};

export const getUnreadNotificationList: ActionCreator<
  ThunkAction<
    Promise<IGetUnreadNotificationListSuccessAction | IGetUnreadNotificationListFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    IGetListPayload<INotificationModel>,                  // The type for the data within the last action
    ISearchParams,                       // The type of the parameter for the nested function 
    IGetUnreadNotificationListSuccessAction | IGetUnreadNotificationListFailureAction            // The type of the last action to be dispatched
  >
> = (searchParams: ISearchParams) => {
    return async (dispatch: Dispatch) => {
        
        const getUnreadNotificationListPendingAction: IGetUnreadNotificationListPendingAction = {
            type: 'GET_UNREAD_NOTIFICATION_LIST_PENDING',
        };
        dispatch(getUnreadNotificationListPendingAction);

        try{
            const response: AxiosResponse<IGetListServerResponse<INotificationModel>> = await Api.get(`/notification?${formatSearchParams(searchParams)}`);
            const payload: IGetListPayload<INotificationModel> = {
                list: response.data.data.content,
                searchParams,
                pageble: {
                    currentPage: response.data.data.number,
                    totalPages: response.data.data.totalPages,
                    totalElements: response.data.data.totalElements
                }
            } 
            const getUnreadNotificationListSuccessAction: IGetUnreadNotificationListSuccessAction = {
                payload,
                type: 'GET_UNREAD_NOTIFICATION_LIST_SUCCESS',
            };
            return dispatch(getUnreadNotificationListSuccessAction);
        } catch (error) {
            const getUnreadNotificationListFailureAction: IGetUnreadNotificationListFailureAction = {
                type: 'GET_UNREAD_NOTIFICATION_LIST_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(getUnreadNotificationListFailureAction);
        }
    };
};


export const readCurrentNotification: ActionCreator<
  ThunkAction<
    Promise<IReadCurrentNotificationSuccessAction | IReadCurrentNotificationFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    string,                       // The type of the parameter for the nested function 
    IReadCurrentNotificationSuccessAction | IReadCurrentNotificationFailureAction            // The type of the last action to be dispatched
  >
> = (id: string) => {
    return async (dispatch: Dispatch) => {
        
        const readCurrenNotificationPendingAction: IReadCurrentNotificationPendingAction = {
            type: 'READ_CURRENT_NOTIFICATION_PENDING',
        };
        dispatch(readCurrenNotificationPendingAction);

        try{
            const response: IDefaultServerResponse = await Api.put(`/notification/read/${id}`);
            const readCurrenNotificationSuccessAction: IReadCurrentNotificationSuccessAction = {
                type: 'READ_CURRENT_NOTIFICATION_SUCCESS',
            };

            return dispatch(readCurrenNotificationSuccessAction);
        } catch (error) {
            const readCurrenNotificationFailureAction: IReadCurrentNotificationFailureAction = {
                type: 'READ_CURRENT_NOTIFICATION_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(readCurrenNotificationFailureAction);
        }
    };
};


export const readAllNotifications: ActionCreator<
  ThunkAction<
    Promise<IReadAllNotificationsSuccessAction | IReadAllNotificationsFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    null,                       // The type of the parameter for the nested function 
    IReadAllNotificationsSuccessAction | IReadAllNotificationsFailureAction            // The type of the last action to be dispatched
  >
> = () => {
    return async (dispatch: Dispatch) => {
        
        const readAllNotificationsPendingAction: IReadAllNotificationsPendingAction = {
            type: 'READ_ALL_NOTIFICATIONS_PENDING',
        };
        dispatch(readAllNotificationsPendingAction);

        try{
            const response: IDefaultServerResponse = await Api.put(`/notification/read/all`);
            const readAllNotificationsSuccessAction: IReadAllNotificationsSuccessAction = {
                type: 'READ_ALL_NOTIFICATIONS_SUCCESS',
            };

            return dispatch(readAllNotificationsSuccessAction);
        } catch (error) {
            const readAllNotificationsFailureAction: IReadAllNotificationsFailureAction = {
                type: 'READ_ALL_NOTIFICATIONS_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(readAllNotificationsFailureAction);
        }
    };
};