import React from 'react'
import { Icon } from '@material-ui/core';
import "./Card.scss";

interface IProps {
    icon: string;
    title: string;
    // className: string | null;
    width: string;
}

export const Card: React.FC<IProps> = props => {
    return (
        <div style={{width:props.width}} className={`card-with-header `}>
            <div className="card-header">
                <div className="card-header-icon">
                    <Icon>{props.icon}</Icon>
                </div>
                <p>{props.title}</p>
            </div>
            <div className="card-body">
                {props.children}
            </div>
        </div>
    )
}
