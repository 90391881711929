import './VehicleScreen.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ILocaleState, IInitLocalesSuccessAction, IInitLocalesFailureAction, IDictionary,  } from 'store/locale/types';
import { IAppState } from 'store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { initLocales } from 'store/locale/actions';
import config from 'locales/config.json';
import { SS_AUTH_TOKEN, ISearchParams } from 'store/types';
import PageTemplate from 'components/PageTemplate';
import { IGetVehicleListSuccessAction, IGetVehicleListFailureAction, ICreateVehicleSuccessAction, ICreateVehicleFailureAction, IVehicleState, IDeleteVehicleSuccessAction, IDeleteVehicleFailureAction, IVehicleModel, VehicleStatusType, VehicleType, IGetVehicleByIdSuccessAction, IGetVehicleByIdFailureAction, IEditVehicleSuccessAction, IVehicleEditModel, IVehicleCreateModel, IEditVehicleFailureAction, IChangeVehicleStatusSuccessAction, IChangeVehicleStatusFailureAction, IVehileChangeStatusModel } from 'store/vehicle/types';
import { getVehicleList, createVehicle, deleteVehicle, editVehicle, changeVehicleStatus } from 'store/vehicle/actions';
import { Dialog, Icon } from '@material-ui/core';
import { Button } from 'components/Button';
import InputField from 'components/InputField';
import { ICompanyNameModel, ICompanyModel } from 'store/company/types';
import { ICityModel } from 'store/city/types';
import { FormBlock } from 'components/FormBlock/FormBlock';
import { IUserState } from 'store/user/types';
import RoleControl from 'components/RoleControl';
import { validateRequiredFields, getNewSort, handleChangeFilter, getErrorsFromDictionary } from 'services';
import equal from 'deep-equal';
import { IWithPageBase, withPageBase } from 'hoc/withPageBase/withPageBase';



interface IProps extends IWithPageBase {
    locale: ILocaleState;
    loc: IDictionary;
    vehicle: IVehicleState;
    history: any;
    user: IUserState;
    // initLocales: () => Promise<IInitLocalesSuccessAction | IInitLocalesFailureAction>;
    // getVehicleInfo: () => Promise<IGetVehicleInfoSuccessAction | IGetVehicleInfoFailureAction>;
    getVehicleList: (params: ISearchParams) => Promise<IGetVehicleListSuccessAction|IGetVehicleListFailureAction>;
    deleteVehicle: (id: string) => Promise<IDeleteVehicleSuccessAction|IDeleteVehicleFailureAction>;
    getVehicleById: (id: string) => Promise<IGetVehicleByIdSuccessAction|IGetVehicleByIdFailureAction>;
    createVehicle: (data: IVehicleCreateModel) => Promise<ICreateVehicleSuccessAction|ICreateVehicleFailureAction>;
    editVehicle: (data: IVehicleEditModel) => Promise<IEditVehicleSuccessAction|IEditVehicleFailureAction>;
    changeVehicleStatus: (userData: IVehileChangeStatusModel) => Promise<IChangeVehicleStatusSuccessAction|IChangeVehicleStatusFailureAction>;
    administration?: boolean;
}

interface IState {
    page: number;
    q: any;
    sort: string;
    // deleteDialogOpen: boolean;
    deleteRow: any | null;
    formDialogOpen: boolean;
    formRow: IVehicleModel | null;
    filters: {[key: string]: string};//{field: string, value: string}[];//{[key: string]: string};
    activeTab: string;
    edit: boolean;
    create: boolean;
    // beforeEdit: IVehicleModel | null;
    formFields: {
        bodyType: string;
        comment: string;
        company: ICompanyNameModel;
        high: number | null;
        length: number | null;
        loadCapacity: number | null;
        mark: string;
        model: string;
        registrationNumber: string;
        status: VehicleStatusType | null;
        trackerNumber: string;
        type: string;
        vehicleType: VehicleType | null;
        width: number | null;
        blockComment: string;
        id: string;
    }
}

export class VehicleScreen extends Component<IProps> {
    state: IState = {
        q: {

        },
        page: 0,
        sort: '',
        formDialogOpen: false,
        formRow: null,
        // deleteDialogOpen: false,
        deleteRow: null,
        filters: {
            'vehicleType|full': 'CAR'
        },
        edit: false,
        create: false,
        activeTab: 'car',
        // beforeEdit: null,
        formFields: {
            id: '',
            bodyType: '',
            comment: '',
            company: {
                name: '',
                id: ''
            },
            high: null,
            length: null,
            loadCapacity: null,
            mark: '',
            model: '',
            registrationNumber: '',
            status: null,
            trackerNumber: '',
            type: '',
            vehicleType: null,
            width: null,
            blockComment: ''
        }
    }

    beforeEdit = {};

    handleChangeFilter = this.props.handleChangeFilter.bind(this);
    handleChangeCompanyFilter = this.props.handleChangeCompanyFilter.bind(this);
    handleChangeCityFilter = this.props.handleChangeCityFilter.bind(this);

    handleChangeField = this.props.handleChangeFieldInForm.bind(this);


    componentWillMount = () => {
        this.props.getVehicleList({page: this.state.page, q: {...this.state.filters}});
    }

    changePageNumber = (page: number) => {
        this.props.getVehicleList({page, q: {...this.state.filters}, sort: this.state.sort})
            .then(data => {
                this.setState({page});
            });
    }

    handleDeleteDialog = (row: any) => {
        // this.setState({deleteDialogOpen: true, deleteRow: {...row}});
        const { loc } = this.props;
        this.setState({
            deleteRow: {...row}
        },
        () => {
            this.props.handleDeleteItem(
                `${loc.VehicleScreen_TryToDelete}`,
                () => {
                    this.deleteRecord();
                }
            );
        });
    }

    deleteRecord = () => {
        this.props.deleteVehicle(this.state.deleteRow !== null && this.state.deleteRow.id? this.state.deleteRow.id: "")
            .then(dataDelete => {
                if(dataDelete.type === 'DELETE_VEHICLE_SUCCESS'){
                    this.props.openSuccessDialog();
                    this.props.getVehicleList({page: this.state.page, q: {...this.state.filters}, sort: this.state.sort});
                    // this.handleCloseDeleteDialog();
                } else {
                    this.props.openErrorDialog({});
                }
               
            })
    }

    handleEdit = (row: any) => {
        // this.props.history.push(`/dashboard/vehicle/edit/${row.id}`)
        this.beforeEdit = {...row}
        this.setState({
            formDialogOpen: true, 
            formFields: {...row},
            // beforeEdit: {...row},
            edit: true,
            create: false
        });
    }

    handleAdd = () => {
        this.setState({
            formDialogOpen: true,
            edit: false,
            create: true
        })
    }

    handleSort = (sortString: string) => {
        const newSort = getNewSort(sortString, this.state);
        this.props.getVehicleList({page: this.state.page, q: {...this.state.filters}, sort: newSort});
        this.setState({sort: newSort});
        
    }


    handleCloseFormDialog = () => {
        this.setState({formDialogOpen: false});
    }

    handleCloseDeleteDialog = () => {
        this.setState({deleteDialogOpen: false});
    }

    handleSearch = () => {
        this.props.getVehicleList({page: 0, q: {...this.state.filters}, sort: this.state.sort})
            .then(() => {
                this.setState({page: 0});
            });
    }

    handleResetSearch = () => {
        this.props.getVehicleList({page: 0, q: {'vehicleType|full': this.state.activeTab.toUpperCase()}, sort: this.state.sort});
        this.setState((prevState: IState) => ({
            page: 0,
            filters: {'vehicleType|full': this.state.activeTab.toUpperCase()}
        }))
    }

    // handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     const Event = event;
    //     const field = Event.target.name//.split('|');
    //     const value = Event.target.type == 'number'? +Event.target.value : Event.target.value;
    //     // console.log('filter value',value)
    //     this.setState((prevState: any) => ({
    //         filters: {
    //             ...prevState.filters,
    //             [field]: value
    //         }
    //     }))
    // }

    // handleChangeCompanyFilter = (resultObject: ICompanyModel) => {
    //     this.setState((prevState: IState) => ({
    //         filters: {
    //             ...prevState.filters,
    //             'company.name|full': resultObject.shortName
    //         }
    //     }))
    // }

    // handleChangeCityFilter = (resultObject: ICityModel) => {
    //     this.setState((prevState: IState) => ({
    //         filters: {
    //             ...prevState.filters,
    //             'city.name|full': resultObject.name
    //         }
    //     }))
    // }

    getDataModel = (): string[] => {
        if(this.props.administration){
            switch (this.state.activeTab) {
                case 'car':
                    return [
                        'registrationNumber',
                        'mark', 
                        'company.name|struct',
                        'model', 
                        'type', 
                        'bodyType',
                        'loadCapacity'
                    ]
                    break;
                case 'trailer':
                    return [
                        'registrationNumber',
                        'mark', 
                        'company.name|struct', 
                        'type', 
                        'loadCapacity'
                    ]
                    break;
                default:
                    return [];
                    break;
            }
        } else {
            switch (this.state.activeTab) {
                case 'car':
                    return [
                        'registrationNumber',
                        'mark', 
                        'model', 
                        'type', 
                        'bodyType',
                        'loadCapacity'
                    ]
                    break;
                case 'trailer':
                    return [
                        'registrationNumber',
                        'mark',
                        'type', 
                        'loadCapacity'
                    ]
                    break;
                default:
                    return [];
                    break;
            }
        }
        
    }

    getHeaders = (): string[] => {
        const { loc } = this.props;
        if(this.props.administration){
            switch (this.state.activeTab) {
                case 'car':
                    return [
                        loc.VehicleScreen_CarTab_GovernmentNumberColumn, 
                        loc.VehicleScreen_CarTab_MarkColumn, 
                        loc.VehicleScreen_CarTab_CompanyColumn, 
                        loc.VehicleScreen_CarTab_ModelColumn, 
                        loc.VehicleScreen_CarTab_VehicleTypeColumn, 
                        loc.VehicleScreen_CarTab_BodyTypeColumn,
                        loc.VehicleScreen_CarTab_CarryingColumn 
                    ]
                    break;
                case 'trailer':
                    return [
                        loc.VehicleScreen_TrailerTab_GovernmentNumberColumn, 
                        loc.VehicleScreen_TrailerTab_MarkColumn, 
                        loc.VehicleScreen_TrailerTab_CompanyColumn, 
                        loc.VehicleScreen_TrailerTab_TrailerTypeColumn, 
                        loc.VehicleScreen_TrailerTab_CarryingColumn,
                    ]
                    break;
                default:
                    return [];
                    break;
            }
        } else {
            switch (this.state.activeTab) {
                case 'car':
                    return [
                        loc.VehicleScreen_CarTab_GovernmentNumberColumn, 
                        loc.VehicleScreen_CarTab_MarkColumn, 
                        loc.VehicleScreen_CarTab_ModelColumn, 
                        loc.VehicleScreen_CarTab_VehicleTypeColumn, 
                        loc.VehicleScreen_CarTab_BodyTypeColumn,
                        loc.VehicleScreen_CarTab_CarryingColumn 
                    ]
                    break;
                case 'trailer':
                    return [
                        loc.VehicleScreen_TrailerTab_GovernmentNumberColumn, 
                        loc.VehicleScreen_TrailerTab_MarkColumn, 
                        loc.VehicleScreen_TrailerTab_TrailerTypeColumn, 
                        loc.VehicleScreen_TrailerTab_CarryingColumn,
                    ]
                    break;
                default:
                    return [];
                    break;
            }
        }
    }

    getFilterList = (): JSX.Element | null => {
        const { loc } = this.props;
        const carFiltersBase = () => <>
            <InputField label={loc.VehicleScreen_CarTab_GovernmentNumberFilter} type="text" onChange={this.handleChangeFilter} value={this.state.filters['registrationNumber|regex']? this.state.filters['registrationNumber|regex']: ""} name='registrationNumber|regex'/>
            <InputField label={loc.VehicleScreen_CarTab_CarryingFilter} type="select" onChange={this.handleChangeFilter} value={this.state.filters['loadCapacity|full']? this.state.filters['loadCapacity|full']: null} name="loadCapacity|full"
                options={[
                        {
                            value: 10,
                            label: '10',
                        },
                        {
                            value: 15,
                            label: '15',
                        },
                        {
                            value: 20,
                            label: '20',
                        },
                    ]}/>
        </>;

        const trailerFiltersBase = () => <>
            <InputField label={loc.VehicleScreen_TrailerTab_GovernmentNumberFilter} type="text" onChange={this.handleChangeFilter} value={this.state.filters['registrationNumber|regex']? this.state.filters['registrationNumber|regex']: ""} name='registrationNumber|regex'/>
            <InputField label={loc.VehicleScreen_TrailerTab_CarryingFilter} type="select" onChange={this.handleChangeFilter} value={this.state.filters['loadCapacity|full']? this.state.filters['loadCapacity|full']: null} name="loadCapacity|full"
                options={[
                        {
                            value: 10,
                            label: '10',
                        },
                        {
                            value: 15,
                            label: '15',
                        },
                        {
                            value: 20,
                            label: '20',
                        },
                    ]}/>
        </>;

        if(this.props.administration){
            switch (this.state.activeTab) {
                case 'car':
                    return (
                        <>
                            { carFiltersBase() }
                            <InputField label={loc.VehicleScreen_CarTab_CompanyFilter} type="company" onSelect={this.handleChangeCompanyFilter} onChange={this.handleChangeFilter} value={this.state.filters['company.name|full']? this.state.filters['company.name|full']: ""} name='company.name|full'/>
                        </>
                    )
                    break;
                case 'trailer':
                    return (
                        <>
                            { trailerFiltersBase() }
                            <InputField label={loc.VehicleScreen_TrailerTab_CompanyFilter} type="company" onSelect={this.handleChangeCompanyFilter} onChange={this.handleChangeFilter} value={this.state.filters['company.name|full']? this.state.filters['company.name|full']: ""} name='company.name|full'/>
                        </>
                    )
                    break;
                default:
                    return null;
                    break;
            }
        } else {
            switch (this.state.activeTab) {
                case 'car':
                    return (
                        <>
                            { carFiltersBase() }
                            {/* <InputField label={loc.VehicleScreen_CarTab_GovernmentNumberFilter} type="text" onChange={this.handleChangeFilter} value={this.state.filters['registrationNumber|full']? this.state.filters['registrationNumber|full']: ""} name='registrationNumber|full'/>
                            <InputField label={loc.VehicleScreen_CarTab_CarryingFilter} type="select" onChange={this.handleChangeFilter} value={this.state.filters['loadCapacity|full']? this.state.filters['loadCapacity|full']: null} name="loadCapacity|full"
                                options={[
                                        {
                                            value: 10,
                                            label: '10',
                                        },
                                        {
                                            value: 15,
                                            label: '15',
                                        },
                                        {
                                            value: 20,
                                            label: '20',
                                        },
                                    ]}/> */}
                        </>
                    )
                    break;
                case 'trailer':
                    return (
                        <>
                            { trailerFiltersBase() }
                            {/* <InputField label={loc.VehicleScreen_TrailerTab_GovernmentNumberFilter} type="text" onChange={this.handleChangeFilter} value={this.state.filters['registrationNumber|full']? this.state.filters['registrationNumber|full']: ""} name='registrationNumber|full'/>
                            <InputField label={loc.VehicleScreen_TrailerTab_CarryingFilter} type="select" onChange={this.handleChangeFilter} value={this.state.filters['loadCapacity|full']? this.state.filters['loadCapacity|full']: null} name="loadCapacity|full"
                                options={[
                                        {
                                            value: 10,
                                            label: '10',
                                        },
                                        {
                                            value: 15,
                                            label: '15',
                                        },
                                        {
                                            value: 20,
                                            label: '20',
                                        },
                                    ]}/> */}
                        </>
                    )
                    break;
                default:
                    return null;
                    break;
            }
        }
    }

    handleTab = (tabName: string) => {
        this.setState((prevState: IState) => ({
            page: 0,
            sort: '',
            activeTab: tabName,
            filters: {
                // ...prevState.filters,
                'vehicleType|full': tabName.toUpperCase()
            },
            formFields: {
                id: '',
                bodyType: '',
                comment: '',
                company: {
                    name: '',
                    id: ''
                },
                high: null,
                length: null,
                loadCapacity: null,
                mark: '',
                model: '',
                registrationNumber: '',
                status: null,
                trackerNumber: '',
                type: '',
                vehicleType: null,
                width: null,
                blockComment: ''
            }
        }), 
        () => {
            this.props.getVehicleList({page: this.state.page, q: {...this.state.filters}});
        });   
    }

    // handleChangeField = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     // console.log(event.target, event.target.name, event.target.value);
    //     let name = event.target.name;
    //     let type = event.target.type
    //     let value = type === 'number'? +event.target.value : event.target.value;

    //         this.setState((prevState: IState) => 
    //             ({
    //                 ...prevState,
    //                 formFields:{
    //                     ...prevState.formFields,
    //                     [name]: value
    //                 }
                    
    //             })
    //         );
    // }

    handleBlock = () => {
        if(this.isDisableEdit()){
            const newStatus: VehicleStatusType = this.state.formFields.status === 'BLOCKED'? 'ACTIVE' : 'BLOCKED';

            this.props.changeVehicleStatus({status: newStatus, id: this.state.formFields.id})
                .then((data) => {
                    if(data.type === "CHANGE_VEHICLE_STATUS_FAILURE"){
                        let error = this.props.locale.currentLocaleJSON[`ERROR_${data.payload.error}`]? this.props.locale.currentLocaleJSON[`ERROR_${data.payload.error}`]: this.props.locale.currentLocaleJSON[`ERROR_UnknownError`];
                        this.setState({displayErrorDialog: true, errorMessages: [error]});
                    } else {
                        this.props.getVehicleList({page: this.state.page, q: {...this.state.filters}, sort: this.state.sort});
                        this.setState({
                            formFields: {
                                ...this.state.formFields,
                                status: newStatus
                            }
                        })
                        // this.props.getVehicleById(this.props.match.params.id)
                        //     .then(() => {
                                
                        //     });

                    }
                    
                });
        }
    }

    handleCancel = () =>{
        // this.setState({formDialogOpen: false});
        this.props.handleCancelEditDialog.apply(this, [this.editIsValid()]);
    }

    handleRecordResult = (data:  ICreateVehicleSuccessAction | ICreateVehicleFailureAction | IEditVehicleSuccessAction | IEditVehicleFailureAction, errorType: 'CREATE_VEHICLE_FAILURE' | 'EDIT_VEHICLE_FAILURE') => {
        if(data.type === errorType){
            // let error = this.props.locale.currentLocaleJSON[`ERROR_${data.payload.error}`]? this.props.locale.currentLocaleJSON[`ERROR_${data.payload.error}`]: this.props.locale.currentLocaleJSON[`ERROR_UnknownError`];
            // this.setState({displayErrorDialog: true, errorMessages: [error]});
            this.props.openErrorDialog(
                {
                    messages: getErrorsFromDictionary(this.props.locale.currentLocaleJSON, [data.payload.error])  
                }
            );
        } else {
            this.props.openSuccessDialog();
            this.props.getVehicleList({page: this.state.page, q: {...this.state.filters}, sort: this.state.sort});
            //this.props.history.push(`/dashboard/administration/users`);
            this.setState({
                formDialogOpen: false,
                formFields: {
                    id: '',
                    bodyType: '',
                    comment: '',
                    company: {
                        name: '',
                        id: ''
                    },
                    high: null,
                    length: null,
                    loadCapacity: null,
                    mark: '',
                    model: '',
                    registrationNumber: '',
                    status: null,
                    trackerNumber: '',
                    type: '',
                    vehicleType: null,
                    width: null,
                    blockComment: ''
                }
            });
        }
        
    }

    handleCreateRecord = () => {
        let { id, company, ...restData } = this.state.formFields;
        let type: VehicleType = this.state.activeTab === 'car'? 'CAR' : 'TRAILER';
        this.props.createVehicle({...restData, company: this.props.user.userInfo.company, vehicleType: type})
                .then(data => { this.handleRecordResult(data, 'CREATE_VEHICLE_FAILURE') });
    }

    handleEditRecord = () => {
        let { company, ...restData } = this.state.formFields;
        this.props.editVehicle({...restData, company: this.props.user.userInfo.company })
                .then(data => {this.handleRecordResult(data, 'EDIT_VEHICLE_FAILURE')});
    }

    isDisableEdit = () => {
        return this.props.user.userInfo.authorities[0] === 'ROLE_MANAGER_LOGIST';
    }

    additionIsValid = () => {
        let { status, id, ...restData } = this.state.formFields;
        return validateRequiredFields(this.props.vehicle.required? this.props.vehicle.required : [], {...restData });
    }

    editIsValid = ():boolean => {
        // let { status, id, ...restData } = this.state.formFields;
        // let { id: idBefore, status: statusBefore, ...beforeUpdate } = this.state.beforeEdit? this.state.beforeEdit: {id: '', status: ''};
        // let afterUpdate = {...restData }
        console.log('equal(',this.beforeEdit, this.state.formFields,')')
        return equal(this.beforeEdit, this.state.formFields);
    }

    render() {
        const { loc } = this.props;
        const { activeTab } = this.state;
        
        return (
            <>
                <PageTemplate 
                    pageTitle={loc.VehicleScreen_PageTitle} 
                    pageIcon={this.props.administration? 'vpn_key': 'local_shipping'}
                    tabs={
                        [
                            {
                                name: loc.VehicleScreen_CarTabTitle,
                                value: 'car'
                            },
                            {
                                name: loc.VehicleScreen_TrailerTabTitle,
                                value: 'trailer'
                            }
                        ]
                    }
                    print
                    onTab={this.handleTab}
                    tabCurrentValue={this.state.activeTab}
                    edit={true}
                    delete={this.props.user.userInfo.authorities[0] === 'ROLE_TC_MANAGER'}
                    tableHeaders={this.getHeaders()}
                    listData={this.props.vehicle.list}
                    dataModel={this.getDataModel()}
                    isLoading={this.props.vehicle.isFetching}
                    pageble={this.props.vehicle.pageble? this.props.vehicle.pageble : {
                        currentPage: 0,
                        totalElements: 0,
                        totalPages: 0
                    }}
                    onChangePage={this.changePageNumber}
                    onDelete={this.handleDeleteDialog}
                    onEdit={this.handleEdit}
                    onSearch={this.handleSearch}
                    onResetSearch={this.handleResetSearch}
                    onSort={this.handleSort}
                    actualSort={this.state.sort}
                >
                    {this.getFilterList()}
                </PageTemplate>
                {/* <Dialog
                    open={this.state.deleteDialogOpen}
                    onClose={this.handleCloseDeleteDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div className="dialog-window">
                        <p>{`${loc.VehicleScreen_TryToDelete}`}</p>
                        <div className="btn-block">
                            <Button padding col={4} color={'primary'} onClick={() => {this.deleteRecord()}}>{loc.Button_Continue}</Button>
                            <Button padding col={4} onClick={() => {this.handleCloseDeleteDialog()}}>{loc.Button_Cancel}</Button>
                        </div>  
                        
                    </div>
                </Dialog> */}
                <Dialog
                    open={this.state.formDialogOpen}
                    onClose={this.handleCloseFormDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    scroll={'body'}
                >
                    
                    <div className="form-dialog-window">
                        <div className="form-dialog-header">
                            <p>{loc.ObjectBlock_ModalTitle}</p>
                            <button onClick={() => {this.handleCloseFormDialog()}}><Icon>close</Icon></button>
                        </div>
                            <div className="object-form-wrapper">
                                {activeTab === 'car' && <FormBlock hideBorder colLeft={4} colRight={8} title={loc.VehicleBlock_CarTab_GovementNumberField}>
                                    <InputField disabled={this.isDisableEdit()} required={true} padding={true} placeholder={loc.VehicleBlock_CarTab_GovementNumberField} hideLabel={true} label={loc.VehicleBlock_CarTab_GovementNumberField} type="text" onChange={this.handleChangeField} value={this.state.formFields.registrationNumber? this.state.formFields.registrationNumber: ""} name='registrationNumber'/>
                                </FormBlock>}

                                {activeTab === 'trailer' && <FormBlock hideBorder colLeft={4} colRight={8} title={loc.VehicleBlock_TrailerTab_GovementNumberField}>
                                    <InputField disabled={this.isDisableEdit()} required={true} padding={true} placeholder={loc.VehicleBlock_TrailerTab_GovementNumberField} hideLabel={true} label={loc.VehicleBlock_TrailerTab_GovementNumberField} type="text" onChange={this.handleChangeField} value={this.state.formFields.registrationNumber? this.state.formFields.registrationNumber: ""} name='registrationNumber'/>
                                </FormBlock>}

                                {activeTab === 'car' && <FormBlock  hideBorder colLeft={4} colRight={8} title={loc.VehicleBlock_CarTab_MarkField}>
                                    <InputField disabled={this.isDisableEdit()} padding={true} placeholder={loc.VehicleBlock_CarTab_MarkField} hideLabel={true} label={loc.VehicleBlock_CarTab_MarkField} type="text" onChange={this.handleChangeField} value={this.state.formFields.mark? this.state.formFields.mark: ''} name='mark'/>
                                </FormBlock>}

                                {activeTab === 'trailer' && <FormBlock  hideBorder colLeft={4} colRight={8} title={loc.VehicleBlock_TrailerTab_MarkField}>
                                    <InputField disabled={this.isDisableEdit()} padding={true} placeholder={loc.VehicleBlock_TrailerTab_MarkField} hideLabel={true} label={loc.VehicleBlock_TrailerTab_MarkField} type="text" onChange={this.handleChangeField} value={this.state.formFields.mark? this.state.formFields.mark: ''} name='mark'/>
                                </FormBlock>}

                                {activeTab === 'car' && <FormBlock  hideBorder colLeft={4} colRight={8} title={loc.VehicleBlock_CarTab_ModelField}>
                                    <InputField disabled={this.isDisableEdit()} padding={true} placeholder={loc.VehicleBlock_CarTab_ModelField} hideLabel={true} label={loc.VehicleBlock_CarTab_ModelField} type="text" onChange={this.handleChangeField} value={this.state.formFields.model? this.state.formFields.model: ''} name='model'/>
                                </FormBlock>}

                                {activeTab === 'car' && <FormBlock  hideBorder colLeft={4} colRight={8} title={loc.VehicleBlock_CarTab_TypeField}>
                                    <InputField disabled={this.isDisableEdit()} padding={true} placeholder={loc.VehicleBlock_CarTab_TypeField} hideLabel={true} label={loc.VehicleBlock_CarTab_TypeField} type="text" onChange={this.handleChangeField} value={this.state.formFields.type? this.state.formFields.type: ''} name='type'/>
                                </FormBlock>}
                                {activeTab === 'car' &&<FormBlock  hideBorder colLeft={4} colRight={8} title={loc.VehicleBlock_CarTab_TrailerTypeField}>
                                    <InputField disabled={this.isDisableEdit()} padding={true} placeholder={loc.VehicleBlock_CarTab_TrailerTypeField} hideLabel={true} label={loc.VehicleBlock_CarTab_TrailerTypeField} type="text" onChange={this.handleChangeField} value={this.state.formFields.bodyType? this.state.formFields.bodyType: ''} name='bodyType'/>
                                </FormBlock>}

                                {activeTab === 'trailer' &&<FormBlock  hideBorder colLeft={4} colRight={8} title={loc.VehicleBlock_TrailerTab_TrailerTypeField}>
                                    <InputField disabled={this.isDisableEdit()} padding={true} placeholder={loc.VehicleBlock_TrailerTab_TrailerTypeField} hideLabel={true} label={loc.VehicleBlock_TrailerTab_TrailerTypeField} type="text" onChange={this.handleChangeField} value={this.state.formFields.bodyType? this.state.formFields.bodyType: ''} name='bodyType'/>
                                </FormBlock>}

                                {activeTab === 'car' && <FormBlock  hideBorder colLeft={4} colRight={8} title={loc.VehicleBlock_CarTab_CapacityField}>
                                    <InputField disabled={this.isDisableEdit()} hideLabel padding={true} label={loc.VehicleBlock_CarTab_CapacityField} type="select" onChange={this.handleChangeField} value={this.state.formFields.loadCapacity? this.state.formFields.loadCapacity: ''} name="loadCapacity"
                                        options={[
                                                {
                                                    value: 10,
                                                    label: '10',
                                                },
                                                {
                                                    value: 15,
                                                    label: '15',
                                                },
                                                {
                                                    value: 20,
                                                    label: '20',
                                                },
                                            ]}/>
                                </FormBlock>}

                                {activeTab === 'trailer' && <FormBlock  hideBorder colLeft={4} colRight={8} title={loc.VehicleBlock_TrailerTab_CapacityField}>
                                    <InputField disabled={this.isDisableEdit()} hideLabel padding={true} label={loc.VehicleBlock_TrailerTab_CapacityField} type="select" onChange={this.handleChangeField} value={this.state.formFields.loadCapacity? this.state.formFields.loadCapacity: ''} name="loadCapacity"
                                        options={[
                                                {
                                                    value: 10,
                                                    label: '10',
                                                },
                                                {
                                                    value: 15,
                                                    label: '15',
                                                },
                                                {
                                                    value: 20,
                                                    label: '20',
                                                },
                                            ]}/>
                                </FormBlock>}

                                {activeTab === 'car' && <FormBlock  hideBorder colLeft={4} colRight={8} title={loc.VehicleBlock_CarTab_HeightField}>
                                    <InputField disabled={this.isDisableEdit()} padding={true} placeholder={loc.VehicleBlock_CarTab_HeightField} hideLabel={true} label={loc.VehicleBlock_CarTab_HeightField} type="number" onChange={this.handleChangeField} value={this.state.formFields.high? this.state.formFields.high: ''} name='high'/>
                                </FormBlock>}
                                
                                {activeTab === 'car' && <FormBlock  hideBorder colLeft={4} colRight={8} title={loc.VehicleBlock_CarTab_WidthField}>
                                    <InputField disabled={this.isDisableEdit()} padding={true} placeholder={loc.VehicleBlock_CarTab_WidthField} hideLabel={true} label={loc.VehicleBlock_CarTab_WidthField} type="number" onChange={this.handleChangeField} value={this.state.formFields.length? this.state.formFields.length: ''} name='length'/>
                                </FormBlock>}
                                {activeTab === 'car' && <FormBlock  hideBorder colLeft={4} colRight={8} title={loc.VehicleBlock_CarTab_LengthField}>
                                    <InputField disabled={this.isDisableEdit()} padding={true} placeholder={loc.VehicleBlock_CarTab_LengthField} hideLabel={true} label={loc.VehicleBlock_CarTab_LengthField} type="number" onChange={this.handleChangeField} value={this.state.formFields.width? this.state.formFields.width: ''} name='width'/>
                                </FormBlock>}
                                {activeTab === 'car' && <FormBlock  hideBorder colLeft={4} colRight={8} title={loc.VehicleBlock_CarTab_TrackerNumberField}>
                                    <InputField disabled={this.isDisableEdit()} padding={true} placeholder={loc.VehicleBlock_CarTab_TrackerNumberField} hideLabel={true} label={loc.VehicleBlock_CarTab_TrackerNumberField} type="text" onChange={this.handleChangeField} value={this.state.formFields.trackerNumber? this.state.formFields.trackerNumber: ''} name='trackerNumber'/>
                                </FormBlock>}

                                {activeTab === 'trailer' && <FormBlock  hideBorder colLeft={4} colRight={8} title={loc.VehicleBlock_TrailerTab_TrackerNumberField}>
                                    <InputField disabled={this.isDisableEdit()} padding={true} placeholder={loc.VehicleBlock_TrailerTab_TrackerNumberField} hideLabel={true} label={loc.VehicleBlock_TrailerTab_TrackerNumberField} type="text" onChange={this.handleChangeField} value={this.state.formFields.trackerNumber? this.state.formFields.trackerNumber: ''} name='trackerNumber'/>
                                </FormBlock>}

                                {activeTab === 'car' && <FormBlock  hideBorder colLeft={4} colRight={8} title={loc.VehicleBlock_CarTab_CompanyField}>
                                    <InputField disabled padding={true} placeholder={loc.VehicleBlock_CarTab_CompanyField} hideLabel={true} label={loc.VehicleBlock_CarTab_CompanyField} type="text" onChange={this.handleChangeField} value={this.props.user.userInfo.company.name? this.props.user.userInfo.company.name: ''} name='companyName'/>
                                </FormBlock>}

                                {activeTab === 'car' && <FormBlock  hideBorder colLeft={4} colRight={8} title={loc.VehicleBlock_CarTab_CommentField}>
                                    <InputField disabled={this.isDisableEdit()} hideLabel padding={true} placeholder={loc.VehicleBlock_CarTab_CommentField} label={loc.VehicleBlock_CarTab_CommentField} type="multiline" onChange={this.handleChangeField} value={this.state.formFields.comment? this.state.formFields.comment: ''} name='comment'/>
                                </FormBlock>}
                                {activeTab === 'trailer' && <FormBlock  hideBorder colLeft={4} colRight={8} title={loc.VehicleBlock_TrailerTab_CommentField}>
                                    <InputField disabled={this.isDisableEdit()} hideLabel padding={true} placeholder={loc.VehicleBlock_TrailerTab_CommentField} label={loc.VehicleBlock_TrailerTab_CommentField} type="multiline" onChange={this.handleChangeField} value={this.state.formFields.comment? this.state.formFields.comment: ''} name='comment'/>
                                </FormBlock>}

                                <RoleControl roles={['ROLE_MANAGER_LOGIST']}>
                                    <FormBlock  hideBorder colLeft={4} colRight={8} title={loc.VehicleBlock_BlockCommentField}>
                                        <InputField hideLabel padding={true} placeholder={loc.VehicleBlock_BlockCommentField} label={loc.VehicleBlock_BlockCommentField} type="multiline" onChange={this.handleChangeField} value={this.state.formFields.blockComment? this.state.formFields.blockComment: ''} name='blockComment'/>
                                    </FormBlock>
                                </RoleControl>
                            <FormBlock hideBorder colLeft={4} colRight={8} title={""}>
                                
                                {
                                    (this.state.edit || this.state.create)?
                                        <>
                                            <Button onClick={this.handleCancel} padding col={6}>
                                                {loc.Button_Cancel}
                                            </Button>
                                            </>
                                    :
                                        null
                                }
                                {
                                    <RoleControl roles={['ROLE_MANAGER_LOGIST']}>
                                        <Button padding onClick={this.handleBlock} col={6} color={this.state.formFields.status === 'ACTIVE'? "red" : "green"}>
                                            {this.state.formFields.status === 'ACTIVE'? loc.Button_Blocked : loc.Button_Unblocked}
                                        </Button>
                                    </RoleControl>
                                }
                                <RoleControl roles={['ROLE_TC_MANAGER']}>
                                    {
                                        this.state.edit?
                                            <Button 
                                            col={6}
                                                disabled={
                                                    this.editIsValid() || !this.additionIsValid() 
                                                }
                                                onClick={this.handleEditRecord} 
                                                padding 
                                                color="primary"
                                            >
                                                {loc.Button_Save}
                                            </Button>
                                        :
                                            null
                                    }
                                </RoleControl>
                                <RoleControl roles={['ROLE_TC_MANAGER']}>
                                    {
                                        this.state.create?
                                            <Button 
                                                disabled={!this.additionIsValid() }
                                                onClick={this.handleCreateRecord} 
                                                padding 
                                                col={6} 
                                                color="primary"
                                            >
                                                {loc.Button_Add}
                                            </Button>
                                        :
                                            null
                                    }
                                </RoleControl>

                                </FormBlock>
                            </div>
                    </div>
                </Dialog>
                <RoleControl roles={['ROLE_TC_MANAGER']}>
                    <button onClick={() => {this.handleAdd()}} className="add-button">
                        <Icon>add</Icon>
                    </button>
                </RoleControl>
                
            </>
        )
    }
}

const mapStateToProps = (state: IAppState) => ({
    locale: state.locale,
    vehicle: state.vehicle,
    loc: state.locale.currentLocaleJSON,
    user: state.user
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
    // initLocales: () => dispatch(initLocales(config)),
    // getVehicleInfo: () => dispatch(getVehicleInfo())
    getVehicleList: (params: ISearchParams) => dispatch(getVehicleList(params)),
    deleteVehicle: (id: string) => dispatch(deleteVehicle(id)),
    createVehicle: (data: IVehicleCreateModel) => dispatch(createVehicle(data)),
    changeVehicleStatus: (statusData: IVehileChangeStatusModel) => dispatch(changeVehicleStatus(statusData)),
    editVehicle: (data: IVehicleEditModel) => dispatch(editVehicle(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withPageBase(VehicleScreen));
