import './UserScreen.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Datetime from "react-datetime";
import { ILocaleState, IInitLocalesSuccessAction, IInitLocalesFailureAction, IDictionary,  } from 'store/locale/types';
import { IAppState } from 'store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { initLocales } from 'store/locale/actions';
import config from 'locales/config.json';
import { getUserInfo } from 'store/user/actions';
import { IGetUserInfoSuccessAction, IGetUserInfoFailureAction } from 'store/user/types';
import { SS_AUTH_TOKEN, ISearchParams } from 'store/types';
import PageTemplate from 'components/PageTemplate';
import { IGetUserListSuccessAction, IGetUserListFailureAction, ICreateUserSuccessAction, ICreateUserFailureAction, IUserState, IDeleteUserSuccessAction, IDeleteUserFailureAction } from 'store/user/types';
import { getUserList, createUser, deleteUser } from 'store/user/actions';
import { Dialog, Icon } from '@material-ui/core';
import { Button } from 'components/Button';
import InputField from 'components/InputField';
import { ICompanyNameModel, ICompanyModel } from 'store/company/types';
import { ICityModel } from 'store/city/types';
import moment from 'moment';
import { getNewSort, handleChangeFilter } from 'services';
import { withPageBase, IWithPageBase } from 'hoc/withPageBase/withPageBase';



interface IProps extends IWithPageBase {
    locale: ILocaleState;
    loc: IDictionary;
    user: IUserState;
    history: any;
    // initLocales: () => Promise<IInitLocalesSuccessAction | IInitLocalesFailureAction>;
    // getUserInfo: () => Promise<IGetUserInfoSuccessAction | IGetUserInfoFailureAction>;
    getUserList: (params: ISearchParams) => Promise<IGetUserListSuccessAction|IGetUserListFailureAction>;
    deleteUser: (id: string) => Promise<IDeleteUserSuccessAction|IDeleteUserFailureAction>;
    // handleChangeFilter: (event: React.ChangeEvent<HTMLInputElement>) => void;
    // handleChangeCompanyFilter: (resultObject: ICompanyModel) => void;
    // handleChangeCityFilter: (resultObject: ICityModel) => void;
    // handleDeleteItem: (text: string, callback: () => void) => void;
}

interface IState {
    page: number;
    // q: any;
    sort: string;
    // deleteDialogOpen: boolean;
    deleteRow: any | null;
    filters: {[key: string]: string};//{field: string, value: string}[];//{[key: string]: string};
}

export class UserScreen extends Component<IProps, IState> {

    handleChangeFilter = this.props.handleChangeFilter.bind(this);
    handleChangeCompanyFilter = this.props.handleChangeCompanyFilter.bind(this);
    handleChangeCityFilter = this.props.handleChangeCityFilter.bind(this);

    state: IState = {
        // q: {},
        page: 0,
        sort: '',
        // deleteDialogOpen: false,
        deleteRow: null,
        filters: {}
    }

    componentWillMount = () => {
        this.props.getUserList({page: this.state.page});
    }

    changePageNumber = (page: number) => {
        this.props.getUserList({page, q: {...this.state.filters}, sort: this.state.sort})
            .then(data => {
                this.setState({page});
            });
    }

    handleDeleteDialog = (row: any) => {
        const { loc } = this.props;
        this.setState({
            deleteRow: {...row}
        },
        () => {
            this.props.handleDeleteItem(
                `${loc.UserScreen_TryToDelete} ${this.state.deleteRow !== null && this.state.deleteRow.fio? this.state.deleteRow.fio: "" }`,
                () => {
                    this.deleteRecord();
                }
            );
        });
    }

    deleteRecord = () => {
        this.props.deleteUser(this.state.deleteRow !== null && this.state.deleteRow.id? this.state.deleteRow.id: "")
            .then(dataDelete => {
                if(dataDelete.type === 'DELETE_USER_SUCCESS'){
                    this.props.openSuccessDialog();
                    this.props.getUserList({page: this.state.page, q: {...this.state.filters}, sort: this.state.sort});
                    // this.handleCloseDeleteDialog();
                } else {
                    this.props.openErrorDialog({});
                }
                
            });
    }


    handleSort = (sortString: string) => {
        // let newSort: string = "";
        // // console.log('new sort',sortString)
        // if(this.state.sort !== '' && this.state.sort.indexOf(',')){
        //     const [currentSortParam, currentSortDirrection] = this.state.sort.split(',');
        //     if(sortString === currentSortParam){
        //         switch (currentSortDirrection) {
        //             case 'asc':
        //                 newSort = `${sortString},desc`;
        //                 break;
        //             case 'desc':
        //                 newSort = `${sortString},asc`;
        //                 break;
        //             default:
        //                 break;
        //         }
        //     } else {
        //         newSort = `${sortString},asc`;
        //     }
             
        // } else {
        //     newSort = `${sortString},asc`;
        // }
        const newSort = getNewSort(sortString, this.state);
        this.props.getUserList({page: this.state.page, q: {...this.state.filters}, sort: newSort});
        this.setState({sort: newSort});
        
    }

    // handleCloseDeleteDialog = () => {
    //     this.setState({deleteDialogOpen: false});
    // }

    handleSearch = () => {
        this.props.getUserList({page: 0, q: {...this.state.filters}, sort: this.state.sort})
            .then(() => {
                this.setState({page: 0});
            });
    }

    handleResetSearch = () => {
        this.props.getUserList({page: 0, q: {}, sort: this.state.sort})
            .then(() => {
                this.setState((prevState: IState) => ({
                    page:0,
                    filters: {}
                }))
            });
        
    }

    handleEdit = (row: any) => {
        this.props.history.push(`/dashboard/user/edit/${row.id}`)
    }

    handleAdd = () => {
        this.props.history.push(`/dashboard/user/create`)
    }


    // handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     const Event = event;
    //     const field = Event.target.name//.split('|');
    //     const value = Event.target.type == 'number'? +Event.target.value : Event.target.value;
    //     // console.log('filter value',value)
    //     this.setState((prevState: IState) => ({
    //         filters: {
    //             ...prevState.filters,
    //             [field]: value
    //         }
    //     }))
    // }

    // handleChangeCompanyFilter = (resultObject: ICompanyModel) => {
    //     this.setState((prevState: IState) => ({
    //         filters: {
    //             ...prevState.filters,
    //             'company.name|full': resultObject.shortName
    //         }
    //     }))
    // }

    // handleChangeCityFilter = (resultObject: ICityModel) => {
    //     this.setState((prevState: IState) => ({
    //         filters: {
    //             ...prevState.filters,
    //             'city.name|full': resultObject.name
    //         }
    //     }))
    // }

    render() {
        const { loc } = this.props;
        return (
            <>
                <PageTemplate 
                    pageTitle={loc.UserScreen_PageTitle} 
                    pageIcon={'vpn_key'}
                    edit={true}
                    delete={true}
                    print
                    tableHeaders={[loc.UserScreen_FullnameColumn, loc.UserScreen_PositionColumn, loc.UserScreen_CompanyColumn, loc.UserScreen_CityColumn, loc.UserScreen_Phone1Column, loc.UserScreen_Phode2olumn, loc.UserScreen_EmailColumn]}
                    listData={this.props.user.list}
                    dataModel={['fio','position', 'company.name|struct', 'city.name|struct', 'contactPhone', 'workPhone', 'email']}
                    isLoading={this.props.user.isFetching}
                    pageble={this.props.user.pageble? this.props.user.pageble : {
                        currentPage: 0,
                        totalElements: 0,
                        totalPages: 0
                    }}
                    onChangePage={this.changePageNumber}
                    onDelete={this.handleDeleteDialog}
                    onEdit={this.handleEdit}
                    onSearch={this.handleSearch}
                    onResetSearch={this.handleResetSearch}
                    onSort={this.handleSort}
                    actualSort={this.state.sort}
                >
                    <InputField padding={true} label={loc.UserScreen_FullnameFilter} type="text" onChange={this.handleChangeFilter} value={this.state.filters['fio|regex']? this.state.filters['fio|regex']: ""} name='fio|regex'/>
                    <InputField padding={true} label={loc.UserScreen_PositionFilter} type="text" onChange={this.handleChangeFilter} value={this.state.filters['position|regex']? this.state.filters['position|regex']: ""} name='position|regex'/>
                    <InputField padding={true} label={loc.UserScreen_CompanyFilter} type="company" onSelect={this.handleChangeCompanyFilter} onChange={this.handleChangeFilter} value={this.state.filters['company.name|full']? this.state.filters['company.name|full']: ""} name='company.name|full'/>
                    <InputField padding={true} label={loc.UserScreen_CityFilter} type="city" onSelect={this.handleChangeCityFilter} onChange={this.handleChangeFilter} value={this.state.filters['city.name|full']? this.state.filters['city.name|full']: ""} name='city.name|full'/>
                </PageTemplate>
                {/* <Dialog
                    open={this.state.deleteDialogOpen}
                    onClose={this.handleCloseDeleteDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div className="dialog-window">
                        <p>{`${loc.UserScreen_TryToDelete} ${this.state.deleteRow !== null && this.state.deleteRow.fio? this.state.deleteRow.fio: "" }`}</p>
                        <div className="btn-block">
                            <Button padding col={4} color={'primary'} onClick={() => {this.deleteRecord()}}>{loc.Button_Continue}</Button>
                            <Button padding col={4} onClick={() => {this.handleCloseDeleteDialog()}}>{loc.Button_Cancel}</Button>
                        </div>  
                        
                    </div>
                </Dialog> */}
                <button onClick={() => {this.handleAdd()}} className="add-button">
                    <Icon>add</Icon>
                </button>
            </>
        )
    }
}

const mapStateToProps = (state: IAppState) => ({
    // locale: state.locale,
    user: state.user,
    // loc: state.locale.currentLocaleJSON
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
    // initLocales: () => dispatch(initLocales(config)),
    // getUserInfo: () => dispatch(getUserInfo())
    getUserList: (params: ISearchParams) => dispatch(getUserList(params)),
    deleteUser: (id: string) => dispatch(deleteUser(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(withPageBase(UserScreen));
