import React, { Component } from 'react'
import { connect } from 'react-redux'
import { IAppState } from 'store';
import { PageHeader } from 'components/PageHeader/PageHeader';
import { ILocaleState } from 'store/locale/types';
import { FormPageTemplate } from 'components/FormPageTemplate';
import { FormBlock } from 'components/FormBlock';
import InputField from 'components/InputField/InputField';
import { CompanyType, ICompanyCreateModel, ICreateCompanySuccessAction, ICreateCompanyFailureAction, IGetCompanyByIdSuccessAction, IGetCompanyByIdFailureAction, ICompanyState, ICompanyEditModel, IEditCompanySuccessAction, IEditCompanyFailureAction } from 'store/company/types';

import moment from 'moment';
import Timepicker from 'components/Timepicker';
import { createCompany, getCompanyById, editCompany } from 'store/company/actions';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { IUserState } from 'store/user/types';
import { match } from 'react-router-dom';
import { Button } from 'components/Button';
import { formatDate, validateRequiredFields, getErrorsFromDictionary } from 'services';
import ErrorDialog from 'components/ErrorDialog';
import WarningDialog from 'components/WarningDialog';
import { IWithPageBase, withPageBase } from 'hoc/withPageBase/withPageBase';
import deepEqual from 'deep-equal';


interface IRouterParams {
    id: string;
}

interface IProps extends IWithPageBase {
    edit?: boolean;
    create?: boolean;
    view?: boolean;
    user: IUserState;
    locale: ILocaleState;
    company: ICompanyState;
    history: any;
    createCompany: (companyData: ICompanyCreateModel) => Promise<ICreateCompanySuccessAction|ICreateCompanyFailureAction>;
    editCompany: (companyData: ICompanyEditModel) => Promise<IEditCompanySuccessAction|IEditCompanyFailureAction>;
    getCompanyById: (id: string) => Promise<IGetCompanyByIdSuccessAction|IGetCompanyByIdFailureAction>;
    match: match<IRouterParams>
}
interface IState {
    // errorMessages: string[] | [];
    account: number | null;
    bankDirector: string;
    bankName: string;
    bankReason: string;
    contractDate: number | null;
    contractNumber: string;
    factAddress: string;
    inn: number | null;
    legalAddress: string;
    longName: string;
    mailAddress: string;
    mfo: number | null;
    okpo: number | null;
    sap: string;
    shortName: string;
    taxNumber: number | null;
    type: CompanyType | null;
    // edited?: ICompanyEditModel;
    // displayErrorDialog: boolean;
    // displayWarningDialog: boolean;
    // companyViewLoaded: boolean;
}

export class CompanyBlock extends Component<IProps> {
    
    handleChangeField = this.props.handleChangeField.bind(this);
    handleChangeCompany = this.props.handleChangeCompany.bind(this);
    handleChangeCity = this.props.handleChangeCity.bind(this);

    state: IState = {
        account: null,
        bankDirector: "",
        bankName: "",
        bankReason: "",
        contractDate: null,
        contractNumber: "",
        factAddress: "",
        inn: null,
        legalAddress: "",
        longName: "",
        mailAddress: "",
        mfo: null,
        okpo: null,
        sap: "",
        shortName: "",
        taxNumber: null,
        type: null,//"COMPANY",
        // displayErrorDialog: false,
        // displayWarningDialog: false,
        // companyViewLoaded: false,
        // errorMessages: []
    };

    beforeEdit = {};

    componentDidMount() {
        // console.log(this.props.user)
        // if(this.props.view && this.props.user.userInfo.company && this.props.user.userInfo.company.id !== ''){
        //     this.props.getCompanyById(this.props.user.userInfo.company.id)
        //         .then(() => {
        //             this.setState({...this.props.company.currentCompany});
        //         });
        // }

        if(this.props.edit && this.props.match.params.id !== ''){

            // console.log('GET BY ID', this.props.match.params.id)
            this.props.getCompanyById(this.props.match.params.id)
                .then(() => {
                    const newState = {...this.props.company.currentCompany};
                    this.beforeEdit = { ...newState };
                    this.setState({ ...newState });
                });
        }

    }

    static getDerivedStateFromProps = (nextProps: IProps, prevState: IState) => {
        // console.log('cwrp', nextProps)


            // let newState: any = null;
            // if(nextProps.view && nextProps.user.userInfo.company && nextProps.user.userInfo.company.id !== '' && Object.keys(nextProps.company.currentCompany).length > 0 && prevState.shortName === ''){
            //     newState = {...nextProps.company.currentCompany };
            //     return ({...newState, companyViewLoaded: true});
            // }


            // if(nextProps.view && nextProps.user.userInfo.company && nextProps.user.userInfo.company.id !== '' && !prevState.companyViewLoaded) {
            //     // console.log('Have a symbol', nextProps.value)
            //     nextProps.getCompanyById(nextProps.user.userInfo.company.id)
            //         .then(() => {
            //             newState = {...nextProps.company.currentCompany };
            //         });
            //     // nextProps.getCityList(nextProps.value);

            //     return ({...newState, companyViewLoaded: true});

            // }

            // if(nextProps.edit && nextProps.match.params.id !== ''){

            //     console.log('GET BY ID', nextProps.match.params.id)
            //     nextProps.getCompanyById(nextProps.match.params.id)
            //         .then(() => {
            //             newState = {...nextProps.company.currentCompany, edited: {}};
            //             // this.setState({...this.props.company.currentCompany, edited: {}});
            //         });
            //     return ({...newState});
            // }

            // console.log(newState)
            return null;

    };



    getTitle = (): string => {

        if(this.props.edit) return this.props.locale.currentLocaleJSON.CompanyBlock_EditPageTitle;
        if(this.props.create) return this.props.locale.currentLocaleJSON.CompanyBlock_CreatePageTitle;
        if(this.props.view) return this.props.locale.currentLocaleJSON.CompanyBlock_ProfilePageTitle;

        return "";
    };

    // handleChangeField = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     // console.log(event.target.name, event.target.value);
    //     let name = event.target.name;
    //     let value = event.target.value;
    //     if(this.props.edit){
    //         // console.log(event.target.name, event.target.value);
    //         this.setState((prevState: IState) => {
    //             // console.log(event.target.name, event.target.value);
    //             return({
    //                 [name]: value,
    //                 edited: {
    //                     ...prevState.edited,
    //                     [name]: value
    //                 }
    //             })}
    //         );
    //     } else {
    //         this.setState((prevState: IState) =>
    //             ({
    //                 [name]: value
    //             })
    //         );
    //     }

    // };

    handleEdit = () => {
        // if(this.state.edited && Object.keys(this.state.edited).length > 0){
            let { type, ...editedData } = this.state
            // const editedData = this.state.edited? {...this.state, id: this.props.match.params.id} : {id: this.props.match.params.id}
            // editedData.id = this.props.match.params.id
            // delete editedData.edited;
            if(type){
                this.props.editCompany({type, ...editedData, id: this.props.match.params.id})
                .then((data) => {
                    if(data.type === "EDIT_COMPANY_FAILURE"){
                        // let error = this.props.locale.currentLocaleJSON[`ERROR_${data.payload.error}`]? this.props.locale.currentLocaleJSON[`ERROR_${data.payload.error}`]: this.props.locale.currentLocaleJSON[`ERROR_UnknownError`];
                        //this.setState({displayErrorDialog: true, errorMessages: [error]});
                        this.props.openErrorDialog(
                            {
                                messages: getErrorsFromDictionary(this.props.locale.currentLocaleJSON, [data.payload.error]) 
                            }
                        );
                    } else {
                        this.props.openSuccessDialog();
                        this.props.history.push(`/dashboard/administration/companies`);
                    }
                });
            }

        // }
    };

    // handleCloseWarningDialog = () => {
    //     this.setState({displayWarningDialog: false});
    // };

    handleCreate = () => {
        if(this.props.create){
            let { type,  ...createData } = this.state;
            if(type){
                this.props.createCompany({type, ...createData})
                    .then((createData) => {
                        if(createData.type === "CREATE_COMPANY_FAILURE"){
                            // let error = this.props.locale.currentLocaleJSON[`ERROR_${createData.payload.error}`]? this.props.locale.currentLocaleJSON[`ERROR_${createData.payload.error}`]: this.props.locale.currentLocaleJSON[`ERROR_UnknownError`];
                            // this.setState({displayErrorDialog: true, errorMessages:  });
                            this.props.openErrorDialog(
                                { 
                                    messages: getErrorsFromDictionary(this.props.locale.currentLocaleJSON, [createData.payload.error]) 
                                }
                            );
                        } else {
                            this.props.openSuccessDialog();
                            this.props.history.push(`/dashboard/administration/companies`);
                        }

                    });
            }
        }

    };

    handleCancel = () => {
        this.props.handleCancelEdit(this.isEdited());
        // if(this.state.edited && Object.keys(this.state.edited).length > 0){
        //     this.setState({displayWarningDialog: true});
        // } else {
        //     this.props.history.goBack();
        // }
    };

    isEdited = () => {
        return deepEqual(this.state, this.beforeEdit);
    }

    handleChangeDate = (name: string, value: string | moment.Moment) => {
        // console.log('value', name,value);
        // this.setState(
        //     {
        //         [name as string]: value.valueOf()
        //     } //as Pick<State, keyof State>
        // );

    
        this.setState((prevState: IState) =>
            ({
                [name]: value.valueOf()
            })
        );
    };

    renderMonth = ( props: any, month: any, year: any, selectedDate: any ) => {
        return <td {...props}>{ `month` }</td>;
    };

    // handleCloseDialog = () => {
    //     this.setState({displayErrorDialog: false});
    // };

    render() {
        // console.log('EDIT VALID', this.props.company.required? this.props.company.required : [], this.state, !validateRequiredFields(this.props.company.required? this.props.company.required : [], this.state))
        const { locale } = this.props;
        const { currentLocaleJSON: loc } = locale;
        // // console.log('Company: ',this.props.match.params.id)
        // var yesterday = moment();
        // // console.log(yesterday)
        // var valid = function( current: any ){
        //     console.log('current')
        //     return current.isAfter( yesterday );
        // };
        return (
            <div className="page-template">
                <PageHeader pageIcon={"work"} pageTitle={this.getTitle()}/>
                <FormPageTemplate col={8} width={900} title={loc.CompanyBlock_MainTitle}>

                    <FormBlock colLeft={4} colRight={8} title={loc.CompanyBlock_BlockTitle_CompanyType}>
                        <InputField required disabled={this.props.view} padding={true} placeholder={loc.CompanyBlock_FieldTitle_CompanyType} hideLabel={true} type="select" onChange={this.handleChangeField} name="type" value={this.state.type} label={loc.CompanyBlock_FieldTitle_CompanyType}
                            options={[
                                    {
                                        value: 'COMPANY',
                                        label: loc.Company_CompanyType_COMPANY,
                                    },
                                    {
                                        value: 'TRANSPORT_COMPANY',
                                        label: loc.Company_CompanyType_TRANSPORT_COMPANY,
                                    }
                                ]}/>
                    </FormBlock>
                    <FormBlock colLeft={4} colRight={8} title={loc.CompanyBlock_BlockTitle_ShortName}>
                        <InputField required disabled={this.props.view} padding={true} placeholder={loc.CompanyBlock_FieldTitle_ShortName} hideLabel={true} label={loc.CompanyBlock_FieldTitle_ShortName} type="text" onChange={this.handleChangeField} value={this.state.shortName} name='shortName'/>
                    </FormBlock>
                    <FormBlock colLeft={4} colRight={8} title={loc.CompanyBlock_BlockTitle_FullName}>
                        <InputField required disabled={this.props.view} padding={true} placeholder={loc.CompanyBlock_FieldTitle_FullName} hideLabel={true} label={loc.CompanyBlock_FieldTitle_FullName} type="text" onChange={this.handleChangeField} value={this.state.longName} name='longName'/>
                    </FormBlock>
                    <FormBlock colLeft={4} colRight={8} title={loc.CompanyBlock_BlockTitle_CompanyCode}>
                        <InputField required disabled={this.props.view} padding={true} placeholder={loc.CompanyBlock_FieldTitle_CompanyCode} hideLabel={true} label={loc.CompanyBlock_FieldTitle_CompanyCode} type="text" onChange={this.handleChangeField} value={this.state.sap} name='sap'/>
                    </FormBlock>
                    <FormBlock colLeft={4} colRight={8} title={loc.CompanyBlock_BlockTitle_LegalAddress}>
                        <InputField disabled={this.props.view} padding={true} placeholder={loc.CompanyBlock_FieldTitle_LegalAddress} hideLabel={true} label={loc.CompanyBlock_FieldTitle_LegalAddress} type="text" onChange={this.handleChangeField} value={this.state.legalAddress} name='legalAddress'/>
                    </FormBlock>
                    <FormBlock colLeft={4} colRight={8} title={loc.CompanyBlock_BlockTitle_FacticalAddress}>
                        <InputField disabled={this.props.view} padding={true} placeholder={loc.CompanyBlock_FieldTitle_FacticalAddress} hideLabel={true} label={loc.CompanyBlock_FieldTitle_FacticalAddress} type="text" onChange={this.handleChangeField} value={this.state.factAddress} name='factAddress'/>
                    </FormBlock>
                    <FormBlock colLeft={4} colRight={8} title={loc.CompanyBlock_BlockTitle_MailAddress}>
                        <InputField disabled={this.props.view} padding={true} placeholder={loc.CompanyBlock_FieldTitle_MailAddress} hideLabel={true} label={loc.CompanyBlock_FieldTitle_MailAddress} type="text" onChange={this.handleChangeField} value={this.state.mailAddress} name='mailAddress'/>
                    </FormBlock>
                    <FormBlock colLeft={4} colRight={8} title={loc.CompanyBlock_BlockTitle_CompanyRequisites}>
                        <InputField disabled={this.props.view} padding={true} col={8} placeholder={loc.CompanyBlock_FieldTitle_ActNumber} label={loc.CompanyBlock_FieldTitle_ActNumber} type="text" onChange={this.handleChangeField} value={this.state.contractNumber} name='contractNumber'/>

                        <InputField disabled={this.props.view} padding={true} col={4} placeholder={loc.CompanyBlock_FieldTitle_ActDate} label={loc.CompanyBlock_FieldTitle_ActDate} type="date" changeDate={this.handleChangeDate} value={`${this.state.contractDate !== null? formatDate(this.state.contractDate) : ''}`} name='contractDate'/>

                        <InputField disabled={this.props.view} padding={true} col={8} placeholder={loc.CompanyBlock_FieldTitle_INN} label={loc.CompanyBlock_FieldTitle_INN} type="number" onChange={this.handleChangeField} value={`${this.state.inn}`} name='inn'/>

                        <InputField disabled={this.props.view} padding={true} col={4} placeholder={loc.CompanyBlock_FieldTitle_OKPO} label={loc.CompanyBlock_FieldTitle_OKPO} type="number" onChange={this.handleChangeField} value={`${this.state.okpo}`} name='okpo'/>

                        <InputField disabled={this.props.view} padding={true} placeholder={loc.CompanyBlock_FieldTitle_NumberNDSPayment} label={loc.CompanyBlock_FieldTitle_NumberNDSPayment} type="number" onChange={this.handleChangeField} value={`${this.state.taxNumber}`} name='taxNumber'/>

                        <InputField disabled={this.props.view} padding={true} col={8} placeholder={loc.CompanyBlock_FieldTitle_CheckingAccountNumber}  label={loc.CompanyBlock_FieldTitle_CheckingAccountNumber} type="number" onChange={this.handleChangeField} value={`${this.state.account}`} name='account'/>

                        <InputField disabled={this.props.view} padding={true} col={4} placeholder={loc.CompanyBlock_FieldTitle_MFO} label={loc.CompanyBlock_FieldTitle_MFO} type="number" onChange={this.handleChangeField} value={`${this.state.mfo}`} name='mfo'/>

                        <InputField disabled={this.props.view} padding={true} placeholder={loc.CompanyBlock_FieldTitle_Bank}  label={loc.CompanyBlock_FieldTitle_Bank} type="text" onChange={this.handleChangeField} value={this.state.bankName} name='bankName'/>

                        <InputField disabled={this.props.view} padding={true} placeholder={loc.CompanyBlock_FieldTitle_FaceAction} label={loc.CompanyBlock_FieldTitle_FaceAction} type="text" onChange={this.handleChangeField} value={this.state.bankDirector} name='bankDirector'/>

                        <InputField disabled={this.props.view} padding={true} placeholder={loc.CompanyBlock_FieldTitle_Based} label={loc.CompanyBlock_FieldTitle_Based} type="text" onChange={this.handleChangeField} value={this.state.bankReason} name='bankReason'/>

                        {
                            !this.props.view?
                                <>
                                    <Button onClick={this.handleCancel} padding col={6}>
                                        {loc.Button_Cancel}
                                    </Button>

                                    {
                                        this.props.create?
                                            <Button disabled={!validateRequiredFields(this.props.company.required? this.props.company.required : [], this.state)} onClick={this.handleCreate} padding col={6} color="primary">
                                                {loc.Button_Add}
                                            </Button>
                                        :
                                            null
                                    }
                                    {
                                        this.props.edit?
                                            <Button disabled={this.isEdited() || !validateRequiredFields(this.props.company.required? this.props.company.required : [], this.state)} onClick={this.handleEdit} padding col={6} color="primary">
                                                {loc.Button_Save}
                                            </Button>
                                        :
                                            null
                                    }

                                </>
                            :
                                null
                        }

                    </FormBlock>
                </FormPageTemplate>
                {/* <WarningDialog display={this.state.displayWarningDialog} okAction={() => {this.props.history.goBack()}} closeDialog={this.handleCloseWarningDialog}/> */}
                {/* <ErrorDialog errorMessages={this.state.errorMessages} display={this.state.displayErrorDialog} closeDialog={this.handleCloseDialog}/> */}
            </div>
        )
    };
}

const mapStateToProps = (state: IAppState) => ({
    locale: state.locale,
    user: state.user,
    company: state.company
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
    createCompany: (companyData: ICompanyCreateModel) => dispatch(createCompany(companyData)),
    editCompany: (companyData: ICompanyEditModel) => dispatch(editCompany(companyData)),
    getCompanyById: (id: string) => dispatch(getCompanyById(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(withPageBase(CompanyBlock))
