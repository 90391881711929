import { 
  REQUEST, 
  SUCCESS, 
  FAILURE, 
  IErrorPayload
} from '../types';
  
import {
  IStaffState,
  StaffActions,
} from "./types";

import { Reducer } from 'redux';

const initialStaffState: IStaffState = {
  isFetching: false,
  list: [],
  searchParams: {},
  currentStaff: {},
  currentStaffId: '',
  required: ['fio', 'city']
}

export const staffReducer: Reducer<IStaffState, StaffActions> = (
  state = initialStaffState,
  action,
) => {

    const defaultStateWithFetchingTrue = {
        ...state,
        isFetching: true,
    };

    const defaultStateWithFetchingFalse = {
        ...state,
        isFetching: false,
    };

    const defaultErrorState = (action: { payload: IErrorPayload }) => ({
        ...state,
        isFetching: false,
        errorInfo: {
            hasError: true,
            ...action.payload
        },
    });
    switch (action.type) {

        // GET FULL LIST
        case 'GET_STAFF_LIST_PENDING': {
            return { ...defaultStateWithFetchingTrue };
        }
        case 'GET_STAFF_LIST_SUCCESS': {
            // console.log(parseJwt(action.authData.data.token))
            return {
                ...state,
                list: [...action.payload.list],
                pageble: {...action.payload.pageble},
                isFetching: false,
            };
        }
        case 'GET_STAFF_LIST_FAILURE': {
            return {
                ...state,
                isFetching: false,
                errorInfo: {
                    hasError: true,
                    ...action.payload
                },
            };
        }

        // CET NAMES LIST
        case 'CHANGE_STAFF_STATUS_PENDING': {
            return { ...defaultStateWithFetchingTrue };
        }
        case 'CHANGE_STAFF_STATUS_SUCCESS': {
            // console.log(parseJwt(action.authData.data.token))
            return {
                ...state,
                isFetching: false,
            };
        }
        case 'CHANGE_STAFF_STATUS_FAILURE': {
            return { ...defaultErrorState(action) };
        }


        // GET BY ID
        case 'GET_STAFF_BY_ID_PENDING': {
            return { ...defaultStateWithFetchingTrue };
        }
        case 'GET_STAFF_BY_ID_SUCCESS': {
            // console.log(parseJwt(action.authData.data.token))
            return {
                ...state,
                currentStaff: {...action.payload.record},
                currentStaffId: action.payload.id,
                isFetching: false,
            };
        }
        case 'GET_STAFF_BY_ID_FAILURE': {
            return { ...defaultErrorState(action) };
        }


        // CREATE 
        case 'CREATE_STAFF_PENDING': {
            return { ...defaultStateWithFetchingTrue };
        }
        case 'CREATE_STAFF_SUCCESS': {
            // console.log(parseJwt(action.authData.data.token))
            return { ...defaultStateWithFetchingFalse };
        }
        case 'CREATE_STAFF_FAILURE': {
            return { ...defaultErrorState(action) };
        }


        // EDIT
        case 'EDIT_STAFF_PENDING': {
            return { ...defaultStateWithFetchingTrue };
        }
        case 'EDIT_STAFF_SUCCESS': {
            // console.log(parseJwt(action.authData.data.token))
            return { ...defaultStateWithFetchingFalse };
        }
        case 'EDIT_STAFF_FAILURE': {
            return { ...defaultErrorState(action) };
        }


        // DELETE
        case 'DELETE_STAFF_PENDING': {
            return { ...defaultStateWithFetchingTrue };
        }
        case 'DELETE_STAFF_SUCCESS': {
            // console.log(parseJwt(action.authData.data.token))
            return { ...defaultStateWithFetchingFalse };
        }
        case 'DELETE_STAFF_FAILURE': {
            return { ...defaultErrorState(action) };
        }

      default:
      neverReached(action); // when a new action is created, this helps us remember to handle it in the reducer
  }
  return state;
};

// tslint:disable-next-line:no-empty
const neverReached = (never: never) => {};