import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { AppState } from "../store";
import ResetPasswordCard from 'components/ResetPasswordCard';

import './ResetPasswordScreen.scss';

interface IProps {
    history: any;
}
interface State {
    
}




export default class ResetPasswordScreen extends Component<IProps> {
    state = {
        
    }

    render() {

        return (
            <div className='auth-screen screen-center'>
                {/* <LoginCard/> */}
                <ResetPasswordCard history={this.props.history}/>
            </div>
        )
    }
}