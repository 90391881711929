import axios, {AxiosRequestConfig} from 'axios';
import {
    SS_AUTH_TOKEN,
} from '../store/types';
import { getUserStoredData } from 'services';

const { token, roles } = getUserStoredData();

const axiosConfig : AxiosRequestConfig = {
    headers: {
        'Authorization': token || 'NONE',
        // "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        // "Access-Control-Max-Age": "3600",
        // get: {
        //     'Content-Type': 'application/x-www-form-urlencoded'
        // },
        post:{
            'Content-Type': 'application/json'//
        },
        put:{
            'Content-Type': 'application/json'
        }
    }
}




if(process.env.NODE_ENV === 'production'){
    axiosConfig.baseURL = '/api';
}

const instance = axios.create(axiosConfig);

export default instance;
