import React from 'react';
import './FormBlock.scss';

interface IProps {
    title: string;
    colLeft?: number;
    colRight?: number;
    hideBorder?: boolean;
    compactStyle?: boolean;
    contentCenter?: boolean;
    compactPadding?: boolean;
}

export const FormBlock: React.FC<IProps> = props => {
    return (
        <div className={`form-block${props.hideBorder? ' without-border': ''}${props.compactPadding? ' compact-padding': ''}${props.compactStyle? ' compact-style': ''}`}>
            <div className="form-block-title-part" style={{width: `${props.colLeft !== undefined? `${(100 / 12 * props.colLeft)}%` : '50%'}`}} ><p>{props.title}</p></div>
            <div className={`form-block-content-part${props.contentCenter? ' content-center': ''}`} style={{width: `${props.colRight !== undefined? `calc(${(100 / 12 * props.colRight)}% + 30px)` : 'calc(50% + 30px)'}`}}>{props.children}</div>
        </div>
    )
}
