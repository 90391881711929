import { 
    REQUEST, 
    SUCCESS, 
    FAILURE, 
    IErrorPayload
} from '../types';
    
import {
    IWarehouseState,
    WarehouseActions,
} from "./types";

import { Reducer } from 'redux';

const initialWarehouseState: IWarehouseState = {
    isFetching: false,
    list: [],
    searchParams: {},
    currentWarehouse: {},
    currentWarehouseId: '',
    required: [
        'city',
        'logist',
        'admin',
        'admin2',
        'company',
        'name'
    ]
}

export const warehouseReducer: Reducer<IWarehouseState, WarehouseActions> = (
    state = initialWarehouseState,
    action,
  ) => {

    const defaultStateWithFetchingTrue = {
        ...state,
        isFetching: true,
    };

    const defaultStateWithFetchingFalse = {
        ...state,
        isFetching: false,
    };

    const defaultErrorState = (action: { payload: IErrorPayload }) => ({
        ...state,
        isFetching: false,
        errorInfo: {
            hasError: true,
            ...action.payload
        },
    });

    switch (action.type) {

        // GET FULL LIST
        case 'GET_WAREHOUSE_LIST_PENDING': {
            return {
                ...state,
                isFetching: true,
            };
        }
        case 'GET_WAREHOUSE_LIST_SUCCESS': {
            // console.log(parseJwt(action.authData.data.token))
            return {
                ...state,
                list: [...action.payload.list],
                pageble: {...action.payload.pageble},
                isFetching: false,
            };
        }
        case 'GET_WAREHOUSE_LIST_FAILURE': {
            return { ...defaultErrorState(action) };
        }

        // CET NAMES LIST
        case 'CHANGE_WAREHOUSE_STATUS_PENDING': {
            return { ...defaultStateWithFetchingTrue };
        }
        case 'CHANGE_WAREHOUSE_STATUS_SUCCESS': {
            // console.log(parseJwt(action.authData.data.token))
            return {
                ...state,
                isFetching: false,
            };
        }
        case 'CHANGE_WAREHOUSE_STATUS_FAILURE': {
            return { ...defaultErrorState(action) };
        }


        // GET BY ID
        case 'GET_WAREHOUSE_BY_ID_PENDING': {
            return { ...defaultStateWithFetchingTrue };
        }
        case 'GET_WAREHOUSE_BY_ID_SUCCESS': {
            // console.log(parseJwt(action.authData.data.token))
            return {
                ...state,
                currentWarehouse: {...action.payload.record},
                currentWarehouseId: action.payload.id,
                isFetching: false,
            };
        }
        case 'GET_WAREHOUSE_BY_ID_FAILURE': {
            return { ...defaultErrorState(action) };
        }


        // CREATE 
        case 'CREATE_WAREHOUSE_PENDING': {
            return { ...defaultStateWithFetchingTrue };
        }
        case 'CREATE_WAREHOUSE_SUCCESS': {
            // console.log(parseJwt(action.authData.data.token))
            return { ...defaultStateWithFetchingFalse };
        }
        case 'CREATE_WAREHOUSE_FAILURE': {
            return { ...defaultErrorState(action) };
        }


        // EDIT
        case 'EDIT_WAREHOUSE_PENDING': {
            return { ...defaultStateWithFetchingTrue };
        }
        case 'EDIT_WAREHOUSE_SUCCESS': {
            // console.log(parseJwt(action.authData.data.token))
            return { ...defaultStateWithFetchingFalse };
        }
        case 'EDIT_WAREHOUSE_FAILURE': {
            return { ...defaultErrorState(action) };
        }


        // DELETE
        case 'DELETE_WAREHOUSE_PENDING': {
            return { ...defaultStateWithFetchingTrue };
        }
        case 'DELETE_WAREHOUSE_SUCCESS': {
            // console.log(parseJwt(action.authData.data.token))
            return { ...defaultStateWithFetchingFalse };
        }
        case 'DELETE_WAREHOUSE_FAILURE': {
            return { ...defaultErrorState(action) };
        }

        default:
        neverReached(action); // when a new action is created, this helps us remember to handle it in the reducer
    }
    return state;
};
  
  // tslint:disable-next-line:no-empty
const neverReached = (never: never) => {};