import React from 'react'
import { Icon } from '@material-ui/core';
import "./Section.scss";

interface IProps {
    // icon: string;
    title?: string;
    // className: string | null;
    // width: string;
    col?: number;
    withoutHeader?: boolean;
    centerTitle?: boolean;
    secondTitle?: string;
}

export const Section: React.FC<IProps> = props => {
    return (
        <div style={{width: `calc(${props.col? (100 / 12 * props.col) : 100}% - 60px)`}}  className={`section-block`}>
            {
                props.withoutHeader?
                    null
                    :
                    props.secondTitle?
                        <>
                            <div className={`section-header${props.centerTitle? ' center-title': ''}`}>
                                <p>{props.title}</p>
                                <p>{props.secondTitle}</p>
                            </div>

                        </>
                            :
                        <div className={`section-header${props.centerTitle? ' center-title': ''}`}>
                            <p>{props.title}</p>
                        </div>
                        
            }

            <div className="section-body">
                {props.children}
            </div>
        </div>
    )
}
