import './TariffScreen.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ILocaleState, IInitLocalesSuccessAction, IInitLocalesFailureAction, IDictionary,  } from 'store/locale/types';
import { IAppState } from 'store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { initLocales } from 'store/locale/actions';
import config from 'locales/config.json';
import { SS_AUTH_TOKEN, ISearchParams } from 'store/types';
import PageTemplate from 'components/PageTemplate';
import { IGetTariffListSuccessAction, IGetTariffListFailureAction, ICreateTariffSuccessAction, ICreateTariffFailureAction, ITariffState, TariffType, ITariffModel, ITariffCreateModel, ITariffEditModel, IEditTariffSuccessAction, IGetTariffByIdSuccessAction, IGetTariffByIdFailureAction, IEditTariffFailureAction } from 'store/tariff/types';
import { getTariffList, createTariff, getTariffById, editTariff } from 'store/tariff/actions';
import { Dialog, Icon } from '@material-ui/core';
import { Button } from 'components/Button';
import InputField from 'components/InputField';
import { ICompanyNameModel, ICompanyModel } from 'store/company/types';
import { ICityModel } from 'store/city/types';
import { formatDate, validateRequiredFields, getNewSort, getErrorsFromDictionary } from 'services';
import moment from 'moment';
import equal from 'deep-equal';
import { FormBlock } from 'components/FormBlock/FormBlock';
import { withPageBase, IWithPageBase } from 'hoc/withPageBase/withPageBase';


interface IProps extends IWithPageBase {
    locale: ILocaleState;
    loc: IDictionary;
    tariff: ITariffState;
    history: any;
    // initLocales: () => Promise<IInitLocalesSuccessAction | IInitLocalesFailureAction>;
    // getTariffInfo: () => Promise<IGetTariffInfoSuccessAction | IGetTariffInfoFailureAction>;
    getTariffList: (params: ISearchParams) => Promise<IGetTariffListSuccessAction|IGetTariffListFailureAction>;
    getTariffById: (id: string) => Promise<IGetTariffByIdSuccessAction|IGetTariffByIdFailureAction>;
    createTariff: (data: ITariffCreateModel) => Promise<ICreateTariffSuccessAction|ICreateTariffFailureAction>;
    editTariff: (data: ITariffEditModel) => Promise<IEditTariffSuccessAction|IEditTariffFailureAction>;
    // deleteTariff: (id: string) => Promise<IDeleteTariffSuccessAction|IDeleteTariffFailureAction>;
}

interface IState {
    page: number;
    q: any;
    sort: string;
    deleteDialogOpen: boolean;
    deleteRow: any | null;
    filters: {[key: string]: string | number};//{field: string, value: string}[];//{[key: string]: string};
    formFields: {
        cityName: string,
        comeBack: "true" | "false" | null;
        companyName: string;
        documentNumber: number | null;
        id: string;
        startDate: number | null;
        tonnage: number | null;
        type: TariffType | null;
        value: number | null;
        withSecurity:  "true" | "false" | null;
    },
    autocompleteFormFields: {
        city: ICityModel;
        company: ICompanyNameModel;
    };
    // beforeEdit: ITariffModel | null;
    formDialogOpen: boolean;
    formRow: ITariffModel | null;
    // displayErrorDialog: boolean;
    // displayWarningDialog: boolean;
    // errorMessages: string[];
    create: boolean;
    edit: boolean;
}

class TariffScreen extends Component<IProps> {
    state: IState = {
        q: {

        },
        page: 0,
        sort: '',
        deleteDialogOpen: false,
        deleteRow: null,
        filters: {},
        formFields: {
            cityName: '',
            comeBack: null,
            companyName: '',
            documentNumber: null,
            id: '',
            startDate: null,
            tonnage: null,
            type: null,
            value: null,
            withSecurity: null
        },
        autocompleteFormFields:{
            city: {
                name: '',
                region: ''
            },
            
            company: {
                name: '',
                id: ''
            }
        },
        // beforeEdit: null,
        formDialogOpen: false,
        formRow: null,
        // displayErrorDialog: false,
        // displayWarningDialog: false,
        // errorMessages: [],
        create: false,
        edit: false
    }

    beforeEdit = {};
    handleChangeField = this.props.handleChangeFieldInForm.bind(this);
    handleChangeCompanyField = this.props.handleChangeCompanyField.bind(this);
    handleChangeCityField = this.props.handleChangeCityField.bind(this);
    handleChangeFilter = this.props.handleChangeFilter.bind(this);
    handleChangeCompanyFilter = this.props.handleChangeCompanyFilter.bind(this);
    handleChangeCityFilter = this.props.handleChangeCityFilter.bind(this);


    static getDerivedStateFromProps = (nextProps: IProps, prevState: IState) => {
        if(prevState.formFields.cityName && prevState.formFields.cityName !== `${prevState.autocompleteFormFields.city.name} (${prevState.autocompleteFormFields.city.region})`){
            return ({
                autocompleteFormFields: {
                    ...prevState.autocompleteFormFields,
                    city: {
                        name: '',
                        region: ''
                    }
                }
            })
        }

        if(prevState.formFields.companyName && prevState.autocompleteFormFields.company && prevState.autocompleteFormFields.company.name && prevState.formFields.companyName !== `${prevState.autocompleteFormFields.company.name}`){
            return ({
                autocompleteFormFields: {
                    ...prevState.autocompleteFormFields,
                    company: {
                        name: '',
                        id: ''
                    }
                }
            })
        }

        if(prevState.filters['startDate|from'] && prevState.filters['startDate|to']){
            if(moment(prevState.filters['startDate|from']).isAfter(moment(prevState.filters['startDate|to']))){
                return ({
                    filters: {
                        ...prevState.filters,
                        ['startDate|to']: null,
                    }
                })
            }

        }

        // console.log(newState)
        return null;
    }

    componentDidMount = () => {
        this.props.getTariffList({page: this.state.page});
    }

    changePageNumber = (page: number) => {
        this.props.getTariffList({page, q: {...this.state.filters}, sort: this.state.sort})
            .then(data => {
                this.setState({page});
            });
    }

    handleAdd = () => {
        this.setState({
            formDialogOpen: true,
            create: true,
            edit: false 
        })
    }

    handleRecordResult = (data: IEditTariffSuccessAction | IEditTariffFailureAction | ICreateTariffSuccessAction | ICreateTariffFailureAction, errorType: "EDIT_TARIFF_FAILURE" | "CREATE_TARIFF_FAILURE") => {
        if(data.type === errorType){
            // let error = this.props.locale.currentLocaleJSON[`ERROR_${data.payload.error}`]? this.props.locale.currentLocaleJSON[`ERROR_${data.payload.error}`]: this.props.locale.currentLocaleJSON[`ERROR_UnknownError`];
            // this.setState({displayErrorDialog: true, errorMessages: [error]});
            this.props.openErrorDialog(
                {
                    messages: getErrorsFromDictionary(this.props.locale.currentLocaleJSON, [data.payload.error])  
                }
            );
        } else {
            this.props.openSuccessDialog();
            this.props.getTariffList({page: this.state.page, q: {...this.state.filters}, sort: this.state.sort});
            this.setState({formDialogOpen: false});
            //this.props.history.push(`/dashboard/administration/users`);
        }
    }

    handleCreateRecord = () => {
        let { cityName, companyName, id, ...restData } = this.state.formFields;
        this.props.createTariff({...restData, ...this.state.autocompleteFormFields })
                .then(data => { this.handleRecordResult(data, 'CREATE_TARIFF_FAILURE') });
    }

    handleEditRecord = () => {
        let { cityName, companyName, ...restData } = this.state.formFields;
        this.props.editTariff({...restData, ...this.state.autocompleteFormFields })
                .then(data => { this.handleRecordResult(data, 'EDIT_TARIFF_FAILURE') });
                
    }

    handleEdit = (row: ITariffModel) => {
        // this.props.history.push(`/dashboard/warehouse/edit/${row.id}`)
        // this.props.getWarehouseById()
        const { city, company, ...restRow } = row;
        this.beforeEdit = {...row};
        this.setState((prevState) => {
            return ({
                
                create: false,
                edit: true,
                formDialogOpen: true,
                formFields: {
                    ...restRow ,
                    cityName: `${city.name} (${city.region})`,
                    companyName: `${company.name}`
                },
                autocompleteFormFields:{
                    city: {...city},
                    company: {...company}
                    
                },
            })
        })
    }
    
    handleCloseFormDialog = () => {
        this.setState({formDialogOpen: false});
    }

    // handleCloseDialog = () => {
    //     this.setState({displayErrorDialog: false});
    // }

    // handleCloseWarningDialog = () => {
    //     this.setState({displayWarningDialog: false});
    // }

    handleCancel = () =>{
        this.setState({formDialogOpen: false});
    }

    // handleDeleteDialog = (row: any) => {
        // const { loc } = this.props;
        // this.setState({
        //     deleteRow: {...row}
        // },
        // () => {
        //     this.props.handleDeleteItem(
        //         `${loc.TariffScreen_TryToDelete} ${this.state.deleteRow !== null && this.state.deleteRow.fio? this.state.deleteRow.fio: "" }`
        //         () => {
        //             this.deleteRecord();
        //         }
        //     );
        // });
        // this.setState({deleteDialogOpen: true, deleteRow: {...row}});
    // }

    // deleteRecord = () => {
    //     this.props.deleteTariff(this.state.deleteRow !== null && this.state.deleteRow.id? this.state.deleteRow.id: "")
    //         .then(data => {
    //             this.props.getTariffList({page: this.state.page});
    //             this.handleCloseDeleteDialog();
    //         })
    // }

    handleSort = (sortString: string) => {
        const newSort = getNewSort(sortString, this.state);
        this.props.getTariffList({page: this.state.page, q: {...this.state.filters}, sort: newSort});
        this.setState({sort: newSort});
        
    }

    // handleCloseDeleteDialog = () => {
    //     this.setState({deleteDialogOpen: false});
    // }

    handleSearch = () => {
        this.props.getTariffList({page: 0, q: {...this.state.filters}, sort: this.state.sort})
            .then(() => {
                this.setState({page: 0});
            });
    }

    handleResetSearch = () => {
        this.props.getTariffList({page: 0, q: {}, sort: this.state.sort});
        this.setState((prevState: IState) => ({
            page: 0,
            filters: {}
        }))
    }

    // handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     const Event = event;
    //     const field = Event.target.name//.split('|');
    //     const value = Event.target.type == 'number'? +Event.target.value : Event.target.value;
    //     // console.log('filter value',value)
    //     this.setState((prevState: IState) => ({
    //         filters: {
    //             ...prevState.filters,
    //             [field]: value
    //         }
    //     }))
    // }

    // handleChangeField = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     // console.log(event.target, event.target.name, event.target.value);
    //     let name = event.target.name;
    //     let type = event.target.type
    //     let value = type === 'number'? +event.target.value : event.target.value;

    //         this.setState((prevState: IState) => 
    //             ({
    //                 ...prevState,
    //                 formFields:{
    //                     ...prevState.formFields,
    //                     [name]: value
    //                 }
                    
    //             })
    //         );
    // }

    // handleChangeCompanyFilter = (resultObject: ICompanyModel) => {
    //     // console.log(resultObject)
    //     this.setState((prevState: IState) => ({
    //         filters: {
    //             ...prevState.filters,
    //             'company.name|full': resultObject.shortName
    //         }
    //     }))
    // }

    // handleChangeCityFilter = (resultObject: ICityModel) => {
    //     // console.log(resultObject)
    //     this.setState((prevState: IState) => ({
    //         filters: {
    //             ...prevState.filters,
    //             'city.name|full': resultObject.name
    //         }
    //     }))
    // }

    // handleChangeDate = (name: string, value: string | moment.Moment) => {

    //     this.setState((prevState: IState) => 
    //         ({
    //             filters: {
    //                 ...prevState.filters,
    //                 [name]: value.valueOf(),
    //             }
    //         })
    //     );
    // }

    // handleChangeCompanyField = (resultObject: ICompanyModel) => {
    //     // console.log(resultObject)
    //     this.setState((prevState: IState) => ({
    //         autocompleteFormFields:{
    //             ...prevState.autocompleteFormFields,
    //             company: {
    //                 name: resultObject.shortName,
    //                 id: resultObject.id
    //             }
    //         },
    //         formFields: {
    //             ...prevState.formFields,
    //             'companyName': resultObject.shortName
    //         }
    //     }))
    // }

    // handleChangeCityField = (resultObject: ICityModel) => {
    //     // console.log(resultObject)
    //     this.setState((prevState: IState) => ({
    //         autocompleteFormFields:{
    //             ...prevState.autocompleteFormFields,
    //             city: {
    //                 name: resultObject.name,
    //                 region: resultObject.region
    //             }
    //         },
    //         formFields: {
    //             ...prevState.formFields,
    //             'cityName': `${resultObject.name} (${resultObject.region})`
    //         }
    //     }))
    // }

    handleChangeDateField = (name: string, value: string | moment.Moment) => {
        // console.log(resultObject)
        this.setState((prevState: IState) => ({
            formFields: {
                ...prevState.formFields,
                'startDate': value.valueOf(),
            }
        }))
    }

    additionIsValid = () => {
        let { cityName, companyName, id, ...restData } = this.state.formFields;
        
        return validateRequiredFields(this.props.tariff.required? this.props.tariff.required : [], {...restData, ...this.state.autocompleteFormFields });
    }

    editIsValid = () => {
        let { cityName, companyName, ...restData } = this.state.formFields;
        // let { id: idBefore, ...beforeUpdate } = this.state.beforeEdit? this.state.beforeEdit: {id: ''};
        let afterUpdate = {...restData, ...this.state.autocompleteFormFields }
        return equal(this.beforeEdit, afterUpdate);
    }


    handleChangeDateFilter = (name: string, value: string | moment.Moment) => {

        this.setState((prevState: IState) => 
            ({
                filters: {
                    ...prevState.filters,
                    [name]: value.valueOf(),
                }
            })
        );

    }


    validSecondDate = (current: any) => {
        const selectedDate = this.state.filters['startDate|from'] ? moment(this.state.filters['startDate|from']) : moment(0) //Datetime.moment().subtract( 1, 'day' );
        
        return current.isAfter( selectedDate );
    }

    render() {
        const { loc } = this.props;
        // console.log('this.state.filters[startDate|from]', this.state.filters['startDate|from'] )
        return (
            <>
                <PageTemplate 
                    pageTitle={loc.TariffScreen_PageTitle} 
                    pageIcon={'monetization_on'}
                    edit={true}
                    import
                    export
                    print
                    entity={'TARIFF'}
                    // delete={true}
                    tableHeaders={[
                        loc.TariffScreen_DriverColumn,
                        loc.TariffScreen_TariffTypeColumn,
                        loc.TariffScreen_InputRegionColumn,
                        loc.TariffScreen_WeightColumn,
                        loc.TariffScreen_SecurityColumn,
                        loc.TariffScreen_StartDateColumn,
                        loc.TariffScreen_DocumentNumberColumn,
                        loc.TariffScreen_TariffColumn,
                        loc.TariffScreen_BothSideColumn,
                    ]}
                    listData={this.props.tariff.list}
                    dataModel={[
                        'company.name|struct',
                        'type|type',
                        'city.name|struct',
                        'tonnage',
                        'withSecurity|type',
                        'startDate|date',
                        'documentNumber',
                        'value',
                        'comeBack|type'
                    ]}
                    isLoading={this.props.tariff.isFetching}
                    pageble={this.props.tariff.pageble? this.props.tariff.pageble : {
                        currentPage: 0,
                        totalElements: 0,
                        totalPages: 0
                    }}
                    typesDictionary={{
                        type: {
                            "DISTANCE": loc.TariffType_ByDistance,
                            "TIME": loc.TariffType_ByTime
                        },
                        withSecurity: {
                            "true": loc.TariffSecurity_Yes, 
                            "false": loc.TariffSecurity_No, 
                        },
                        comeBack: {
                            "true": loc.TariffBothSide_Yes, 
                            "false": loc.TariffBothSide_No, 
                        }
                    }}
                    onChangePage={this.changePageNumber}
                    // onDelete={this.handleDeleteDialog}
                    onEdit={this.handleEdit}
                    onSearch={this.handleSearch}
                    onResetSearch={this.handleResetSearch}
                    onSort={this.handleSort}
                    actualSort={this.state.sort}
                >
                    <InputField label={loc.TariffScreen_TariffTypeFilter} type="select" onChange={this.handleChangeFilter} value={this.state.filters['type|full']? this.state.filters['type|full']: null} name="type|full"
                        options={[
                                {
                                    value: 'DISTANCE',
                                    label: loc.TariffType_ByDistance
                                },
                                {
                                    value: 'TIME',
                                    label: loc.TariffType_ByTime
                                }
                            ]}/>
                    <InputField label={loc.TariffScreen_CityFilter} type="city" onSelect={this.handleChangeCityFilter} onChange={this.handleChangeFilter} value={this.state.filters['city.name|full']? this.state.filters['city.name|full']: ""} name='city.name|full'/>
                    <InputField label={loc.TariffScreen_WeightFilter} type="select" onChange={this.handleChangeFilter} value={this.state.filters['tonnage|full']? this.state.filters['tonnage|full']: null} name="tonnage|full"
                        options={[
                                {
                                    value: 10,
                                    label: '10',
                                },
                                {
                                    value: 15,
                                    label: '15',
                                },
                                {
                                    value: 20,
                                    label: '20',
                                },
                            ]}/>
                    <InputField label={loc.TariffScreen_Date1Filter} type="date" changeDate={this.handleChangeDateFilter} value={this.state.filters['startDate|from'] && typeof this.state.filters['startDate|from'] === 'number'? formatDate(this.state.filters['startDate|from']): ''} name='startDate|from'/>
                    <InputField isValidDate={this.validSecondDate} label={loc.TariffScreen_Date2Filter} type="date" changeDate={this.handleChangeDateFilter} value={this.state.filters['startDate|to']  && typeof this.state.filters['startDate|to'] === 'number'? formatDate(this.state.filters['startDate|to']): ''} name='startDate|to'/>
                    {/* {Date WITH VALIDE AND TIME PICKER} */}
                    {/* <InputField label={loc.CompanyBlock_FieldTitle_ActDate} type="date" changeDate={this.handleChangeDate} value={this.state.filters['startDate|from'] && typeof this.state.filters['startDate|from'] === 'number'? formatDate(this.state.filters['startDate|from']): ''} name='startDate|from'/> */}
                    {/* <InputField label={loc.CompanyBlock_FieldTitle_ActDate} type="date" changeDate={this.handleChangeDate} value={this.state.filters['startDate|to'] && typeof this.state.filters['startDate|to'] === 'number'? formatDate(this.state.filters['startDate|to']): ''} name='startDate|to'/> */}
                    <InputField label={loc.TariffScreen_DocumentNumberFilter} type="number" onChange={this.handleChangeFilter} value={this.state.filters['documentNumber|full']? this.state.filters['documentNumber|full']: null} name='documentNumber|full'/>
                    <InputField label={loc.TariffScreen_TariffFilter} type="number" onChange={this.handleChangeFilter} value={this.state.filters['value|full']? this.state.filters['value|full']: null} name='value|full'/>
                    <InputField label={loc.TariffScreen_BothSideFilter} type="select" onChange={this.handleChangeFilter} value={this.state.filters['comeBack|full']? this.state.filters['comeBack|full']: null} name="comeBack|full"
                        options={[
                                {
                                    value: 'true',
                                    label: loc.TariffBothSide_Yes
                                },
                                {
                                    value: 'false',
                                    label: loc.TariffBothSide_No
                                }
                            ]}/>
                </PageTemplate>
                {/* <Dialog
                    open={this.state.deleteDialogOpen}
                    onClose={this.handleCloseDeleteDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    scroll={'body'}
                >
                    <div className="dialog-window">
                        <p>{`${loc.TariffScreen_TryToDelete} ${this.state.deleteRow !== null && this.state.deleteRow.fio? this.state.deleteRow.fio: "" }`}</p>
                        <div className="btn-block">
                            
                            <Button padding col={4} onClick={() => {this.handleCloseDeleteDialog()}}>{loc.Button_Cancel}</Button>
                        </div>  
                        
                    </div>
                </Dialog> */}
                <Dialog
                    open={this.state.formDialogOpen}
                    onClose={this.handleCloseFormDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    scroll={'body'}
                >
                    
                    <div className="form-dialog-window">
                        <div className="form-dialog-header">
                            <p>{this.state.create? loc.TariffBlock_TitleCreate :  loc.TariffBlock_TitleEdit}</p>
                            <button onClick={() => {this.handleCloseFormDialog()}}><Icon>close</Icon></button>
                        </div>
                            <div className="object-form-wrapper">
                                <FormBlock hideBorder colLeft={4} colRight={8} title={loc.TariffBlock_DriverField}>
                                    <InputField required={true} isValid={(this.state.autocompleteFormFields.company && this.state.autocompleteFormFields.company.name !== '' && this.state.autocompleteFormFields.company.id !== '')}  padding={true} placeholder={loc.TariffBlock_DriverField} hideLabel={true} label={loc.TariffBlock_DriverField} type="company" autoSelect additionalFilters={{'type|full': 'TRANSPORT_COMPANY'}} onSelect={this.handleChangeCompanyField} onChange={this.handleChangeField} value={this.state.formFields.companyName? this.state.formFields.companyName: ""} name='companyName'/>
                                </FormBlock>

                                <FormBlock hideBorder colLeft={4} colRight={8} title={loc.TariffBlock_InputRegionField}>
                                    <InputField required={true} isValid={(this.state.autocompleteFormFields.city && this.state.autocompleteFormFields.city.name !== '' && this.state.autocompleteFormFields.city.region !== '')} padding={true} placeholder={loc.TariffBlock_InputRegionField} hideLabel={true} label={loc.TariffBlock_InputRegionField} type="city" onSelect={this.handleChangeCityField} onChange={this.handleChangeField} value={this.state.formFields.cityName? this.state.formFields.cityName: ""} name='cityName'/>
                                </FormBlock>

                                <FormBlock hideBorder colLeft={4} colRight={8} title={loc.TariffBlock_WeightField}>
                                    <InputField required={true} padding={true} placeholder={loc.TariffBlock_WeightField} hideLabel={true} label={loc.TariffBlock_WeightField} type="select" onChange={this.handleChangeField} value={this.state.formFields.tonnage? this.state.formFields.tonnage: ""} name='tonnage'
                                        options={[
                                            {
                                                value: 10,
                                                label: '10',
                                            },
                                            {
                                                value: 15,
                                                label: '15',
                                            },
                                            {
                                                value: 20,
                                                label: '20',
                                            },
                                        ]}/>
                                </FormBlock>
                                

                                <FormBlock hideBorder colLeft={4} colRight={8} title={loc.TariffBlock_TariffTypeField}>
                                    <InputField required={true} padding={true} placeholder={loc.TariffBlock_TariffTypeField} hideLabel={true} label={loc.TariffBlock_TariffTypeField} type="select" onChange={this.handleChangeField} value={this.state.formFields.type? this.state.formFields.type: ""} name='type'
                                        options={[
                                            {
                                                value: 'DISTANCE',
                                                label: loc.TariffType_ByDistance,
                                            },
                                            {
                                                value: 'TIME',
                                                label: loc.TariffType_ByTime,
                                            },
                                        ]}/>
                                </FormBlock>

                                <FormBlock hideBorder colLeft={4} colRight={8} title={loc.TariffBlock_BothSideField}>
                                    <InputField required={true} padding={true} placeholder={loc.TariffBlock_BothSideField} hideLabel={true} label={loc.TariffBlock_BothSideField} type="select" onChange={this.handleChangeField} value={this.state.formFields.comeBack !== null ? this.state.formFields.comeBack : ""} name='comeBack'
                                        options={[
                                            {
                                                value: 'true',
                                                label: loc.TariffBothSide_Yes,
                                            },
                                            {
                                                value: 'false',
                                                label: loc.TariffBothSide_No,
                                            },
                                        ]}/>
                                </FormBlock>

                                

                                <FormBlock hideBorder colLeft={4} colRight={8} title={loc.TariffBlock_SecurityField}>
                                    <InputField required={true} padding={true} placeholder={loc.TariffBlock_SecurityField} hideLabel={true} label={loc.TariffBlock_SecurityField} type="select" onChange={this.handleChangeField} value={this.state.formFields.withSecurity !== null ? this.state.formFields.withSecurity: ""} name='withSecurity'
                                    options={[
                                        {
                                            value: 'true',
                                            label: loc.TariffSecurity_Yes,
                                        },
                                        {
                                            value: 'false',
                                            label: loc.TariffSecurity_No,
                                        },
                                    ]}/>
                                </FormBlock>

                                <FormBlock hideBorder colLeft={4} colRight={8} title={loc.TariffBlock_StartDateField}>
                                    <InputField required={true} padding={true} placeholder={loc.TariffBlock_StartDateField} hideLabel={true} label={loc.TariffBlock_StartDateField} type="date" changeDate={this.handleChangeDateField} onChange={this.handleChangeField} value={this.state.formFields.startDate? formatDate(this.state.formFields.startDate): ""} name='startDate'/>
                                </FormBlock>

                                <FormBlock hideBorder colLeft={4} colRight={8} title={loc.TariffBlock_DocumentNumberField}>
                                    <InputField required={true} padding={true} placeholder={loc.TariffBlock_DocumentNumberField} hideLabel={true} label={loc.TariffBlock_DocumentNumberField} type="number" onChange={this.handleChangeField} value={this.state.formFields.documentNumber? this.state.formFields.documentNumber: ""} name='documentNumber'/>
                                </FormBlock>

                                <FormBlock hideBorder colLeft={4} colRight={8} title={loc.TariffBlock_TariffField}>
                                    <InputField required={true} padding={true} placeholder={loc.TariffBlock_TariffField} hideLabel={true} label={loc.TariffBlock_TariffField} type="number" onChange={this.handleChangeField} value={this.state.formFields.value? this.state.formFields.value: ""} name='value'/>
                                </FormBlock>


                            <FormBlock hideBorder colLeft={4} colRight={8} title={""}>
                                
                                {
                                    (this.state.edit || this.state.create)?
                                        <>
                                            <Button onClick={this.handleCancel} padding col={6}>
                                                {loc.Button_Cancel}
                                            </Button>
                                            </>
                                    :
                                        null
                                }


                                    {
                                        this.state.edit?
                                            <Button 
                                            col={6}
                                                disabled={
                                                    this.editIsValid() || !this.additionIsValid() 
                                                }
                                                onClick={this.handleEditRecord} 
                                                padding 
                                                color="primary"
                                            >
                                                {loc.Button_Save}
                                            </Button>
                                        :
                                            null
                                    }
                                    {
                                        this.state.create?
                                            <Button 
                                                disabled={!this.additionIsValid() }
                                                onClick={this.handleCreateRecord} 
                                                padding 
                                                col={6} 
                                                color="primary"
                                            >
                                                {loc.Button_Add}
                                            </Button>
                                        :
                                            null
                                    }

                                </FormBlock>
                            </div>
                    </div>
                </Dialog>
                <button onClick={() => {this.handleAdd()}} className="add-button">
                    <Icon>add</Icon>
                </button>
                
            </>
        )
    }
}

const mapStateToProps = (state: IAppState) => ({
    locale: state.locale,
    tariff: state.tariff,
    loc: state.locale.currentLocaleJSON
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
    // initLocales: () => dispatch(initLocales(config)),
    // getTariffInfo: () => dispatch(getTariffInfo())
    getTariffList: (params: ISearchParams) => dispatch(getTariffList(params)),
    getTariffById: (id: string) => dispatch(getTariffById(id)),
    createTariff: (data: ITariffCreateModel) => dispatch(createTariff(data)),
    editTariff: (data: ITariffEditModel) => dispatch(editTariff(data)),
    // deleteTariff: (id: string) => dispatch(deleteTariff(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(withPageBase(TariffScreen));
