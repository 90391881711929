import React from 'react'
import "./ErrorMessage.scss";

interface IProps {
}

export const ErrorMessage: React.FC<IProps> = props => {
    return (
        <div className={'error-message'}>
            {props.children}
        </div>
    )
}
