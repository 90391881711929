import Api from "api";
import { ISearchParams, IGetListServerResponse, IDefaultServerResponse, IGetListPayload, IErrorServerResponse, IGetDataListServerResponse, IGetByIdServerResponse, IGetByIdPayload } from "store/types";
import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { 
    IGetRouteListPendingAction,
    IGetRouteListSuccessAction,
    IGetRouteListFailureAction,
    IGetRouteByIdPendingAction,
    IGetRouteByIdSuccessAction,
    IGetRouteByIdFailureAction,
    ICreateRoutePendingAction,
    ICreateRouteSuccessAction,
    ICreateRouteFailureAction,
    IEditRoutePendingAction,
    IEditRouteSuccessAction,
    IEditRouteFailureAction,
    IDeleteRoutePendingAction,
    IDeleteRouteSuccessAction,
    IDeleteRouteFailureAction,
    IRouteModel,
    IRouteCreateModel,
    IRouteEditModel,
    IGetRouteByCityFailureAction,
    IGetRouteByCitySuccessAction,
    IGetRouteByCityPendingAction,
    ISearchRoute
} from "./types";
import { formatSearchParams } from "services";
import { AxiosResponse, AxiosError } from "axios";

export const getRouteList: ActionCreator<
  ThunkAction<
    Promise<IGetRouteListSuccessAction | IGetRouteListFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    IRouteModel[],                  // The type for the data within the last action
    ISearchParams,                       // The type of the parameter for the nested function 
    IGetRouteListSuccessAction | IGetRouteListFailureAction            // The type of the last action to be dispatched
  >
> = ( searchParams: ISearchParams) => {
    return async (dispatch: Dispatch) => {
        
        const getRouteListPendingAction: IGetRouteListPendingAction = {
            type: 'GET_ROUTE_LIST_PENDING',
        };
        dispatch(getRouteListPendingAction);

        try{
            const response: AxiosResponse<IGetListServerResponse<IRouteModel>> = await Api.get(`/routes?${formatSearchParams(searchParams)}`);
            const payload: IGetListPayload<IRouteModel> = {
                list: response.data.data.content,
                searchParams,
                pageble: {
                    currentPage: response.data.data.number,
                    totalPages: response.data.data.totalPages,
                    totalElements: response.data.data.totalElements
                }
            }
            const getRouteListSuccessAction: IGetRouteListSuccessAction = {
                payload,
                type: 'GET_ROUTE_LIST_SUCCESS',
            };
            return dispatch(getRouteListSuccessAction);
        } catch (error) {
            const getRouteListFailureAction: IGetRouteListFailureAction = {
                type: 'GET_ROUTE_LIST_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(getRouteListFailureAction);
        }
    };
};

export const getRouteByCity: ActionCreator<
  ThunkAction<
    Promise<IGetRouteByCitySuccessAction | IGetRouteByCityFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    IGetByIdPayload<IRouteModel> ,                  // The type for the data within the last action
    ISearchRoute,                       // The type of the parameter for the nested function 
    IGetRouteByCitySuccessAction | IGetRouteByCityFailureAction            // The type of the last action to be dispatched
  >
> = (searchInfo: ISearchRoute) => {
    return async (dispatch: Dispatch) => {
        
        const getRouteByCityPendingAction: IGetRouteByCityPendingAction = {
            type: 'GET_ROUTE_BY_CITY_PENDING',
        };
        dispatch(getRouteByCityPendingAction);
        try{
            let citiesRow = '';
            searchInfo.cities.forEach(e => {
                citiesRow += `cities=${e}&`
            })
            const response: AxiosResponse<IGetByIdServerResponse<IRouteModel>> = await Api.get(`/route/download/cities?${citiesRow}uploadPointCity=${searchInfo.uploadPointCity}`);
            console.log('/route/download/cities',response)
            const payload: IGetByIdPayload<IRouteModel> = {
                record: response.data.hasOwnProperty('data')? response.data.data: {
                    distance: null,
                    downloadPoints: [],
                    id: '',
                    intermediatePoints: [],
                    name: '',
                    roads: [],
                    sap: '',
                    time: null,
                    transports: [],
                    type: 'DISTANCE',
                    uploadPoint: {
                        city: {
                            name: '',
                            region: ''
                        },
                        comment: '',
                        coordinates: '',
                        type: 'DOWNLOAD'
                    }
                    
                },
                id: response.data.hasOwnProperty('data') && response.data.data.id? response.data.data.id: ''
            } 
            const getRouteByCitySuccessAction: IGetRouteByCitySuccessAction = {
                payload,
                type: 'GET_ROUTE_BY_CITY_SUCCESS',
            };
            return dispatch(getRouteByCitySuccessAction);
        } catch (error) {
            console.log('GET_ROUTE_BY_CITY_SUCCESS',error)
            const getRouteByCityFailureAction: IGetRouteByCityFailureAction = {
                type: 'GET_ROUTE_BY_CITY_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(getRouteByCityFailureAction);
        }
    };
};


export const getRouteById: ActionCreator<
  ThunkAction<
    Promise<IGetRouteByIdSuccessAction | IGetRouteByIdFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    IGetByIdPayload<IRouteModel> ,                  // The type for the data within the last action
    string,                       // The type of the parameter for the nested function 
    IGetRouteByIdSuccessAction | IGetRouteByIdFailureAction            // The type of the last action to be dispatched
  >
> = (id: string) => {
    return async (dispatch: Dispatch) => {
        
        const getRouteByIdPendingAction: IGetRouteByIdPendingAction = {
            type: 'GET_ROUTE_BY_ID_PENDING',
        };
        dispatch(getRouteByIdPendingAction);
        try{
            const response: AxiosResponse<IGetByIdServerResponse<IRouteModel>> = await Api.get(`/route/${id}`);
            const payload: IGetByIdPayload<IRouteModel> = {
                record: response.data.data,
                id
            } 
            const getRouteByIdSuccessAction: IGetRouteByIdSuccessAction = {
                payload,
                type: 'GET_ROUTE_BY_ID_SUCCESS',
            };
            return dispatch(getRouteByIdSuccessAction);
        } catch (error) {
            const getRouteByIdFailureAction: IGetRouteByIdFailureAction = {
                type: 'GET_ROUTE_BY_ID_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(getRouteByIdFailureAction);
        }
    };
};




export const createRoute: ActionCreator<
  ThunkAction<
    Promise<ICreateRouteSuccessAction | ICreateRouteFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    IRouteCreateModel,                       // The type of the parameter for the nested function 
    ICreateRouteSuccessAction | ICreateRouteFailureAction            // The type of the last action to be dispatched
  >
> = ( createdData: IRouteCreateModel) => {
    return async (dispatch: Dispatch) => {
        
        const createRoutePendingAction: ICreateRoutePendingAction = {
            type: 'CREATE_ROUTE_PENDING',
        };
        dispatch(createRoutePendingAction);

        try{
            const response: AxiosResponse<IDefaultServerResponse> = await Api.post(`/route`, { ...createdData });
            const createRouteSuccessAction: ICreateRouteSuccessAction = {
                type: 'CREATE_ROUTE_SUCCESS',
            };
            return dispatch(createRouteSuccessAction);
        } catch (error) {
            const createRouteFailureAction: ICreateRouteFailureAction = {
                type: 'CREATE_ROUTE_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(createRouteFailureAction);
        }
    };
};

export const editRoute: ActionCreator<
  ThunkAction<
    Promise<IEditRouteSuccessAction | IEditRouteFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    IRouteEditModel,                       // The type of the parameter for the nested function 
    IEditRouteSuccessAction | IEditRouteFailureAction            // The type of the last action to be dispatched
  >
> = ( editData: IRouteEditModel) => {
    return async (dispatch: Dispatch) => {
        
        const editRoutePendingAction: IEditRoutePendingAction = {
            type: 'EDIT_ROUTE_PENDING',
        };
        dispatch(editRoutePendingAction);

        try{
            const response: AxiosResponse<IDefaultServerResponse> = await Api.put(`/route`, { ...editData });
            const editRouteSuccessAction: IEditRouteSuccessAction = {
                type: 'EDIT_ROUTE_SUCCESS',
            };
            return dispatch(editRouteSuccessAction);
        } catch (error) {
            const editRouteFailureAction: IEditRouteFailureAction = {
                type: 'EDIT_ROUTE_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(editRouteFailureAction);
        }
    };
};


export const deleteRoute: ActionCreator<
  ThunkAction<
    Promise<IDeleteRouteSuccessAction | IDeleteRouteFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    string,                       // The type of the parameter for the nested function 
    IDeleteRouteSuccessAction | IDeleteRouteFailureAction            // The type of the last action to be dispatched
  >
> = ( id: string) => {
    return async (dispatch: Dispatch) => {
        
        const deleteRoutePendingAction: IDeleteRoutePendingAction = {
            type: 'DELETE_ROUTE_PENDING',
        };
        dispatch(deleteRoutePendingAction);

        try{
            const response: AxiosResponse<IDefaultServerResponse> = await Api.delete(`/route/${id}`);
            const deleteRouteSuccessAction: IDeleteRouteSuccessAction = {
                type: 'DELETE_ROUTE_SUCCESS',
            };
            return dispatch(deleteRouteSuccessAction);
        } catch (error) {
            const deleteRouteFailureAction: IDeleteRouteFailureAction = {
                type: 'DELETE_ROUTE_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(deleteRouteFailureAction);
        }
    };
};