import Api from "api";
import { ISearchParams, IGetListServerResponse, IDefaultServerResponse } from "store/types";
import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { IGetCityListSuccessAction, IGetCityListFailureAction, ICityModel, IGetCityListPendingAction } from "./types";

export const getCityList: ActionCreator<
  ThunkAction<
    Promise<IGetCityListSuccessAction | IGetCityListFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    ICityModel[],                  // The type for the data within the last action
    string,                       // The type of the parameter for the nested function 
    IGetCityListSuccessAction | IGetCityListFailureAction            // The type of the last action to be dispatched
  >
> = (namePart: string) => {
    return async (dispatch: Dispatch) => {
        
        const getCityListPendingAction: IGetCityListPendingAction = {
            type: 'GET_CITY_LIST_PENDING',
        };
        dispatch(getCityListPendingAction);

        try{
            console.log(namePart)
            const authDataResponse = await Api.get(`/city/names?namePart=${namePart}`);
            const list: ICityModel[] = authDataResponse.data.data; 
            const getCityListSuccessAction: IGetCityListSuccessAction = {
                list,
                type: 'GET_CITY_LIST_SUCCESS',
            };
            return dispatch(getCityListSuccessAction);
        } catch (e) {

            const getCityListFailureAction: IGetCityListFailureAction = {
                type: 'GET_CITY_LIST_FAILURE',
            };

            return dispatch(getCityListFailureAction);
        }
    };
};