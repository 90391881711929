import './RouteScreen.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Datetime from "react-datetime";
import { ILocaleState, IInitLocalesSuccessAction, IInitLocalesFailureAction, IDictionary,  } from 'store/locale/types';
import { IAppState } from 'store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { initLocales } from 'store/locale/actions';
import config from 'locales/config.json';

import { SS_AUTH_TOKEN, ISearchParams } from 'store/types';
import PageTemplate from 'components/PageTemplate';
import { IGetRouteListSuccessAction, IGetRouteListFailureAction, ICreateRouteSuccessAction, ICreateRouteFailureAction, IRouteState, IDeleteRouteSuccessAction, IDeleteRouteFailureAction } from 'store/route/types';
import { getRouteList, createRoute, deleteRoute } from 'store/route/actions';
import { Dialog, Icon } from '@material-ui/core';
import { Button } from 'components/Button';
import InputField from 'components/InputField';
import { ICompanyNameModel, ICompanyModel } from 'store/company/types';
import { ICityModel } from 'store/city/types';
import moment from 'moment';
import { getNewSort } from 'services';
import { withPageBase, IWithPageBase } from 'hoc/withPageBase/withPageBase';



interface IProps extends IWithPageBase {
    locale: ILocaleState;
    loc: IDictionary;
    route: IRouteState;
    history: any;
    // initLocales: () => Promise<IInitLocalesSuccessAction | IInitLocalesFailureAction>;
    // getRouteInfo: () => Promise<IGetRouteInfoSuccessAction | IGetRouteInfoFailureAction>;
    getRouteList: (params: ISearchParams) => Promise<IGetRouteListSuccessAction|IGetRouteListFailureAction>;
    deleteRoute: (id: string) => Promise<IDeleteRouteSuccessAction|IDeleteRouteFailureAction>;
}

interface IState {
    page: number;
    q: any;
    sort: string;
    deleteDialogOpen: boolean;
    deleteRow: any | null;
    filters: {[key: string]: string};//{field: string, value: string}[];//{[key: string]: string};
}

export class RouteScreen extends Component<IProps> {
    state: IState = {
        q: {

        },
        page: 0,
        sort: '',
        deleteDialogOpen: false,
        deleteRow: null,
        filters: {}
    }

    handleChangeFilter = this.props.handleChangeFilter.bind(this);
    handleChangeCompanyFilter = this.props.handleChangeCompanyFilter.bind(this);
    handleChangeCityFilter = this.props.handleChangeCityFilter.bind(this);

    componentWillMount = () => {
        this.props.getRouteList({page: this.state.page});
    }

    changePageNumber = (page: number) => {
        this.props.getRouteList({page, q: {...this.state.filters}, sort: this.state.sort})
            .then(data => {
                this.setState({page});
            });
    }

    handleDeleteDialog = (row: any) => {
        
        const { loc } = this.props;
        this.setState({
            deleteRow: {...row}
        },
        () => {
            this.props.handleDeleteItem(
                `${loc.RouteScreen_TryToDelete} ${this.state.deleteRow !== null && this.state.deleteRow.fio? this.state.deleteRow.fio: "" }`,
                () => {
                    this.deleteRecord();
                }
            );
        });
        // this.setState({deleteDialogOpen: true, deleteRow: {...row}});
    }

    deleteRecord = () => {
        this.props.deleteRoute(this.state.deleteRow !== null && this.state.deleteRow.id? this.state.deleteRow.id: "")
            .then(dataDelete => {
                if(dataDelete.type === 'DELETE_ROUTE_SUCCESS'){
                    this.props.openSuccessDialog();
                    this.props.getRouteList({page: this.state.page});
                } else {
                    this.props.openErrorDialog({});
                }
                
                // this.handleCloseDeleteDialog();
            })
    }

    handleEdit = (row: any) => {
        this.props.history.push(`/dashboard/route/edit/${row.id}`)
    }

    handleAdd = () => {
        this.props.history.push(`/dashboard/route/create`)
    }

    handleSort = (sortString: string) => {
        const newSort = getNewSort(sortString, this.state);
        this.props.getRouteList({page: this.state.page, q: {...this.state.filters}, sort: newSort});
        this.setState({sort: newSort});
        
    }

    handleCloseDeleteDialog = () => {
        this.setState({deleteDialogOpen: false});
    }

    handleSearch = () => {
        this.props.getRouteList({page: 0, q: {...this.state.filters}, sort: this.state.sort})
            .then(() => {
                this.setState({page: 0});
            });
    }

    handleResetSearch = () => {
        this.props.getRouteList({page: 0, q: {}, sort: this.state.sort});
        this.setState((prevState: IState) => ({
            page: 0,
            filters: {}
        }))
    }

    // handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     const Event = event;
    //     const field = Event.target.name//.split('|');
    //     const value = Event.target.type == 'number'? +Event.target.value : Event.target.value;
    //     // console.log('filter value',value)
    //     this.setState((prevState: IState) => ({
    //         filters: {
    //             ...prevState.filters,
    //             [field]: value
    //         }
    //     }))
    // }

    // handleChangeCompanyFilter = (resultObject: ICompanyModel) => {
    //     // console.log(resultObject)
    //     this.setState((prevState: IState) => ({
    //         filters: {
    //             ...prevState.filters,
    //             'company.name|full': resultObject.shortName
    //         }
    //     }))
    // }

    // handleChangeCityFilter = (resultObject: ICityModel, name: string) => {
    //     // console.log(resultObject)
    //     this.setState((prevState: IState) => ({
    //         filters: {
    //             ...prevState.filters,
    //             [name]: resultObject.name
    //         }
    //     }))
    // }

    render() {
        const { loc } = this.props;
        return (
            <>
                <PageTemplate 
                    pageTitle={loc.RouteScreen_PageTitle} 
                    pageIcon={'map'}
                    edit={true}
                    delete={true}
                    print
                    tableHeaders={[
                        loc.RouteScreen_SAPColumn, 
                        loc.RouteScreen_NameColumn, 
                        loc.RouteScreen_InputCityColumn, 
                        loc.RouteScreen_OutputCityColumn, 
                        loc.RouteScreen_OutputCity2Column, 
                        loc.RouteScreen_OutputCity3Column, 
                        loc.RouteScreen_TypeColumn, 
                        loc.RouteScreen_DistanseColumn
                    ]}
                    listData={this.props.route.list}
                    dataModel={[
                        'sap',
                        'name',
                        'uploadPoint.city.name|struct',
                        'downloadPoints.0.city.name|struct',
                        'downloadPoints.1.city.name|struct',
                        'downloadPoints.2.city.name|struct',
                        'type',
                        'distance'
                    ]}
                    isLoading={this.props.route.isFetching}
                    pageble={this.props.route.pageble? this.props.route.pageble : {
                        currentPage: 0,
                        totalElements: 0,
                        totalPages: 0
                    }}
                    onChangePage={this.changePageNumber}
                    onDelete={this.handleDeleteDialog}
                    onEdit={this.handleEdit}
                    onSearch={this.handleSearch}
                    onResetSearch={this.handleResetSearch}
                    onSort={this.handleSort}
                    actualSort={this.state.sort}
                >
                    <InputField padding={true} label={loc.RouteScreen_SAPFilter} type="text" onChange={this.handleChangeFilter} value={this.state.filters['sap|regex']? this.state.filters['sap|regex']: ""} name='sap|regex'/>
                    <InputField padding={true} label={loc.RouteScreen_NameFilter} type="text" onChange={this.handleChangeFilter} value={this.state.filters['name|regex']? this.state.filters['name|regex']: ""} name='name|regex'/>
                    {/* <InputField padding={true} label={loc.RouteScreen_CompanyFilter} type="company" onSelect={this.handleChangeCompanyFilter} onChange={this.handleChangeFilter} value={this.state.filters['company.name|full']? this.state.filters['company.name|full']: ""} name='company.name|full'/> */}
                    <InputField padding={true} label={loc.RouteScreen_InputCityFilter} type="city" onSelect={(row) => {this.handleChangeCityFilter(row, 'uploadPoint.city.name|full')}} onChange={this.handleChangeFilter} value={this.state.filters['uploadPoint.city.name|full']? this.state.filters['uploadPoint.city.name|full']: ""} name='uploadPoint.city.name|full'/>
                    <InputField padding={true} label={loc.RouteScreen_OutputCityFilter} type="city" onSelect={(row) => {this.handleChangeCityFilter(row, 'downloadPoints.city.name|full')}} onChange={this.handleChangeFilter} value={this.state.filters['downloadPoints.city.name|full']? this.state.filters['downloadPoints.city.name|full']: ""} name='downloadPoints.city.name|full'/>
                    <InputField label={loc.RouteScreen_TypeFilter} type="select" onChange={this.handleChangeFilter} value={this.state.filters['type|full']? this.state.filters['type|full']: null} name="type|full"
                        options={[
                                {
                                    value: "DISTANCE",
                                    label: loc.RouteType_Distanse
                                },
                                {
                                    value: "TIME",
                                    label: loc.RouteType_Time,
                                },
                            ]}/>
                </PageTemplate>
                <Dialog
                    open={this.state.deleteDialogOpen}
                    onClose={this.handleCloseDeleteDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div className="dialog-window">
                        <p>{`${loc.RouteScreen_TryToDelete} ${this.state.deleteRow !== null && this.state.deleteRow.fio? this.state.deleteRow.fio: "" }`}</p>
                        <div className="btn-block">
                            <Button padding col={4} color={'primary'} onClick={() => {this.deleteRecord()}}>{loc.Button_Continue}</Button>
                            <Button padding col={4} onClick={() => {this.handleCloseDeleteDialog()}}>{loc.Button_Cancel}</Button>
                        </div>  
                        
                    </div>
                </Dialog>
                <button onClick={() => {this.handleAdd()}} className="add-button">
                    <Icon>add</Icon>
                </button>
            </>
        )
    }
}

const mapStateToProps = (state: IAppState) => ({
    locale: state.locale,
    route: state.route,
    loc: state.locale.currentLocaleJSON
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
    // initLocales: () => dispatch(initLocales(config)),
    // getRouteInfo: () => dispatch(getRouteInfo())
    getRouteList: (params: ISearchParams) => dispatch(getRouteList(params)),
    deleteRoute: (id: string) => dispatch(deleteRoute(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(withPageBase(RouteScreen));
