import React, {Component} from 'react'
import {connect} from 'react-redux'
import {IAppState} from 'store';
import {PageHeader} from 'components/PageHeader/PageHeader';
import {ILocaleState} from 'store/locale/types';
import InputField from 'components/InputField/InputField';
import {
    IChangeOrderStatusByLogistFailureAction,
    IChangeOrderStatusByLogistSuccessAction,
    IChangeOrderStatusByManagerFailureAction,
    IChangeOrderStatusByManagerSuccessAction,
    ICreateOrderFailureAction,
    ICreateOrderSuccessAction,
    IEditOrderFailureAction,
    IEditOrderSuccessAction,
    IEventModel,
    IGetOrderByIdFailureAction,
    IGetOrderByIdSuccessAction,
    ILoadOutPointModel as ILoadOutPointModelSend,
    IOrderAuthor,
    IOrderChangeStatusModel,
    IOrderComment,
    IOrderCreateModel,
    IOrderEditModel,
    IOrderModel,
    IOrderState,
    IRouteDetailsModel,
    ITransportDetails,
    OrderStatus,
    OrderType,
    IOrderCreateCommentModel,
    ICreateOrderCommentSuccessAction,
    ICreateOrderCommentFailureAction,
    IOrderEditByManagerModel,
    IEditOrderByManagerSuccessAction,
    IEditOrderByManagerFailureAction
} from 'store/order/types';

import moment from 'moment';
import {
    changeOrderStatusByLogist,
    changeOrderStatusByManager,
    createOrder,
    editOrder,
    getOrderById,
    createOrderComment,
    editOrderByManager
} from 'store/order/actions';
import {ThunkDispatch} from 'redux-thunk';
import {AnyAction} from 'redux';
import {match} from 'react-router-dom';
import {Button} from 'components/Button';
import {Icon} from '@material-ui/core';
import {ICityModel} from 'store/city/types';
import {ICompanyModel, ICompanyNameModel} from 'store/company/types';
import {formatDate, formatTime, handleChangeFilter} from 'services';
import WarningDialog from 'components/WarningDialog';
import {Section} from 'components/Section/Section';
import './OrderBlock.scss';

import equal from 'deep-equal';
// import { ITariffDetailsModel, ITariffDetailsModel, IEventModel } from 'store/tariff/types';
import {IGetRouteListFailureAction, IGetRouteListSuccessAction, PointType, ISearchRoute, IGetRouteByCitySuccessAction, IGetRouteByCityFailureAction} from 'store/route/types';
import {IGetTariffListFailureAction, IGetTariffListSuccessAction, ITariffState} from 'store/tariff/types';
import {IWarehouseModel} from 'store/warehouse/types';
import {IUserState} from 'store/user/types';
import {getTariffList} from 'store/tariff/actions';
import {ISearchParams} from 'store/types';
import {getRouteList, getRouteByCity} from 'store/route/actions';
import { FormBlock } from 'components/FormBlock/FormBlock';
import { IVehicleModel } from 'store/vehicle/types';
import { IStaffModel } from 'store/staff/types';
import { IDialogState, IDoneDialogData, IOpenDoneDialogAction } from 'store/dialog/types';
import { openDoneDialog } from 'store/dialog/actions';
// import equal from 'deep-equal';

interface IOrderParams {
    type?: string;
    id?: string;
    token?: string;
}

interface IProps {
    isLogist: boolean;
    isManager: boolean;
    edit?: boolean;
    create?: boolean;
    view?: boolean;
    order: IOrderState;
    user: IUserState;
    locale: ILocaleState;
    tariff: ITariffState;
    dialog: IDialogState;
    openSuccessDialog: (data: IDoneDialogData) => Promise<IOpenDoneDialogAction>; 
    getTariffList: (params: ISearchParams) => Promise<IGetTariffListSuccessAction | IGetTariffListFailureAction>;
    getRouteList: (params: ISearchParams) => Promise<IGetRouteListSuccessAction | IGetRouteListFailureAction>;
    getRouteByCity: (params: ISearchRoute) => Promise<IGetRouteByCitySuccessAction | IGetRouteByCityFailureAction>;
    createOrder: (orderData: IOrderCreateModel) => Promise<ICreateOrderSuccessAction | ICreateOrderFailureAction>;
    createOrderComment: (orderData: IOrderCreateCommentModel) => Promise<ICreateOrderCommentSuccessAction | ICreateOrderCommentFailureAction>;
    editOrder: (orderData: IOrderEditModel) => Promise<IEditOrderSuccessAction | IEditOrderFailureAction>;
    editOrderByManager: (orderData: IOrderEditByManagerModel) => Promise<IEditOrderByManagerSuccessAction | IEditOrderByManagerFailureAction>;
    changeOrderStatusByLogist: (statusData: IOrderChangeStatusModel) => Promise<IChangeOrderStatusByLogistSuccessAction | IChangeOrderStatusByLogistFailureAction>;
    changeOrderStatusByManager: (statusData: IOrderChangeStatusModel) => Promise<IChangeOrderStatusByManagerSuccessAction | IChangeOrderStatusByManagerFailureAction>;
    // changeOrderStatus: (orderData: IChangeOrderStatusModel) => Promise<IChangeOrderStatusSuccessAction|IChangeOrderStatusFailureAction>;
    // editCurrentOrder: (OrderData: ICurrentOrderEditModel) => Promise<IEditCurrentOrderSuccessAction|IEditCurrentOrderFailureAction>;
    getOrderById: (id: string) => Promise<IGetOrderByIdSuccessAction | IGetOrderByIdFailureAction>;
    match: match<IOrderParams>;
    history: any;
}


// interface ITransport {
//     companyName: string;
//     company: ICompanyNameModel;
//     tonnage: number | null;
//     uploadPercent: number | null;
// }

// interface IPoint {
//     cityName: string;
//     city: ICityModel;
//     comment: string;
//     coordinates: string;
//     type: PointType;
// }

export interface ILoadInPointModel {
    city: ICityModel;
    cityName: string;
    orderName: string;
    departureDate: number | null;
    uploadDate: number | null;
    warehouse: IWarehouseDetails;
    warehouseName: string;
    company: ICompanyNameModel;
    companyName: string;
}

export interface ILoadOutPointModel {
    city: ICityModel;
    cityName: string;
    orderName: string;
    downloadDate: number | null;
    rrpBoxes: number | null;
    sigBoxes: number | null;
    sto: number | null;
    ttn: number | null;
    warehouse: IWarehouseDetails;
    warehouseName: string;
    company: ICompanyNameModel;
    companyName: string;
}

export interface IWarehouseDetails {
    company: ICompanyNameModel;
    // companyName: string;
    id: string;
    name: string;
}

export interface ITariffDetailsModel {
    company: ICompanyNameModel;
    companyName: string;
    comeBack: boolean | null;
    tariffName: string;
    distance: number | null;
    shipmentNumber: string;
    time: number | null;
    tonnage: number | null;
    value: number | null;
    withSecurity: boolean;
    id: string | null;
    city: ICityModel;
    cityName: string;
}

interface IKeysStringObject {
    [key: string]: any;
}

interface IState extends IKeysStringObject {
    author: IOrderAuthor;
    comment: string;
    comments: {
        [key: string]: IOrderComment
    },
    createdDate: number | null;
    events: {
        [key: string]: IEventModel;
    };
    id: number | null;
    loadInPoint: ILoadInPointModel;
    loadOutPoints: {
        [key: string]: ILoadOutPointModel;
    };
    price: number | null;
    route: IRouteDetailsModel;
    status: OrderStatus;
    tariff: ITariffDetailsModel;
    transport: ITransportDetails;
    type: OrderType;
    additional: {
        step: number;
        type: string;
        displayWarningDialog: boolean;
        displayDoneDialog: boolean;
    }
}

export class OrderBlock extends Component<IProps> {
    _isMounted = false;
    beforeUpdate = {};
    beforeTransport = {};
    state: IState = {
        author: {
            fio: '',
            id: '',
            phone: null
        },
        comment: '',
        comments: {
            // 'comment_1': {
            //     author: '44tegre',
            //     createdDate: 45436436346346,
            //     text: 'SOme text fo comment',
            // }
        },
        createdDate: null,
        events: {
            'event_1': {
                author: '',
                createdDate: 123,
                status: 'FORMED'
            },
            'event_2': {
                author: 'Uhbujhbq rdbnrf jcyjdmzytyrj',
                createdDate: 3543,
                status: 'UNDER_CONSIDERATION'
            },
            'event_7': {
                author: 'Uhbujhbq rdbnrf jcyjdmzytyrj',
                createdDate: 342342,
                status: 'REJECTED'
            },
            'event_3': {
                author: 'Uhbujhbq rdbnrf jcyjj',
                createdDate: 323,
                status: 'AGREED'
            },
            'event_4': {
                author: 'Uhbujhbq rddmzytyrj',
                createdDate: 344,
                status: 'ON_ROUTE'
            },
            'event_5': {
                author: 'Uhbujhbq rdbnrf jcyjdmzytyrj',
                createdDate: 4342,
                status: 'COMPLETED'
            },
            'event_8': {
                author: 'Uhbujhbq rdbnrf jcyjdmzytyrj',
                createdDate: 554,
                status: 'ARCHIEVED'
            },
            'event_9': {
                author: 'Uhbujhbq rdbnrf jcyjdmzytyrj',
                createdDate: 43434,
                status: 'CHANGED'
            }
        },
        id: null,
        loadInPoint: {
            city: {
                name: '',
                region: ''
            },
            cityName: '',
            orderName: '',
            departureDate: null,
            uploadDate: null,
            company: {
                name: '',
                id: ''
            },
            companyName: '',
            warehouseName: '',
            warehouse: {
                company: {
                    name: '',
                    id: ''
                },
                // companyName: '',
                id: '',
                name: ''
            }
        },
        loadOutPoints: {
            'loadOutPoint_1': {
                city: {
                    name: '',
                    region: ''
                },
                cityName: '',
                orderName: '',
                downloadDate: null,
                rrpBoxes: null,
                sigBoxes: null,
                sto: null,
                ttn: null,
                company: {
                    name: '',
                    id: ''
                },
                companyName: '',
                warehouseName: '',
                warehouse: {
                    company: {
                        name: '',
                        id: ''
                    },
                    // companyName: '',
                    id: '',
                    name: ''
                }
            }
        },
        price: 0,
        route: {
            id: '',
            name: '',
            sap: '',
        },
        status: 'FORMED',
        tariff: {
            company: {
                name: '',
                id: ''
            },
            companyName: '',
            comeBack: false,
            tariffName: '',
            distance: null,
            shipmentNumber: '',
            time: null,
            tonnage: null,
            value: 0,
            withSecurity: false,
            id: '',
            city: {
                name: '',
                region: ''
            },
            cityName: ''
        },
        transport: {
            car: {
                bodyType: '',
                comment: '',
                loadCapacity: null,
                mark: '',
                model: '',
                registrationNumber: '',
                type: '',
            },
            driver: {
                drivingLicence: '',
                fio: '',
                passport: '',
                phone: null
            },
            security: {
                extra: '',
                fio: '',
                passport: '',
                phone: null,
                securityCompanyName: ''
            },
            trailer: {
                comment: '',
                loadCapacity: null,
                mark: '',
                registrationNumber: '',
                type: ''
            }
        },
        type: 'TIME',
        additional: {
            type: '',
            step: 1,
            displayWarningDialog: false,
            displayDoneDialog: false
        }
    };

    icons = {
        'FORMED': 'assignment',//?
        'UNDER_CONSIDERATION': 'add_circle_outline',
        'REJECTED': 'cancel',
        'AGREED': 'assignment_turned_in',
        'ON_ROUTE': 'local_shipping',
        'COMPLETED': 'check',
        'ARCHIEVED': 'archive',//?
        'CHANGED': 'update'
    };

    comment_icon = 'sms';


    
    static getDerivedStateFromProps = (nextProps: IProps, prevState: IState) => {
        // console.log('cwrp', nextProps)


        let newState: any = null;

        if (prevState.loadInPoint.city.name !== ''
            && prevState.loadInPoint.city.region !== ''
            && prevState.loadInPoint.city.name !== prevState.tariff.city.name
            && prevState.loadInPoint.city.region !== prevState.tariff.city.region
        ) {
            return ({
                tariff: {
                    ...prevState.tariff,
                    city: {
                        name: prevState.loadInPoint.city.name,
                        region: prevState.loadInPoint.city.region
                    },
                    cityName: `${prevState.loadInPoint.city.name} (${prevState.loadInPoint.city.region})`
                }
            })
        }

        if (prevState.additional.step === 2 && prevState.route.name === '') {
            let routeName = `${prevState.loadInPoint.city.name} (${prevState.loadInPoint.city.region})`;

            Object.keys(prevState.loadOutPoints).forEach(key => {
                routeName += ` - `;
                routeName += `${prevState.loadOutPoints[key].city.name} (${prevState.loadOutPoints[key].city.region})`;
            });
            return ({
                route: {
                    ...prevState.route,
                    name: routeName
                }
            })
        }

        

        // if(nextProps.view && nextProps.order.orderInfo && nextProps.order.orderProfileIsLoad && !prevState.viewProfileIsLoad) {

        //     const { authorities, city, company, ...rest } = nextProps.order.orderInfo;

        //     return ({edited: {}, authorities: authorities[0], company, companyName: `${company && company.name? company.name: ''}`, city, cityName: city.name && city.region?`${city.name} (${city.region})`: '', ...rest, viewProfileIsLoad: true});
        // }

        // if(prevState.cityName && prevState.cityName !== `${prevState.city.name} (${prevState.city.region})`){
        //     return ({city: {name: '', region: ''}})
        // }
        if(prevState.tariff.companyName && prevState.tariff.company && prevState.tariff.company.name && prevState.tariff.companyName !== `${prevState.tariff.company.name}`){
            return ({
                tariff: {
                    ...prevState.tariff,
                    company: {
                        name: '', 
                        id: ''
                    }
                }});
        }


        if(prevState.loadInPoint.companyName && prevState.loadInPoint.company && prevState.loadInPoint.company.name && prevState.loadInPoint.companyName !== `${prevState.loadInPoint.company.name}`){
            return ({
                loadInPoint: {
                    ...prevState.loadInPoint,
                    company: {
                        name: '', 
                        id: ''
                    },
                    warehouseName: '',
                    warehouse: {
                        company: {
                            name: '',
                            id: ''
                        },
                        name: '', 
                        id: ''
                    }
                }});
        }

        if(prevState.loadInPoint.cityName && prevState.loadInPoint.city && prevState.loadInPoint.city.name && prevState.loadInPoint.city.region && prevState.loadInPoint.cityName !== `${prevState.loadInPoint.city.name} (${prevState.loadInPoint.city.region})`){
            return ({
                loadInPoint: {
                    ...prevState.loadInPoint,
                    city: {
                        name: '', 
                        region: ''
                    },
                    companyName: '',
                    company: {
                        name: '', 
                        id: ''
                    },
                    warehouseName: '',
                    warehouse: {
                        company: {
                            name: '',
                            id: ''
                        },
                        name: '', 
                        id: ''
                    }
                }});
        }

        if(prevState.loadInPoint.warehouseName && prevState.loadInPoint.warehouse && prevState.loadInPoint.warehouse.name && prevState.loadInPoint.warehouseName !== `${prevState.loadInPoint.warehouse.name}`){
            return ({
                loadInPoint: {
                    ...prevState.loadInPoint,
                    warehouse: {
                        company: {
                            name: '',
                            id: ''
                        },
                        name: '', 
                        id: ''
                    }
                }});
        }

        // Object.keys(prevState.loadOutPoints).forEach((el, num) => 
        for (let el of Object.keys(prevState.loadOutPoints)) {
            if(prevState.loadOutPoints[el].companyName && prevState.loadOutPoints[el].company && prevState.loadOutPoints[el].company.name && prevState.loadOutPoints[el].companyName !== `${prevState.loadOutPoints[el].company.name}`){
                return ({
                    loadOutPoints: {
                        ...prevState.loadOutPoints,
                        [el]:{
                            ...prevState.loadOutPoints[el],
                            company: {
                                name: '', 
                                id: ''
                            },
                            warehouseName: '',
                            warehouse: {
                                company: {
                                    name: '',
                                    id: ''
                                },
                                name: '', 
                                id: ''
                            }
                        }
                    }});
            }
        }

        for (let el of Object.keys(prevState.loadOutPoints)) {
            if(prevState.loadOutPoints[el].warehouseName && prevState.loadOutPoints[el].warehouse && prevState.loadOutPoints[el].warehouse.name && prevState.loadOutPoints[el].warehouseName !== `${prevState.loadOutPoints[el].warehouse.name}`){
                return ({
                    loadOutPoints: {
                        ...prevState.loadOutPoints,
                        [el]:{
                            ...prevState.loadOutPoints[el],
                            warehouse: {
                                company: {
                                    name: '',
                                    id: ''
                                },
                                name: '', 
                                id: ''
                            }
                        }
                    }});
            }
        }

        for (let el of Object.keys(prevState.loadOutPoints)) {
            if(prevState.loadOutPoints[el].cityName && prevState.loadOutPoints[el].city && prevState.loadOutPoints[el].city.name && prevState.loadOutPoints[el].city.region && prevState.loadOutPoints[el].cityName !== `${prevState.loadOutPoints[el].city.name} (${prevState.loadOutPoints[el].city.region})`){
                return ({
                    loadOutPoints: {
                        ...prevState.loadOutPoints,
                        [el]:{
                            ...prevState.loadOutPoints[el],
                            city: {
                                name: '', 
                                region: ''
                            },
                            companyName: '',
                            company: {
                                name: '', 
                                id: ''
                            },
                            warehouseName: '',
                            warehouse: {
                                company: {
                                    name: '',
                                    id: ''
                                },
                                name: '', 
                                id: ''
                            }
                        }
                    }});
            }
        }


        if(prevState.loadInPoint.uploadDate && prevState.loadInPoint.departureDate){
            if(moment(prevState.loadInPoint.uploadDate).isAfter(moment(prevState.loadInPoint.departureDate))){
                return ({
                    loadInPoint: {
                        ...prevState.loadInPoint,
                        departureDate: null
                    }
                })
            }

        }

        // console.log(newState)
        return null;

    };

    componentDidMount = () => {
        // console.log(this.props.order)
        // if(this.props.view && this.props.order.orderInfo){
        //     console.log(this.props.order.orderInfo)
        //     this.setState({...this.props.order.orderInfo});
        // }
        
        // Object.keys(this).forEach(el => {
            
        //     if(typeof el === 'function'){
        //         console.log(el);
        //     }
        // })

        this._isMounted = true;

        let type = '';
        switch (this.props.match.params.type) {
            case 'pickup':
                type = this.props.match.params.type.toUpperCase();
                break;
            case 'time':
                type = this.props.match.params.type.toUpperCase();
                break;
            case 'distance':
                type = this.props.match.params.type.toUpperCase();
                break;

            default:
                // this.props.history.push('/dashboard/order/active')
                break;
        }

        this.setState({
            author: {
                fio: this.props.user.userInfo.fio,
                phone: this.props.user.userInfo.workPhone,
                id: this.props.user.userInfo.id
            },
            type
        });

        if ((this.props.edit || this.props.view) && this.props.match.params.id && this.props.match.params.id !== '') {
            // console.log("EDIT GET BY ID")
            this.props.getOrderById(this.props.match.params.id)
                .then((data) => {
                    if (this._isMounted) {
                        if(data.type === 'GET_ORDER_BY_ID_SUCCESS'){
                            this.beforeTransport = {...data.payload.record.transport};
                            this.beforeUpdate = {...this.adaptToState(data.payload.record)};
                            this.setState({...this.adaptToState(data.payload.record)})
                        }
                    }
                    // const { authorities, city, company, ...rest } = this.props.order.currentOrder;
                    // this.setState({edited: {}, authorities: authorities[0], company, companyName: `${company && company.name? company.name: ''}`, city, cityName: city.name && city.region?`${city.name} (${city.region})`: '', ...rest});
                });
        }
    };

    componentWillUnmoun = () => {
        this._isMounted = false;
      }

    setTitleInfo = () => {
        let type = '';
        switch (this.props.match.params.type) {
            case 'pickup':
                type = this.props.locale.currentLocaleJSON.OrderType_Pickup
                break;
            case 'time':
                type = this.props.locale.currentLocaleJSON.OrderType_Time
                break;
            case 'distance':
                type = this.props.locale.currentLocaleJSON.OrderType_Distance
                break;

            default:
                break;
        }
        return (
            <div className="title-info">
                <p>{`${this.props.locale.currentLocaleJSON.OrderInfo_Tariff}: ${type}`}</p>
                <p>{`${this.props.locale.currentLocaleJSON.OrderInfo_Data}: ${formatDate(new Date().getTime())}`}</p>
                <p>{`${this.props.locale.currentLocaleJSON[`User_Role_${this.props.user.userInfo.authorities[0]}`]}: ${this.props.user.userInfo.fio}`}</p>
            </div>
        )
//         Тариф: самовывоз
// Дата: 05.10.2019
// Логист ФМ: Менеджер-логист тест
    };

    addNewRoad = () => {
        this.setState((prevState: IState) => ({
            roads: []
        }))
    };

    getTitle = (): string => {

        if (this.props.edit) return `${this.props.locale.currentLocaleJSON.OrderBlock_EditPageTitle} №${this.props.match.params.id}`;
        if (this.props.create) return this.props.locale.currentLocaleJSON.OrderBlock_CreatePageTitle;
        if (this.props.view) return `${this.props.locale.currentLocaleJSON.OrderBlock_EditPageTitle} №${this.props.match.params.id}`;//this.props.locale.currentLocaleJSON.OrderBlock_ProfilePageTitle;

        return "";
    };

    handleChangeField = (event: React.ChangeEvent<HTMLInputElement>) => {
        const Event = event;
        const field = Event.target.name//.split('|');
        const value = Event.target.type == 'number'? +Event.target.value : Event.target.value;
        // console.log('filter value',value)
        this.setState((prevState: any) => ({
            filters: {
                ...prevState.filters,
                [field]: value
            }
        }));

    };


    handleCloseDialog = () => {
        this.setState({displayErrorDialog: false});
    };

    handleCloseWarningDialog = () => {
        // this.setState({displayWarningDialog: false});
        this.setState((prevState: IState) => {

            return {
                additional: {
                    ...prevState.additional,
                    displayWarningDialog: false
                }
            }
        });
    };

    handleCancel = () => {

    };

    additionIsValid = () => {
        return true;
    };

    //change methods

    adaptToState = (inputData: IOrderModel) => {

        let { transport, comments, events, loadOutPoints, loadInPoint, tariff, ...restData } = inputData;

        let coms: { [key: string]: any } = {};
        comments && comments.forEach((el, num) => {
            coms[`comment_${num}`] = {
                ...el
            };
        });
        let evs: { [key: string]: any } = {};
        events && events.forEach((el, num) => {
            evs[`intermediatePoint_${num}`] = {
                ...el
            };
        });
        let lops: { [key: string]: any } = {};
        loadOutPoints && loadOutPoints.forEach((el, num) => {
            // console.log(el)
            lops[`loadOutPoint_${num}`] = {
                ...el,
                company: {
                    name: el.warehouse.company && el.warehouse.company.name? el.warehouse.company.name: '',
                    id:  el.warehouse.company && el.warehouse.company.id? el.warehouse.company.id: ''
                },
                companyName: `${el.warehouse.company && el.warehouse.company.name? el.warehouse.company.name: ''}`,
                warehouseName: `${el.warehouse && el.warehouse.name? el.warehouse.name: ''}`,
                cityName: `${el.city.name? el.city.name: ''} (${el.city.region? el.city.region: ''})`
            };
        });


        // let rs: {[key: string]: any} = {};
        // roads.map((el, num) => {
        //     rs[`road_${num}`] = el;
        // });

        let tf = {
            ...tariff,
            companyName: `${tariff.company.name}`,
        }

        if(transport === null){
            transport = {
                car: {
                    bodyType: '',
                    comment: '',
                    loadCapacity: null,
                    mark: '',
                    model: '',
                    registrationNumber: '',
                    type: '',
                },
                driver: {
                    drivingLicence: '',
                    fio: '',
                    passport: '',
                    phone: null
                },
                security: {
                    extra: '',
                    fio: '',
                    passport: '',
                    phone: null,
                    securityCompanyName: ''
                },
                trailer: {
                    comment: '',
                    loadCapacity: null,
                    mark: '',
                    registrationNumber: '',
                    type: ''
                }
            }
        }
        // console.log(loadInPoint)
        let lip = {
            ...loadInPoint,
            // transport,
            company: {
                name: loadInPoint.warehouse.company && loadInPoint.warehouse.company.name? loadInPoint.warehouse.company.name: '',
                id:  loadInPoint.warehouse.company && loadInPoint.warehouse.company.id? loadInPoint.warehouse.company.id: ''
            },
            companyName: `${loadInPoint.warehouse.company && loadInPoint.warehouse.company.name? loadInPoint.warehouse.company.name: ''}`,
            warehouseName: `${loadInPoint.warehouse && loadInPoint.warehouse.name? loadInPoint.warehouse.name: ''}`,
            cityName: `${loadInPoint.city.name} (${loadInPoint.city.region})`
        }
        // console.log(lip);

        return {
            ...restData,
            comments: coms,
            events: evs,
            loadOutPoints: lops,
            tariff: tf,
            loadInPoint: {...lip},
            // downloadPoints: dps,
            // intermediatePoints: ips,
            transport
            // roads: rs,
            // uploadPoint: up
        }
    };

    adaptToSend = (inputData: IState, withId: boolean) => {
//        let { downloadPoints, intermediatePoints, transports, roads, uploadPoint, ...restData } = inputData;
        let { id, status, additional, comments, events, loadOutPoints, loadInPoint, tariff, ...restData} = inputData;

        // let coms: IOrderComment[] = [];
        let coms: IOrderComment[] = Object.keys(comments).map((el, num) => {
            let {...commentsRest} = comments[el];
            // coms.push({
            //     ...commentsRest
            // });
            return ({
                ...commentsRest
            })
        });

        // let evs: IEventModel[] = [];
        let evs: IEventModel[] = Object.keys(events).map((el, num) => {
            let {...eventsRest} = events[el];
            // evs.push({
            //     ...eventsRest
            // });
            return ({
                ...eventsRest
            });
        });

        // let lops: ILoadOutPointModelSend[] = [];
        let lops: ILoadOutPointModelSend[] = Object.keys(loadOutPoints).map((el, num) => {
            let {companyName, warehouseName, cityName, ...loadOutPointsRest} = loadOutPoints[el];
            // lops.push({
            //     ...loadOutPointsRest
            // });
            // console.log(loadOutPointsRest)
            return ({
                ...loadOutPointsRest
            });
        });

        const {companyName: cntf, ...tf} = tariff;

        const {companyName: cnLip, warehouseName: wnLip, cityName: ctnLip, ...lip} = loadInPoint;

        if(withId){
            return {
                ...restData,
                comments: coms,
                events: evs,
                loadOutPoints: lops,
                tariff: tf,
                loadInPoint: lip,
                id
            }
        } else {
            return {
                ...restData,
                comments: coms,
                events: evs,
                loadOutPoints: lops,
                tariff: tf,
                loadInPoint: lip,
            }
        }
        

    };

    changeCheckbox = (fieldName: string, value: PointType) => {
        this.setState((prevState: IState) => ({
            intermediatePoints: {
                ...prevState.intermediatePoints,
                [fieldName]: {
                    ...prevState.intermediatePoints[fieldName],
                    type: value
                }
            }
        }))
    };

    changeFieldInArray = (event: React.ChangeEvent<HTMLInputElement>, sectionName: string, arrayName: string) => {
        let fieldName: string = event.target.name;
        let value: string | number = event.target.value;
        this.setState((prevState: IState) => ({
            [sectionName]: {
                ...prevState[sectionName],
                [arrayName]: {
                    ...prevState[sectionName][arrayName],
                    [fieldName]: value
                }
            }
        }));
    }


    changeField(event: React.ChangeEvent<HTMLInputElement>) {
        let fieldName: string = event.target.name;
        let value: string | number = event.target.value;
        this.setState((prevState: IState) => ({
            [fieldName]: value
        }));
    }

    //VALID
    changeFieldInSection(event: React.ChangeEvent<HTMLInputElement>, sectionName: string) {
        let eventItem = event;
        let fieldName: string = eventItem.target.name;
        let value: string | number = eventItem.target.type === 'checkbox' ? eventItem.target.checked.toString() : eventItem.target.value;
        // console.log(eventItem.target.name , eventItem.target.type, eventItem.target.checked, eventItem.target.checked.toString())
        this.setState((prevState: IState) => ({
            [sectionName]: {
                ...prevState[sectionName],
                [fieldName]: value
            }
        }));
    }

    //VALID
    changeCheckboxInSection(event: { target: { value: string, name: string } }, sectionName: string) {
        let eventItem = event;
        let fieldName: string = eventItem.target.name;
        let value: string | number = eventItem.target.value;
        // console.log(eventItem.target.name , eventItem.target.type, eventItem.target.checked, eventItem.target.checked.toString())
        this.setState((prevState: IState) => ({
            [sectionName]: {
                ...prevState[sectionName],
                [fieldName]: value
            }
        }));
    }

    changeDateInSection(name: string, value: string | moment.Moment, sectionName: string) {
        // console.log(sectionName, arrayName)

        this.setState((prevState: IState) => ({
            [sectionName]: {
                ...prevState[sectionName],
                [name]: value.valueOf(),

            }
        }));
    }

    changeTimeInSection(name: string, value: string | moment.Moment, sectionName: string) {
        // console.log(sectionName, arrayName)
        let newTime: moment.Moment | null = null;
        if (typeof value !== 'string')
            newTime = moment(this.state[sectionName][name]).hours(value.hours()).minutes(value.minutes());

        if (newTime !== null && newTime) {
            this.setState((prevState: IState) => ({
                [sectionName]: {
                    ...prevState[sectionName],
                    [name]: newTime !== null ? newTime.valueOf() : 0,

                }
            }));
        }
    }

    //VALID
    changeCityInArray = (row: ICityModel, sectionName: string, arrayName: string) => {
        // console.log(sectionName, arrayName)

        this.setState((prevState: IState) => ({
            [sectionName]: {
                ...prevState[sectionName],
                [arrayName]: {
                    ...prevState[sectionName][arrayName],
                    cityName: `${row.name} (${row.region})`,
                    city: {
                        name: row.name,
                        region: row.region
                    }
                }
            }
        }));
    }

//VALID
    changeCompanyInArray(row: ICompanyModel, sectionName: string, arrayName: string) {
    // console.log(sectionName, arrayName)

        this.setState((prevState: IState) => ({
            [sectionName]: {
                ...prevState[sectionName],
                [arrayName]: {
                    ...prevState[sectionName][arrayName],
                    companyName: `${row.shortName}`,
                    company: {
                        name: row.shortName,
                        id: row.id
                    }
                }
            }
        }));
    }

    //VALID
    changeCompanyByCityInArray(row: ICompanyNameModel, sectionName: string, arrayName: string) {
        // console.log(sectionName, arrayName)

        this.setState((prevState: IState) => ({
            [sectionName]: {
                ...prevState[sectionName],
                [arrayName]: {
                    ...prevState[sectionName][arrayName],
                    companyName: `${row.name}`,
                    company: {
                        name: row.name,
                        id: row.id
                    }
                }
            }
        }));
    }

    changeDateInArray(name: string, value: string | moment.Moment, sectionName: string, arrayName: string) {
        // console.log(sectionName, arrayName)

        this.setState((prevState: IState) => ({
            [sectionName]: {
                ...prevState[sectionName],
                [arrayName]: {
                    ...prevState[sectionName][arrayName],
                    [name]: value.valueOf(),
                }
            }
        }));
    }

    changeTimeInArray(name: string, value: string | moment.Moment, sectionName: string, arrayName: string) {
        // console.log(sectionName, arrayName)

        // console.log('time', value, value.hours(), value.minutes())
        // let time = moment(this.state[sectionName][arrayName][name]).hour(hours).minute(mins);
        let newTime: moment.Moment | null = null;
        if (typeof value !== 'string')
            newTime = moment(this.state[sectionName][arrayName][name]).hours(value.hours()).minutes(value.minutes());

        if (newTime !== null && newTime) {
            this.setState((prevState: IState) => ({
                [sectionName]: {
                    ...prevState[sectionName],
                    [arrayName]: {
                        ...prevState[sectionName][arrayName],
                        [name]: newTime !== null ? newTime.valueOf() : 0,
                    }
                }
            }));
        }

    }

    changeWarehouseInArray(row: IWarehouseModel, sectionName: string, arrayName: string) {
        // console.log(sectionName, arrayName)

        this.setState((prevState: IState) => ({
            [sectionName]: {
                ...prevState[sectionName],
                [arrayName]: {
                    ...prevState[sectionName][arrayName],
                    warehouseName: `${row.name}`,
                    warehouse: {
                        name: row.name,
                        id: row.id,
                        company: {
                            name: row.company.name,
                            id: row.company.id
                        }
                    }
                }
            }
        }));
    }


    /// VALID
    changeCityInSection = (row: ICityModel, sectionName: string) => {
        this.setState((prevState: IState) => ({
            [sectionName]: {
                ...prevState[sectionName],
                cityName: `${row.name} (${row.region})`,
                city: {
                    name: row.name,
                    region: row.region
                }
            }
        }));
    }


    changeCompanyInSection(row: ICompanyModel, sectionName: string) {
        this.setState((prevState: IState) => ({
            [sectionName]: {
                ...prevState[sectionName],
                companyName: `${row.shortName}`,
                company: {
                    name: row.shortName,
                    id: row.id
                }
            }
        }));
    }

    changeCompanyByCityInSection(row: ICompanyNameModel, sectionName: string) {
        this.setState((prevState: IState) => ({
            [sectionName]: {
                ...prevState[sectionName],
                companyName: `${row.name}`,
                company: {
                    name: row.name,
                    id: row.id
                }
            }
        }));
    }

    changeWarehouseInSection(row: IWarehouseModel, sectionName: string) {
        this.setState((prevState: IState) => ({
            [sectionName]: {
                ...prevState[sectionName],
                warehouseName: `${row.name}`,
                warehouse: {
                    name: row.name,
                    id: row.id,
                    company: {
                        name: row.company.name,
                        id: row.company.id
                    }
                }
            }
        }));
    }


    addNewLoadOutPointInArray = () => {
        this.setState((prevState: IState) => ({
            loadOutPoints: {
                ...prevState.loadOutPoints,
                [`loadOutPoint_${Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)}`]: {
                    city: {
                        name: '',
                        region: ''
                    },
                    cityName: '',
                    orderName: '',
                    downloadDate: null,
                    rrpBoxes: null,
                    sigBoxes: null,
                    sto: null,
                    ttn: null,
                    company: {
                        name: '',
                        id: ''
                    },
                    companyName: '',
                    warehouseName: '',
                    warehouse: {
                        company: {
                            name: '',
                            id: ''
                        },
                        // companyName: '',
                        id: '',
                        name: ''
                    }
                }
            }
        }));
    };

    deleteLoadOutPointInArray = (id: string) => {


        this.setState((prevState: IState) => {
            let {[id]: _, ...rest} = prevState.loadOutPoints;

            return {
                loadOutPoints: {
                    ...rest
                }
            }
        });
    };

    addNewIntermediatePointInArray = () => {

        this.setState((prevState: IState) => ({
            intermediatePoints: {
                ...prevState.intermediatePoints,
                [`intermediatePoint_${Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)}`]: {
                    city: {
                        name: '',
                        region: ''
                    },
                    cityName: '',
                    comment: '',
                    coordinates: '',
                    type: 'PARCKING'
                }
            }
        }));
    };

    deleteNewIntermediatePointInArray = (id: string) => {


        this.setState((prevState: IState) => {
            let {[id]: _, ...rest} = prevState.intermediatePoints;

            return {
                intermediatePoints: {
                    ...rest
                }
            }
        });
    };

    addNewDownloadPointInArray = () => {

        this.setState((prevState: IState) => ({
            downloadPoints: {
                ...prevState.downloadPoints,
                [`intermediatePoint_${Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)}`]: {
                    city: {
                        name: '',
                        region: ''
                    },
                    cityName: '',
                    comment: '',
                    coordinates: '',
                    type: 'DOWNLOAD'
                }
            }
        }));
    };

    deleteNewDownloadPointInArray = (id: string) => {


        this.setState((prevState: IState) => {
            let {[id]: _, ...rest} = prevState.downloadPoints;

            return {
                downloadPoints: {
                    ...rest
                }
            }
        });
    };

    addNewTransportInArray = () => {

        this.setState((prevState: IState) => ({
            transports: {
                ...prevState.transports,
                [`transport_${Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)}`]: {
                    company: {
                        name: '',
                        id: ''
                    },
                    companyName: '',
                    tonnage: null,
                    uploadPercent: null
                }
            }
        }));
    };

    deleteTransportInArray = (id: string) => {


        this.setState((prevState: IState) => {
            let {[id]: _, ...rest} = prevState.transports;
            // console.log(id, prevState)
            return {
                transports: {
                    ...rest
                }
            }
        });
    };

    addRoadInArray = () => {

        this.setState((prevState: IState) => ({
            roads: {
                ...prevState.roads,
                [`road_${Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)}`]: ''
            }
        }));
    };

    deleteRoadInArray = (id: string) => {


        this.setState((prevState: IState) => {
            let {[id]: _, ...rest} = prevState.roads;
            // console.log(id, prevState)
            return {
                roads: {
                    ...rest
                }
            }
        });
    };

    handleCreateRecord = () => {
        // let data: IOrderCreateModel = this.adaptToSend(this.state);
        // this.props.createOrder(data)
        //     .then(data => {

        //     })
    };

    handleEditRecord = () => {

        // this.props.editOrder({...this.adaptToSend(this.state), id: this.props.match.params.id})
        //     .then(data => {

        //     })
    };

    editIsValid = () => {
        return equal(this.state, this.beforeUpdate);
    };

    calcSum(a: number | null, b: number | null): number {
        if (a !== null && b !== null) {
            // console.log(+a + +b, (+a + +b).toString())
            return +a + +b;
        } else {
            return a ? a : b ? b : 0;
        }
    };

    fullBoxesSum = (): number => {
        let sum = 0;
        Object.keys(this.state.loadOutPoints).forEach(e => {
            let suma = this.state.loadOutPoints[e].sigBoxes !== null ? this.state.loadOutPoints[e].sigBoxes : 0;
            let sumb = this.state.loadOutPoints[e].rrpBoxes !== null ? this.state.loadOutPoints[e].rrpBoxes : 0;
            sum += suma !== null ? suma : 0;
            sum += sumb !== null ? sumb : 0;
        })
        return sum;
    };

    setStep = (step: number) => {
        // if(document.querySelector('.dashboard') !== null){
        //     let elem: Element = document.querySelector('.dashboard') !== null? document.querySelector('.dashboard') : <div></div>;
        //     (document.querySelector('.dashboard') !== null? document.querySelector('.dashboard') : <div></div>).scrollTo({ top: 0, behavior: 'smooth' }) : null;
        // }

        this.setState((prevState: IState) => {

            return {
                additional: {
                    ...prevState.additional,
                    step
                }
            }
        });


    };

    validFirstStep = (): boolean => {
        let {loadOutPoints, loadInPoint} = this.state;
        let isValid = true;
        const {uploadDate, departureDate, company, city, warehouse} = loadInPoint;
        if (company.name === '' || company.id === '' || warehouse.company.name === '' || warehouse.company.id === '' || warehouse.id === null || warehouse.name === null || city.name === '' || city.region === '' || departureDate === null || uploadDate === null) {
            isValid = false;
        }
        Object.keys(loadOutPoints).forEach((el, num) => {
            const {downloadDate, company, city, warehouse} = loadOutPoints[el];
            if (company.name === '' || company.id === '' || warehouse.company.name === '' || warehouse.company.id === '' || warehouse.id === null || warehouse.name === null || city.name === '' || city.region === '' || downloadDate === null) {
                isValid = false;
            }
        });

        return isValid;
    };

    validPickupStep = (): boolean => {
        let {loadOutPoints, loadInPoint} = this.state;
        let isValid = true;
        const {company, city, warehouse} = loadInPoint;
        if (company.name === '' || company.id === '' || warehouse.company.name === '' || warehouse.company.id === '' || warehouse.id === null || warehouse.name === null || city.name === '' || city.region === '') {
            isValid = false;
        }
        Object.keys(loadOutPoints).forEach((el, num) => {
            const {company, city, warehouse} = loadOutPoints[el];
            if (company.name === '' || company.id === '' || warehouse.company.name === '' || warehouse.company.id === '' || warehouse.id === null || warehouse.name === null || city.name === '' || city.region === '') {
                isValid = false;
            }
        });

        return isValid;
    };

    validSecondStep = (): boolean => {
        let {tariff} = this.state;
        let isValid = true;
        const {tonnage, company} = tariff;
        if (company.name === '' || company.id === '' || tonnage === null) {
            isValid = false;
        }
        return isValid;
    };

    validSecondDate = (current: any, date: number | null):boolean => {
        const selectedDate = date ? moment(date) : moment(0) //Datetime.moment().subtract( 1, 'day' );
        
        return current.isAfter( selectedDate );
    }

    step1Content = () => {
        const {locale} = this.props;
        const {currentLocaleJSON: loc} = locale;
        const pickup = this.props.match.params.type === 'pickup';
        return (
            <>
                <Section title={`1. ${loc.OrderBlock_Step11Title}`}>
                    <div className="fields-section">


                        <InputField centerText centerMode col={pickup ? 4 : 2.5} required={true}
                                    disabled={this.props.view} padding={true} placeholder={loc.OrderBlock_InputCity}
                                    label={loc.OrderBlock_InputCity} type="city" onSelect={(row) => {
                            this.changeCityInSection(row, 'loadInPoint')
                        }} onChange={(e) => {
                            this.changeFieldInSection(e, 'loadInPoint')
                        }} value={this.state.loadInPoint.cityName} name='cityName'/>
                        <InputField centerText centerMode col={pickup ? 4 : 2.5}
                                    cityName={this.state.loadInPoint.city.name}
                                    disabled={this.state.loadInPoint.city.region === '' && this.state.loadInPoint.city.name === ''}
                                    padding={true} placeholder={loc.OrderBlock_Sender} label={loc.OrderBlock_Sender}
                                    autoSelect type="companyByCity" onSelect={(row) => {
                            this.changeCompanyByCityInSection(row, 'loadInPoint')
                        }} onChange={(e) => {
                            this.changeFieldInSection(e, 'loadInPoint')
                        }} value={this.state.loadInPoint.companyName} name='companyName'/>
                        <InputField centerText centerMode col={pickup ? 4 : 2.5}
                                    additionalFilters={{'company.name|full': this.state.loadInPoint.company.name}}
                                    disabled={this.state.loadInPoint.company.id === '' && this.state.loadInPoint.company.name === ''}
                                    padding={true} placeholder={loc.OrderBlock_InputWarehouse}
                                    label={loc.OrderBlock_InputWarehouse} autoSelect type="warehouse"
                                    onSelect={(row) => {
                                        this.changeWarehouseInSection(row, 'loadInPoint')
                                    }} onChange={(e) => {
                            this.changeFieldInSection(e, 'loadInPoint')
                        }} value={this.state.loadInPoint.warehouseName} name='warehouseName'/>
                        {
                            pickup ?
                                null
                                :
                                <>
                                    <InputField centerText centerMode col={1.125}
                                                label={loc.OrderBlock_InputDate} type="date"
                                                changeDate={(name, value) => {
                                                    this.changeDateInSection(name, value, 'loadInPoint')
                                                }} value={formatDate(this.state.loadInPoint.uploadDate)}
                                                name='uploadDate'/>
                                    <InputField disabled={this.state.loadInPoint.uploadDate === null} centerText
                                                centerMode col={1.125}
                                                // isValidDate={(current:any) => { return this.validSecondDate(current, this.state.loadInPoint.uploadDate)}}
                                                label={loc.OrderBlock_InputTime} type="time"
                                                changeDate={(name, value) => {
                                                    this.changeTimeInSection(name, value, 'loadInPoint')
                                                }} value={formatTime(this.state.loadInPoint.uploadDate)}
                                                name='uploadDate'/>
                                    <InputField centerText centerMode col={1.125}
                                                isValidDate={(current:any) => { return this.validSecondDate(current, this.state.loadInPoint.uploadDate)}}
                                                label={loc.OrderBlock_InputDate} type="date"
                                                changeDate={(name, value) => {
                                                    this.changeDateInSection(name, value, 'loadInPoint')
                                                }} value={formatDate(this.state.loadInPoint.departureDate)}
                                                name='departureDate'/>
                                    <InputField disabled={this.state.loadInPoint.departureDate === null} centerText
                                                centerMode col={1.125} 
                                                
                                                label={loc.OrderBlock_InputTime} type="time"
                                                changeDate={(name, value) => {
                                                    this.changeTimeInSection(name, value, 'loadInPoint')
                                                }} value={formatTime(this.state.loadInPoint.departureDate)}
                                                name='departureDate'/>
                                </>
                        }


                    </div>
                    <div className="section-header">
                        {/* <div className="card-header-icon">
                        <Icon>{props.icon}</Icon>
                    </div> */}
                        <p>{`1.2 ${loc.OrderBlock_Step12Title}`}</p>
                    </div>
                    {
                        Object.keys(this.state.loadOutPoints).map((ip, ipNum) => {
                            let ipData = this.state.loadOutPoints[ip];
                            return <div key={`${ip}_${ipNum}`} className="fields-section top-sparator padding">
                                <InputField centerText withoutLabelBorder centerMode col={pickup ? 3.7 : 2.22}
                                            required={true} disabled={this.props.view} padding={true}
                                            placeholder={loc.OrderBlock_InputCity} label={loc.OrderBlock_InputCity}
                                            type="city" onSelect={(row) => {
                                    this.changeCityInArray(row, 'loadOutPoints', ip)
                                }} onChange={(e) => {
                                    this.changeFieldInArray(e, 'loadOutPoints', ip)
                                }} value={this.state.loadOutPoints[ip].cityName} name='cityName'/>
                                <InputField centerText withoutLabelBorder centerMode col={pickup ? 3.7 : 2.22}
                                            cityName={this.state.loadOutPoints[ip].city.name}
                                            disabled={this.state.loadOutPoints[ip].city.region === '' && this.state.loadOutPoints[ip].city.name === ''}
                                            padding={true} placeholder={loc.OrderBlock_Addressant}
                                            label={loc.OrderBlock_Addressant} autoSelect type="companyByCity"
                                            onSelect={(row) => {
                                                this.changeCompanyByCityInArray(row, 'loadOutPoints', ip)
                                            }} onChange={(e) => {
                                    this.changeFieldInArray(e, 'loadOutPoints', ip)
                                }} value={this.state.loadOutPoints[ip].companyName} name='companyName'/>
                                <InputField centerText withoutLabelBorder centerMode col={pickup ? 3.7 : 2.22}
                                            additionalFilters={{'company.name|full': this.state.loadOutPoints[ip].company.name}}
                                            disabled={this.state.loadOutPoints[ip].company.id === '' && this.state.loadOutPoints[ip].company.name === ''}
                                            padding={true} placeholder={loc.OrderBlock_OutputWarehouse}
                                            label={loc.OrderBlock_OutputWarehouse} autoSelect type="warehouse"
                                            onSelect={(row) => {
                                                this.changeWarehouseInArray(row, 'loadOutPoints', ip)
                                            }} onChange={(e) => {
                                    this.changeFieldInArray(e, 'loadOutPoints', ip)
                                }} value={this.state.loadOutPoints[ip].warehouseName} name='warehouseName'/>
                                {/* //'validSecondDate' */}
                                {
                                    pickup ?
                                        null
                                        :
                                        <>
                                            <InputField centerText withoutLabelBorder centerMode col={2.22}
                                                        isValidDate={() => true} label={loc.OrderBlock_Step2_OutputDate}
                                                        type="date" changeDate={(name, value) => {
                                                this.changeDateInArray(name, value, 'loadOutPoints', ip)
                                            }} value={formatDate(this.state.loadOutPoints[ip].downloadDate)}
                                                        name='downloadDate'/>
                                            <InputField disabled={this.state.loadOutPoints[ip].downloadDate === null}
                                                        centerText withoutLabelBorder centerMode col={2.22}
                                                        isValidDate={() => true} label={loc.OrderBlock_Step2_OutputTime}
                                                        type="time" changeDate={(name, value) => {
                                                this.changeTimeInArray(name, value, 'loadOutPoints', ip)
                                            }} value={formatTime(this.state.loadOutPoints[ip].downloadDate)}
                                                        name='downloadDate'/>
                                        </>
                                }
                                <InputField centerText withoutLabelBorder centerMode col={2.22}
                                            disabled={this.props.view} padding={true} placeholder={loc.OrderBlock_STO}
                                            label={loc.OrderBlock_STO} type="number" onChange={(e) => {
                                    this.changeFieldInArray(e, 'loadOutPoints', ip)
                                }} value={this.state.loadOutPoints[ip].sto} name='sto'/>
                                <InputField centerText withoutLabelBorder centerMode col={2.22}
                                            disabled={this.props.view} padding={true} placeholder={loc.OrderBlock_ТТН}
                                            label={loc.OrderBlock_ТТН} type="number" onChange={(e) => {
                                    this.changeFieldInArray(e, 'loadOutPoints', ip)
                                }} value={this.state.loadOutPoints[ip].ttn} name='ttn'/>
                                <InputField centerText withoutLabelBorder centerMode col={2.22}
                                            disabled={this.props.view} padding={true}
                                            placeholder={loc.OrderBlock_SigBoxes} label={loc.OrderBlock_SigBoxes}
                                            type="number" onChange={(e) => {
                                    this.changeFieldInArray(e, 'loadOutPoints', ip)
                                }} value={this.state.loadOutPoints[ip].sigBoxes} name='sigBoxes'/>
                                <InputField centerText withoutLabelBorder centerMode col={2.22}
                                            disabled={this.props.view} padding={true}
                                            placeholder={loc.OrderBlock_RRPBoxes} label={loc.OrderBlock_RRPBoxes}
                                            type="number" onChange={(e) => {
                                    this.changeFieldInArray(e, 'loadOutPoints', ip)
                                }} value={this.state.loadOutPoints[ip].rrpBoxes} name='rrpBoxes'/>
                                <InputField disabled centerText withoutLabelBorder centerMode col={2.22} padding={true}
                                            placeholder={loc.OrderBlock_AllBoxes} label={loc.OrderBlock_AllBoxes}
                                            type="number" onChange={(e) => {
                                }}
                                            value={this.calcSum(this.state.loadOutPoints[ip].sigBoxes, this.state.loadOutPoints[ip].rrpBoxes)}
                                            name='sumBoxes'/>
                                <div onClick={Object.keys(this.state.loadOutPoints).length <= 1 ? () => {
                                } : () => {
                                    this.deleteLoadOutPointInArray(ip)
                                }}
                                     className={`delete-row-btn${Object.keys(this.state.loadOutPoints).length <= 1 ? ' disable' : ''}`}>
                                    <Icon>close</Icon>
                                </div>
                            </div>
                        })
                    }
                    <div className="add-block-line">
                        <div onClick={this.addNewLoadOutPointInArray}>
                            <Icon>add</Icon>
                        </div>
                        <p>{loc.OrderBlock_AddPoint}</p>
                    </div>
                </Section>
                {
                    pickup ?
                        null
                        :
                        <Section title={`2. ${loc.OderBlock_CommentSectionTitle}`}>
                            <div className="fields-section">
                                <InputField col={12} padding={true} placeholder={loc.OderBlock_CommentSectionTitle}
                                            label={loc.OderBlock_CommentSectionTitle} type="multiline"
                                            onChange={(e) => {
                                                this.changeField(e)
                                            }} value={this.state.comment} name='comment'/>
                            </div>
                        </Section>
                }
            </>
        )
    };

    getNewRouteName = (): string => {
        let routeName = `${this.state.loadInPoint.city.name}`;

        Object.keys(this.state.loadOutPoints).forEach(key => {
            routeName += ` - `;
            routeName += `${this.state.loadOutPoints[key].city.name}`;
        });

        // this.setState((prevState: IState) => ({
        //     route: {
        //         ...prevState.route,
        //         name: routeName
        //     }
        // }));
        return routeName;
    };

    step2Content = () => {
        const {locale} = this.props;
        const {currentLocaleJSON: loc} = locale;
        this.searchRoute();
        // this.getNewRouteName();
        return (
            <>
                <Section title={`1. ${loc.OrderScreen_Other}`}>
                    <div className="fields-section line">


                        <InputField centerText centerMode col={2} padding={true}
                                    placeholder={loc.OrderScreen_Transferer} label={loc.OrderScreen_Transferer}
                                    autoSelect cityName={this.state.loadInPoint.city.name} type="company" onSelect={(row) => {
                            this.changeCompanyInSection(row, 'tariff')
                        }} onChange={(e) => {
                            this.changeFieldInSection(e, 'tariff')
                        }} value={this.state.tariff.companyName} name='companyName'/>
                        <InputField centerText disabled centerMode col={1} padding={true}
                                    placeholder={loc.OrderScreen_Tariff} label={loc.OrderScreen_Tariff} type="number"
                                    onChange={(e) => {
                                        this.changeFieldInSection(e, 'tariff')
                                    }} value={this.state.tariff.value} name='value'/>
                        <InputField centerMode col={2} required={true} disabled={this.props.view} padding={true}
                                    placeholder={loc.OrderScreen_Capacity} label={loc.OrderScreen_Capacity}
                                    type="select" onChange={(e) => {
                            this.changeFieldInSection(e, 'tariff')
                        }} name="tonnage" value={this.state.tariff.tonnage}
                                    options={[
                                        {
                                            value: 10,
                                            label: '10',
                                        },
                                        {
                                            value: 15,
                                            label: '15',
                                        },
                                        {
                                            value: 20,
                                            label: '20',
                                        },
                                    ]}/>
                        <InputField centerText centerMode col={1} disabled={this.props.view} padding={true}
                                    placeholder={loc.OrderScreen_TimeInWay} label={loc.OrderScreen_TimeInWay}
                                    type="number" onChange={(e) => {
                            this.changeFieldInSection(e, 'tariff')
                        }} value={this.state.tariff.time} name='time'/>
                        <InputField centerText centerMode col={1} disabled={this.props.view} padding={true}
                                    placeholder={loc.OrderScreen_DistanceInWay} label={loc.OrderScreen_DistanceInWay}
                                    type="number" onChange={(e) => {
                            this.changeFieldInSection(e, 'tariff')
                        }} value={this.state.tariff.distance} name='distance'/>
                        <InputField centerText centerMode col={1.2} disabled padding={true}
                                    placeholder={loc.OrderScreen_CountOfBoxes} label={loc.OrderScreen_CountOfBoxes}
                                    type="number" onChange={(e) => {
                        }} value={this.fullBoxesSum()} name='fullBoxesSum'/>
                        <InputField centerText centerMode col={1} disabled={this.props.view} padding={true}
                                    placeholder={loc.OrderScreen_ShipmentNum} label={loc.OrderScreen_ShipmentNum}
                                    type="text" onChange={(e) => {
                            this.changeFieldInSection(e, 'tariff')
                        }} value={this.state.tariff.shipmentNumber} name='shipmentNumber'/>
                        <InputField centerText centerMode col={1} disabled={this.props.view} padding={true}
                                    placeholder={loc.OrderScreen_BothSide} label={loc.OrderScreen_BothSide}
                                    type="checkbox" onChangeCheckbox={(name, value) => {
                            this.changeCheckboxInSection({target: {name, value}}, 'tariff')
                        }} value={this.state.tariff.comeBack !== null ? this.state.tariff.comeBack.toString() : 'false'}
                                    name='comeBack'/>
                        <InputField centerText centerMode col={.8} disabled={this.props.view} padding={true}
                                    placeholder={loc.OrderScreen_Security} label={loc.OrderScreen_Security}
                                    type="checkbox" onChangeCheckbox={(name, value) => {
                            this.changeCheckboxInSection({target: {name, value}}, 'tariff')
                        }}
                                    value={this.state.tariff.withSecurity !== null ? this.state.tariff.withSecurity.toString() : 'false'}
                                    name='withSecurity'/>
                        <Button small padding color="primary"
                                disabled={this.state.tariff.company.name === '' || this.state.tariff.company.id === '' || this.state.tariff.tonnage === null}
                                col={1} onClick={() => {
                            this.searchTarrif()
                        }}>{loc.OderBlock_TariffBtn}</Button>
                    </div>

                </Section>
                <Section title={`2. ${loc.OrderBlock_Step22Title}`}>
                    <div className='routes-list'>
                        
                        {
                            this.state.route.name && this.state.route.name !== ''?
                                <p>{this.state.route.name}</p>
                            :
                            <>
                                <p>{`${this.state.loadInPoint.city.name}`}</p>
                                {Object.keys(this.state.loadOutPoints).map((key, num) =>
                                    <React.Fragment key={`${this.state.loadOutPoints[key].city.name}_${num}`}>
                                        <p> - </p>
                                        <p>{`${this.state.loadOutPoints[key].city.name}`}</p>
                                    </React.Fragment>
                                )}
                            </>
                        }
                    </div>
                    {
                        this.state.route.sap && this.state.route.sap !== ''?
                            <div className="route-list-btn-wrapper">
                                <InputField centerText centerMode hideLabel col={5} disabled padding={true}
                                            placeholder={loc.OrderScreen_ShipmentNum} label={loc.OrderScreen_ShipmentNum}
                                            type="text" onChange={(e) => {
                                    this.changeFieldInSection(e, 'route')
                                }} value={this.state.route.sap} name='sap'/>
                            </div>
                        :
                            null
                    }
                </Section>
                <Section title={`3. ${loc.OrderBlock_Step23Title}`}>
                    <p className="no-data-text">{loc.OrderBlock_NoData}</p>
                </Section>
                <Section title={`4. ${loc.OderBlock_CommentSectionTitle}`}>
                    <div className="fields-section">
                        <InputField col={12} padding={true} placeholder={loc.OderBlock_CommentSectionTitle}
                                    label={loc.OderBlock_CommentSectionTitle} type="multiline" onChange={(e) => {
                            this.changeField(e)
                        }} value={this.state.comment} name='comment'/>
                    </div>
                </Section>
                <Section withoutHeader>
                    <div className="order-price-block">
                        <div className="result">
                            <p>{loc.OrderBlock_ResultTitle}</p>
                        </div>
                        <div className="price">
                            <p>{loc.OrderBlock_ResultPrice}</p>
                            <InputField centerText centerMode col={12} hideLabel label={loc.OrderScreen_TimeInWay}
                                        type="number" onChange={(e) => {
                                this.changeField(e)
                            }} value={this.state.price} name='price'/>
                        </div>
                    </div>
                </Section>

            </>
        )
    };

    step3Content = () => {
        const {locale} = this.props;
        const {currentLocaleJSON: loc} = locale;
        const pickup = this.props.match.params.type === 'pickup';
        return (
            <>
                <Section title={`1. ${loc.OrderBlock_Step11Title}`}>
                    <div className="fields-section">


                        <InputField centerText centerMode col={pickup ? 4 : 2.5} required={true} disabled padding={true}
                                    placeholder={loc.OrderBlock_InputCity} label={loc.OrderBlock_InputCity} type="city"
                                    onSelect={(row) => {
                                        this.changeCityInSection(row, 'loadInPoint')
                                    }} onChange={(e) => {
                            this.changeFieldInSection(e, 'loadInPoint')
                        }} value={this.state.loadInPoint.cityName} name='cityName'/>
                        <InputField centerText centerMode col={pickup ? 4 : 2.5} disabled padding={true}
                                    placeholder={loc.OrderBlock_Sender} label={loc.OrderBlock_Sender} autoSelect
                                    type="company" onSelect={(row) => {
                            this.changeCompanyInSection(row, 'loadInPoint')
                        }} onChange={(e) => {
                            this.changeFieldInSection(e, 'loadInPoint')
                        }} value={this.state.loadInPoint.companyName} name='companyName'/>
                        <InputField centerText centerMode col={pickup ? 4 : 2.5}
                                    additionalFilters={{'company.name|full': this.state.loadInPoint.company.name}}
                                    disabled padding={true} placeholder={loc.OrderBlock_InputWarehouse}
                                    label={loc.OrderBlock_InputWarehouse} autoSelect type="warehouse"
                                    onSelect={(row) => {
                                        this.changeWarehouseInSection(row, 'loadInPoint')
                                    }} onChange={(e) => {
                            this.changeFieldInSection(e, 'loadInPoint')
                        }} value={this.state.loadInPoint.warehouseName} name='warehouseName'/>
                        {
                            pickup ?
                                null
                                :
                                <>
                                    <InputField disabled centerText centerMode col={1.125} isValidDate={() => true}
                                                label={loc.OrderBlock_InputDate} type="date"
                                                changeDate={(name, value) => {
                                                    this.changeDateInSection(name, value, 'loadInPoint')
                                                }} value={formatDate(this.state.loadInPoint.uploadDate)}
                                                name='uploadDate'/>
                                    <InputField disabled centerText centerMode col={1.125} isValidDate={() => true}
                                                label={loc.OrderBlock_InputTime} type="time"
                                                changeDate={(name, value) => {
                                                    this.changeTimeInSection(name, value, 'loadInPoint')
                                                }} value={formatTime(this.state.loadInPoint.uploadDate)}
                                                name='uploadDate'/>
                                    <InputField disabled centerText centerMode col={1.125} isValidDate={() => true}
                                                label={loc.OrderBlock_InputDate} type="date"
                                                changeDate={(name, value) => {
                                                    this.changeDateInSection(name, value, 'loadInPoint')
                                                }} value={formatDate(this.state.loadInPoint.departureDate)}
                                                name='departureDate'/>
                                    <InputField disabled centerText centerMode col={1.125} isValidDate={() => true}
                                                label={loc.OrderBlock_InputTime} type="time"
                                                changeDate={(name, value) => {
                                                    this.changeTimeInSection(name, value, 'loadInPoint')
                                                }} value={formatTime(this.state.loadInPoint.departureDate)}
                                                name='departureDate'/>
                                </>
                        }


                    </div>
                </Section>
                <Section title={`2. ${loc.OrderBlock_Step12Title}`}>
                    {
                        Object.keys(this.state.loadOutPoints).map((ip, ipNum) => {
                            let ipData = this.state.loadOutPoints[ip];
                            return <div key={`${ip}_${ipNum}`} className="fields-section top-sparator">
                                <InputField centerText centerMode col={pickup ? 3.7 : 2.52} required={true} disabled
                                            padding={true} placeholder={loc.OrderBlock_InputCity}
                                            label={loc.OrderBlock_InputCity} type="city" onSelect={(row) => {
                                    this.changeCityInArray(row, 'loadOutPoints', ip)
                                }} onChange={(e) => {
                                    this.changeFieldInArray(e, 'loadOutPoints', ip)
                                }} value={this.state.loadOutPoints[ip].cityName} name='cityName'/>
                                <InputField centerText centerMode col={pickup ? 3.7 : 2.52} disabled padding={true}
                                            placeholder={loc.OrderBlock_Addressant} label={loc.OrderBlock_Addressant}
                                            autoSelect type="company" onSelect={(row) => {
                                    this.changeCompanyInArray(row, 'loadOutPoints', ip)
                                }} onChange={(e) => {
                                    this.changeFieldInArray(e, 'loadOutPoints', ip)
                                }} value={this.state.loadOutPoints[ip].companyName} name='companyName'/>
                                <InputField centerText centerMode col={pickup ? 3.7 : 2.52}
                                            additionalFilters={{'company.name|full': this.state.loadOutPoints[ip].company.name}}
                                            disabled padding={true} placeholder={loc.OrderBlock_OutputWarehouse}
                                            label={loc.OrderBlock_OutputWarehouse} autoSelect type="warehouse"
                                            onSelect={(row) => {
                                                this.changeWarehouseInArray(row, 'loadOutPoints', ip)
                                            }} onChange={(e) => {
                                    this.changeFieldInArray(e, 'loadOutPoints', ip)
                                }} value={this.state.loadOutPoints[ip].warehouseName} name='warehouseName'/>
                                {/* //'validSecondDate' */}
                                {
                                    pickup ?
                                        null
                                        :
                                        <>
                                            <InputField disabled centerText centerMode col={2.22}
                                                        isValidDate={() => true} label={loc.OrderBlock_Step2_OutputDate}
                                                        type="date" changeDate={(name, value) => {
                                                this.changeDateInArray(name, value, 'loadOutPoints', ip)
                                            }} value={formatDate(this.state.loadOutPoints[ip].downloadDate)}
                                                        name='downloadDate'/>
                                            <InputField disabled centerText centerMode col={2.22}
                                                        isValidDate={() => true} label={loc.OrderBlock_Step2_OutputTime}
                                                        type="time" changeDate={(name, value) => {
                                                this.changeTimeInArray(name, value, 'loadOutPoints', ip)
                                            }} value={formatTime(this.state.loadOutPoints[ip].downloadDate)}
                                                        name='downloadDate'/>
                                        </>
                                }
                                <InputField centerText withoutLabelBorder centerMode col={2.52} disabled padding={true} placeholder={loc.OrderBlock_STO}  label={loc.OrderBlock_STO} type="number" onChange={(e) => {this.changeFieldInArray(e, 'loadOutPoints', ip)}} value={this.state.loadOutPoints[ip].sto} name='sto'/>
                            <InputField centerText withoutLabelBorder centerMode col={2.52} disabled padding={true} placeholder={loc.OrderBlock_ТТН}  label={loc.OrderBlock_ТТН} type="number" onChange={(e) => {this.changeFieldInArray(e, 'loadOutPoints', ip)}} value={this.state.loadOutPoints[ip].ttn} name='ttn'/>
                            <InputField centerText withoutLabelBorder centerMode col={2.52} disabled padding={true} placeholder={loc.OrderBlock_SigBoxes}  label={loc.OrderBlock_SigBoxes} type="number" onChange={(e) => {this.changeFieldInArray(e, 'loadOutPoints', ip)}} value={this.state.loadOutPoints[ip].sigBoxes} name='sigBoxes'/>
                            <InputField disabled centerText withoutLabelBorder centerMode col={2.52} padding={true} placeholder={loc.OrderBlock_RRPBoxes}  label={loc.OrderBlock_RRPBoxes} type="number" onChange={(e) => {this.changeFieldInArray(e, 'loadOutPoints', ip)}} value={this.state.loadOutPoints[ip].rrpBoxes} name='rrpBoxes'/>
                            <InputField disabled centerText withoutLabelBorder centerMode col={2.52} padding={true} placeholder={loc.OrderBlock_AllBoxes}  label={loc.OrderBlock_AllBoxes} type="number" onChange={(e) => {}} value={this.calcSum(this.state.loadOutPoints[ip].sigBoxes, this.state.loadOutPoints[ip].rrpBoxes)} name='sumBoxes'/>
                            {/* <div onClick={Object.keys(this.state.loadOutPoints).length <= 1? () => {} : () => {this.deleteLoadOutPointInArray(ip)} } className={`delete-row-btn${Object.keys(this.state.loadOutPoints).length <= 1? ' disable' : ''}`}>
                                <Icon>close</Icon>
                            </div> */}
                            </div>
                        })
                    }
                </Section>
                <Section title={`3. ${loc.OrderBlock_Step22Title}`}>
                    <div className='routes-list'>
                        
                        {
                            this.state.route.name && this.state.route.name !== ''?
                                <p>{this.state.route.name}</p>
                            :
                            <>
                                
                                <p>{`${this.state.loadInPoint.city.name}`}</p>
                                {
                                Object.keys(this.state.loadOutPoints).map((key, num) =>
                                    <React.Fragment key={`${this.state.loadOutPoints[key].city.name}_${num}`}>
                                        <p> - </p>
                                        <p>{`${this.state.loadOutPoints[key].city.name}`}</p>
                                    </React.Fragment>
                                )}
                            </>
                        }
                    </div>
                    {
                        this.state.route.sap && this.state.route.sap !== ''?
                            <InputField centerText centerMode hideLabel col={5} disabled padding={true}
                                        placeholder={loc.OrderScreen_ShipmentNum} label={loc.OrderScreen_ShipmentNum}
                                        type="text" onChange={(e) => {
                                this.changeFieldInSection(e, 'route')
                            }} value={this.state.route.sap} name='sap'/>
                        :
                            null
                    }
                </Section>
                <Section title={`4. ${loc.OrderScreen_Other}`}>
                    <div className="fields-section line">


                        <InputField centerText disabled centerMode col={2.5} padding={true}
                                    placeholder={loc.OrderScreen_Transferer} label={loc.OrderScreen_Transferer}
                                    autoSelect type="company" onSelect={(row) => {
                            this.changeCompanyInSection(row, 'tariff')
                        }} onChange={(e) => {
                            this.changeFieldInSection(e, 'tariff')
                        }} value={this.state.tariff.companyName} name='companyName'/>
                        <InputField centerText disabled centerMode col={1} padding={true}
                                    placeholder={loc.OrderScreen_Tariff} label={loc.OrderScreen_Tariff} type="number"
                                    onChange={(e) => {
                                        this.changeFieldInSection(e, 'tariff')
                                    }} value={this.state.tariff.value} name='value'/>
                        <InputField centerMode col={2} required={true} disabled padding={true}
                                    placeholder={loc.OrderScreen_Capacity} label={loc.OrderScreen_Capacity}
                                    type="select" onChange={(e) => {
                            this.changeFieldInSection(e, 'tariff')
                        }} name="tonnage" value={this.state.tariff.tonnage}
                                    options={[
                                        {
                                            value: 10,
                                            label: '10',
                                        },
                                        {
                                            value: 15,
                                            label: '15',
                                        },
                                        {
                                            value: 20,
                                            label: '20',
                                        },
                                    ]}/>
                        <InputField centerText centerMode col={1} disabled padding={true}
                                    placeholder={loc.OrderScreen_TimeInWay} label={loc.OrderScreen_TimeInWay}
                                    type="number" onChange={(e) => {
                            this.changeFieldInSection(e, 'tariff')
                        }} value={this.state.tariff.time} name='time'/>
                        <InputField centerText centerMode col={1} disabled padding={true}
                                    placeholder={loc.OrderScreen_DistanceInWay} label={loc.OrderScreen_DistanceInWay}
                                    type="number" onChange={(e) => {
                            this.changeFieldInSection(e, 'tariff')
                        }} value={this.state.tariff.distance} name='distance'/>
                        <InputField centerText centerMode col={1.5} disabled padding={true}
                                    placeholder={loc.OrderScreen_CountOfBoxes} label={loc.OrderScreen_CountOfBoxes}
                                    type="number" onChange={(e) => {
                        }} value={this.fullBoxesSum()} name='fullBoxesSum'/>
                        <InputField centerText centerMode col={1} disabled padding={true}
                                    placeholder={loc.OrderScreen_ShipmentNum} label={loc.OrderScreen_ShipmentNum}
                                    type="text" onChange={(e) => {
                            this.changeFieldInSection(e, 'tariff')
                        }} value={this.state.tariff.shipmentNumber} name='shipmentNumber'/>
                        <InputField centerText centerMode col={1} disabled padding={true}
                                    placeholder={loc.OrderScreen_BothSide} label={loc.OrderScreen_BothSide}
                                    type="checkbox" onChangeCheckbox={(name, value) => {
                            this.changeCheckboxInSection({target: {name, value}}, 'tariff')
                        }} value={this.state.tariff.comeBack !== null ? this.state.tariff.comeBack.toString() : 'false'}
                                    name='comeBack'/>
                        <InputField centerText centerMode col={1} disabled padding={true}
                                    placeholder={loc.OrderScreen_Security} label={loc.OrderScreen_Security}
                                    type="checkbox" onChangeCheckbox={(name, value) => {
                            this.changeCheckboxInSection({target: {name, value}}, 'tariff')
                        }}
                                    value={this.state.tariff.withSecurity !== null ? this.state.tariff.withSecurity.toString() : 'false'}
                                    name='withSecurity'/>

                    </div>

                </Section>
                <Section title={`5. ${loc.OderBlock_CommentSectionTitle}`}>
                    <div className="fields-section">
                        <InputField disabled col={12} padding={true} placeholder={loc.OderBlock_CommentSectionTitle}
                                    label={loc.OderBlock_CommentSectionTitle} type="multiline" onChange={(e) => {
                            this.changeField(e)
                        }} value={this.state.comment} name='comment'/>
                    </div>
                </Section>
                <Section withoutHeader>
                    <div className="order-price-block">
                        <div className="result">
                            <p>{loc.OrderBlock_ResultTitle}</p>
                        </div>
                        <div className="price">
                            <p>{loc.OrderBlock_ResultPrice}</p>
                            <p>{this.state.price}</p>
                            {/* <InputField disabled centerText centerMode col={12} hideLabel label={loc.OrderScreen_TimeInWay} type="number" onChange={(e) => {this.changeField(e)}} value={this.state.price} name='price'/> */}
                        </div>
                    </div>
                </Section>
            </>
        )
    };

    getStepTabs = (step: number) => {
        const {locale} = this.props;
        const {currentLocaleJSON: loc} = locale;
        return (
            <div className={`steps-tab`}>
                <div
                    className={`steps-tab-item ${step === 1 ? ' active' : ''}`}>{'1. ' + loc.OrderCreate_Step1Title}</div>
                <div
                    className={`steps-tab-item ${step === 2 ? ' active' : ''}`}>{'2. ' + loc.OrderCreate_Step2Title}</div>
                <div
                    className={`steps-tab-item ${step === 3 ? ' active' : ''}`}>{'3. ' + loc.OrderCreate_Step3Title}</div>
            </div>
        )
    };

    returnStep = (number: number) => {
        switch (number) {
            case 1:
                return this.step1Content();

            case 2:
                return this.step2Content();

            case 3:
                return this.step3Content();

            default:
                return null;
        }
    };

    searchRoute = () => {
        
        let searchParam: ISearchRoute = {
            cities: [],
            uploadPointCity: ''
        };

        const {loadOutPoints, loadInPoint} = this.state;
        Object.keys(loadOutPoints).forEach((el, num) => {
            let {city} = loadOutPoints[el];
            searchParam.cities.push(city.name);
            // searchParam[`downloadPoints.${num}.region|full`] = city.region;
        });
        const {city} = loadInPoint;
        searchParam.uploadPointCity = city.name;
        // searchParam[`uploadPoint.city|full`] = city.name;
        // searchParam[`uploadPoint.region|full`] = city.region;
        // console.log('route SEARCHING');

        this.props.getRouteByCity({ ...searchParam })
            .then(data => {
                if(data.type === 'GET_ROUTE_BY_CITY_SUCCESS'){
                    // console.log('route SEARCHING', data.payload.record);
                    const { id, name, sap } = data.payload.record
                    if(id !== this.state.route.id && sap !== this.state.route.sap && name !== this.state.route.name ){
                        this.setState((prevState: IState) => ({
                            route: {
                                id, name, sap
                            }
                        }));
                    }
                    
                }
                
            })

    };

    

    searchTarrif = () => {
        let {company, comeBack, withSecurity, tonnage, ...restData} = this.state.tariff;
        this.props.getTariffList({
            page: 0, size: 1000, q: {
                'company.name|full': company.name,
                'tonnage|full': tonnage,
                'comeBack|full': comeBack,
                'withSecurity|full': withSecurity
            }
        })
            .then(data => {
                // console.log(data)
                if (data.type === 'GET_TARIFF_LIST_SUCCESS') {
                    if (data.payload.list && data.payload.list.length) {
                        let tariff = {...data.payload.list[0]};
                        this.props.openSuccessDialog({});
                        this.setState((prevState: IState) => ({
                            tariff: {
                                company: {
                                    name: tariff.company.name,
                                    id: tariff.company.id
                                },
                                companyName: `${tariff.company.name}`,
                                comeBack: tariff.comeBack,
                                tariffName: '',
                                distance: restData.distance,
                                shipmentNumber: restData.shipmentNumber,
                                time: restData.time,
                                tonnage: tariff.tonnage,
                                value: tariff.value,
                                withSecurity: tariff.withSecurity,
                                id: tariff.id,
                            },
                            additional: {
                                ...prevState.additional,
                                displayDoneDialog: true
                            }

                        }));
                        
                    } else {
                        this.setState((prevState: IState) => {

                            return {
                                additional: {
                                    ...prevState.additional,
                                    displayWarningDialog: true
                                }
                            }
                        });
                    }

                }
            });
    };

    autocompleteCar = (row: IVehicleModel) => {
        const { bodyType, comment, loadCapacity, mark, model, registrationNumber, type } = row;
        this.setState((prevState: IState) => ({
            transport: {
                ...prevState.transport,
                car: {
                    ...prevState.transport.car,
                    bodyType, comment, loadCapacity, mark, model, registrationNumber, type
                }
            }
        }));
        //{bodyType, comment, loadCapacity, mark, model, registrationNumber, type}
    }

    autocompleteTrailer = (row: IVehicleModel) => {
        const { comment, loadCapacity, mark, registrationNumber, type } = row;
        this.setState((prevState: IState) => ({
            transport: {
                ...prevState.transport,
                trailer: {
                    ...prevState.transport.trailer,
                    comment, loadCapacity, mark, registrationNumber, type
                }
            }
        }));
    }

    autocompleteDriver = (row: IStaffModel) => {
        const { drivingLicence, fio, passport, phone } = row;
        this.setState((prevState: IState) => ({
            transport: {
                ...prevState.transport,
                driver: {
                    ...prevState.transport.driver,
                    drivingLicence, fio, passport, phone
                }
            }
        }));
    }

    autocompleteSecurity = (row: IStaffModel) => {
        const { fio, passport, phone, securityCompanyName } = row;
        this.setState((prevState: IState) => ({
            transport: {
                ...prevState.transport,
                security: {
                    ...prevState.transport.security,
                    fio, passport, phone, securityCompanyName
                }
            }
        }));
    }

    writeComment = () => {
        this.props.createOrderComment({id: this.props.match.params.id? this.props.match.params.id: '', comment: this.state.comment})
            .then(data => {
                if(data.type === 'CREATE_ORDER_COMMENT_SUCCESS'){
                    this.props.getOrderById(this.props.match.params.id? this.props.match.params.id: '')
                        .then((data) => {
                            if(data.type === 'GET_ORDER_BY_ID_SUCCESS'){
                                this.beforeUpdate = {...this.adaptToState(data.payload.record)};
                                this.setState({...this.adaptToState(data.payload.record), comment: ''});
                            }
                            
                        });
                }
            });
    };

    agreeFormByManager = () => {
        this.props.changeOrderStatusByManager({status: "AGREED", id: this.state.id? this.state.id.toString(): ''})
            .then(data => {
                if (data.type === 'CHANGE_ORDER_STATUS_BY_MANAGER_SUCCESS') {
                    this.props.openSuccessDialog({});
                    this.props.history.push('/dashboard/order/active');
                }
            });
    };

    rejectFormByManager = () => {
        this.props.changeOrderStatusByManager({status: "REJECTED", id: this.state.id? this.state.id.toString(): ''})
            .then(rejectChaengeData => {
                if (rejectChaengeData.type === 'CHANGE_ORDER_STATUS_BY_MANAGER_SUCCESS') {
                    this.props.openSuccessDialog({});
                    this.props.history.push('/dashboard/order/active');
                }
            });
    };

    sendOrderOnWay = () => {
        this.props.changeOrderStatusByLogist({status: "ON_ROUTE", id: this.state.id? this.state.id.toString(): ''})
            .then(onRouteChangeData => {
                if (onRouteChangeData.type === 'CHANGE_ORDER_STATUS_BY_LOGIST_SUCCESS') {
                    this.props.openSuccessDialog({});
                    this.props.history.push('/dashboard/order/active');
                }
            });
    }

    archiveFormByLogist = () => {
        this.props.changeOrderStatusByLogist({status: "ARCHIEVED", id: this.props.match.params.id? this.props.match.params.id.toString(): ''})
            .then(arhLogistData => {
                if (arhLogistData.type === 'CHANGE_ORDER_STATUS_BY_LOGIST_SUCCESS') {
                    this.props.openSuccessDialog({});
                    this.props.history.push('/dashboard/order/active');
                }
            });
    }

    completeOrder = () => {
        this.props.changeOrderStatusByLogist({status: "COMPLETED", id: this.props.match.params.id? this.props.match.params.id.toString(): ''})
            .then(data => {
                if (data.type === 'CHANGE_ORDER_STATUS_BY_LOGIST_SUCCESS') {

                    this.props.history.push('/dashboard/order/active');
                }
            });
    }

    validateEdit = () => {
        const { additional, ...rest } = this.state
        // console.log('`````````VALID EDIT' ,this.validFirstStep() === false || this.validSecondStep() === false || equal(this.state, this.beforeUpdate) === true, this.validFirstStep(),  this.validSecondStep(), equal(this.state, this.beforeUpdate), rest, this.beforeUpdate)
        let disabled = true;
        if(this.props.isLogist){
            disabled = (this.validFirstStep() === false || this.validSecondStep() === false || equal(rest, this.beforeUpdate) === true);
        }
        if(this.props.isManager){
            disabled =  equal(rest.transport, this.beforeTransport) || 
                rest.transport.car.registrationNumber === '' ||
                rest.transport.trailer.registrationNumber === '' ||
                rest.transport.driver.fio === '' ||
                rest.transport.security.fio === '';
        }
        return disabled;
    }

    editOrderByManager = () => {
        let id = this.props.match.params.id?  parseInt(this.props.match.params.id) : null;
        this.props.editOrderByManager({ transport: {...this.state.transport}, id })
            .then(editManagerData => {
                if (editManagerData.type === 'EDIT_ORDER_BY_MANAGER_SUCCESS') {
                    this.props.openSuccessDialog({});
                    this.props.history.push('/dashboard/order/active');
                }
            });
    }

    editForm = () => {
        const {locale, isLogist, isManager} = this.props;
        const {currentLocaleJSON: loc} = locale;
        const pickup = this.props.match.params.type === 'pickup';

        return (
            <>
                <Section title={`1.1 ${loc.OrderBlock_Step11Title}`}>
                    <div className="fields-section">


                        <InputField centerText centerMode col={pickup ? 4 : 2.5} required={true} disabled={!isLogist || this.props.view} padding={true}
                                    placeholder={loc.OrderBlock_InputCity} label={loc.OrderBlock_InputCity} type="city"
                                    onSelect={(row) => {
                                        this.changeCityInSection(row, 'loadInPoint')
                                    }} onChange={(e) => {
                            this.changeFieldInSection(e, 'loadInPoint')
                        }} value={this.state.loadInPoint.cityName} name='cityName'/>
                        <InputField centerText centerMode col={pickup ? 4 : 2.5} disabled={!isLogist || this.props.view} padding={true}
                                    placeholder={loc.OrderBlock_Sender} label={loc.OrderBlock_Sender} autoSelect
                                    type="company" onSelect={(row) => {
                            this.changeCompanyInSection(row, 'loadInPoint')
                        }} onChange={(e) => {
                            this.changeFieldInSection(e, 'loadInPoint')
                        }} value={this.state.loadInPoint.companyName} name='companyName'/>
                        <InputField centerText centerMode col={pickup ? 4 : 2.5}
                                    additionalFilters={{'company.name|full': this.state.loadInPoint.company.name}}
                                    disabled={!isLogist || this.props.view} padding={true} placeholder={loc.OrderBlock_InputWarehouse}
                                    label={loc.OrderBlock_InputWarehouse} autoSelect type="warehouse"
                                    onSelect={(row) => {
                                        this.changeWarehouseInSection(row, 'loadInPoint')
                                    }} onChange={(e) => {
                            this.changeFieldInSection(e, 'loadInPoint')
                        }} value={this.state.loadInPoint.warehouseName} name='warehouseName'/>
                        {
                            pickup ?
                                null
                                :
                                <>
                                    <InputField disabled={!isLogist || this.props.view} centerText centerMode col={1.125} isValidDate={() => true}
                                                label={loc.OrderBlock_InputDate} type="date"
                                                changeDate={(name, value) => {
                                                    this.changeDateInSection(name, value, 'loadInPoint')
                                                }} value={formatDate(this.state.loadInPoint.uploadDate)}
                                                name='uploadDate'/>
                                    <InputField disabled={!isLogist || this.props.view} centerText centerMode col={1.125} isValidDate={() => true}
                                                label={loc.OrderBlock_InputTime} type="time"
                                                changeDate={(name, value) => {
                                                    this.changeTimeInSection(name, value, 'loadInPoint')
                                                }} value={formatTime(this.state.loadInPoint.uploadDate)}
                                                name='uploadDate'/>
                                    <InputField disabled={!isLogist || this.props.view} centerText centerMode col={1.125} isValidDate={() => true}
                                                label={loc.OrderBlock_InputDate} type="date"
                                                changeDate={(name, value) => {
                                                    this.changeDateInSection(name, value, 'loadInPoint')
                                                }} value={formatDate(this.state.loadInPoint.departureDate)}
                                                name='departureDate'/>
                                    <InputField disabled={!isLogist || this.props.view} centerText centerMode col={1.125} isValidDate={() => true}
                                                label={loc.OrderBlock_InputTime} type="time"
                                                changeDate={(name, value) => {
                                                    this.changeTimeInSection(name, value, 'loadInPoint')
                                                }} value={formatTime(this.state.loadInPoint.departureDate)}
                                                name='departureDate'/>
                                </>
                        }


                    </div>
                    <div className="section-header">
                        <p>{`1.2 ${loc.OrderBlock_Step12Title}`}</p>
                    </div>
                    {
                        Object.keys(this.state.loadOutPoints).map((ip, ipNum) => {
                            let ipData = this.state.loadOutPoints[ip];
                            return <div key={`${ip}_${ipNum}`} className="fields-section top-sparator">
                                <InputField centerText centerMode col={pickup ? 3.7 : 2.22} required={true} disabled={!isLogist || this.props.view}
                                            padding={true} placeholder={loc.OrderBlock_InputCity}
                                            label={loc.OrderBlock_InputCity} type="city" onSelect={(row) => {
                                    this.changeCityInArray(row, 'loadOutPoints', ip)
                                }} onChange={(e) => {
                                    this.changeFieldInArray(e, 'loadOutPoints', ip)
                                }} value={this.state.loadOutPoints[ip].cityName} name='cityName'/>
                                <InputField centerText centerMode col={pickup ? 3.7 : 2.22} disabled={!isLogist || this.props.view} padding={true}
                                            placeholder={loc.OrderBlock_Addressant} label={loc.OrderBlock_Addressant}
                                            autoSelect type="company" onSelect={(row) => {
                                    this.changeCompanyInArray(row, 'loadOutPoints', ip)
                                }} onChange={(e) => {
                                    this.changeFieldInArray(e, 'loadOutPoints', ip)
                                }} value={this.state.loadOutPoints[ip].companyName} name='companyName'/>
                                <InputField centerText centerMode col={pickup ? 3.7 : 2.22}
                                            additionalFilters={{'company.name|full': this.state.loadOutPoints[ip].company.name}}
                                            disabled={!isLogist || this.props.view} padding={true} placeholder={loc.OrderBlock_OutputWarehouse}
                                            label={loc.OrderBlock_OutputWarehouse} autoSelect type="warehouse"
                                            onSelect={(row) => {
                                                this.changeWarehouseInArray(row, 'loadOutPoints', ip)
                                            }} onChange={(e) => {
                                    this.changeFieldInArray(e, 'loadOutPoints', ip)
                                }} value={this.state.loadOutPoints[ip].warehouseName} name='warehouseName'/>
                                {/* //'validSecondDate' */}
                                {
                                    pickup ?
                                        null
                                        :
                                        <>
                                            <InputField disabled={!isLogist || this.props.view} centerText centerMode col={2.22}
                                                        isValidDate={() => true} label={loc.OrderBlock_Step2_OutputDate}
                                                        type="date" changeDate={(name, value) => {
                                                this.changeDateInArray(name, value, 'loadOutPoints', ip)
                                            }} value={formatDate(this.state.loadOutPoints[ip].downloadDate)}
                                                        name='downloadDate'/>
                                            <InputField disabled={!isLogist || this.props.view} centerText centerMode col={2.22}
                                                        isValidDate={() => true} label={loc.OrderBlock_Step2_OutputTime}
                                                        type="time" changeDate={(name, value) => {
                                                this.changeTimeInArray(name, value, 'loadOutPoints', ip)
                                            }} value={formatTime(this.state.loadOutPoints[ip].downloadDate)}
                                                        name='downloadDate'/>
                                        </>
                                }
                                <InputField centerText withoutLabelBorder centerMode col={2.22} disabled={!isLogist || this.props.view} padding={true} placeholder={loc.OrderBlock_STO}  label={loc.OrderBlock_STO} type="number" onChange={(e) => {this.changeFieldInArray(e, 'loadOutPoints', ip)}} value={this.state.loadOutPoints[ip].sto} name='sto'/>
                            <InputField centerText withoutLabelBorder centerMode col={2.22} disabled={!isLogist || this.props.view} padding={true} placeholder={loc.OrderBlock_ТТН}  label={loc.OrderBlock_ТТН} type="number" onChange={(e) => {this.changeFieldInArray(e, 'loadOutPoints', ip)}} value={this.state.loadOutPoints[ip].ttn} name='ttn'/>
                            <InputField centerText withoutLabelBorder centerMode col={2.22} disabled={!isLogist || this.props.view} padding={true} placeholder={loc.OrderBlock_SigBoxes}  label={loc.OrderBlock_SigBoxes} type="number" onChange={(e) => {this.changeFieldInArray(e, 'loadOutPoints', ip)}} value={this.state.loadOutPoints[ip].sigBoxes} name='sigBoxes'/>
                            <InputField disabled={!isLogist || this.props.view} centerText withoutLabelBorder centerMode col={2.22} padding={true} placeholder={loc.OrderBlock_RRPBoxes}  label={loc.OrderBlock_RRPBoxes} type="number" onChange={(e) => {this.changeFieldInArray(e, 'loadOutPoints', ip)}} value={this.state.loadOutPoints[ip].rrpBoxes} name='rrpBoxes'/>
                            <InputField disabled centerText withoutLabelBorder centerMode col={2.22} padding={true} placeholder={loc.OrderBlock_AllBoxes}  label={loc.OrderBlock_AllBoxes} type="number" onChange={(e) => {}} value={this.calcSum(this.state.loadOutPoints[ip].sigBoxes, this.state.loadOutPoints[ip].rrpBoxes)} name='sumBoxes'/>
                            {
                                this.props.view ?
                                    null
                                    :
                                    <div onClick={Object.keys(this.state.loadOutPoints).length <= 1? () => {} : () => {this.deleteLoadOutPointInArray(ip)} } className={`delete-row-btn${Object.keys(this.state.loadOutPoints).length <= 1? ' disable' : ''}`}>
                                        <Icon>close</Icon>
                                    </div>
                            }
                            </div>
                        })
                    }
                </Section>

                <Section title={`2. ${loc.OrderScreen_Other}`}>
                    <div className="fields-section line">


                        <InputField centerText disabled={!isLogist || this.props.view} centerMode col={2.5} padding={true}
                                    placeholder={loc.OrderScreen_Transferer} label={loc.OrderScreen_Transferer}
                                    autoSelect type="company" onSelect={(row) => {
                            this.changeCompanyInSection(row, 'tariff')
                        }} onChange={(e) => {
                            this.changeFieldInSection(e, 'tariff')
                        }} value={this.state.tariff.companyName} name='companyName'/>
                        <InputField centerText disabled centerMode col={1} padding={true}
                                    placeholder={loc.OrderScreen_Tariff} label={loc.OrderScreen_Tariff} type="number"
                                    onChange={(e) => {
                                        this.changeFieldInSection(e, 'tariff')
                                    }} value={this.state.tariff.value} name='value'/>
                        <InputField centerMode col={2} required={true} disabled={!isLogist || this.props.view} padding={true}
                                    placeholder={loc.OrderScreen_Capacity} label={loc.OrderScreen_Capacity}
                                    type="select" onChange={(e) => {
                            this.changeFieldInSection(e, 'tariff')
                        }} name="tonnage" value={this.state.tariff.tonnage}
                                    options={[
                                        {
                                            value: 10,
                                            label: '10',
                                        },
                                        {
                                            value: 15,
                                            label: '15',
                                        },
                                        {
                                            value: 20,
                                            label: '20',
                                        },
                                    ]}/>
                        <InputField centerText centerMode col={1} disabled={!isLogist || this.props.view} padding={true}
                                    placeholder={loc.OrderScreen_TimeInWay} label={loc.OrderScreen_TimeInWay}
                                    type="number" onChange={(e) => {
                            this.changeFieldInSection(e, 'tariff')
                        }} value={this.state.tariff.time} name='time'/>
                        <InputField centerText centerMode col={1} disabled={!isLogist || this.props.view} padding={true}
                                    placeholder={loc.OrderScreen_DistanceInWay} label={loc.OrderScreen_DistanceInWay}
                                    type="number" onChange={(e) => {
                            this.changeFieldInSection(e, 'tariff')
                        }} value={this.state.tariff.distance} name='distance'/>
                        <InputField centerText centerMode col={1.5} disabled={!isLogist || this.props.view} padding={true}
                                    placeholder={loc.OrderScreen_CountOfBoxes} label={loc.OrderScreen_CountOfBoxes}
                                    type="number" onChange={(e) => {
                        }} value={this.fullBoxesSum()} name='fullBoxesSum'/>
                        {/* <InputField centerText centerMode col={1} disabled={!isLogist} padding={true}
                                    placeholder={loc.OrderScreen_ShipmentNum} label={loc.OrderScreen_ShipmentNum}
                                    type="text" onChange={(e) => {
                            this.changeFieldInSection(e, 'tariff')
                        }} value={this.state.tariff.shipmentNumber} name='shipmentNumber'/> */}
                        <InputField centerText centerMode col={1} disabled={!isLogist || this.props.view} padding={true}
                                    placeholder={loc.OrderScreen_BothSide} label={loc.OrderScreen_BothSide}
                                    type="checkbox" onChangeCheckbox={(name, value) => {
                            this.changeCheckboxInSection({target: {name, value}}, 'tariff')
                        }} value={this.state.tariff.comeBack !== null ? this.state.tariff.comeBack.toString() : 'false'}
                                    name='comeBack'/>
                        <InputField centerText centerMode col={1} disabled={!isLogist || this.props.view} padding={true}
                                    placeholder={loc.OrderScreen_Security} label={loc.OrderScreen_Security}
                                    type="checkbox" onChangeCheckbox={(name, value) => {
                            this.changeCheckboxInSection({target: {name, value}}, 'tariff')
                        }}
                                    value={this.state.tariff.withSecurity !== null ? this.state.tariff.withSecurity.toString() : 'false'}
                                    name='withSecurity'/>

                    </div>

                </Section>
                <Section title={`3. ${loc.OrderBlock_Step22Title}`}>
                    <div className='routes-list'>
                        
                        {
                            this.state.route.name && this.state.route.name !== ''?
                                <p>{this.state.route.name}</p>
                            :
                            <>
                                
                                <p>{`${this.state.loadInPoint.city.name}`}</p>
                                {
                                Object.keys(this.state.loadOutPoints).map((key, num) =>
                                    <React.Fragment key={`${this.state.loadOutPoints[key].city.name}_${num}`}>
                                        <p> - </p>
                                        <p>{`${this.state.loadOutPoints[key].city.name}`}</p>
                                    </React.Fragment>
                                )}
                            </>
                        }
                    </div>
                    {
                        this.state.route.sap && this.state.route.sap !== ''?
                            <div className="route-list-btn-wrapper">
                                <InputField centerText centerMode hideLabel col={5} disabled padding={true}
                                            placeholder={loc.OrderScreen_ShipmentNum} label={loc.OrderScreen_ShipmentNum}
                                            type="text" onChange={(e) => {
                                    this.changeFieldInSection(e, 'route')
                                }} value={this.state.route.sap} name='sap'/>
                            </div>
                        :
                            null
                    }

                    
                </Section>

                <Section withoutHeader>
                    <div className="order-price-block">
                        
                        {
                            this.state.status !== 'FORMED'?
                                
                                <div className="half-block">
                                    <div className="section-header">
                                        <p>{`4. ${loc.OrderBlock_Step12Title}`}</p>
                                    </div>
                                    <div className="fields-section">
                                        <InputField col={4} centerText placeholder={loc.OrderBlock_ShipmentNumber} disabled={!isLogist || this.props.view} required={true} padding={true} hideLabel={true} label={''} type="text" onChange={(e) => { this.changeFieldInSection(e, 'tariff')}} value={this.state.tariff.shipmentNumber} name='shipmentNumber'/>

                                        <InputField col={4} centerText placeholder={loc.OrderBlock_ActNumber} disabled={!isLogist || this.props.view} required={true} padding={true} hideLabel={true} label={''} type="number" onChange={this.handleChangeField} value={this.state.transport.car.model} name='model'/>

                                        <InputField col={4} centerText disabled={true} required={true} padding={true} hideLabel={true} label={''} type="date" onChange={this.handleChangeField} value={formatDate(this.state.createdDate)} name='createdDate'/>
                                    </div>
                                </div>
                            :
                                <div className='result'>
                                    <p>{loc.OrderBlock_ResultTitle}</p>
                                </div>
                        }
                        
                        
                        <div className="price">
                            <p>{loc.OrderBlock_ResultPrice}</p>
                            {/* <p>{this.state.price}</p> */}
                            <InputField disabled={!isLogist || this.props.view} centerText centerMode col={12} hideLabel label={loc.OrderScreen_TimeInWay} type="number" onChange={(e) => {this.changeField(e)}} value={this.state.price} name='price'/>
                        </div>
                    </div>
                </Section>
  


                {
                    this.state.status === 'FORMED' || (this.state.status === 'UNDER_CONSIDERATION' && isLogist)?
                        null
                    :
                    <>
                        <Section title={`5. ${loc.OrderBlock_CarTitle}`} secondTitle={`6. ${loc.OrderBlock_TrailerTitle}`}>
                                <div className="half-block">
                                    <FormBlock compactPadding colLeft={6} colRight={6} title={loc.VehicleBlock_CarTab_GovementNumberField}>
                                        <InputField centerText additionalFilters={{'company.id|full': this.props.user.userInfo.company.id, 'vehicleType|full': 'CAR'}} disabled={!isManager || this.props.view} required={true} padding={true} hideLabel={true} label={''} onSelect={this.autocompleteCar} type="vehicle" autoSelect  onChange={(e) => {this.changeFieldInArray(e, 'transport', 'car') }} value={this.state.transport.car.registrationNumber} name='registrationNumber'/>
                                    </FormBlock>

                                    <FormBlock compactPadding colLeft={6} colRight={6} title={loc.VehicleBlock_CarTab_ModelField}>
                                        <InputField centerText disabled={true} required={true} padding={true} hideLabel={true} label={''} type="text" onChange={this.handleChangeField} value={this.state.transport.car.model} name='model'/>
                                    </FormBlock>

                                    <FormBlock compactPadding colLeft={6} colRight={6} title={loc.VehicleBlock_CarTab_MarkField}>
                                        <InputField centerText disabled={true} required={true} padding={true} hideLabel={true} label={''} type="text" onChange={this.handleChangeField} value={this.state.transport.car.mark} name='mark'/>
                                    </FormBlock>

                                    <FormBlock compactPadding colLeft={6} colRight={6} title={loc.VehicleBlock_CarTab_TypeField}>
                                        <InputField centerText disabled={true} required={true} padding={true} hideLabel={true} label={''} type="text" onChange={this.handleChangeField} value={this.state.transport.car.type} name='type'/>
                                    </FormBlock>

                                    <FormBlock compactPadding colLeft={6} colRight={6} title={loc.VehicleBlock_CarTab_TrailerTypeField}>
                                        <InputField centerText disabled={true} required={true} padding={true} hideLabel={true} label={''} type="text" onChange={this.handleChangeField} value={this.state.transport.car.bodyType} name='bodyType'/>
                                    </FormBlock>

                                    <FormBlock compactPadding colLeft={6} colRight={6} title={loc.VehicleBlock_CarTab_CapacityField}>
                                        <InputField centerText disabled={true} required={true} padding={true} hideLabel={true} label={''} type="text" onChange={this.handleChangeField} value={this.state.transport.car.loadCapacity} name='loadCapacity'/>
                                    </FormBlock>
                                    <FormBlock compactPadding colLeft={6} colRight={6} title={loc.VehicleBlock_CarTab_CommentField}>
                                        <InputField withoutLabelBorder centerText disabled={true} required={true} padding={true} hideLabel={true} label={''} type="multiline" onChange={this.handleChangeField} value={this.state.transport.car.comment} name='comment'/>
                                    </FormBlock>
                                </div>
                                <div className="half-block">
                                    <FormBlock compactPadding colLeft={6} colRight={6} title={loc.VehicleBlock_TrailerTab_GovementNumberField}>
                                        <InputField centerText additionalFilters={{'company.id|full': this.props.user.userInfo.company.id, 'vehicleType|full': 'TRAILER'}} disabled={!isManager || this.props.view} required={true} padding={true} hideLabel={true} label={''} onSelect={this.autocompleteTrailer} type="vehicle" autoSelect  onChange={(e) => {this.changeFieldInArray(e, 'transport', 'trailer') }} value={this.state.transport.trailer.registrationNumber} name='registrationNumber'/>
                                    </FormBlock>

                                    <FormBlock compactPadding colLeft={6} colRight={6} title={loc.VehicleBlock_TrailerTab_MarkField}>
                                        <InputField centerText disabled={true} required={true} padding={true} hideLabel={true} label={''} type="text" onChange={this.handleChangeField} value={this.state.transport.trailer.mark} name='mark'/>
                                    </FormBlock>

                                    <FormBlock compactPadding colLeft={6} colRight={6} title={loc.VehicleBlock_TrailerTab_TrailerTypeField}>
                                        <InputField centerText disabled={true} required={true} padding={true} hideLabel={true} label={''} type="text" onChange={this.handleChangeField} value={this.state.transport.trailer.type} name='type'/>
                                    </FormBlock>

                                    <FormBlock compactPadding colLeft={6} colRight={6} title={loc.VehicleBlock_TrailerTab_CapacityField}>
                                        <InputField centerText disabled={true} required={true} padding={true} hideLabel={true} label={''} type="text" onChange={this.handleChangeField} value={this.state.transport.trailer.loadCapacity} name='loadCapacity'/>
                                    </FormBlock>
                                    <FormBlock compactPadding colLeft={6} colRight={6} title={loc.VehicleBlock_TrailerTab_CommentField}>
                                        <InputField centerText disabled={true} required={true} padding={true} hideLabel={true} label={''} type="multiline" onChange={this.handleChangeField} value={this.state.transport.trailer.comment} name='comment'/>
                                    </FormBlock>
                                </div>
                        </Section>

                        <Section title={`7. ${loc.OrderBlock_DriverTitle}`} secondTitle={`8. ${loc.OrderBlock_SecurityTitle}`}>
                                <div className="half-block">
                                    <FormBlock compactPadding colLeft={6} colRight={6} title={loc.StaffBlock_DriverTab_FullnameField}>
                                        <InputField centerText additionalFilters={{'company.id|full': this.props.user.userInfo.company.id, 'staffType|full': 'DRIVER'}} disabled={!isManager || this.props.view} required={true} padding={true} hideLabel={true} label={''} onSelect={this.autocompleteDriver} type="staff" autoSelect  onChange={(e) => {this.changeFieldInArray(e, 'transport', 'driver') }} value={this.state.transport.driver.fio} name='fio'/>
                                    </FormBlock>

                                    <FormBlock compactPadding colLeft={6} colRight={6} title={loc.StaffBlock_DriverTab_PassportDataField}>
                                        <InputField centerText disabled={true} required={true} padding={true} hideLabel={true} label={''} type="text" onChange={this.handleChangeField} value={this.state.transport.driver.passport} name='passport'/>
                                    </FormBlock>

                                    <FormBlock compactPadding colLeft={6} colRight={6} title={loc.StaffBlock_DriverTab_PhoneField}>
                                        <InputField centerText disabled={true} required={true} padding={true} hideLabel={true} label={''} type="text" onChange={this.handleChangeField} value={this.state.transport.driver.phone} name='phone'/>
                                    </FormBlock>

                                    <FormBlock compactPadding colLeft={6} colRight={6} title={loc.StaffBlock_DriverTab_DriversLicenseField}>
                                        <InputField centerText disabled={true} required={true} padding={true} hideLabel={true} label={''} type="text" onChange={this.handleChangeField} value={this.state.transport.driver.drivingLicence} name='drivingLicence'/>
                                    </FormBlock>

                                </div>
                                <div className="half-block">
                                    <FormBlock compactPadding colLeft={6} colRight={6} title={loc.StaffBlock_SecurityTab_FullnameField}>
                                        <InputField centerText additionalFilters={{'company.id|full': this.props.user.userInfo.company.id, 'staffType|full': 'SECURITY'}} disabled={!isManager || this.props.view} required={true} padding={true} hideLabel={true} label={''} onSelect={this.autocompleteSecurity} type="staff" autoSelect  onChange={(e) => {this.changeFieldInArray(e, 'transport', 'security') }} value={this.state.transport.security.fio} name='fio'/>
                                    </FormBlock>

                                    <FormBlock compactPadding colLeft={6} colRight={6} title={loc.StaffBlock_SecurityTab_PassportDataField}>
                                        <InputField centerText disabled={true} required={true} padding={true} hideLabel={true} label={''} type="text" onChange={this.handleChangeField} value={this.state.transport.security.passport} name='passport'/>
                                    </FormBlock>

                                    <FormBlock compactPadding colLeft={6} colRight={6} title={loc.StaffBlock_SecurityTab_PhoneField}>
                                        <InputField centerText disabled={true} required={true} padding={true} hideLabel={true} label={''} type="text" onChange={this.handleChangeField} value={this.state.transport.security.phone} name='phone'/>
                                    </FormBlock>

                                    <FormBlock compactPadding colLeft={6} colRight={6} title={loc.StaffBlock_SecurityTab_SecurityCompanyField}>
                                        <InputField centerText disabled={true} required={true} padding={true} hideLabel={true} label={''} type="text" onChange={this.handleChangeField} value={this.state.transport.security.securityCompanyName} name='securityCompanyName'/>
                                    </FormBlock>

                                    <FormBlock compactPadding colLeft={6} colRight={6} title={loc.StaffBlock_SecurityTab_ExtraField}>
                                        <InputField withoutLabelBorder centerText disabled={!isManager} required={true} padding={true} hideLabel={true} label={''} type="text" onChange={this.handleChangeField} value={this.state.transport.security.extra} name='extra'/>
                                    </FormBlock>
                                </div>
                        </Section>
                    </>
                }

                {
                    this.props.view?
                        null
                        :
                        <Section withoutHeader>
                            <div className="command-panel-btn">
                                <Button padding col={3} onClick={() => {this.props.history.push('/dashboard/order/active')}}>{loc.Button_Exit}</Button>
                                {isLogist?
                                    <Button padding color='red' col={3} onClick={this.archiveFormByLogist} >{loc.OrderBlock_ArchiveLogistBtn}</Button>
                                :
                                    null
                                }

                                <div className="spacer-3"></div>
                                
                                {this.state.status == "UNDER_CONSIDERATION" && isManager?
                                    <Button padding color='green' col={2} onClick={this.agreeFormByManager} >{loc.OrderBlock_AgreeManagerBtn}</Button>
                                :
                                    null
                                }
                                {this.state.status == "UNDER_CONSIDERATION" && isManager?
                                    <Button padding color='red' col={2} onClick={this.rejectFormByManager} >{loc.OrderBlock_RejectManagerBtn}</Button>
                                :
                                    null
                                }
                                {(this.state.status == "FORMED" && isLogist) || (this.state.status == "REJECTED" && isLogist)?
                                    <Button padding color='primary' col={3} onClick={this.sendOrderFromFormat} >{loc.OrderBlock_SendBtn}</Button>
                                :
                                    null
                                }

                                {(this.state.status == "AGREED" && isLogist)?
                                    <Button padding color='primary' col={3} onClick={this.sendOrderOnWay} >{loc.OrderBlock_OnWayBtn}</Button>
                                :
                                    null
                                }
                                {(this.state.status == "ON_ROUTE" && isLogist)?
                                    <Button padding color='green' col={3} onClick={this.completeOrder} >{loc.OrderBlock_CompleteLogistBtn}</Button>
                                :
                                    null
                                }
                                <Button padding disabled={this.validateEdit()} color='primary' col={3} onClick={isLogist? this.saveEditOrder : isManager? this.editOrderByManager: () => {}} >{loc.Button_Save}</Button>
                            </div>
                        </Section>
                }
                


                <Section centerTitle title={loc.OrderBlock_CommentsTitle}>
                    <div className={'wright-comment-block'}>
                        <Icon>{this.comment_icon}</Icon>
                        <div className={'comment-wright-field'}>
                        <InputField disabled={this.props.view} hideLabel col={12} padding={false} placeholder={loc.OderBlock_CommentSectionTitle}
                                    label={loc.OderBlock_CommentSectionTitle} type="multiline" onChange={(e) => {
                            this.changeField(e)
                        }} value={this.state.comment} name='comment'/>
                        </div>
                        <div className="btn-wright-wrapper">
                            <Button disabled={this.props.view} col={4} onClick={this.writeComment}>{loc.OrderBlock_SendBtn}</Button>
                        </div>
                        
                    </div>
                    {
                            Object.keys(this.state.events).length?
                                Object.keys(this.state.comments).map((elem, num) =>
                                    <div key={`${this.state.comments[elem].createdDate}_${num}`} className={`event-list without-lines`}>
                                        <div className={'time'}>
                                            <p>{formatDate(this.state.comments[elem].createdDate)}</p>
                                            <p>{formatTime(this.state.comments[elem].createdDate)}</p>
                                        </div>
                                        <div className={'status'}>
                                            <Icon>{this.comment_icon}</Icon>
                                            <p>{this.state.comments[elem].text}</p>
                                        </div>
                                        <p>{this.state.comments[elem].author}</p>
                                    </div>
                                )
                            :
                            <p className={'msg'}>{loc.OrderBlock_CommentsNull}</p>

                    }
                </Section>
                <Section centerTitle title={loc.OrderBlock_EventsTitle}>
                    {
                        Object.keys(this.state.events).length?
                            Object.keys(this.state.events).map((elem, num) =>
                                <div key={`${this.state.events[elem].createdDate}_${num}`}  className={`event-list`}>
                                    <div className={'time'}>
                                        <p>{formatDate(this.state.events[elem].createdDate)}</p>
                                        <p>{formatTime(this.state.events[elem].createdDate)}</p>
                                    </div>
                                    <div className={'status'}>
                                        <Icon>{this.icons[this.state.events[elem].status]}</Icon>
                                        <p>{loc[`Order_OrderStatus_${this.state.events[elem].status}`]}</p>
                                    </div>
                                    <p>{this.state.events[elem].author}</p>
                                </div>
                            )
                            :
                            <p className={'msg'}>{loc.OrderBlock_CommentsNull}</p>
                    }
                </Section>

            </>
        )
    }

    formatOrder = () => {

        this.props.createOrder({...this.adaptToSend(this.state, false)})
            .then(data => {
                if (data.type === 'CREATE_ORDER_SUCCESS') {
                    this.props.openSuccessDialog({});
                    this.props.history.push('/dashboard/order/active')
                }
            });
    };

    saveEditOrder = () => {
        let id = this.props.match.params.id?  parseInt(this.props.match.params.id) : null;
        this.props.editOrder({...this.adaptToSend(this.state, true), id })
            .then(editData => {
                if (editData.type === 'EDIT_ORDER_SUCCESS') {
                    this.props.openSuccessDialog({});
                    this.props.history.push('/dashboard/order/active');
                }
            });
    };

    sendOrder = () => {
        // let id = this.props.match.params.id?  this.props.match.params.id : "";
        this.props.createOrder({...this.adaptToSend(this.state, false)})
            .then(sendData => {
                if (sendData.type === 'CREATE_ORDER_SUCCESS') {
                    // let id = "dfsdf";
                    this.props.changeOrderStatusByLogist({status: "UNDER_CONSIDERATION", id: sendData.id.toString()})
                        .then(ucSendData => {
                            if (ucSendData.type === 'CHANGE_ORDER_STATUS_BY_LOGIST_SUCCESS') {
                                this.props.openSuccessDialog({});
                                this.props.history.push('/dashboard/order/active');
                            }
                        });
                }
            });
    };


    sendOrderFromFormat = () => {
        // let id = this.props.match.params.id?  this.props.match.params.id : "";
       
        this.props.changeOrderStatusByLogist({status: "UNDER_CONSIDERATION", id: this.props.match.params.id? this.props.match.params.id.toString(): ''})
            .then(ucChangeData => {
                if (ucChangeData.type === 'CHANGE_ORDER_STATUS_BY_LOGIST_SUCCESS') {
                    this.props.openSuccessDialog({});
                    this.props.history.push('/dashboard/order/active');
                }
            });

    };

    toArchive = () => {
        let id = this.props.match.params.id?  this.props.match.params.id : "";
        this.props.changeOrderStatusByLogist({status: "ARCHIEVED", id})
            .then(arhChangeData => {
                if (arhChangeData.type === 'CHANGE_ORDER_STATUS_BY_LOGIST_SUCCESS') {
                    this.props.openSuccessDialog({});
                    this.props.history.push('/dashboard/order/active');
                }
            });
    }

    render() {

        const {locale} = this.props;
        const {currentLocaleJSON: loc} = locale;

        return (
            <div className="page-template">
                <PageHeader 
                    pageIcon={"assignment"} 
                    pageTitle={this.getTitle()} 
                    btnVisible={this.props.isLogist && this.props.edit} 
                    titleBtnText={loc.OrderBlock_CancelOrderBtn} 
                    titleBtnIcon="archive" 
                    titleBtnAction={this.toArchive}
                    rightContent={<div className={`status-checker ${this.state.status}`}>{ loc[`Order_OrderStatus_${this.state.status}`]}</div>}
                    />

                {this.props.create ?
                    <>
                        {this.setTitleInfo()}
                        {this.props.match.params.type !== 'pickup' ? this.getStepTabs(this.state.additional.step) : null}
                        {this.returnStep(this.state.additional.step)}

                        <div className="routes-btns-block">
                            {
                                this.props.create && this.state.additional.step === 1 ?
                                    <Button onClick={this.handleCancel} padding col={3}>
                                        {loc.Button_Cancel}
                                    </Button>
                                    :
                                    <Button onClick={() => {
                                        this.setStep(this.state.additional.step - 1)
                                    }} padding col={3}>
                                        {"< " + loc.OrderBlock_PrevBtn}
                                    </Button>
                            }
                            {
                                this.props.edit ?
                                    <Button
                                        col={3}
                                        disabled={
                                            this.editIsValid() || !this.additionIsValid()
                                        }
                                        onClick={this.handleEditRecord}
                                        padding
                                        color="primary"
                                    >
                                        {loc.Button_Save}
                                    </Button>
                                    :
                                    null
                            }
                            {
                                this.props.create && this.state.additional.step === 1 && this.props.match.params.type !== 'pickup' ?
                                    <Button
                                        disabled={!this.validFirstStep()}
                                        onClick={() => {
                                            this.setStep(2)
                                        }}
                                        padding
                                        col={3}
                                        color="grey"
                                    >
                                        {loc.OrderBlock_NextStep} >
                                    </Button>
                                    :
                                    null
                            }
                            {
                                this.props.create && this.state.additional.step === 2 && this.props.match.params.type !== 'pickup' ?
                                    <Button
                                        disabled={!this.validSecondStep()}
                                        onClick={() => {
                                            this.setStep(3)
                                        }}
                                        padding
                                        col={3}
                                        color="grey"
                                    >
                                        {loc.OrderBlock_NextStep} >
                                    </Button>
                                    :
                                    null
                            }

                            {
                                (this.props.create && this.state.additional.step === 3) || this.props.match.params.type === 'pickup' ?
                                    <Button
                                        disabled={this.props.match.params.type === 'pickup' ? !this.validPickupStep() : false}
                                        onClick={() => {
                                            this.formatOrder()
                                        }}
                                        padding
                                        col={3}
                                        color="primary"
                                    >
                                        {loc.OrderBlock_OrderBtn}
                                    </Button>
                                    :
                                    null
                            }
                            {
                                (this.props.create && this.state.additional.step === 3) || this.props.match.params.type === 'pickup' ?
                                    <Button
                                        disabled={this.props.match.params.type === 'pickup' ? !this.validPickupStep() : false}
                                        onClick={() => {
                                            this.sendOrder()
                                        }}
                                        padding
                                        col={3}
                                        color="primary"
                                    >
                                        {loc.OrderBlock_SendBtn}
                                    </Button>
                                    :
                                    null
                            }
                        </div>
                    </>
                    :
                    null
                }
                {
                    this.props.edit || this.props.view ?
                        this.editForm()
                        :
                        null

                }
                {/* <div className="spinner">
                    <CircularProgress />
                </div> */}
                {/* <WarningDialog 
                    smart 
                    warningText={loc.OrderBlock_TariffWarning}           
                    display={this.state.additional.displayWarningDialog} okAction={() => {
                        this.handleCloseWarningDialog()
                    }} 
                    closeDialog={this.handleCloseWarningDialog}/> */}
                {/* <ErrorDialog errorMessages={this.state.errorMessages} display={this.state.displayErrorDialog} closeDialog={this.handleCloseDialog}/> */}

            </div>
        )
    };
}

const mapStateToProps = (state: IAppState) => ({
    locale: state.locale,
    order: state.order,
    user: state.user,
    tariff: state.tariff,
    dialog: state.dialog,
    isLogist: state.user.userInfo.authorities[0] === 'ROLE_MANAGER_LOGIST',
    isManager: state.user.userInfo.authorities[0] === 'ROLE_TC_MANAGER'
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
    createOrder: (orderData: IOrderCreateModel) => dispatch(createOrder(orderData)),
    createOrderComment: (orderData: IOrderCreateCommentModel) => dispatch(createOrderComment(orderData)),
    editOrder: (orderData: IOrderEditModel) => dispatch(editOrder(orderData)),
    editOrderByManager: (orderData: IOrderEditByManagerModel) => dispatch(editOrderByManager(orderData)),
    // editCurrentOrder: (orderData: ICurrentOrderEditModel) => dispatch(editCurrentOrder(orderData)),
    // changeOrderStatus: (statusData: IChangeOrderStatusModel) => dispatch(changeOrderStatus(statusData)),
    openSuccessDialog: (data: IDoneDialogData) => dispatch(openDoneDialog(data)),
    changeOrderStatusByLogist: (statusData: IOrderChangeStatusModel) => dispatch(changeOrderStatusByLogist(statusData)),
    changeOrderStatusByManager: (statusData: IOrderChangeStatusModel) => dispatch(changeOrderStatusByManager(statusData)),
    getTariffList: (params: ISearchParams) => dispatch(getTariffList(params)),
    getRouteByCity: (params: ISearchRoute) => dispatch(getRouteByCity(params)),
    getRouteList: (params: ISearchParams) => dispatch(getRouteList(params)),
    getOrderById: (id: string) => dispatch(getOrderById(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderBlock);
