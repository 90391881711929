
import React, { Component } from 'react'
import { connect } from 'react-redux'

import Icon from '@material-ui/core/Icon';
import { IAppState } from 'store';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Button } from 'components/Button';
import { loginUser, getUserInfo, logoutUser } from 'store/user/actions';
import { ILoginData, IUserState, ILoginUserSuccessAction, ILoginUserFailureAction, IGetUserInfoSuccessAction, IGetUserInfoFailureAction, ILogoutUserSuccessAction, ILogoutUserFailureAction } from 'store/user/types';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { App } from 'App';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import InputField from 'components/InputField/InputField';
import { ILocaleState, IDictionary } from 'store/locale/types';
import { Card } from 'components/Card/Card';
import { ErrorMessage } from 'components/ErrorMessage';

import './LoginCard.scss';
import { Link } from 'react-router-dom';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { isSavedInSystem } from 'services';

interface OwnProps {
}

interface IProps {
    loginUserHandle: (email: string, password: string, saveMe?: boolean) => Promise<ILoginUserSuccessAction | ILoginUserFailureAction>;
    getUserInfo: () => Promise<IGetUserInfoSuccessAction | IGetUserInfoFailureAction>;
    logoutUser: () => Promise<ILogoutUserSuccessAction | ILogoutUserFailureAction>;
    user: IUserState;
    locale: ILocaleState;
    history: any;
    loc: IDictionary;
}
interface State {
    email: string;
    password: string;
    checkedMe: boolean;
    errorMsg: string | null;
}


class LoginCard extends Component<IProps> {
    state: State = {
        email: '',
        password: '',
        checkedMe: false,
        errorMsg: ''
    }

    componentWillMount() {
        this.props.logoutUser();
    }
    

    handleChangeField = (event: React.ChangeEvent<HTMLInputElement>) => {
        // console.log(event.target.name, event.target.value);
        this.setState(
            {
                [event.target.name]: event.target.value
            } //as Pick<State, keyof State>
        );
    }

    handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        // console.log(event.target.name, event.target.value);
        this.setState(
            {
                [event.target.name]: event.target.checked
            } //as Pick<State, keyof State>
        );

    }

    singIn = (email: string, password: string) => {
        this.props.loginUserHandle(email, password, this.state.checkedMe)
            .then((data) => {
                console.log(data)
                if(data.type === 'LOGIN_USER_FAILURE'){
                    console.log(this.props.loc[`ERROR_${data.payload.error}`])
                    this.setState({errorMsg: this.props.loc[`ERROR_${data.payload.error}`]})
                    // console.log();
                } else {
                    this.props.history.push('/dashboard/order/active');
                    this.props.getUserInfo();
                }
                // console.log('sing in error',data)
                
            })
            .catch(error=> {
                console.log('sing in error',error)
            });
    }

    render() {
        const { locale } = this.props;
        const { currentLocaleJSON: loc } = locale;
        // console.log(loc)
        return (
            <Card icon="person" title={loc.AuthScreen_CardTitle} width="455px">
                {
                    this.state.errorMsg && this.state.errorMsg !== null && this.state.errorMsg !== ''?
                        <ErrorMessage>
                            {this.state.errorMsg}
                        </ErrorMessage>
                    :
                        null
                }
                <InputField label={loc.AuthScreen_LoginTitle} type="text" onChange={this.handleChangeField} value={this.state.email} name='email'/>
                <InputField label={loc.AuthScreen_PasswordTitle} type="password" onChange={this.handleChangeField} value={this.state.password} name='password'/>
                
                <div className='check-me-wrapper'>
                    <FormControlLabel
                    className="checked-me-checkbox"
                        control={
                        <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            checked={this.state.checkedMe}
                            onChange={this.handleChangeCheckbox}
                            value={this.state.checkedMe}
                            color="primary"
                            name="checkedMe"
                            disabled={isSavedInSystem()}
                            
                        />
                        }
                        label={loc.AuthScreen_CheckedMe}
                    />
                    <Link className="reset-password-link" to="/password/email">{loc.AuthScreen_ForgetPasswordLink}</Link>
                </div>
                <Button 
                    col={12}
                        disabled={
                            this.state.email === '' || this.state.password === ''
                        }
                        onClick={() => {this.singIn(this.state.email, this.state.password)}} 
                        padding 
                        color="primary"
                    >
                        {loc.AuthScreen_LoginButton}
                    </Button>
                {/* <Button disabled={true} className={'blue-btn fullwidth'} onClick={} variant="contained" color="primary" >
                    {loc.AuthScreen_LoginButton}
                </Button> */}
            </Card>
        )
    }
}



const mapStateToProps = (store: IAppState) => ({
    user: store.user,
    locale: store.locale,
    loc: store.locale.currentLocaleJSON
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
    loginUserHandle: (email: string, password: string, saveMe?: boolean) => dispatch(loginUser({email, password, saveMe})),
    logoutUser: () => dispatch(logoutUser()),
    getUserInfo: () => dispatch(getUserInfo())
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginCard)
