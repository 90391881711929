import Api from "api";
import { ISearchParams, IGetListServerResponse, IDefaultServerResponse, IGetListPayload, IErrorServerResponse, IGetDataListServerResponse, IGetByIdServerResponse, IGetByIdPayload } from "store/types";
import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { 
    IGetVehicleListPendingAction,
    IGetVehicleListSuccessAction,
    IGetVehicleListFailureAction,
    IGetVehicleByIdPendingAction,
    IGetVehicleByIdSuccessAction,
    IGetVehicleByIdFailureAction,
    ICreateVehiclePendingAction,
    ICreateVehicleSuccessAction,
    ICreateVehicleFailureAction,
    IEditVehiclePendingAction,
    IEditVehicleSuccessAction,
    IEditVehicleFailureAction,
    IDeleteVehiclePendingAction,
    IDeleteVehicleSuccessAction,
    IDeleteVehicleFailureAction,
    IVehicleModel,
    IVehicleCreateModel,
    IVehicleEditModel,
    IChangeVehicleStatusFailureAction,
    IChangeVehicleStatusSuccessAction,
    IChangeVehicleStatusPendingAction,
    IVehileChangeStatusModel,
} from "./types";
import { formatSearchParams } from "services";
import { AxiosResponse, AxiosError } from "axios";

export const getVehicleList: ActionCreator<
  ThunkAction<
    Promise<IGetVehicleListSuccessAction | IGetVehicleListFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    IVehicleModel[],                  // The type for the data within the last action
    ISearchParams,                       // The type of the parameter for the nested function 
    IGetVehicleListSuccessAction | IGetVehicleListFailureAction            // The type of the last action to be dispatched
  >
> = ( searchParams: ISearchParams) => {
    return async (dispatch: Dispatch) => {
        
        const getVehicleListPendingAction: IGetVehicleListPendingAction = {
            type: 'GET_VEHICLE_LIST_PENDING',
        };
        dispatch(getVehicleListPendingAction);

        try{
            const response: AxiosResponse<IGetListServerResponse<IVehicleModel>> = await Api.get(`/vehicles?${formatSearchParams(searchParams)}`);
            const payload: IGetListPayload<IVehicleModel> = {
                list: response.data.data.content,
                searchParams,
                pageble: {
                    currentPage: response.data.data.number,
                    totalPages: response.data.data.totalPages,
                    totalElements: response.data.data.totalElements
                }
            }
            const getVehicleListSuccessAction: IGetVehicleListSuccessAction = {
                payload,
                type: 'GET_VEHICLE_LIST_SUCCESS',
            };
            return dispatch(getVehicleListSuccessAction);
        } catch (error) {
            const getVehicleListFailureAction: IGetVehicleListFailureAction = {
                type: 'GET_VEHICLE_LIST_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(getVehicleListFailureAction);
        }
    };
};

export const getVehicleById: ActionCreator<
  ThunkAction<
    Promise<IGetVehicleByIdSuccessAction | IGetVehicleByIdFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    IGetByIdPayload<IVehicleModel> ,                  // The type for the data within the last action
    string,                       // The type of the parameter for the nested function 
    IGetVehicleByIdSuccessAction | IGetVehicleByIdFailureAction            // The type of the last action to be dispatched
  >
> = (id: string) => {
    return async (dispatch: Dispatch) => {
        
        const getVehicleByIdPendingAction: IGetVehicleByIdPendingAction = {
            type: 'GET_VEHICLE_BY_ID_PENDING',
        };
        dispatch(getVehicleByIdPendingAction);
        try{
            const response: AxiosResponse<IGetByIdServerResponse<IVehicleModel>> = await Api.get(`/vehicle/${id}`);
            const payload: IGetByIdPayload<IVehicleModel> = {
                record: response.data.data,
                id
            } 
            const getVehicleByIdSuccessAction: IGetVehicleByIdSuccessAction = {
                payload,
                type: 'GET_VEHICLE_BY_ID_SUCCESS',
            };
            return dispatch(getVehicleByIdSuccessAction);
        } catch (error) {
            const getVehicleByIdFailureAction: IGetVehicleByIdFailureAction = {
                type: 'GET_VEHICLE_BY_ID_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(getVehicleByIdFailureAction);
        }
    };
};

export const changeVehicleStatus: ActionCreator<
  ThunkAction<
    Promise<IChangeVehicleStatusSuccessAction | IChangeVehicleStatusFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    string,                       // The type of the parameter for the nested function 
    IChangeVehicleStatusSuccessAction | IChangeVehicleStatusFailureAction            // The type of the last action to be dispatched
  >
> = (changeStatusData: IVehileChangeStatusModel) => {
    return async (dispatch: Dispatch) => {
        
        const changeVehicleStatusPendingAction: IChangeVehicleStatusPendingAction = {
            type: 'CHANGE_VEHICLE_STATUS_PENDING',
        };
        dispatch(changeVehicleStatusPendingAction);

        try{
            const authDataResponse: AxiosResponse<IDefaultServerResponse> = await Api.put(`/vehicle/status/${changeStatusData.id}/${changeStatusData.status}`);
            // const list: IVehicleNameModel[] = authDataResponse.data.data; 
            const changeVehicleStatusSuccessAction: IChangeVehicleStatusSuccessAction = {
                type: 'CHANGE_VEHICLE_STATUS_SUCCESS',
            };
            return dispatch(changeVehicleStatusSuccessAction);
        } catch (error) {

            const ChangeVehicleStatusFailureAction: IChangeVehicleStatusFailureAction = {
                type: 'CHANGE_VEHICLE_STATUS_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(ChangeVehicleStatusFailureAction);
        }
    };
};


export const createVehicle: ActionCreator<
  ThunkAction<
    Promise<ICreateVehicleSuccessAction | ICreateVehicleFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    IVehicleCreateModel,                       // The type of the parameter for the nested function 
    ICreateVehicleSuccessAction | ICreateVehicleFailureAction            // The type of the last action to be dispatched
  >
> = ( createdData: IVehicleCreateModel) => {
    return async (dispatch: Dispatch) => {
        
        const createVehiclePendingAction: ICreateVehiclePendingAction = {
            type: 'CREATE_VEHICLE_PENDING',
        };
        dispatch(createVehiclePendingAction);

        try{
            const response: AxiosResponse<IDefaultServerResponse> = await Api.post(`/vehicle`, { ...createdData });
            const createVehicleSuccessAction: ICreateVehicleSuccessAction = {
                type: 'CREATE_VEHICLE_SUCCESS',
            };
            return dispatch(createVehicleSuccessAction);
        } catch (error) {
            const createVehicleFailureAction: ICreateVehicleFailureAction = {
                type: 'CREATE_VEHICLE_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(createVehicleFailureAction);
        }
    };
};

export const editVehicle: ActionCreator<
  ThunkAction<
    Promise<IEditVehicleSuccessAction | IEditVehicleFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    IVehicleEditModel,                       // The type of the parameter for the nested function 
    IEditVehicleSuccessAction | IEditVehicleFailureAction            // The type of the last action to be dispatched
  >
> = ( editData: IVehicleEditModel) => {
    return async (dispatch: Dispatch) => {
        
        const editVehiclePendingAction: IEditVehiclePendingAction = {
            type: 'EDIT_VEHICLE_PENDING',
        };
        dispatch(editVehiclePendingAction);

        try{
            const response: AxiosResponse<IDefaultServerResponse> = await Api.put(`/vehicle`, { ...editData });
            const editVehicleSuccessAction: IEditVehicleSuccessAction = {
                type: 'EDIT_VEHICLE_SUCCESS',
            };
            return dispatch(editVehicleSuccessAction);
        } catch (error) {
            const editVehicleFailureAction: IEditVehicleFailureAction = {
                type: 'EDIT_VEHICLE_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(editVehicleFailureAction);
        }
    };
};


export const deleteVehicle: ActionCreator<
  ThunkAction<
    Promise<IDeleteVehicleSuccessAction | IDeleteVehicleFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    string,                       // The type of the parameter for the nested function 
    IDeleteVehicleSuccessAction | IDeleteVehicleFailureAction            // The type of the last action to be dispatched
  >
> = ( id: string) => {
    return async (dispatch: Dispatch) => {
        
        const deleteVehiclePendingAction: IDeleteVehiclePendingAction = {
            type: 'DELETE_VEHICLE_PENDING',
        };
        dispatch(deleteVehiclePendingAction);

        try{
            const response: AxiosResponse<IDefaultServerResponse> = await Api.delete(`/vehicle/${id}`);
            const deleteVehicleSuccessAction: IDeleteVehicleSuccessAction = {
                type: 'DELETE_VEHICLE_SUCCESS',
            };
            return dispatch(deleteVehicleSuccessAction);
        } catch (error) {
            const deleteVehicleFailureAction: IDeleteVehicleFailureAction = {
                type: 'DELETE_VEHICLE_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(deleteVehicleFailureAction);
        }
    };
};