import React from 'react';
import {createStyles, makeStyles, Theme, useTheme} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import TableHead from '@material-ui/core/TableHead';
import Icon from '@material-ui/core/Icon';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import './DataTable.scss';
import {formatDate, formatTime} from 'services';
import {IPagable} from 'store/types';

import {Link} from 'react-router-dom';
import { IOrderModel } from 'store/order/types';
import NoData from 'components/NoData/NoData';

const useStyles1 = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexShrink: 0,
            color: theme.palette.text.secondary,
            marginLeft: theme.spacing(2.5),
        },
    }),
);

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onChangePage: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, newPage: number) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    function handleFirstPageButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        onChangePage(event, 0);
    }

    function handleBackButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        onChangePage(event, page - 1);
    }

    function handleNextButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        onChangePage(event, page + 1);
    }

    function handleLastPageButtonClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    }

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon/> : <FirstPageIcon/>}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon/> : <LastPageIcon/>}
            </IconButton>
        </div>
    );
}


//const rows = [
    // createData(),

//]//.sort((a, b) => (a.calories < b.calories ? -1 : 1));

const useStyles2 = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing(3),
        },
        table: {
            minWidth: 500,
        },
        tableWrapper: {
            overflowX: 'auto',
        },
    }),
);

interface IProps {
    tableHeaders: string[];
    dataModel: string[];
    listData: any[];
    view?: boolean;
    viewTriggerKey?: string;
    edit?: boolean;
    delete?: boolean;
    pageble: IPagable;
    onChangePage: (page: number) => void;
    onDelete?: (row: any) => void;
    onEdit?: (row: any) => void;
    onView?: (row: any) => void;
    typesDictionary?: any;
    linksDictionary?: { [key: string]: string };
    boldFromKey?: string;
    onSort: (sortString: string) => void;
    actualSort: string;
    check?: boolean;
    onCheck?: (orders: IOrderModel[]) => void;
    checkedAll?: boolean;
}

export default function DataTable(props: IProps) {
    const classes = useStyles2();
    // const [page, setPage] = React.useState(0);
    // const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [ , setPage ] = React.useState(0);
    const [ rowsPerPage ] = React.useState(10);
    const [ checkedId, setCheckedId ] = React.useState([] as number[]);
    const [ checkedOrders, setCheckedOrders ] = React.useState([] as any[]);
    // const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    function handleChangePage(
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
        newPage: number,
    ) {
        props.onChangePage(newPage);
        setPage(newPage);
    }

    // function handleChangeRowsPerPage(
    //     event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    // ) {
    //     setRowsPerPage(parseInt(event.target.value, 10));
    //     setPage(0);
    // }

    function handeleDelete(row: any) {
        if (props.onDelete)
            props.onDelete(row);
    }

    function handeleEdit(row: any) {
        if (props.onEdit)
            props.onEdit(row);
    }

    function handeleView(row: any) {
        if (props.onView)
            props.onView(row);
    }

    const parseFromDots = (objInput: any, prop: string): string | number => {
        if (objInput) {
            let parts = prop.split('.');
            let obj = {...objInput};
            for (let i = 0, l = parts.length; i < l; i++) {
                let part = parts[i];
                // console.log('part', part, obj);
                if ((obj !== null) && (typeof (obj) === 'object') && (part in obj)) {
                    obj = obj[part];
                    if (typeof obj === 'string' || typeof obj === 'number') {
                        return obj;

                    }
                    // console.log(obj)
                } else {
                    // console.log(console.log(obj[part] !== undefined ? obj[part] : ""));
                    return '---'; // return obj;
                }
            }
            return "---";
        } else {
            return "---";
        }
    };

    function stringParameter(row: any, key: string) {
        const [stringKey, parameter] = key.split('|');
        switch (parameter) {
            case "date":
                return cutString(formatDate(row[stringKey]));

            case "time":
                return cutString(formatTime(row[stringKey]));

            case "route":
                let route = row[stringKey].sap? <Link to={`/dashboard/route/edit/${row[stringKey].id}`}>{cutString(row[stringKey].name)}</Link>: <span title={row[stringKey].name}>{cutString(row[stringKey].name)}</span>;
                return route;

            case "type":
                // console.log('stringKey, parament', stringKey, parament, props.typesDictionary[stringKey][row[stringKey]])
                return props.typesDictionary && props.typesDictionary[stringKey] && props.typesDictionary[stringKey][row[stringKey]] && cutString(props.typesDictionary[stringKey][row[stringKey]]);

            case "link":
                return props.linksDictionary && props.linksDictionary[stringKey] &&
                    <Link to={`${props.linksDictionary[stringKey]}${row[stringKey]}`}>{cutString(row[stringKey])}</Link>;

            case "struct":
                if (row) {
                    return cutString(parseFromDots(row, stringKey).toString());
                } else {
                    return "";
                }

            case "structDate":
                if (row) {
                    return formatDate(parseFromDots(row, stringKey));
                } else {
                    return "";
                }

            case "structTime":
                if (row) {
                    return formatTime(parseFromDots(row, stringKey));
                } else {
                    return "";
                }

            default:
                break;
        }
    }


    function parseActualSort(sortString: string, modelName: string): string {
        let correctModelName = "";
        // console.log("modelName", modelName)
        if (modelName && sortString) {
            if (modelName.indexOf("|") !== -1) {
                correctModelName = modelName.split("|")[0];
            } else {
                correctModelName = modelName;
            }
            // console.log("correctModelName", correctModelName)
            if (sortString !== "" && sortString.indexOf(',') !== -1 && sortString.indexOf(correctModelName) !== -1) {
                const [ , direction ] = sortString.split(',');
                return ` ${direction}`;
            } else {
                return "";
            }
        } else {
            return "";
        }
    }

    function handleOnSort(modelName: string) {
        let correctModelName = "";
        // console.log("modelName", modelName)
        if (modelName.indexOf("|") !== -1) {
            correctModelName = modelName.split("|")[0];
        } else {
            correctModelName = modelName;
        }
        props.onSort(correctModelName)
    }

    function cutString(value: string): string {
        if (value) {
            if (value.length > 20) {
                return `${value.substr(0, 20)}...`;
            } else {
                return value;
            }
            //return value;
        } else {
            if(typeof value === 'number'){
                return value;
            } else {
                return "";
            }
            // console.log('breack',value)
        }
    }

    function handleChangeCheck (row: any): void{
        let newArrayId: number[] =  [...checkedId, row.id];
        let newArrayOrders: any[] =  [...checkedOrders, row];
        setCheckedId(newArrayId);
        setCheckedOrders(newArrayOrders);
        if(props.onCheck){
            props.onCheck(newArrayOrders);
        }
    }
    function handleChangeUncheck (id: number): void{
        let idInArray: number =  checkedId.indexOf(id);
        if(idInArray !== -1){
            let newArrayId =  checkedId.filter(e => e !== id);
            let newArrayOrders =  checkedOrders.filter(e => e.id !== id);
            setCheckedId(newArrayId);
            setCheckedOrders(newArrayOrders);
            if(props.onCheck){
                props.onCheck(newArrayOrders);
            }
        }
        
    }

    return (
        <Paper className={classes.root}>
            <div className={classes.tableWrapper}>
                <Table id="table-to-print" className={classes.table}>
                    <TableHead>
                        <TableRow>
                            {props.tableHeaders.map((headerName: string, n: number): object =>
                                <TableCell align="center" key={n}>
                                    {
                                        headerName === '' ?
                                            <span className="table-header-item">
                                        {headerName}
                                    </span>
                                            :
                                            <span onClick={() => {
                                                handleOnSort(props.dataModel[n])
                                            }}
                                                  className={`table-header-item${parseActualSort(props.actualSort, props.dataModel[n])}`}>
                                        {headerName}
                                                <Icon className='sort-arrow-up'>arrow_drop_up</Icon>
                                        <Icon className='sort-arrow-down'>arrow_drop_down</Icon>
                                    </span>
                                    }
                                </TableCell>
                            )}
                            {
                                props.edit || props.view || props.delete || props.check?
                                    <TableCell align="center" key={'view-edit-delete-column'}>
                                    </TableCell>
                                    :
                                    null
                            }
                            {/* <TableCell>Dessert (100g serving)</TableCell>
                    <TableCell align="right">Calories</TableCell>
                    <TableCell align="right">Fat&nbsp;(g)</TableCell> */}
                            {/* <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                    <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        {props.listData.length?
                        <>
                            {
                                props.listData.map((row, rowNumber) =>
                                    <TableRow key={rowNumber}>
                                        {
                                            props.dataModel.map((key, n) => {
                                                // console.log('key',key, row[key],key.indexOf("|") )
                                                const itemClassName = `table-body-item${props.boldFromKey ? !row[props.boldFromKey] ? " bold" : "" : ""}`;
                                                return (<TableCell key={n} align="center">
                                                    {

                                                        // key.indexOf(".") !== -1?
                                                        // <span></span>// <span className={itemClassName}>{row[key.split('.')[0]] && row[key.split('.')[0]][key.split('.')[1]] && row[key.split('.')[0]][key.split('.')[1]] !== "" && row[key.split('.')[0]][key.split('.')[1]] !== null ? cutString(row[key.split('.')[0]][key.split('.')[1]]): '---'}</span>
                                                        //:
                                                        row[key] !== "" && row[key] !== null ?
                                                            key.indexOf("|") !== -1 ?
                                                                <span
                                                                    className={itemClassName}>{stringParameter(row, key)}</span>
                                                                :
                                                                <span className={itemClassName}>{cutString(row[key])}</span>
                                                            :
                                                            <span className={itemClassName}>{'---'}</span>
                                                    }

                                                </TableCell>)
                                            })
                                        }

                                        {
                                            props.edit || props.view || props.delete || props.check ?
                                                <TableCell align={props.check? "center" :"right"} key={`view-edit-delete-${rowNumber}`}>
                                                    {
                                                        props.view && props.viewTriggerKey && !row[props.viewTriggerKey] ?
                                                            <button onClick={() => {
                                                                handeleView(row)
                                                            }}>
                                                                <Icon>remove_red_eye</Icon>
                                                            </button>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        props.edit ?
                                                            <button onClick={() => {
                                                                handeleEdit(row)
                                                            }}>
                                                                <Icon>edit</Icon>
                                                            </button>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        props.delete ?
                                                            <button onClick={() => {
                                                                handeleDelete(row)
                                                            }}>
                                                                <Icon>delete</Icon>
                                                            </button>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        props.check ?
                                                            <>
                                                            {
                                                                checkedId.indexOf(row.id) !== -1 || props.checkedAll?
                                                                    <CheckBoxIcon className="checked" onClick={props.checkedAll? () => {} : () => {handleChangeUncheck(row.id)}} fontSize="small" />
                                                                :
                                                                    <CheckBoxOutlineBlankIcon className="unchecked" onClick={() => {handleChangeCheck(row)}} fontSize="small" />
                                                            }
                                                            </>
                                                            :
                                                            null
                                                    }
                                                </TableCell>
                                                :
                                                null
                                        }
                                    </TableRow>
                                )
                            }
                        </>
                        :
                        <TableRow key={'no-data'}>
                            <TableCell colSpan={props.tableHeaders.length} key={'no-data-cell'} align="center">
                                <NoData/>
                            </TableCell>
                        </TableRow>
                        
                        }
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[10]}
                                // colSpan={3}
                                count={props.pageble.totalElements}
                                rowsPerPage={rowsPerPage}
                                page={props.pageble.currentPage}
                                SelectProps={{
                                    inputProps: {'aria-label': 'rows per page'},
                                    native: true,
                                }}
                                onChangePage={handleChangePage}
                                // onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </div>
        </Paper>
    );
}
