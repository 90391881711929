import './StaffScreen.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ILocaleState, IInitLocalesSuccessAction, IInitLocalesFailureAction, IDictionary,  } from 'store/locale/types';
import { IAppState } from 'store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { initLocales } from 'store/locale/actions';
import config from 'locales/config.json';
import { SS_AUTH_TOKEN, ISearchParams } from 'store/types';
import PageTemplate from 'components/PageTemplate';
import { IGetStaffListSuccessAction, IGetStaffListFailureAction, ICreateStaffSuccessAction, ICreateStaffFailureAction, IStaffState, IDeleteStaffSuccessAction, IDeleteStaffFailureAction, StaffType, IStaffModel, IStaffCreateData, IStaffEditModel, IEditStaffSuccessAction, IEditStaffFailureAction, IStaffChangeStatusModel, IChangeStaffStatusSuccessAction, IChangeStaffStatusFailureAction, StaffStatusType } from 'store/staff/types';
import { getStaffList, createStaff, deleteStaff, changeStaffStatus, editStaff } from 'store/staff/actions';
import { Dialog, Icon } from '@material-ui/core';
import { Button } from 'components/Button';
import InputField from 'components/InputField';
import { ICompanyNameModel, ICompanyModel } from 'store/company/types';
import { ICityModel } from 'store/city/types';
import { FormBlock } from 'components/FormBlock/FormBlock';
import RoleControl from 'components/RoleControl/RoleControl';
import { validateRequiredFields, returnSetForActiveTab, getNewSort, getErrorsFromDictionary } from 'services';
import equal from 'deep-equal';
import { IUserState } from 'store/user/types';
import { IWithPageBase, withPageBase } from 'hoc/withPageBase/withPageBase';


interface IProps extends IWithPageBase {
    locale: ILocaleState;
    loc: IDictionary;
    staff: IStaffState;
    history: any;
    user: IUserState;
    // initLocales: () => Promise<IInitLocalesSuccessAction | IInitLocalesFailureAction>;
    // getStaffInfo: () => Promise<IGetStaffInfoSuccessAction | IGetStaffInfoFailureAction>;
    getStaffList: (params: ISearchParams) => Promise<IGetStaffListSuccessAction|IGetStaffListFailureAction>;
    deleteStaff: (id: string) => Promise<IDeleteStaffSuccessAction|IDeleteStaffFailureAction>;
    createStaff: (data: IStaffCreateData) => Promise<ICreateStaffSuccessAction|ICreateStaffFailureAction>;
    editStaff: (data: IStaffEditModel) => Promise<IEditStaffSuccessAction|IEditStaffFailureAction>;
    changeStaffStatus: (userData: IStaffChangeStatusModel) => Promise<IChangeStaffStatusSuccessAction|IChangeStaffStatusFailureAction>;
    administration?: boolean;
    
}

interface IState {
    page: number;
    q: any;
    sort: string;
    // deleteDialogOpen: boolean;
    formDialogOpen: boolean;
    formRow: IStaffModel | null;
    deleteRow: any | null;
    filters: {[key: string]: string};//{field: string, value: string}[];//{[key: string]: string};
    activeTab: string;
    formFields: {
        cityName: string;
        drivingLicence: string;
        drivingLicenceProvided: string;
        fio: string;
        id: string;
        passport: string;
        passportProvided: string;
        phone: number | null;
        phone2: number | null;
        securityCompanyName: string;
        staffType: StaffType;
        status: StaffStatusType;
        blockComment: string;
    };
    edit: boolean;
    create: boolean;
    // beforeEdit: IStaffModel | null;
    autocompleteFormFields: {
        city: ICityModel;
        company: ICompanyNameModel;
    };
}

export class StaffScreen extends Component<IProps> {
    state: IState = {
        q: {

        },
        page: 0,
        sort: '',
        // deleteDialogOpen: false,
        deleteRow: null,
        formDialogOpen: false,
        formRow: null,
        filters: {
            'staffType|full': 'DRIVER'
        },
        edit: false,
        create: false,
        activeTab: 'driver',
        // beforeEdit: null,
        autocompleteFormFields: {
            city: {
                name: '',
                region: ''
            },
            company: {
                name: '',
                id: ''
            }
        },
        formFields: {
            cityName: '',
            drivingLicence: '',
            drivingLicenceProvided: '',
            fio: '',
            id: '',
            passport: '',
            passportProvided: '',
            phone: null,
            phone2: null,
            securityCompanyName: '',
            staffType: 'DRIVER',
            status: 'ACTIVE',
            blockComment: ''
        }
    }

    beforeEdit = {};

    handleChangeFilter = this.props.handleChangeFilter.bind(this);
    handleChangeCompanyFilter = this.props.handleChangeCompanyFilter.bind(this);
    handleChangeCityFilter = this.props.handleChangeCityFilter.bind(this);
    handleChangeField = this.props.handleChangeFieldInForm.bind(this);
    handleChangeCityField = this.props.handleChangeCityField.bind(this);

    static getDerivedStateFromProps = (nextProps: IProps, prevState: IState) => {
        if(prevState.formFields.cityName && prevState.formFields.cityName !== `${prevState.autocompleteFormFields.city.name} (${prevState.autocompleteFormFields.city.region})`){
            return ({
                autocompleteFormFields: {
                    ...prevState.autocompleteFormFields,
                    city: {
                        name: '',
                        region: ''
                    }
                }
            });
        }
        return null;
    }

    componentDidMount = () => {
        this.props.getStaffList({page: this.state.page, q: {...this.state.filters}});
    }

    changePageNumber = (page: number) => {
        this.props.getStaffList({page, q: {...this.state.filters}, sort: this.state.sort})
            .then(data => {
                this.setState({page});
            });
    }

    handleDeleteDialog = (row: any) => {
        
        const { loc } = this.props;
        this.setState({
            deleteRow: {...row}
        },
        () => {
            this.props.handleDeleteItem(
                `${loc.StaffScreen_TryToDelete} ${this.state.deleteRow !== null && this.state.deleteRow.fio? this.state.deleteRow.fio: "" }`,
                () => {
                    this.deleteRecord();
                }
            );
        });
        // this.setState({deleteDialogOpen: true, deleteRow: {...row}});
    }

    deleteRecord = () => {
        this.props.deleteStaff(this.state.deleteRow !== null && this.state.deleteRow.id? this.state.deleteRow.id: "")
            .then(dataDelete => {
                if(dataDelete.type === 'DELETE_STAFF_SUCCESS'){
                    this.props.openSuccessDialog();
                    this.props.getStaffList({page: this.state.page, q: {...this.state.filters}, sort: this.state.sort});
                } else {
                    this.props.openErrorDialog({});
                }
                
                // this.handleCloseDeleteDialog();
            })
    }

    handleEdit = (row: any) => {
        // this.props.history.push(`/dashboard/staff/edit/${row.id}`)
        this.beforeEdit = { ...row };
        let { city, company, ...restData } = row;
        this.setState({
            formDialogOpen: true, 
            formFields: {...restData, cityName: `${city.name} (${city.region})`},
            // beforeEdit: {...restData, cityName: `${city.name} (${city.region})`},
            autocompleteFormFields: {
                city: {...city},
                company: {...company}
            },
            edit: true,
            create: false
        });
    }

    handleAdd = () => {
        this.setState({
            formDialogOpen: true,
            edit: false,
            create: true
        })
    }

    handleCloseFormDialog = () => {
        this.setState({formDialogOpen: false});
    }


    handleSort = (sortString: string) => {
        const newSort = getNewSort(sortString, this.state);
        this.props.getStaffList({page: this.state.page, q: {...this.state.filters}, sort: newSort});
        this.setState({sort: newSort});
        
    }

    handleCloseDeleteDialog = () => {
        this.setState({deleteDialogOpen: false});
    }

    handleSearch = () => {
        this.props.getStaffList({page: 0, q: {...this.state.filters}, sort: this.state.sort})
            .then(() => {
                this.setState({page: 0});
            });
    }

    handleResetSearch = () => {
        this.props.getStaffList({page: 0, q: {'staffType|full': this.state.activeTab.toUpperCase()}, sort: this.state.sort});
        this.setState((prevState: IState) => ({
            page: 0,
            filters: {'staffType|full': this.state.activeTab.toUpperCase()}
        }))
    }

    // handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     const Event = event;
    //     const field = Event.target.name//.split('|');
    //     const value = Event.target.type == 'number'? +Event.target.value : Event.target.value;
    //     // console.log('filter value',value)
    //     this.setState((prevState: IState) => ({
    //         filters: {
    //             ...prevState.filters,
    //             [field]: value
    //         }
    //     }))
    // }

    // handleChangeCompanyFilter = (resultObject: ICompanyModel) => {
    //     // console.log(resultObject)
    //     this.setState((prevState: IState) => ({
    //         filters: {
    //             ...prevState.filters,
    //             'company.name|full': resultObject.shortName
    //         }
    //     }))
    // }

    // handleChangeCityFilter = (resultObject: ICityModel) => {
    //     // console.log(resultObject)
    //     this.setState((prevState: IState) => ({
    //         filters: {
    //             ...prevState.filters,
    //             'city.name|full': resultObject.name
    //         }
    //     }))
    // }

    getDataModel = (): string[] => {
        const { administration } = this.props;
        const staffModels = {
            positive: {
                driver: [
                    'fio',
                    'company.name|struct', 
                    'city.name|struct',
                    'phone', 
                    'drivingLicence', 
                    'passport'
                ],
                security: [
                    'fio',
                    'securityCompanyName', 
                    'city.name|struct',
                    'phone',
                    'passport'
                ]
            },
            negative: {
                driver: [
                    'fio',
                    'city.name|struct',
                    'phone', 
                    'drivingLicence', 
                    'passport'
                ],
                security: [
                    'fio',
                    'securityCompanyName', 
                    'city.name|struct',
                    'phone',
                    'passport'
                ]
            }
        }
        return returnSetForActiveTab(this.state.activeTab, !!administration, staffModels);

        // if(this.props.administration){
        //     switch (this.state.activeTab) {
        //         case 'driver':
        //             return [
        //                 'fio',
        //                 'company.name|struct', 
        //                 'city.name|struct',
        //                 'phone', 
        //                 'drivingLicence', 
        //                 'passport'
        //             ]
        //             break;
        //         case 'security':
        //             return [
        //                 'fio',
        //                 'securityCompanyName', 
        //                 'city.name|struct',
        //                 'phone',
        //                 'passport'
        //             ]
        //             break;
        //         default:
        //             return [];
        //             break;
        //     }
        // } else {
        //     switch (this.state.activeTab) {
        //         case 'driver':
        //             return [
        //                 'fio',
        //                 'city.name|struct',
        //                 'phone', 
        //                 'drivingLicence', 
        //                 'passport'
        //             ]
        //             break;
        //         case 'security':
        //             return [
        //                 'fio',
        //                 'securityCompanyName', 
        //                 'city.name|struct',
        //                 'phone',
        //                 'passport'
        //             ]
        //             break;
        //         default:
        //             return [];
        //             break;
        //     }
        // }
        
    }

    getHeaders = (): string[] => {
        const { loc, administration } = this.props;
        const staffHeaders = {
            positive: {
                driver: [
                    loc.StaffScreen_DriverTab_FullnameColumn, 
                    loc.StaffScreen_DriverTab_CompanyColumn, 
                    loc.StaffScreen_DriverTab_BaseCityColumn, 
                    loc.StaffScreen_DriverTab_PhoneColumn, 
                    loc.StaffScreen_DriverTab_DriversLicenseColumn, 
                    loc.StaffScreen_DriverTab_PassportDataColumn, 
                ],
                security: [
                    loc.StaffScreen_SecurityTab_FullnameColumn, 
                    loc.StaffScreen_SecurityTab_SecurityCompanyColumn, 
                    loc.StaffScreen_SecurityTab_BaseCityColumn, 
                    loc.StaffScreen_SecurityTab_PhoneColumn, 
                    loc.StaffScreen_SecurityTab_PassportDataColumn,
                ]
            },
            negative: {
                driver: [
                    loc.StaffScreen_DriverTab_FullnameColumn, 
                    loc.StaffScreen_DriverTab_BaseCityColumn, 
                    loc.StaffScreen_DriverTab_PhoneColumn, 
                    loc.StaffScreen_DriverTab_DriversLicenseColumn, 
                    loc.StaffScreen_DriverTab_PassportDataColumn, 
                ],
                security: [
                    loc.StaffScreen_SecurityTab_FullnameColumn, 
                    loc.StaffScreen_SecurityTab_SecurityCompanyColumn, 
                    loc.StaffScreen_SecurityTab_BaseCityColumn, 
                    loc.StaffScreen_SecurityTab_PhoneColumn, 
                    loc.StaffScreen_SecurityTab_PassportDataColumn,
                ]
            }
        }
        return returnSetForActiveTab(this.state.activeTab, !!administration, staffHeaders);

        // if(this.props.administration){
        //     switch (this.state.activeTab) {
        //         case 'driver':
        //             return [
        //                 loc.StaffScreen_DriverTab_FullnameColumn, 
        //                 loc.StaffScreen_DriverTab_CompanyColumn, 
        //                 loc.StaffScreen_DriverTab_BaseCityColumn, 
        //                 loc.StaffScreen_DriverTab_PhoneColumn, 
        //                 loc.StaffScreen_DriverTab_DriversLicenseColumn, 
        //                 loc.StaffScreen_DriverTab_PassportDataColumn, 
        //             ]
        //             break;
        //         case 'security':
        //             return [
        //                 loc.StaffScreen_SecurityTab_FullnameColumn, 
        //                 loc.StaffScreen_SecurityTab_SecurityCompanyColumn, 
        //                 loc.StaffScreen_SecurityTab_BaseCityColumn, 
        //                 loc.StaffScreen_SecurityTab_PhoneColumn, 
        //                 loc.StaffScreen_SecurityTab_PassportDataColumn,
        //             ]
        //             break;
        //         default:
        //             return [];
        //             break;
        //     }
        // } else {
        //     switch (this.state.activeTab) {
        //         case 'driver':
        //             return [
        //                 loc.StaffScreen_DriverTab_FullnameColumn, 
        //                 loc.StaffScreen_DriverTab_BaseCityColumn, 
        //                 loc.StaffScreen_DriverTab_PhoneColumn, 
        //                 loc.StaffScreen_DriverTab_DriversLicenseColumn, 
        //                 loc.StaffScreen_DriverTab_PassportDataColumn, 
        //             ]
        //             break;
        //         case 'security':
        //             return [
        //                 loc.StaffScreen_SecurityTab_FullnameColumn, 
        //                 loc.StaffScreen_SecurityTab_SecurityCompanyColumn, 
        //                 loc.StaffScreen_SecurityTab_BaseCityColumn, 
        //                 loc.StaffScreen_SecurityTab_PhoneColumn, 
        //                 loc.StaffScreen_SecurityTab_PassportDataColumn,
        //             ]
        //             break;
        //         default:
        //             return [];
        //             break;
        //     }
        // }
    }

    getFilterList = (): JSX.Element | null => {
        const { loc } = this.props;
        const driverFiltersTemplate = () => [
            <InputField label={loc.StaffScreen_DriverTab_CompanyNameFilter} type="company" onSelect={this.handleChangeCompanyFilter} onChange={this.handleChangeFilter} value={this.state.filters['company.name|full']? this.state.filters['company.name|full']: ""} name='company.name|full'/>,
            <InputField label={loc.StaffScreen_DriverTab_CityFilter} type="city" onSelect={this.handleChangeCityFilter} onChange={this.handleChangeFilter} value={this.state.filters['city.name|full']? this.state.filters['city.name|full']: ""} name='city.name|full'/>
        ]
        const securityFiltersTemplate = () => [
            <InputField label={loc.StaffScreen_SecurityTab_FullnameFilter} type="text" onChange={this.handleChangeFilter} value={this.state.filters['fio|regex']? this.state.filters['fio|regex']: ""} name='fio|regex'/>,
            <InputField label={loc.StaffScreen_SecurityTab_CityFilter} type="city" onSelect={this.handleChangeCityFilter} onChange={this.handleChangeFilter} value={this.state.filters['city.name|full']? this.state.filters['city.name|full']: ""} name='city.name|full'/>
        ]

        if(this.props.administration){
            switch (this.state.activeTab) {
                case 'driver':
                    return (
                        <>
                            <InputField label={loc.StaffScreen_DriverTab_FullnameFilter} type="text" onChange={this.handleChangeFilter} value={this.state.filters['fio|regex']? this.state.filters['fio|regex']: ""} name='fio|regex'/>
                            { driverFiltersTemplate() }
                            {/* <InputField label={loc.StaffScreen_DriverTab_StaffNameFilter} type="company" onSelect={this.handleChangeCompanyFilter} onChange={this.handleChangeFilter} value={this.state.filters['company.name|full']? this.state.filters['company.name|full']: ""} name='company.name|full'/>
                            <InputField label={loc.StaffScreen_DriverTab_CityFilter} type="city" onSelect={this.handleChangeCityFilter} onChange={this.handleChangeFilter} value={this.state.filters['city.name|full']? this.state.filters['city.name|full']: ""} name='city.name|full'/> */}
                        </>
                    )
                    break;
                case 'security':
                    return (
                        <>
                            { securityFiltersTemplate() }
                            {/* <InputField label={loc.StaffScreen_SecurityTab_FullnameFilter} type="text" onChange={this.handleChangeFilter} value={this.state.filters['fio|full']? this.state.filters['fio|full']: ""} name='fio|full'/>
                            <InputField label={loc.StaffScreen_SecurityTab_CityFilter} type="city" onSelect={this.handleChangeCityFilter} onChange={this.handleChangeFilter} value={this.state.filters['city.name|full']? this.state.filters['city.name|full']: ""} name='city.name|full'/> */}
                        </>
                    )
                    break;
                default:
                    return null;
                    break;
            }
        } else {
            switch (this.state.activeTab) {
                case 'driver':
                    return (
                        <>
                            { driverFiltersTemplate() }
                            {/* <InputField label={loc.StaffScreen_DriverTab_FullnameFilter} type="text" onChange={this.handleChangeFilter} value={this.state.filters['fio|full']? this.state.filters['fio|full']: ""} name='fio|full'/>
                            <InputField label={loc.StaffScreen_DriverTab_CityFilter} type="city" onSelect={this.handleChangeCityFilter} onChange={this.handleChangeFilter} value={this.state.filters['city.name|full']? this.state.filters['city.name|full']: ""} name='city.name|full'/> */}
                        </>
                    )
                    break;
                case 'security':
                    return (
                        <>
                            { securityFiltersTemplate() }
                            {/* <InputField label={loc.StaffScreen_SecurityTab_FullnameFilter} type="text" onChange={this.handleChangeFilter} value={this.state.filters['fio|full']? this.state.filters['fio|full']: ""} name='fio|full'/>
                            <InputField label={loc.StaffScreen_SecurityTab_CityFilter} type="city" onSelect={this.handleChangeCityFilter} onChange={this.handleChangeFilter} value={this.state.filters['city.name|full']? this.state.filters['city.name|full']: ""} name='city.name|full'/> */}
                        </>
                    )
                    break;
                default:
                    return null;
                    break;
            }
        }
    }

    handleTab = (tabName: string) => {
        this.setState((prevState: IState) => ({
            page: 0,
            sort: '',
            activeTab: tabName,
            filters: {
                // ...prevState.filters,
                'staffType|full': tabName.toUpperCase()
            },
            autocompleteFormFields: {
                city: {
                    name: '',
                    region: ''
                },
                company: {
                    name: '',
                    id: ''
                }
            },
            formFields: {
                cityName: '',
                drivingLicence: '',
                drivingLicenceProvided: '',
                fio: '',
                id: '',
                passport: '',
                passportProvided: '',
                phone: null,
                phone2: null,
                securityCompanyName: '',
                staffType: 'DRIVER',
                status: 'ACTIVE',
                blockComment: ''
            }
        }), 
        () => {
            this.props.getStaffList({page: this.state.page, q: {...this.state.filters}});
        });

        
    }

    handleBlock = () => {
        if(this.isDisableEdit()){
            const newStatus: StaffStatusType = this.state.formFields.status === 'BLOCKED'? 'ACTIVE' : 'BLOCKED';

            this.props.changeStaffStatus({status: newStatus, id: this.state.formFields.id})
                .then((data) => {
                    if(data.type === "CHANGE_STAFF_STATUS_FAILURE"){
                        let error = this.props.locale.currentLocaleJSON[`ERROR_${data.payload.error}`]? this.props.locale.currentLocaleJSON[`ERROR_${data.payload.error}`]: this.props.locale.currentLocaleJSON[`ERROR_UnknownError`];
                        this.setState({displayErrorDialog: true, errorMessages: [error]});
                    } else {
                        this.props.getStaffList({page: this.state.page, q: {...this.state.filters}, sort: this.state.sort});
                        this.setState({
                            formFields: {
                                ...this.state.formFields,
                                status: newStatus
                            }
                        })
                        // this.props.getStaffById(this.props.match.params.id)
                        //     .then(() => {
                                
                        //     });

                    }
                    
                });
        }
    }

    handleCreateRecord = () => {
        // let { id, ...restData } = this.state.formFields;
        let type: 'driver' | 'security' = this.state.activeTab === 'driver'? 'driver' : 'security';
        if(type === 'driver'){
            let { fio, phone, drivingLicence, drivingLicenceProvided, passport, passportProvided } = this.state.formFields;
            let { city } = this.state.autocompleteFormFields;
            this.props.createStaff({data: {fio, phone, city, drivingLicence, drivingLicenceProvided, passport, passportProvided}, type })
                .then(data => this.handlingStaffResult(data, 'CREATE_STAFF_FAILURE'));
        }
        if(type === 'security'){
            let { fio, phone, securityCompanyName, passport, passportProvided } = this.state.formFields;
            let { city } = this.state.autocompleteFormFields;
            this.props.createStaff({data: {fio, phone, city, securityCompanyName, passport, passportProvided}, type })
                .then(data => this.handlingStaffResult(data, 'CREATE_STAFF_FAILURE'));
        }
        // this.props.createStaff({data: ...restData, type })
        //         .then((data) => {
        //             if(data.type === "CREATE_STAFF_FAILURE"){
        //                 let error = this.props.locale.currentLocaleJSON[`ERROR_${data.payload.error}`]? this.props.locale.currentLocaleJSON[`ERROR_${data.payload.error}`]: this.props.locale.currentLocaleJSON[`ERROR_UnknownError`];
        //                 this.setState({displayErrorDialog: true, errorMessages: [error]});
        //             } else {
        //                 this.props.getStaffList({page: this.state.page, q: {...this.state.filters}, sort: this.state.sort});
                      
        //                 this.setState({formDialogOpen: false});
        //             }
                    
        //         });
    }


    handlingStaffResult = (data: IEditStaffSuccessAction | IEditStaffFailureAction | ICreateStaffSuccessAction | ICreateStaffFailureAction, errorType: 'EDIT_STAFF_FAILURE' | "CREATE_STAFF_FAILURE") => {
        if(data.type === errorType){
            // let error = this.props.locale.currentLocaleJSON[`ERROR_${data.payload.error}`]? this.props.locale.currentLocaleJSON[`ERROR_${data.payload.error}`]: this.props.locale.currentLocaleJSON[`ERROR_UnknownError`];
            // this.setState({displayErrorDialog: true, errorMessages: [error]});
            this.props.openErrorDialog(
                {
                    messages: getErrorsFromDictionary(this.props.locale.currentLocaleJSON, [data.payload.error])  
                }
            );
        } else {
            this.props.openSuccessDialog();
            this.props.getStaffList({page: this.state.page, q: {...this.state.filters}, sort: this.state.sort});
        
            this.setState({
                formDialogOpen: false,
                autocompleteFormFields: {
                    city: {
                        name: '',
                        region: ''
                    },
                    company: {
                        name: '',
                        id: ''
                    }
                },
                formFields: {
                    cityName: '',
                    drivingLicence: '',
                    drivingLicenceProvided: '',
                    fio: '',
                    id: '',
                    passport: '',
                    passportProvided: '',
                    phone: null,
                    phone2: null,
                    securityCompanyName: '',
                    staffType: 'DRIVER',
                    status: 'ACTIVE',
                    blockComment: ''
                }
            });
        }
        
    }

    handleEditRecord = () => {
        // let { id, ...restData } = this.state.formFields;
        let type: 'driver' | 'security' = this.state.activeTab === 'driver'? 'driver' : 'security';
        if(type === 'driver'){
            let { fio, phone, id, drivingLicence, drivingLicenceProvided, passport, passportProvided } = this.state.formFields;
            let { city, company } = this.state.autocompleteFormFields;
            this.props.editStaff({fio, phone, city, company, drivingLicence, drivingLicenceProvided, passport, passportProvided, id })
                .then(data => this.handlingStaffResult(data, 'EDIT_STAFF_FAILURE'));
        }
        if(type === 'security'){
            let { fio, phone, id, securityCompanyName, passport, passportProvided } = this.state.formFields;
            let { city, company } = this.state.autocompleteFormFields;
            this.props.editStaff({fio, phone, city, company, securityCompanyName, passport, passportProvided, id })
                .then(data => this.handlingStaffResult(data, 'EDIT_STAFF_FAILURE'));
        }
    }
                    
    // handleChangeField = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     // console.log(event.target, event.target.name, event.target.value);
    //     let name = event.target.name;
    //     let type = event.target.type
    //     let value = type === 'number'? +event.target.value : event.target.value;

    //         this.setState((prevState: IState) => 
    //             ({
    //                 ...prevState,
    //                 formFields:{
    //                     ...prevState.formFields,
    //                     [name]: value
    //                 }
                    
    //             })
    //         );
    // }

    handleCancel = () =>{
        // this.setState({formDialogOpen: false});
        this.props.handleCancelEditDialog.apply(this, [this.editIsValid()]);
    }

    isDisableEdit = () => {
        return this.props.user.userInfo.authorities[0] === 'ROLE_MANAGER_LOGIST';
    }

    additionIsValid = () => {
        let { status, id, ...restData } = this.state.formFields;
        

        return validateRequiredFields(this.props.staff.required? this.props.staff.required : [], {...restData, ...this.state.autocompleteFormFields });
    }

    editIsValid = ():boolean => {
        let { cityName, ...restData } = this.state.formFields;
        // let { id: idBefore, status: statusBefore, ...beforeUpdate } = this.state.beforeEdit? this.state.beforeEdit: {id: '', status: ''};
        let afterUpdate = { ...restData, ...this.state.autocompleteFormFields }
        // console.log('equal(',this.beforeEdit, afterUpdate,')')
        return equal(this.beforeEdit, afterUpdate);
    }

    // handleChangeCityField = (resultObject: ICityModel) => {
    //     // console.log(resultObject)
    //     this.setState((prevState: IState) => ({
    //         autocompleteFormFields:{
    //             ...prevState.autocompleteFormFields,
    //             city: {
    //                 name: resultObject.name,
    //                 region: resultObject.region
    //             }
    //         },
    //         formFields: {
    //             ...prevState.formFields,
    //             'cityName': `${resultObject.name} (${resultObject.region})`
    //         }
    //     }))
    // }

    render() {
        const { loc } = this.props;
        const { activeTab } = this.state;
        return (
            <>
                <PageTemplate
                    pageTitle={loc.StaffScreen_PageTitle} 
                    pageIcon={this.props.administration? 'vpn_key': 'people'}
                    print
                    tabs={
                        [
                            {
                                name: loc.StaffScreen_DriverTabTitle,
                                value: 'driver'
                            },
                            {
                                name: loc.StaffScreen_SecurityTabTitle,
                                value: 'security'
                            }
                        ]
                    }
                    onTab={this.handleTab}
                    tabCurrentValue={this.state.activeTab}
                    edit={true}
                    delete={this.props.user.userInfo.authorities[0] === 'ROLE_TC_MANAGER'}
                    tableHeaders={this.getHeaders()}
                    listData={this.props.staff.list}
                    dataModel={this.getDataModel()}
                    isLoading={this.props.staff.isFetching}
                    pageble={this.props.staff.pageble? this.props.staff.pageble : {
                        currentPage: 0,
                        totalElements: 0,
                        totalPages: 0
                    }}
                    onChangePage={this.changePageNumber}
                    onDelete={this.handleDeleteDialog}
                    onEdit={this.handleEdit}
                    onSearch={this.handleSearch}
                    onResetSearch={this.handleResetSearch}
                    onSort={this.handleSort}
                    actualSort={this.state.sort}
                >
                    {this.getFilterList()}
                </PageTemplate>
                {/* <Dialog
                    open={this.state.deleteDialogOpen}
                    onClose={this.handleCloseDeleteDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div className="dialog-window">
                        <p>{`${loc.StaffScreen_TryToDelete} ${this.state.deleteRow !== null && this.state.deleteRow.fio? this.state.deleteRow.fio: "" }`}</p>
                        <div className="btn-block">
                            <Button padding col={4} color={'primary'} onClick={() => {this.deleteRecord()}}>{loc.Button_Continue}</Button>
                            <Button padding col={4} onClick={() => {this.handleCloseDeleteDialog()}}>{loc.Button_Cancel}</Button>
                        </div>  
                        
                    </div>
                </Dialog> */}
                <Dialog
                    open={this.state.formDialogOpen}
                    onClose={this.handleCloseFormDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    scroll={'body'}
                >
                    
                    <div className="form-dialog-window">
                        <div className="form-dialog-header">
                            <p>{ activeTab === 'driver'? loc.StaffBlock_DriverTab_Title: loc.StaffBlock_SecurityTab_Title }</p>
                            <button onClick={() => {this.handleCloseFormDialog()}}><Icon>close</Icon></button>
                        </div>
                            <div className="object-form-wrapper">
                                {activeTab === 'driver' && <FormBlock hideBorder colLeft={4} colRight={8} title={loc.StaffBlock_DriverTab_FullnameField}>
                                    <InputField disabled={this.isDisableEdit()} required={true} padding={true} placeholder={loc.StaffBlock_DriverTab_FullnameField} hideLabel={true} label={loc.StaffBlock_DriverTab_FullnameField} type="text" onChange={this.handleChangeField} value={this.state.formFields.fio? this.state.formFields.fio: ""} name='fio'/>
                                </FormBlock>}

                                {activeTab === 'security' && <FormBlock hideBorder colLeft={4} colRight={8} title={loc.StaffBlock_SecurityTab_FullnameField}>
                                    <InputField disabled={this.isDisableEdit()} required={true} padding={true} placeholder={loc.StaffBlock_SecurityTab_FullnameField} hideLabel={true} label={loc.StaffBlock_SecurityTab_FullnameField} type="text" onChange={this.handleChangeField} value={this.state.formFields.fio? this.state.formFields.fio: ""} name='fio'/>
                                </FormBlock>}

                                {activeTab === 'driver' && <FormBlock  hideBorder colLeft={4} colRight={8} title={loc.StaffBlock_DriverTab_BaseCityField}>
                                    {this.isDisableEdit()?
                                    <InputField disabled={this.isDisableEdit()} padding={true} placeholder={loc.StaffBlock_DriverTab_BaseCityField} hideLabel={true} label={loc.StaffBlock_DriverTab_BaseCityField} type="text" onChange={this.handleChangeField} value={this.state.formFields.cityName? this.state.formFields.cityName: ''} name='cityName'/>
                                    :
                                    <InputField isValid={(this.state.autocompleteFormFields.city && this.state.autocompleteFormFields.city.name !== '' && this.state.autocompleteFormFields.city.region !== '')} disabled={this.isDisableEdit()} padding={true} placeholder={loc.StaffBlock_DriverTab_BaseCityField} hideLabel={true} label={loc.StaffBlock_DriverTab_BaseCityField} onSelect={this.handleChangeCityField} type="city" onChange={this.handleChangeField} value={this.state.formFields.cityName? this.state.formFields.cityName: ''} name='cityName'/>
                                    }
                                </FormBlock>}

                                {/* {activeTab === 'security' && <FormBlock  hideBorder colLeft={4} colRight={8} title={loc.StaffBlock_TrailerTab_MarkField}>
                                    <InputField disabled={this.isDisableEdit()} padding={true} placeholder={loc.StaffBlock_TrailerTab_MarkField} hideLabel={true} label={loc.StaffBlock_TrailerTab_MarkField} onSelect={this.handleChangeCityField} type="city" onChange={this.handleChangeField} value={this.state.formFields.cityName? this.state.formFields.cityName: ''} name='cityName'/>
                                </FormBlock>} */}

                                {activeTab === 'driver' && <FormBlock  hideBorder colLeft={4} colRight={8} title={loc.StaffBlock_DriverTab_PhoneField}>
                                    <InputField disabled={this.isDisableEdit()} padding={true} placeholder={loc.StaffBlock_DriverTab_PhoneField} hideLabel={true} label={loc.StaffBlock_DriverTab_PhoneField} type="phone" onChange={this.handleChangeField} value={this.state.formFields.phone? this.state.formFields.phone: ''} name='phone'/>
                                </FormBlock>}

                                {activeTab === 'security' && <FormBlock  hideBorder colLeft={4} colRight={8} title={loc.StaffBlock_SecurityTab_PhoneField}>
                                    <InputField disabled={this.isDisableEdit()} padding={true} placeholder={loc.StaffBlock_SecurityTab_PhoneField} hideLabel={true} label={loc.StaffBlock_SecurityTab_PhoneField} type="phone" onChange={this.handleChangeField} value={this.state.formFields.phone? this.state.formFields.phone: ''} name='phone'/>
                                </FormBlock>}


                                {activeTab === 'driver' &&<FormBlock  hideBorder colLeft={4} colRight={8} title={loc.StaffBlock_DriverTab_DriversLicenseField}>
                                    <InputField disabled={this.isDisableEdit()} col={4} padding={true} placeholder={loc.StaffBlock_DriverTab_DriversLicenseSeriaField} hideLabel={true} label={loc.StaffBlock_DriverTab_DriversLicenseSeriaField} type="text" onChange={this.handleChangeField} value={this.state.formFields.drivingLicence? this.state.formFields.drivingLicence: ''} name='drivingLicence'/>
                                    <InputField disabled={this.isDisableEdit()} col={8} padding={true} placeholder={loc.StaffBlock_DriverTab_DriversLicenseOutputField} hideLabel={true} label={loc.StaffBlock_DriverTab_DriversLicenseOutputField} type="text" onChange={this.handleChangeField} value={this.state.formFields.drivingLicenceProvided? this.state.formFields.drivingLicenceProvided: ''} name='drivingLicenceProvided'/>
                                </FormBlock>}

                                {activeTab === 'driver' &&<FormBlock  hideBorder colLeft={4} colRight={8} title={loc.StaffBlock_DriverTab_PassportDataField}>
                                    <InputField disabled={this.isDisableEdit()} col={4} padding={true} placeholder={loc.StaffBlock_DriverTab_PassportDataSeriaField} hideLabel={true} label={loc.StaffBlock_DriverTab_PassportDataSeriaField} type="text" onChange={this.handleChangeField} value={this.state.formFields.passport? this.state.formFields.passport: ''} name='passport'/>
                                    <InputField disabled={this.isDisableEdit()} col={8} padding={true} placeholder={loc.StaffBlock_DriverTab_PassportDataOutputField} hideLabel={true} label={loc.StaffBlock_DriverTab_PassportDataOutputField} type="text" onChange={this.handleChangeField} value={this.state.formFields.passportProvided? this.state.formFields.passportProvided: ''} name='passportProvided'/>
                                </FormBlock>}

                                {activeTab === 'security' && <FormBlock  hideBorder colLeft={4} colRight={8} title={loc.StaffBlock_SecurityTab_SecurityCompanyField}>
                                    <InputField disabled={this.isDisableEdit()} padding={true} placeholder={loc.StaffBlock_SecurityTab_SecurityCompanyField} hideLabel={true} label={loc.StaffBlock_SecurityTab_SecurityCompanyField} type="text" onChange={this.handleChangeField} value={this.state.formFields.securityCompanyName? this.state.formFields.securityCompanyName: ''} name='securityCompanyName'/>
                                </FormBlock>}

                                {activeTab === 'security' && <FormBlock  hideBorder colLeft={4} colRight={8} title={loc.StaffBlock_DriverTab_BaseCityField}>
                                    {this.isDisableEdit()?
                                        <InputField disabled={this.isDisableEdit()} padding={true} placeholder={loc.StaffBlock_DriverTab_BaseCityField} hideLabel={true} label={loc.StaffBlock_DriverTab_BaseCityField} type="text" onChange={this.handleChangeField} value={this.state.formFields.cityName? this.state.formFields.cityName: ''} name='cityName'/>
                                        :
                                        <InputField isValid={(this.state.autocompleteFormFields.city && this.state.autocompleteFormFields.city.name !== '' && this.state.autocompleteFormFields.city.region !== '')} disabled={this.isDisableEdit()} padding={true} placeholder={loc.StaffBlock_DriverTab_BaseCityField} hideLabel={true} label={loc.StaffBlock_DriverTab_BaseCityField} onSelect={this.handleChangeCityField} type="city" onChange={this.handleChangeField} value={this.state.formFields.cityName? this.state.formFields.cityName: ''} name='cityName'/>
                                    }
                                </FormBlock>}

                                {activeTab === 'security' &&<FormBlock  hideBorder colLeft={4} colRight={8} title={loc.StaffBlock_SecurityTab_PassportDataField}>
                                    <InputField disabled={this.isDisableEdit()} col={4} padding={true} placeholder={loc.StaffBlock_SecurityTab_PassportSeriaField} hideLabel={true} label={loc.StaffBlock_SecurityTab_PassportSeriaField} type="text" onChange={this.handleChangeField} value={this.state.formFields.passport? this.state.formFields.passport: ''} name='passport'/>
                                    <InputField disabled={this.isDisableEdit()} col={8} padding={true} placeholder={loc.StaffBlock_SecurityTab_PassportOutputField} hideLabel={true} label={loc.StaffBlock_SecurityTab_PassportOutputField} type="text" onChange={this.handleChangeField} value={this.state.formFields.passportProvided? this.state.formFields.passportProvided: ''} name='passportProvided'/>
                                </FormBlock>}


                                <RoleControl roles={['ROLE_MANAGER_LOGIST']}>
                                    <FormBlock  hideBorder colLeft={4} colRight={8} title={loc.StaffBlock_BlockCommentField}>
                                        <InputField hideLabel padding={true} placeholder={loc.StaffBlock_BlockCommentField} label={loc.StaffBlock_BlockCommentField} type="multiline" onChange={this.handleChangeField} value={this.state.formFields.blockComment? this.state.formFields.blockComment: ''} name='blockComment'/>
                                    </FormBlock>
                                </RoleControl>
                            <FormBlock hideBorder colLeft={4} colRight={8} title={""}>
                                
                                {
                                    (this.state.edit || this.state.create)?
                                        <>
                                            <Button onClick={this.handleCancel} padding col={6}>
                                                {loc.Button_Cancel}
                                            </Button>
                                            </>
                                    :
                                        null
                                }
                                {
                                    <RoleControl roles={['ROLE_MANAGER_LOGIST']}>
                                        <Button padding onClick={this.handleBlock} col={6} color={this.state.formFields.status === 'ACTIVE'? "red" : "green"}>
                                            {this.state.formFields.status === 'ACTIVE'? loc.Button_Blocked : loc.Button_Unblocked}
                                        </Button>
                                    </RoleControl>
                                }
                                <RoleControl roles={['ROLE_TC_MANAGER']}>
                                    {
                                        this.state.edit?
                                            <Button 
                                            col={6}
                                                disabled={
                                                    this.editIsValid() || !this.additionIsValid() 
                                                }
                                                onClick={this.handleEditRecord} 
                                                padding 
                                                color="primary"
                                            >
                                                {loc.Button_Save}
                                            </Button>
                                        :
                                            null
                                    }
                                </RoleControl>
                                <RoleControl roles={['ROLE_TC_MANAGER']}>
                                    {
                                        this.state.create?
                                            <Button 
                                                disabled={!this.additionIsValid() }
                                                onClick={this.handleCreateRecord} 
                                                padding 
                                                col={6} 
                                                color="primary"
                                            >
                                                {loc.Button_Add}
                                            </Button>
                                        :
                                            null
                                    }
                                </RoleControl>

                                </FormBlock>
                            </div>
                    </div>
                </Dialog>
                <RoleControl roles={['ROLE_TC_MANAGER']}>
                    <button onClick={() => {this.handleAdd()}} className="add-button">
                        <Icon>add</Icon>
                    </button>
                </RoleControl>
            </>
        )
    }
}

const mapStateToProps = (state: IAppState) => ({
    locale: state.locale,
    staff: state.staff,
    loc: state.locale.currentLocaleJSON,
    user: state.user
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
    // initLocales: () => dispatch(initLocales(config)),
    // getStaffInfo: () => dispatch(getStaffInfo())
    getStaffList: (params: ISearchParams) => dispatch(getStaffList(params)),
    deleteStaff: (id: string) => dispatch(deleteStaff(id)),
    createStaff: (data: IStaffCreateData) => dispatch(createStaff(data)),
    changeStaffStatus: (statusData: IStaffChangeStatusModel) => dispatch(changeStaffStatus(statusData)),
    editStaff: (data: IStaffEditModel) => dispatch(editStaff(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(withPageBase(StaffScreen));
