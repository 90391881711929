import React, { Component, ReactInstance } from 'react';
import { connect } from 'react-redux';
import { IAppState } from 'store';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import './PageTemplate.scss';
import DataTable from 'components/DataTable';
import { InputField } from 'components/InputField/InputField';
import { IGetCityListSuccessAction, IGetCityListFailureAction, ICityState } from 'store/city/types';
import { getStaffList } from 'store/staff/actions';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { getCityList } from 'store/city/actions';
// import AutocompleteCity from 'components/AutocompleteField/AutocompleteCity';
import { PageHeader } from 'components/PageHeader';
import { IPagable } from 'store/types';
import { EntityType, IFileState, IFileSendModel, IGetFileSuccessAction, IGetFileFailureAction, ISendFileSuccessAction, ISendFileFailureAction } from 'store/file/types';
import { file } from '@babel/types';
import { getFile, sendFile } from 'store/file/actions';
import { any } from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import { IOrderModel } from 'store/order/types';
// import ReactToPrint from 'react-to-print';
import jsPDF from 'jspdf';
import { IDoneDialogData, IErrorDialogData, IOpenDoneDialogAction, IOpenErrorDialogAction } from 'store/dialog/types';
import { openDoneDialog, openErrorDialog } from 'store/dialog/actions';
import { getErrorsFromDictionary } from 'services';
import { ILocaleState } from 'store/locale/types';

interface Props {
    file: IFileState;
    locale: ILocaleState;
    // city?: ICityState
    pageTitle: string;
    pageIcon: string;
    sendFile: (fileData: IFileSendModel) => Promise<ISendFileSuccessAction|ISendFileFailureAction>;
    getFile: (entity: EntityType) => Promise<IGetFileSuccessAction|IGetFileFailureAction>;
    openSuccessDialog: (data: IDoneDialogData) => Promise<IOpenDoneDialogAction>;
    openErrorDialog: (data: IErrorDialogData) => Promise<IOpenErrorDialogAction>;
    // getCityList?: (name: string) => Promise<IGetCityListSuccessAction|IGetCityListFailureAction>;
    isLoading: boolean;
    listData: any[];
    tableHeaders: string[];
    dataModel: string[];
    view?: boolean;
    check?: boolean;
    onCheck?: (orders: IOrderModel[]) => void;
    viewTriggerKey?: string;
    edit?: boolean;
    delete?: boolean;
    pageble: IPagable;
    onChangePage: (page: number) => void;
    onDelete?: (row: any) => void;
    onEdit?: (row: any) => void;
    onView?: (row: any) => void;
    typesDictionary?: any;
    linksDictionary?: {[key: string]: string};
    boldFromKey?: string;
    onSearch?: () => void;
    onResetSearch?: () => void;
    onSort: (sortString: string) => void;
    actualSort: string;
    tabs?: {name: string, value: string}[];
    tabCurrentValue?: string;
    onTab?: (tabName: string) => void;
    entity?: EntityType;
    import?: boolean;
    export?: boolean;
    print?: boolean;
    warningFilter?: boolean;
    warningSearch?: () => void;
    isFetching?: boolean;
    insertBottom?: () => JSX.Element;
    checkedAll?: boolean;
    children?: any;
}
interface State {
    
}

export class PageTemplate extends Component<Props, State> {
    
    private componentRef = React.createRef<Component>()

    state = {
        field: "",
        auto: ""
    }

    handleSearch = () => {
        if(this.props.onSearch) this.props.onSearch();
    }
    handleResetSearch = () => {
        if(this.props.onResetSearch) this.props.onResetSearch();
    }

    handleWarningSearch = () => {
        if(this.props.warningSearch) this.props.warningSearch();
    }

    handleTab = (tabName: string) => {
        if(this.props.onTab) this.props.onTab(tabName);
    }

    handlePrint = () => {
        let sTable: any = document.getElementById('table-to-print')

        let createbleTable = document.createElement('table');
        createbleTable.innerHTML = sTable.innerHTML;
        createbleTable.querySelectorAll('.MuiTableCell-alignRight').forEach((el) => { el.remove() });
        createbleTable.querySelectorAll('.material-icons').forEach((el) => { el.remove() });
        createbleTable.querySelectorAll('.MuiTableFooter-root').forEach((el) => { el.remove() });
        createbleTable.querySelectorAll('thead>tr>th:last-child').forEach((el) => { el.remove() });
        
        let baseStyles = '';
        document.head.querySelectorAll('style').forEach(el => {baseStyles += el.innerHTML});
        let ownStyles = `
            .table-header-item {
                padding-right: 0px;
            }
            table{
                background: #fff;
            }
        `;
        baseStyles += ownStyles;
        
        // console.log('sTable.innerHTML',sTable.innerHTML, baseStyles)
        
        const resultToPrint = createbleTable.innerHTML;
        let pri: any = document.getElementById("ifmcontentstoprint");//.contentWindow;
        //pri.src = 'data:text/html;charset=utf-8,' + content.innerHTML;//'javascript:"<table><tr><td>dushyanth</td></tr></table>"';//`'javascript:"${content.innerHTML}"'`;
        let cw = pri.contentWindow;
        cw.document.open();
        cw.document.write('<html><head>');
        cw.document.write('<style>'+baseStyles+'</style>');
        // cw.document.write(`<link type="text/css" rel="Stylesheet" href="Style/simple.css" />`);
        
        cw.document.write('</head><body><table>'+resultToPrint+'</table></body></html>');
        cw.document.close();
        cw.focus();
        cw.print();

        
    }

    handleExport = () => {
        if(this.props.entity){
            this.props.getFile(this.props.entity);
        }
    }

    handleImport = (fileList: FileList) => {
        // console.log('fileList', fileList)
        if(this.props.entity && fileList.length !== 0) {
            this.props.sendFile({entity: this.props.entity, file: fileList[0]})
                .then(dataImport => {
                    if(dataImport.type === 'SEND_FILE_SUCCESS'){
                        this.props.openSuccessDialog({});
                        this.handleSearch();
                    } else {
                        this.props.openErrorDialog(
                            {
                                messages: getErrorsFromDictionary(this.props.locale.currentLocaleJSON, [dataImport.payload.error])  
                            }
                        );
                    }
                })
        }
    }

    handleEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
        // if (event.charCode == 13) {
        //     alert('Enter... (KeyPress, use charCode)');
        //   }
        if (event.keyCode == 13 || event.charCode == 13) {
            this.handleSearch()
            //alert('Enter... (KeyDown, use keyCode)');
        }
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.props.print?
                        <iframe id="ifmcontentstoprint" style={{height: '0', width: '0', position: 'absolute'}}>
                            {/* <table><div>defewfew</div></table> */}
                        </iframe>
                    :
                        null
                }
                
                    <div className="page-template">
                        <PageHeader pageIcon={this.props.pageIcon} pageTitle={this.props.pageTitle} import={this.props.import} importAction={this.handleImport} exportAction={this.handleExport} printAction={this.handlePrint} export={this.props.export} print={this.props.print}/>
                        {
                            this.props.tabs && this.props.tabs.length && this.props.tabCurrentValue?
                                <Grid container>
                                    <Grid item xs={12}>
                                        <div className="tab-wrapper">
                                            {
                                                this.props.tabs.map((point, number) => (
                                                    <div key={`${point.value}_${number}`} onClick={() => {this.handleTab(point.value)}} className={`tab-point${this.props.tabCurrentValue === point.value? ' active' : ''}`}>
                                                        {point.name}
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </Grid>
                                </Grid>
                            :
                                null
                        }
                        {/* {
                            this.componentRef.current?
                                <ReactToPrint
                                    trigger={() => <a href="#">Print this out!</a>}
                                    content={() => this.componentRef.current}
                                />
                            :
                            null
                        } */}
                        
                        <Grid onKeyPress={this.handleEnter} container className="filter-block">
                            <Grid item xs={10} >
                            <Grid container>
                                {this.props.children}
                                {/* <InputField autocompleteListFields={{key: "name", value: "region"}} autocompeleteFunction={this.props.getCityList} type="autocomplete" onChange={(event) =>{this.setState({auto: event.target.value}) }} name="auto" value={this.state.auto} label="auto" /> */}
                                {/* <AutocompleteCity  onChange={(event) =>{this.setState({auto: event.target.value}) }} name="auto" value={this.state.auto} label="auto" /> */}
                                {/* <InputField type="select" onChange={(event) =>{this.setState({field: event.target.value}) }} name="field" value={this.state.field} label="name" 
                                    options={[
                                            {
                                            value: '0-20',
                                            label: '0 to 20',
                                            },
                                            {
                                            value: '21-50',
                                            label: '21 to 50',
                                            },
                                            {
                                            value: '51-100',
                                            label: '51 to 100',
                                            },
                                        ]}/>
                                <InputField type="datepicker" changeDate={(date: Date | null, value?: string | null | undefined) =>{this.setState({field: date}) }} name="field" value={this.state.field} label="name" /> */}
                                {/* 
                                    [
                                        {
                                            type: autocomplete | select | datepicker | number
                                            label: string
                                            name: string
                                        }
                                    ]
                                */}
                                </Grid>
                            </Grid>
                            <Grid className='content-right' container justify="flex-end" alignItems="center" item xs={2}>
                                <IconButton onClick={this.handleSearch} aria-label="search" className="grey-btn-full">
                                    <Icon>search</Icon>
                                </IconButton>
                                <IconButton onClick={this.handleResetSearch} aria-label="reset" className="grey-btn-full">
                                    <Icon>refresh</Icon>
                                </IconButton>
                                {
                                    this.props.warningFilter?
                                        <IconButton onClick={this.handleWarningSearch} aria-label="report_problem" className="orange-btn-full">
                                            <Icon>report_problem</Icon>
                                        </IconButton>
                                    :
                                        null
                                }
                                
                            </Grid>
                        </Grid>
                        <Grid style={{padding: '0 30px'}} container >
                {
                    !this.props.isFetching?
                    // false?

                        
                            <DataTable 
                                // ref={ (el) => this.componentRef = el}
                                view={this.props.view}
                                viewTriggerKey={this.props.viewTriggerKey}
                                check={this.props.check}
                                onCheck={this.props.onCheck}
                                edit={this.props.edit}
                                delete={this.props.delete}
                                tableHeaders={this.props.tableHeaders}
                                dataModel={this.props.dataModel}
                                listData={this.props.listData}
                                pageble={this.props.pageble}
                                onChangePage={this.props.onChangePage}
                                onDelete={this.props.onDelete}
                                onEdit={this.props.onEdit}
                                onView={this.props.onView}
                                typesDictionary={this.props.typesDictionary}
                                linksDictionary={this.props.linksDictionary}
                                boldFromKey={this.props.boldFromKey}
                                onSort={this.props.onSort}
                                actualSort={this.props.actualSort}
                                checkedAll={this.props.checkedAll}
                                // tableHeaders={['Перевозчик', 'Тип тарифа', 'Регион загрузки', 'Тоннаж, тн.', 'Охрана', 'Дата начала', 'Номер документа', 'Тариф, грн. без НДС', 'В обе стороны', '', '', '']}
                                // dataModel={['transfer', 'rateType', 'region', 'weight', 'security', 'date', 'documentNumber', 'rateNDS', 'bothSide', 'view', 'edit', 'delete']}
                                // listData={
                                //     [
                                //         {
                                //             'transfer': 'Надежда', 
                                //             'rateType': 'Pochasovoi', 
                                //             'region': 'Odessa',
                                //             'weight': '10', 
                                //             'security': 'Yes',
                                //             'date': '10.09.2019',
                                //             'documentNumber': '666',
                                //             'rateNDS': '66.66',
                                //             'bothSide': 'Yes',
                                //             'parametr': '1',
                                //             'param': '2'
                                //         }
                                //     ]
                                // }
                            />
                        
                        :
                    <div className="spinner">
                        <CircularProgress />
                    </div> 
                }
                        </Grid>
                        {
                            this.props.insertBottom?
                                this.props.insertBottom()
                            :
                                null
                        }
                    </div>
                
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state: IAppState) => ({
    // city: state.city,
    locale: state.locale,
    file: state.file
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ( {
    // getCityList: (partName: string) => dispatch(getCityList(partName))
    getFile: (entity: EntityType) => dispatch(getFile(entity)),
    sendFile: (fileData: IFileSendModel) => dispatch(sendFile(fileData)),
    openSuccessDialog: (data: IDoneDialogData) => dispatch(openDoneDialog(data)),
    openErrorDialog: (data: IErrorDialogData) => dispatch(openErrorDialog(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PageTemplate)
