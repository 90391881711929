import React from "react";
import { ICompanyModel } from "store/company/types";
import { IAppState } from "store";
import { connect } from 'react-redux'
import { ThunkDispatch } from "redux-thunk";
import { AnyAction, compose } from "redux";
import { openDoneDialog, openErrorDialog, openWarningDialog } from "store/dialog/actions";
import { IDoneDialogData, IOpenDoneDialogAction, IDialogState, IOpenErrorDialogAction, IErrorDialogData, IWarningDialogData, IOpenWarningDialogAction } from "store/dialog/types";
import { ILocaleState, IDictionary } from "store/locale/types";
import { ICityModel } from "store/city/types";
import moment from "moment";
import { IUserModel } from "store/user/types";
// import { Optionalize } from "@types/utils";

export interface IWithPageBase {
    handleChangeFilter: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleChangeCompanyFilter: (resultObject: ICompanyModel) => void;
    handleChangeCityFilter: (resultObject: ICityModel, name?: string) => void;
    handleChangeLogistFilter: (resultObject: IUserModel) => void;
    handleChangePersonFilter: (resultObject: IUserModel, name?: string) => void;
    handleChangeDateFilter: (name: string, value: string | moment.Moment) => void;
    handleChangeField: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleChangeCompany: (resultObject: ICompanyModel) => void;
    handleChangeCity: (resultObject: ICityModel) => void;
    handleDeleteItem: (text: string, callback: () => void) => void;
    openErrorDialog: (data: IErrorDialogData) => void;
    openWarningDialog: (data: IWarningDialogData) => void;
    openSuccessDialog: () => void;
    handleCancelEdit: (isEdited: boolean) => void;
    handleCancelEditDialog: (isEdited: boolean) => void;
    handleChangeFieldInForm: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleChangeCompanyField: (resultObject: ICompanyModel) => void;
    handleChangeLogistField: (resultObject: IUserModel) => void;
    handleChangeAdminField: (resultObject: IUserModel) => void;
    handleChangeAdmin2Field: (resultObject: IUserModel) => void;
    handleChangeCityField: (resultObject: ICityModel) => void;
}

export interface IWithPersonalizationProps {
    locale: ILocaleState;
    loc: IDictionary;
    dialog: IDialogState;
    history: any;
    openSuccessDialog: (data: IDoneDialogData) => Promise<IOpenDoneDialogAction>;
    openErrorDialog: (data: IErrorDialogData) => Promise<IOpenErrorDialogAction>;
    openWarningDialog: (data: IWarningDialogData) => Promise<IOpenWarningDialogAction>;
    
}

type HOC<PWrapped, PHoc> = React.ComponentClass<PWrapped & PHoc> | React.SFC<PWrapped & PHoc>;

//  let returnWithPageBase: any;

export function returnWithPageBase<P, S>(Component: React.ComponentClass<P>): React.ComponentClass<P & IWithPersonalizationProps> {
    const displayName = Component.displayName || Component.name || "Component";
    
    return class WithPageBase extends React.Component<P & IWithPersonalizationProps> {
        
        public static displayName = `withPageBase(${displayName})`;


        //Change form values

        handleChangeField(event: React.ChangeEvent<HTMLInputElement>){
            // console.log(event.target, event.target.name, event.target.value);
            let name = event.target.name;
            let type = event.target.type
            let value = type === 'number'? +event.target.value : event.target.value;
    
            this.setState((prevState: any) => 
                ({
                    [name]: value
                })
            );
        }
    
        
        handleChangeCompany(resultObject: ICompanyModel) {
            // console.log('resultObject',resultObject)
            this.setState((prevState: any) => ({
                company: {
                    name: resultObject.shortName,
                    id: resultObject.id
                    // ...resultObject
                },
                companyName: resultObject.shortName
            }))
        }
    
        handleChangeCity(resultObject: ICityModel) {
            // console.log(resultObject)
            this.setState((prevState: any) => ({
                city: {
                    name: resultObject.name,
                    region: resultObject.region
                    // ...resultObject
                },
                cityName: `${resultObject.name} (${resultObject.region})`
            }))
        }
        
        //Change value of filters

        handleChangeFilter(event: React.ChangeEvent<HTMLInputElement>) {
            const Event = event;
            // console.log('Event',Event.target)
            const field = Event.target.name//.split('|');
            const value = Event.target.type == 'number' ? +Event.target.value : Event.target.value;
            // console.log('filter value',value)
            this.setState((prevState: any) => ({
                filters: {
                    ...prevState.filters,
                    [field]: value
                }
            }))
        }


        handleChangeCompanyFilter(resultObject: ICompanyModel) {
            this.setState((prevState: any) => ({
                filters: {
                    ...prevState.filters,
                    'company.name|full': resultObject.shortName
                }
            }))
        }


        handleChangeCityFilter(resultObject: ICityModel, name?: string) {
            let fieldName = name? name : 'city.name|full';
            this.setState((prevState: any) => ({
                filters: {
                    ...prevState.filters,
                    [fieldName]: resultObject.name
                }
            }))
        }

        ///default realisition for notifications page
        handleChangePersonFilter(resultObject: IUserModel, name?: string) {
            // console.log(resultObject)
            let personFieldName = name? name : 'sender|full';
            this.setState((prevState: any) => ({
                filters: {
                    ...prevState.filters,
                    [personFieldName]: resultObject.fio
                }
            }))
        }

        handleChangeDateFilter(name: string, value: string | moment.Moment) {

            this.setState((prevState: any) => 
                ({
                    filters: {
                        ...prevState.filters,
                        [name]: value.valueOf(),
                    }
                })
            );
    
        }

        handleChangeLogistFilter(resultObject: IUserModel) {
            // console.log('---------',resultObject)
            this.setState((prevState: any) => ({
                filters: {
                    ...prevState.filters,
                    'logist.fio|full': resultObject.fio
                }
            }))
        }

        //Change value in formFields with autocomplete 

        handleChangeFieldInForm(event: React.ChangeEvent<HTMLInputElement>) {
            // console.log(event.target, event.target.name, event.target.value);
            let name = event.target.name;
            let type = event.target.type
            let value = type === 'number'? +event.target.value : event.target.value;
    
                this.setState((prevState: any) => 
                    ({
                        formFields:{
                            ...prevState.formFields,
                            [name]: value
                        }
                        
                    })
                );
        }

        handleChangeCompanyField(resultObject: ICompanyModel) {
            // console.log(resultObject)
            this.setState((prevState: any) => ({
                autocompleteFormFields:{
                    ...prevState.autocompleteFormFields,
                    company: {
                        name: resultObject.shortName,
                        id: resultObject.id
                    }
                },
                formFields: {
                    ...prevState.formFields,
                    'companyName': resultObject.shortName
                }
            }))
        }
    
        handleChangeLogistField(resultObject: IUserModel) {
            // console.log(resultObject)
            this.setState((prevState: any) => ({
                autocompleteFormFields:{
                    ...prevState.autocompleteFormFields,
                    logist: {
                        fio: resultObject.fio,
                        id: resultObject.id,
                        phone: resultObject.workPhone
                    }
                },
                formFields: {
                    ...prevState.formFields,
                    'logistFio': resultObject.fio,
                    'logistPhone': resultObject.workPhone
                }
            }))
        }
    
        handleChangeAdminField(resultObject: IUserModel) {
            // console.log(resultObject)
            this.setState((prevState: any) => ({
                autocompleteFormFields:{
                    ...prevState.autocompleteFormFields,
                    admin: {
                        fio: resultObject.fio,
                        id: resultObject.id,
                        phone: resultObject.workPhone
                    }
                },
                formFields: {
                    ...prevState.formFields,
                    'adminFio': resultObject.fio,
                    'adminPhone': resultObject.workPhone
                }
            }))
        }
    
        handleChangeAdmin2Field(resultObject: IUserModel) {
            // console.log(resultObject)
            this.setState((prevState: any) => ({
                autocompleteFormFields:{
                    ...prevState.autocompleteFormFields,
                    admin2: {
                        fio: resultObject.fio,
                        id: resultObject.id,
                        phone: resultObject.workPhone
                    }
                },
                formFields: {
                    ...prevState.formFields,
                    'admin2Fio': resultObject.fio,
                    'admin2Phone': resultObject.workPhone
                }
            }))
        }
    
        handleChangeCityField(resultObject: ICityModel) {
            // console.log(resultObject)
            this.setState((prevState: any) => ({
                autocompleteFormFields:{
                    ...prevState.autocompleteFormFields,
                    city: {
                        name: resultObject.name,
                        region: resultObject.region
                    }
                },
                formFields: {
                    ...prevState.formFields,
                    'cityName': `${resultObject.name} (${resultObject.region})`
                }
            }))
        }


        //Change date

        handleChangeDate(name: string, value: string | moment.Moment) {
            this.setState(
                {
                    [name]: value.valueOf()
                }
            );
        }

        openSuccessDialog = () => {
            this.props.openSuccessDialog({});
        }

        openErrorDialog = (data: IErrorDialogData) => {
            this.props.openErrorDialog(data);
        }

        openWarningDialog = (data: IErrorDialogData) => {
            this.props.openErrorDialog(data);
        }

        handleDeleteItem = (text: string, callback: () => void) => {
            this.props.openWarningDialog({
                callback,
                text,
                isDelete: true
            });
        }

        handleCancelEdit = (isEdited: boolean) => {
            if(!isEdited){
                this.props.openWarningDialog({
                    callback: () => {
                        this.props.history.goBack();
                    }
                });
                // this.setState({displayWarningDialog: true});
            } else {
                this.props.history.goBack();
            }
            
        }

        handleCancelEditDialog(isEdited: boolean) {
            if(!isEdited){
                this.props.openWarningDialog({
                    callback: () => {
                        this.setState({formDialogOpen: false});
                    }
                });
                // this.setState({displayWarningDialog: true});
            } else {
                this.setState({formDialogOpen: false});
            }
            
        }

        public render(): JSX.Element {
            // console.log(this.props);

            const { ...rest } = this.props as P & IWithPersonalizationProps;

            return (
            <Component
                handleChangeField={this.handleChangeField}
                handleChangeCompany={this.handleChangeCompany}
                handleChangeCity={this.handleChangeCity}
                openSuccessDialog={this.openSuccessDialog}
                handleChangeCompanyFilter={this.handleChangeCompanyFilter}
                handleChangeFilter={this.handleChangeFilter}
                handleChangeLogistFilter={this.handleChangeLogistFilter}
                handleChangeCityFilter={this.handleChangeCityFilter}
                handleChangePersonFilter={this.handleChangePersonFilter}
                handleChangeDateFilter={this.handleChangeDateFilter}

                openErrorDialog={this.openErrorDialog}
                handleCancelEdit={this.handleCancelEdit}
                handleCancelEditDialog={this.handleCancelEditDialog}
                handleDeleteItem={this.handleDeleteItem}
                handleChangeFieldInForm={this.handleChangeFieldInForm}
                handleChangeCompanyField={this.handleChangeCompanyField}
                handleChangeLogistField={this.handleChangeLogistField}
                handleChangeAdminField={this.handleChangeAdminField}
                handleChangeAdmin2Field={this.handleChangeAdmin2Field}
                handleChangeCityField={this.handleChangeCityField}
                {...rest} />
            );
        }
    }

}

// export function withPageBase<P, S>(Component: HOC<P, IWithPersonalizationProps>): React.ComponentClass<P & IWithPersonalizationProps> {
export function withPageBase<P, S>(Component: React.ComponentClass<P>): React.ComponentClass<P & IWithPersonalizationProps> {

  // class WithPageBase extends React.Component<P & IWithPersonalizationProps> {
    function rreturnWithPageBase<P, S>(Component: React.ComponentClass<P>): React.ComponentClass<P & IWithPersonalizationProps> {
        const displayName = Component.displayName || Component.name || "Component";
        
        return class WithPageBase extends React.Component<P & IWithPersonalizationProps> {
            
            public static displayName = `withPageBase(${displayName})`;


            //Change form values

            handleChangeField(event: React.ChangeEvent<HTMLInputElement>){
                // console.log(event.target, event.target.name, event.target.value);
                let name = event.target.name;
                let type = event.target.type
                let value = type === 'number'? +event.target.value : event.target.value;
        
                this.setState((prevState: any) => 
                    ({
                        [name]: value
                    })
                );
            }
        
            
            handleChangeCompany(resultObject: ICompanyModel) {
                // console.log('resultObject',resultObject)
                this.setState((prevState: any) => ({
                    company: {
                        name: resultObject.shortName,
                        id: resultObject.id
                        // ...resultObject
                    },
                    companyName: resultObject.shortName
                }))
            }
        
            handleChangeCity(resultObject: ICityModel) {
                // console.log(resultObject)
                this.setState((prevState: any) => ({
                    city: {
                        name: resultObject.name,
                        region: resultObject.region
                        // ...resultObject
                    },
                    cityName: `${resultObject.name} (${resultObject.region})`
                }))
            }
            
            //Change value of filters

            handleChangeFilter(event: React.ChangeEvent<HTMLInputElement>) {
                const Event = event;
                // console.log('Event',Event.target)
                const field = Event.target.name//.split('|');
                const value = Event.target.type == 'number' ? +Event.target.value : Event.target.value;
                // console.log('filter value',value)
                this.setState((prevState: any) => ({
                    filters: {
                        ...prevState.filters,
                        [field]: value
                    }
                }))
            }


            handleChangeCompanyFilter(resultObject: ICompanyModel) {
                this.setState((prevState: any) => ({
                    filters: {
                        ...prevState.filters,
                        'company.name|full': resultObject.shortName
                    }
                }))
            }


            handleChangeCityFilter(resultObject: ICityModel, name?: string) {
                let fieldName = name? name : 'city.name|full';
                this.setState((prevState: any) => ({
                    filters: {
                        ...prevState.filters,
                        [fieldName]: resultObject.name
                    }
                }))
            }

            ///default realisition for notifications page
            handleChangePersonFilter(resultObject: IUserModel, name?: string) {
                // console.log(resultObject)
                let personFieldName = name? name : 'sender|full';
                this.setState((prevState: any) => ({
                    filters: {
                        ...prevState.filters,
                        [personFieldName]: resultObject.fio
                    }
                }))
            }

            handleChangeDateFilter(name: string, value: string | moment.Moment) {

                this.setState((prevState: any) => 
                    ({
                        filters: {
                            ...prevState.filters,
                            [name]: value.valueOf(),
                        }
                    })
                );
        
            }

            handleChangeLogistFilter(resultObject: IUserModel) {
                // console.log('---------',resultObject)
                this.setState((prevState: any) => ({
                    filters: {
                        ...prevState.filters,
                        'logist.fio|full': resultObject.fio
                    }
                }))
            }

            //Change value in formFields with autocomplete 

            handleChangeFieldInForm(event: React.ChangeEvent<HTMLInputElement>) {
                // console.log(event.target, event.target.name, event.target.value);
                let name = event.target.name;
                let type = event.target.type
                let value = type === 'number'? +event.target.value : event.target.value;
        
                    this.setState((prevState: any) => 
                        ({
                            formFields:{
                                ...prevState.formFields,
                                [name]: value
                            }
                            
                        })
                    );
            }

            handleChangeCompanyField(resultObject: ICompanyModel) {
                // console.log(resultObject)
                this.setState((prevState: any) => ({
                    autocompleteFormFields:{
                        ...prevState.autocompleteFormFields,
                        company: {
                            name: resultObject.shortName,
                            id: resultObject.id
                        }
                    },
                    formFields: {
                        ...prevState.formFields,
                        'companyName': resultObject.shortName
                    }
                }))
            }
        
            handleChangeLogistField(resultObject: IUserModel) {
                // console.log(resultObject)
                this.setState((prevState: any) => ({
                    autocompleteFormFields:{
                        ...prevState.autocompleteFormFields,
                        logist: {
                            fio: resultObject.fio,
                            id: resultObject.id,
                            phone: resultObject.workPhone
                        }
                    },
                    formFields: {
                        ...prevState.formFields,
                        'logistFio': resultObject.fio,
                        'logistPhone': resultObject.workPhone
                    }
                }))
            }
        
            handleChangeAdminField(resultObject: IUserModel) {
                // console.log(resultObject)
                this.setState((prevState: any) => ({
                    autocompleteFormFields:{
                        ...prevState.autocompleteFormFields,
                        admin: {
                            fio: resultObject.fio,
                            id: resultObject.id,
                            phone: resultObject.workPhone
                        }
                    },
                    formFields: {
                        ...prevState.formFields,
                        'adminFio': resultObject.fio,
                        'adminPhone': resultObject.workPhone
                    }
                }))
            }
        
            handleChangeAdmin2Field(resultObject: IUserModel) {
                // console.log(resultObject)
                this.setState((prevState: any) => ({
                    autocompleteFormFields:{
                        ...prevState.autocompleteFormFields,
                        admin2: {
                            fio: resultObject.fio,
                            id: resultObject.id,
                            phone: resultObject.workPhone
                        }
                    },
                    formFields: {
                        ...prevState.formFields,
                        'admin2Fio': resultObject.fio,
                        'admin2Phone': resultObject.workPhone
                    }
                }))
            }
        
            handleChangeCityField(resultObject: ICityModel) {
                // console.log(resultObject)
                this.setState((prevState: any) => ({
                    autocompleteFormFields:{
                        ...prevState.autocompleteFormFields,
                        city: {
                            name: resultObject.name,
                            region: resultObject.region
                        }
                    },
                    formFields: {
                        ...prevState.formFields,
                        'cityName': `${resultObject.name} (${resultObject.region})`
                    }
                }))
            }


            //Change date

            handleChangeDate(name: string, value: string | moment.Moment) {
                this.setState(
                    {
                        [name]: value.valueOf()
                    }
                );
            }

            openSuccessDialog = () => {
                this.props.openSuccessDialog({});
            }

            openErrorDialog = (data: IErrorDialogData) => {
                this.props.openErrorDialog(data);
            }

            openWarningDialog = (data: IErrorDialogData) => {
                this.props.openErrorDialog(data);
            }

            handleDeleteItem = (text: string, callback: () => void) => {
                this.props.openWarningDialog({
                    callback,
                    text,
                    isDelete: true
                });
            }

            handleCancelEdit = (isEdited: boolean) => {
                if(!isEdited){
                    this.props.openWarningDialog({
                        callback: () => {
                            this.props.history.goBack();
                        }
                    });
                    // this.setState({displayWarningDialog: true});
                } else {
                    this.props.history.goBack();
                }
                
            }

            handleCancelEditDialog(isEdited: boolean) {
                if(!isEdited){
                    this.props.openWarningDialog({
                        callback: () => {
                            this.setState({formDialogOpen: false});
                        }
                    });
                    // this.setState({displayWarningDialog: true});
                } else {
                    this.setState({formDialogOpen: false});
                }
                
            }

            public render(): JSX.Element {
                // console.log(this.props);

                const { ...rest } = this.props as P & IWithPersonalizationProps;

                return (
                <Component
                    handleChangeField={this.handleChangeField}
                    handleChangeCompany={this.handleChangeCompany}
                    handleChangeCity={this.handleChangeCity}
                    openSuccessDialog={this.openSuccessDialog}
                    handleChangeCompanyFilter={this.handleChangeCompanyFilter}
                    handleChangeFilter={this.handleChangeFilter}
                    handleChangeLogistFilter={this.handleChangeLogistFilter}
                    handleChangeCityFilter={this.handleChangeCityFilter}
                    handleChangePersonFilter={this.handleChangePersonFilter}
                    handleChangeDateFilter={this.handleChangeDateFilter}

                    openErrorDialog={this.openErrorDialog}
                    handleCancelEdit={this.handleCancelEdit}
                    handleCancelEditDialog={this.handleCancelEditDialog}
                    handleDeleteItem={this.handleDeleteItem}
                    handleChangeFieldInForm={this.handleChangeFieldInForm}
                    handleChangeCompanyField={this.handleChangeCompanyField}
                    handleChangeLogistField={this.handleChangeLogistField}
                    handleChangeAdminField={this.handleChangeAdminField}
                    handleChangeAdmin2Field={this.handleChangeAdmin2Field}
                    handleChangeCityField={this.handleChangeCityField}
                    {...rest} />
                );
            }
        }
    
    }

    //withPageBase.returnWithPageBase = returnWithPageBase;

    const mapStateToProps = (state: IAppState) => ({
        locale: state.locale,
        loc: state.locale.currentLocaleJSON,
        dialog: state.dialog
    });

    const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
        openSuccessDialog: (data: IDoneDialogData) => dispatch(openDoneDialog(data)),
        openErrorDialog: (data: IErrorDialogData) => dispatch(openErrorDialog(data)),
        openWarningDialog: (data: IWarningDialogData) => dispatch(openWarningDialog(data))
    });

  // console.log(Component)

    return compose<React.ComponentClass<P & IWithPersonalizationProps>>(
        connect(mapStateToProps, mapDispatchToProps),
        returnWithPageBase
    )(Component);

};