
import React, { Component } from 'react'
import { connect } from 'react-redux'

import Icon from '@material-ui/core/Icon';
import { IAppState } from 'store';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
// import Button from '@material-ui/core/Button';
import { loginUser, editUserPassword, createUserPassword } from 'store/user/actions';
import { ILoginData, IUserState, ILoginUserSuccessAction, ILoginUserFailureAction, IChangeUserPasswordModel, IEditUserPasswordSuccessAction, IEditUserPasswordFailureAction, ICreateUserPasswordSuccessAction, ICreateUserPasswordFailureAction, ICreateUserPasswordModel } from 'store/user/types';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { App } from 'App';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import InputField from 'components/InputField/InputField';
import { ILocaleState } from 'store/locale/types';
import { Card } from 'components/Card/Card';

import './CreatePasswordCard.scss';
import { Link, match } from 'react-router-dom';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { passwordStrengthCheck, parseJwt } from 'services';
import { Button } from 'components/Button/Button';
import { ErrorMessage } from 'components/ErrorMessage/ErrorMessage';
import { IDoneDialogData, IOpenDoneDialogAction } from 'store/dialog/types';
import { openDoneDialog } from 'store/dialog/actions';



interface IProps {
    loginUserHandle: (email: string, password: string) => Promise<ILoginUserSuccessAction | ILoginUserFailureAction>;
    createUserPassword: (passwordData: ICreateUserPasswordModel) => Promise<ICreateUserPasswordSuccessAction | ICreateUserPasswordFailureAction>;
    user: IUserState;
    locale: ILocaleState;
    token: string;
    history?: any;
    openSuccessDialog: (data: IDoneDialogData) => Promise<IOpenDoneDialogAction>;
}
interface IState {
    newPassword: string;
    repeatNewPassword: string;
    errorMsg: string;
}


class CreatePasswordCard extends Component<IProps> {
    state: IState = {
        newPassword: '',
        repeatNewPassword: '',
        errorMsg: ''
    }


    handleChangeFieldS = (event: React.ChangeEvent<HTMLInputElement>) => {
        // console.log(event.target.name, event.target.value);
        this.setState(
            {
                [event.target.name]: event.target.value
            } //as Pick<State, keyof State>
        );
    }

    // handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     console.log(event.target.name, event.target.value);
    //     this.setState(
    //         {
    //             [event.target.name]: event.target.checked
    //         } //as Pick<State, keyof State>
    //     );
    // }

    handleChangePassword = () => {
        const { newPassword } = this.state;
        if(this.validToChange()){
            this.props.createUserPassword({newPassword, token: this.props.token})
                .then((data) => {
                    if(data.type === 'CREATE_USER_PASSWORD_FAILURE'){
                        this.setState({
                            errorMsg: this.props.locale.currentLocaleJSON[`ERROR_${data.payload.error}`]
                        })
                        
                    } else if(data.type === 'CREATE_USER_PASSWORD_SUCCESS') {
                        if(this.props.history){
                            this.props.openSuccessDialog({});
                            this.props.history.push('/')
                        }
                    }
                });
        }   
    }

    validToChange = () => {
        const { newPassword, repeatNewPassword } = this.state;
        return newPassword.length >= 9 && repeatNewPassword.length >= 9 && newPassword === repeatNewPassword && passwordStrengthCheck(this.state.newPassword) === 5 && passwordStrengthCheck(this.state.repeatNewPassword) === 5;
    }

    render() {
        const { locale } = this.props;
        const { currentLocaleJSON: loc } = locale;
        const userEmail = parseJwt(this.props.token).sub;
        return (
            <Card icon="security" title={`${loc.CreatePasswordScreen_CardTitle} [${userEmail}]`} width="890px">
                {
                    this.state.errorMsg !== ''?
                        <ErrorMessage>
                            {this.state.errorMsg}
                        </ErrorMessage>
                    :
                        null
                }
                {/* <InputField required label={loc.ChangePasswordScreen_OldPasswordField} type="password" onChange={this.handleChangeField} value={this.state.oldPassword} name='oldPassword'/> */}
                <InputField isValid={(this.state.newPassword.length >= 9)} errorMsg={loc.ERROR_PasswordLength} required label={loc.CreatePasswordScreen_NewPasswordField} type="password" onChange={this.handleChangeFieldS} value={this.state.newPassword} name='newPassword'/>
                <div className="password-strength-block">
                    <p>{loc.ChangePasswordScreen_PasswordStrengthTitle}</p>
                    <ul className={`level-${passwordStrengthCheck(this.state.newPassword)}`}>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                </div>
                <InputField isValid={(this.state.newPassword === this.state.repeatNewPassword)} errorMsg={loc.ERROR_PasswordMustBeIdentical} required label={loc.CreatePasswordScreen_NewPasswordRepeatField} type="password" onChange={this.handleChangeFieldS} value={this.state.repeatNewPassword} name='repeatNewPassword'/>
               

               <Button className={'right-align-margin-reset-password-btn'}  disabled={!this.validToChange()} onClick={this.handleChangePassword} color="primary" col={3}>
                    {loc.CreatePasswordScreen_ChangeButton}
                </Button>

            </Card>
        )
    }
}



const mapStateToProps = (store: IAppState) => ({
    user: store.user,
    locale: store.locale
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
    loginUserHandle: (email: string, password: string) => dispatch(loginUser({email, password})),
    createUserPassword: (passwordData: ICreateUserPasswordModel) => dispatch(createUserPassword(passwordData)),
    openSuccessDialog: (data: IDoneDialogData) => dispatch(openDoneDialog(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreatePasswordCard)
