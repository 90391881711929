import Api from "api";
import { ISearchParams, IGetListServerResponse, IDefaultServerResponse, IGetListPayload, IErrorServerResponse, IGetByIdServerResponse, IGetByIdPayload } from "store/types";
import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { 
    IGetStaffListSuccessAction, 
    IGetStaffListFailureAction, 
    IStaffModel, 
    IStaffCreateData,
    IGetStaffListPendingAction, 
    ICreateStaffPendingAction, 
    ICreateStaffSuccessAction, 
    IDriverModel, 
    ISecurityModel, 
    ICreateStaffFailureAction, 
    IChangeStaffStatusFailureAction,
    IChangeStaffStatusSuccessAction,
    IChangeStaffStatusPendingAction,
    IGetStaffByIdPendingAction,
    IGetStaffByIdSuccessAction,
    IGetStaffByIdFailureAction,
    IEditStaffFailureAction,
    IEditStaffPendingAction,
    IEditStaffSuccessAction,
    IDeleteStaffFailureAction,
    IDeleteStaffPendingAction,
    IDeleteStaffSuccessAction,
    IStaffEditModel,
    IStaffChangeStatusModel
} from "./types";
import { formatSearchParams } from "services";
import { AxiosResponse, AxiosError } from "axios";

export const getStaffList: ActionCreator<
  ThunkAction<
    Promise<IGetStaffListSuccessAction | IGetStaffListFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    IStaffModel[],                  // The type for the data within the last action
    ISearchParams,                       // The type of the parameter for the nested function 
    IGetStaffListSuccessAction | IGetStaffListFailureAction            // The type of the last action to be dispatched
  >
> = ( searchParams: ISearchParams) => {
    return async (dispatch: Dispatch) => {
        
        const getStaffListPendingAction: IGetStaffListPendingAction = {
            type: 'GET_STAFF_LIST_PENDING',
        };
        dispatch(getStaffListPendingAction);

        try{
            console.log("STAFF REQ")
            const response: AxiosResponse<IGetListServerResponse<IStaffModel>> = await Api.get(`/staffs?${formatSearchParams(searchParams)}`);
            console.log(response)
            const payload: IGetListPayload<IStaffModel> = {
                list: response.data.data.content,
                searchParams,
                pageble: {
                    currentPage: response.data.data.number,
                    totalPages: response.data.data.totalPages,
                    totalElements: response.data.data.totalElements
                }
            }
            const getStaffListSuccessAction: IGetStaffListSuccessAction = {
                payload,
                type: 'GET_STAFF_LIST_SUCCESS',
            };
            return dispatch(getStaffListSuccessAction);
        } catch (error) {
            // console.log('d',error.response.data)
            const getStaffListFailureAction: IGetStaffListFailureAction = {
                type: 'GET_STAFF_LIST_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(getStaffListFailureAction);
        }
    };
};


export const createStaff: ActionCreator<
  ThunkAction<
    Promise<ICreateStaffSuccessAction | ICreateStaffFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    IStaffCreateData,                       // The type of the parameter for the nested function 
    ICreateStaffSuccessAction | ICreateStaffFailureAction            // The type of the last action to be dispatched
  >
> = ( createdData: IStaffCreateData) => {
    return async (dispatch: Dispatch) => {
        
        const createStaffPendingAction: ICreateStaffPendingAction = {
            type: 'CREATE_STAFF_PENDING',
        };
        dispatch(createStaffPendingAction);

        try{
            console.log("STAFF REQ Create")
            const response: AxiosResponse<IDefaultServerResponse> = await Api.post(`/staff/${createdData.type}`, { ...createdData.data });
            console.log(response)
            // const staffList: {} = authDataResponse.data; 
            const createStaffSuccessAction: ICreateStaffSuccessAction = {
                // staffList,
                type: 'CREATE_STAFF_SUCCESS',
            };
            return dispatch(createStaffSuccessAction);
        } catch (error) {
            const createStaffFailureAction: ICreateStaffFailureAction = {
                type: 'CREATE_STAFF_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(createStaffFailureAction);
        }
    };
};


export const getStaffById: ActionCreator<
  ThunkAction<
    Promise<IGetStaffByIdSuccessAction | IGetStaffByIdFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    IGetByIdPayload<IStaffModel> ,                  // The type for the data within the last action
    string,                       // The type of the parameter for the nested function 
    IGetStaffByIdSuccessAction | IGetStaffByIdFailureAction            // The type of the last action to be dispatched
  >
> = (id: string) => {
    return async (dispatch: Dispatch) => {
        
        const getStaffByIdPendingAction: IGetStaffByIdPendingAction = {
            type: 'GET_STAFF_BY_ID_PENDING',
        };
        dispatch(getStaffByIdPendingAction);
        try{
            const response: AxiosResponse<IGetByIdServerResponse<IStaffModel>> = await Api.get(`/staff/${id}`);
            const payload: IGetByIdPayload<IStaffModel> = {
                record: response.data.data,
                id
            } 
            const getStaffByIdSuccessAction: IGetStaffByIdSuccessAction = {
                payload,
                type: 'GET_STAFF_BY_ID_SUCCESS',
            };
            return dispatch(getStaffByIdSuccessAction);
        } catch (error) {
            const getStaffByIdFailureAction: IGetStaffByIdFailureAction = {
                type: 'GET_STAFF_BY_ID_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(getStaffByIdFailureAction);
        }
    };
};

export const changeStaffStatus: ActionCreator<
  ThunkAction<
    Promise<IChangeStaffStatusSuccessAction | IChangeStaffStatusFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    string,                       // The type of the parameter for the nested function 
    IChangeStaffStatusSuccessAction | IChangeStaffStatusFailureAction            // The type of the last action to be dispatched
  >
> = (changeStatusData: IStaffChangeStatusModel) => {
    return async (dispatch: Dispatch) => {
        
        const changeStaffStatusPendingAction: IChangeStaffStatusPendingAction = {
            type: 'CHANGE_STAFF_STATUS_PENDING',
        };
        dispatch(changeStaffStatusPendingAction);

        try{
            const authDataResponse: AxiosResponse<IDefaultServerResponse> = await Api.put(`/staff/status/${changeStatusData.id}/${changeStatusData.status}`);
            // const list: IStaffNameModel[] = authDataResponse.data.data; 
            const changeStaffStatusSuccessAction: IChangeStaffStatusSuccessAction = {
                type: 'CHANGE_STAFF_STATUS_SUCCESS',
            };
            return dispatch(changeStaffStatusSuccessAction);
        } catch (error) {

            const changeStaffStatusFailureAction: IChangeStaffStatusFailureAction = {
                type: 'CHANGE_STAFF_STATUS_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(changeStaffStatusFailureAction);
        }
    };
};


export const editStaff: ActionCreator<
  ThunkAction<
    Promise<IEditStaffSuccessAction | IEditStaffFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    IStaffEditModel,                       // The type of the parameter for the nested function 
    IEditStaffSuccessAction | IEditStaffFailureAction            // The type of the last action to be dispatched
  >
> = ( editData: IStaffEditModel) => {
    return async (dispatch: Dispatch) => {
        
        const editStaffPendingAction: IEditStaffPendingAction = {
            type: 'EDIT_STAFF_PENDING',
        };
        dispatch(editStaffPendingAction);

        try{
            const response: AxiosResponse<IDefaultServerResponse> = await Api.put(`/staff`, { ...editData });
            const editStaffSuccessAction: IEditStaffSuccessAction = {
                type: 'EDIT_STAFF_SUCCESS',
            };
            return dispatch(editStaffSuccessAction);
        } catch (error) {
            const editStaffFailureAction: IEditStaffFailureAction = {
                type: 'EDIT_STAFF_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(editStaffFailureAction);
        }
    };
};


export const deleteStaff: ActionCreator<
  ThunkAction<
    Promise<IDeleteStaffSuccessAction | IDeleteStaffFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    string,                       // The type of the parameter for the nested function 
    IDeleteStaffSuccessAction | IDeleteStaffFailureAction            // The type of the last action to be dispatched
  >
> = ( id: string) => {
    return async (dispatch: Dispatch) => {
        
        const deleteStaffPendingAction: IDeleteStaffPendingAction = {
            type: 'DELETE_STAFF_PENDING',
        };
        dispatch(deleteStaffPendingAction);

        try{
            const response: AxiosResponse<IDefaultServerResponse> = await Api.delete(`/Staff/${id}`);
            const deleteStaffSuccessAction: IDeleteStaffSuccessAction = {
                type: 'DELETE_STAFF_SUCCESS',
            };
            return dispatch(deleteStaffSuccessAction);
        } catch (error) {
            const deleteStaffFailureAction: IDeleteStaffFailureAction = {
                type: 'DELETE_STAFF_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(deleteStaffFailureAction);
        }
    };
};