import Api from "api";
import { ISearchParams, IGetListServerResponse, IDefaultServerResponse, IGetListPayload, IErrorServerResponse, IGetDataListServerResponse, IGetByIdServerResponse, IGetByIdPayload } from "store/types";
import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { 
    IGetOrderListPendingAction,
    IGetOrderListSuccessAction,
    IGetOrderListFailureAction,
    IGetOrderByIdPendingAction,
    IGetOrderByIdSuccessAction,
    IGetOrderByIdFailureAction,
    ICreateOrderPendingAction,
    ICreateOrderSuccessAction,
    ICreateOrderFailureAction,
    IEditOrderPendingAction,
    IEditOrderSuccessAction,
    IEditOrderFailureAction,
    IOrderModel,
    IOrderCreateModel,
    IOrderEditModel,
    IChangeOrderStatusByLogistSuccessAction,
    IChangeOrderStatusByLogistFailureAction,
    IChangeOrderStatusByLogistPendingAction,
    IChangeOrderStatusByManagerPendingAction,
    IChangeOrderStatusByManagerFailureAction,
    IChangeOrderStatusByManagerSuccessAction,
    IEditOrderByManagerSuccessAction,
    IEditOrderByManagerPendingAction,
    IEditOrderByManagerFailureAction,
    IOrderChangeStatusModel,
    IGetOrderUncompleteListPendingAction,
    IGetOrderUncompleteListSuccessAction,
    IGetOrderUncompleteListFailureAction,
    ICreateOrderCommentFailureAction,
    ICreateOrderCommentSuccessAction,
    ICreateOrderCommentPendingAction,
    IOrderCreateCommentModel,
    IOrderEditByManagerModel
} from "./types";
import { formatSearchParams } from "services";
import { AxiosResponse, AxiosError } from "axios";

export const getOrderList: ActionCreator<
  ThunkAction<
    Promise<IGetOrderListSuccessAction | IGetOrderListFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    IOrderModel[],                  // The type for the data within the last action
    ISearchParams,                       // The type of the parameter for the nested function 
    IGetOrderListSuccessAction | IGetOrderListFailureAction            // The type of the last action to be dispatched
  >
> = ( searchParams: ISearchParams) => {
    return async (dispatch: Dispatch) => {
        
        const getOrderListPendingAction: IGetOrderListPendingAction = {
            type: 'GET_ORDER_LIST_PENDING',
        };
        dispatch(getOrderListPendingAction);

        try{
            let route = searchParams.additional && searchParams.additional['uncomplete'] && searchParams.additional['uncomplete'] === 'true'? '/orders/TTNAndSTO?' : '/orders?';
            const response: AxiosResponse<IGetListServerResponse<IOrderModel>> = await Api.get(`${route}${formatSearchParams(searchParams)}`);
            const payload: IGetListPayload<IOrderModel> = {
                list: response.data.data.content,
                searchParams,
                pageble: {
                    currentPage: response.data.data.number,
                    totalPages: response.data.data.totalPages,
                    totalElements: response.data.data.totalElements
                }
            }
            const getOrderListSuccessAction: IGetOrderListSuccessAction = {
                payload,
                type: 'GET_ORDER_LIST_SUCCESS',
            };
            return dispatch(getOrderListSuccessAction);
        } catch (error) {
            const getOrderListFailureAction: IGetOrderListFailureAction = {
                type: 'GET_ORDER_LIST_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(getOrderListFailureAction);
        }
    };
};

export const getOrderUncompleteList: ActionCreator<
  ThunkAction<
    Promise<IGetOrderUncompleteListSuccessAction | IGetOrderUncompleteListFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    IOrderModel[],                  // The type for the data within the last action
    ISearchParams,                       // The type of the parameter for the nested function 
    IGetOrderUncompleteListSuccessAction | IGetOrderUncompleteListFailureAction            // The type of the last action to be dispatched
  >
> = ( searchParams: ISearchParams) => {
    return async (dispatch: Dispatch) => {
        
        const getOrderUncompleteListPendingAction: IGetOrderUncompleteListPendingAction = {
            type: 'GET_ORDER_UNCOMPLETE_LIST_PENDING',
        };
        dispatch(getOrderUncompleteListPendingAction);

        try{
            const response: AxiosResponse<IGetListServerResponse<IOrderModel>> = await Api.get(`/orders/TTNAndSTO?${formatSearchParams(searchParams)}`);
            const payload: IGetListPayload<IOrderModel> = {
                list: response.data.data.content,
                searchParams,
                pageble: {
                    currentPage: response.data.data.number,
                    totalPages: response.data.data.totalPages,
                    totalElements: response.data.data.totalElements
                }
            }
            const getOrderUncompleteListSuccessAction: IGetOrderUncompleteListSuccessAction = {
                payload,
                type: 'GET_ORDER_UNCOMPLETE_LIST_SUCCESS',
            };
            return dispatch(getOrderUncompleteListSuccessAction);
        } catch (error) {
            const getOrderUncompleteListFailureAction: IGetOrderUncompleteListFailureAction = {
                type: 'GET_ORDER_UNCOMPLETE_LIST_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(getOrderUncompleteListFailureAction);
        }
    };
};

export const getOrderById: ActionCreator<
  ThunkAction<
    Promise<IGetOrderByIdSuccessAction | IGetOrderByIdFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    IGetByIdPayload<IOrderModel> ,                  // The type for the data within the last action
    string,                       // The type of the parameter for the nested function 
    IGetOrderByIdSuccessAction | IGetOrderByIdFailureAction            // The type of the last action to be dispatched
  >
> = (id: string) => {
    return async (dispatch: Dispatch) => {
        
        const getOrderByIdPendingAction: IGetOrderByIdPendingAction = {
            type: 'GET_ORDER_BY_ID_PENDING',
        };
        dispatch(getOrderByIdPendingAction);
        // console.log('id',id)
        try{
            const response: AxiosResponse<IGetByIdServerResponse<IOrderModel>> = await Api.get(`/order/${id}`);
            const payload: IGetByIdPayload<IOrderModel> = {
                record: response.data.data,
                id
            } 
            const getOrderByIdSuccessAction: IGetOrderByIdSuccessAction = {
                payload,
                type: 'GET_ORDER_BY_ID_SUCCESS',
            };
            return dispatch(getOrderByIdSuccessAction);
        } catch (error) {
            const getOrderByIdFailureAction: IGetOrderByIdFailureAction = {
                type: 'GET_ORDER_BY_ID_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(getOrderByIdFailureAction);
        }
    };
};


export const createOrder: ActionCreator<
  ThunkAction<
    Promise<ICreateOrderSuccessAction | ICreateOrderFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    IOrderCreateModel,                       // The type of the parameter for the nested function 
    ICreateOrderSuccessAction | ICreateOrderFailureAction            // The type of the last action to be dispatched
  >
> = ( createdData: IOrderCreateModel) => {
    return async (dispatch: Dispatch) => {
        
        const createOrderPendingAction: ICreateOrderPendingAction = {
            type: 'CREATE_ORDER_PENDING',
        };
        dispatch(createOrderPendingAction);

        try{
            const response: AxiosResponse<IDefaultServerResponse> = await Api.post(`/order`, { ...createdData });
            const createOrderSuccessAction: ICreateOrderSuccessAction = {
                type: 'CREATE_ORDER_SUCCESS',
                id: response.data.data
            };
            return dispatch(createOrderSuccessAction);
        } catch (error) {
            const createOrderFailureAction: ICreateOrderFailureAction = {
                type: 'CREATE_ORDER_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(createOrderFailureAction);
        }
    };
};

export const createOrderComment: ActionCreator<
  ThunkAction<
    Promise<ICreateOrderCommentSuccessAction | ICreateOrderCommentFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    IOrderCreateCommentModel,                       // The type of the parameter for the nested function 
    ICreateOrderCommentSuccessAction | ICreateOrderCommentFailureAction            // The type of the last action to be dispatched
  >
> = ( createdComment: IOrderCreateCommentModel) => {
    return async (dispatch: Dispatch) => {
        
        const createOrderCommentPendingAction: ICreateOrderCommentPendingAction = {
            type: 'CREATE_ORDER_COMMENT_PENDING',
        };
        dispatch(createOrderCommentPendingAction);

        try{
            const response: AxiosResponse<IDefaultServerResponse> = await Api.post(`/order/${createdComment.id}/comment/${createdComment.comment}`);
            const createOrderCommentSuccessAction: ICreateOrderCommentSuccessAction = {
                type: 'CREATE_ORDER_COMMENT_SUCCESS',
            };
            return dispatch(createOrderCommentSuccessAction);
        } catch (error) {
            const createOrderCommentFailureAction: ICreateOrderCommentFailureAction = {
                type: 'CREATE_ORDER_COMMENT_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(createOrderCommentFailureAction);
        }
    };
};

export const editOrder: ActionCreator<
  ThunkAction<
    Promise<IEditOrderSuccessAction | IEditOrderFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    IOrderEditModel,                       // The type of the parameter for the nested function 
    IEditOrderSuccessAction | IEditOrderFailureAction            // The type of the last action to be dispatched
  >
> = ( editData: IOrderEditModel) => {
    return async (dispatch: Dispatch) => {
        
        const editOrderPendingAction: IEditOrderPendingAction = {
            type: 'EDIT_ORDER_PENDING',
        };
        dispatch(editOrderPendingAction);

        try{
            const response: AxiosResponse<IDefaultServerResponse> = await Api.put(`/order`, { ...editData });
            const editOrderSuccessAction: IEditOrderSuccessAction = {
                type: 'EDIT_ORDER_SUCCESS',
            };
            return dispatch(editOrderSuccessAction);
        } catch (error) {
            const editOrderFailureAction: IEditOrderFailureAction = {
                type: 'EDIT_ORDER_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(editOrderFailureAction);
        }
    };
};


export const editOrderByManager: ActionCreator<
  ThunkAction<
    Promise<IEditOrderByManagerSuccessAction | IEditOrderByManagerFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    IOrderEditModel,                       // The type of the parameter for the nested function 
    IEditOrderByManagerSuccessAction | IEditOrderByManagerFailureAction            // The type of the last action to be dispatched
  >
> = ( editData: IOrderEditByManagerModel) => {
    return async (dispatch: Dispatch) => {
        
        const editOrderByManagerPendingAction: IEditOrderByManagerPendingAction = {
            type: 'EDIT_ORDER_BY_MANAGER_PENDING',
        };
        dispatch(editOrderByManagerPendingAction);

        try{
            const response: AxiosResponse<IDefaultServerResponse> = await Api.put(`/order/manager/${editData.id}`, { ...editData.transport });
            const editOrderByManagerSuccessAction: IEditOrderByManagerSuccessAction = {
                type: 'EDIT_ORDER_BY_MANAGER_SUCCESS',
            };
            return dispatch(editOrderByManagerSuccessAction);
        } catch (error) {
            const editOrderByManagerFailureAction: IEditOrderByManagerFailureAction = {
                type: 'EDIT_ORDER_BY_MANAGER_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(editOrderByManagerFailureAction);
        }
    };
};

export const changeOrderStatusByLogist: ActionCreator<
  ThunkAction<
    Promise<IChangeOrderStatusByLogistSuccessAction | IChangeOrderStatusByLogistFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    string,                       // The type of the parameter for the nested function 
    IChangeOrderStatusByLogistSuccessAction | IChangeOrderStatusByLogistFailureAction            // The type of the last action to be dispatched
  >
> = (changeStatusData: IOrderChangeStatusModel) => {
    return async (dispatch: Dispatch) => {
        
        const changeOrderStatusByLogistPendingAction: IChangeOrderStatusByLogistPendingAction = {
            type: 'CHANGE_ORDER_STATUS_BY_LOGIST_PENDING',
        };
        dispatch(changeOrderStatusByLogistPendingAction);

        try{
            const authDataResponse: AxiosResponse<IDefaultServerResponse> = await Api.put(`/order/logist/status/${changeStatusData.id}/${changeStatusData.status}`);
            // const list: IOrderNameModel[] = authDataResponse.data.data; 
            const changeOrderStatusByLogistSuccessAction: IChangeOrderStatusByLogistSuccessAction = {
                type: 'CHANGE_ORDER_STATUS_BY_LOGIST_SUCCESS',
            };
            return dispatch(changeOrderStatusByLogistSuccessAction);
        } catch (error) {

            const changeOrderStatusByLogistFailureAction: IChangeOrderStatusByLogistFailureAction = {
                type: 'CHANGE_ORDER_STATUS_BY_LOGIST_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(changeOrderStatusByLogistFailureAction);
        }
    };
};


export const changeOrderStatusByManager: ActionCreator<
  ThunkAction<
    Promise<IChangeOrderStatusByManagerSuccessAction | IChangeOrderStatusByManagerFailureAction>,  // The type of the last action to be dispatched - will always be promise<T> for async actions
    null,                  // The type for the data within the last action
    string,                       // The type of the parameter for the nested function 
    IChangeOrderStatusByManagerSuccessAction | IChangeOrderStatusByManagerFailureAction            // The type of the last action to be dispatched
  >
> = (changeStatusData: IOrderChangeStatusModel) => {
    return async (dispatch: Dispatch) => {
        
        const changeOrderStatusByManagerPendingAction: IChangeOrderStatusByManagerPendingAction = {
            type: 'CHANGE_ORDER_STATUS_BY_MANAGER_PENDING',
        };
        dispatch(changeOrderStatusByManagerPendingAction);

        try{
            const authDataResponse: AxiosResponse<IDefaultServerResponse> = await Api.put(`/order/manager/status/${changeStatusData.id}/${changeStatusData.status}`);
            // const list: IOrderNameModel[] = authDataResponse.data.data; 
            const changeOrderStatusByManagerSuccessAction: IChangeOrderStatusByManagerSuccessAction = {
                type: 'CHANGE_ORDER_STATUS_BY_MANAGER_SUCCESS',
            };
            return dispatch(changeOrderStatusByManagerSuccessAction);
        } catch (error) {

            const changeOrderStatusByManagerFailureAction: IChangeOrderStatusByManagerFailureAction = {
                type: 'CHANGE_ORDER_STATUS_BY_MANAGER_FAILURE',
                payload: {
                    ...error.response.data
                }
            };

            return dispatch(changeOrderStatusByManagerFailureAction);
        }
    };
};