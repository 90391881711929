import React, { Component } from 'react';
import LoginCard from "components/LoginCard";
import './AuthScreen.scss';

interface IProps {
    history: any;
}

interface IState {
    expanded: boolean;
}




export class AuthScreen extends Component<IProps> {
    state: IState = {expanded: false};

    handleExpandClick = () => {
        this.setState({expanded: !this.state.expanded})
        //setExpanded(!expanded);
    };
    render() {

        return (
            <div className='auth-screen screen-center'>
                <LoginCard history={this.props.history}/>
            </div>
        )
    }
}
