import { 
	REQUEST, 
	SUCCESS, 
	FAILURE, 
    IErrorPayload
} from '../types';
	
import {
	IUserState,
	UserActions,
} from "./types";

import { Reducer } from 'redux';
import { parseJwt } from 'services';

const initialUserState: IUserState = {
	isFetching: false,
	isFetchingUserInfo: false,
	userProfileIsLoad: false,
	token: '',
	userInfo: {
	  authorities: [],
	  city: {
		name: '',
		region: ''
	  },
	  company: {
		name: '',
		id: ''
	  },
	  contactPhone: 0,
	  email: '',
	  fio: '',
	  login: '',
	  notificationType: 'EMAIL',
	  position: '',
	  status: 'ACTIVE',
	  workPhone: 0,
	  id: ''
	},
	currentUser: {
	  authorities: [],
	  city: {
		name: '',
		region: ''
	  },
	  company: {
		name: '',
		id: ''
	  },
	  contactPhone: 0,
	  email: '',
	  fio: '',
	  login: '',
	  notificationType: 'EMAIL',
	  position: '',
	  status: 'ACTIVE',
	  workPhone: 0,
	  id: ''
	},
	loggedIn: false,
	userRole: [],
	list: [],
	logistList: [],
	adminList: [],
	personList: [],
	fullRolesList: [
	  'ROLE_LOGIST',
	  'ROLE_MANAGER_LOGIST',
	  'ROLE_TC_MANAGER',
	  'ROLE_WAREHOUSE_MANAGER',
	  'CLIENT_LOGIST'
	],
	required: [
	  'authorities',
	  'email',
	  'fio',
	  'position',
	  'login',
	  'company',
	  'city',
	  'workPhone'
	]
}


export const userReducer: Reducer<IUserState, UserActions> = (
	state = initialUserState,
	action,
  ) => {

	const defaultStateWithFetchingTrue = {
		...state,
		isFetching: true,
	};

	const defaultStateWithFetchingFalse = {
		...state,
		isFetching: false,
	};

	const defaultErrorState = (action: { payload: IErrorPayload }) => ({
		...state,
		isFetching: false,
		errorInfo: {
			hasError: true,
			...action.payload
		},
	});
	
	switch (action.type) {

	  /// GET_USER_INFO
	  case 'GET_USER_INFO_PENDING': {
		return { 
			...defaultStateWithFetchingTrue,
			userProfileIsLoad: false,
			isFetchingUserInfo: true,
		};
	  }
	  case 'GET_USER_INFO_SUCCESS': {
		// console.log(parseJwt(action.authData.data.token))
		return {
		  ...state,
		  userInfo: {...action.userInfo},
		  userProfileIsLoad: true,
		  isFetchingUserInfo: false,
		};
	  }
	  case 'GET_USER_INFO_FAILURE': {
		return { 
			...defaultErrorState(action),
			userProfileIsLoad: false,
			isFetchingUserInfo: false,
		};
	  }

	  ///GET_USER_LIST

	  case 'GET_USER_LIST_PENDING': {
		return { ...defaultStateWithFetchingTrue };
	  }
	  case 'GET_USER_LIST_SUCCESS': {
		// console.log(parseJwt(action.authData.data.token))
		return {
		  ...state,
		  list: [...action.payload.list],
		  pageble: {...action.payload.pageble},
		  isFetching: false,
		};
	  }
	  case 'GET_USER_LIST_FAILURE': {
		return { ...defaultErrorState(action) };
	  }

	  ///GET_PERSON_LIST

	  case 'GET_PERSON_LIST_PENDING': {
		return { ...defaultStateWithFetchingTrue };
	  }
	  case 'GET_PERSON_LIST_SUCCESS': {
		// console.log(parseJwt(action.authData.data.token))
		return {
		  ...state,
		  personList: [...action.payload.list],
		  isFetching: false,
		};
	  }
	  case 'GET_PERSON_LIST_FAILURE': {
		return { ...defaultErrorState(action) };
	  }

	  ///GET_LOGIST_LIST

	  case 'GET_LOGIST_LIST_PENDING': {
		return { ...defaultStateWithFetchingTrue };
	  }
	  case 'GET_LOGIST_LIST_SUCCESS': {
		// console.log(parseJwt(action.authData.data.token))
		return {
		  ...state,
		  logistList: [...action.payload.list],
		  isFetching: false,
		};
	  }
	  case 'GET_LOGIST_LIST_FAILURE': {
		return { ...defaultErrorState(action) };
	  }
	  
	  ///GET_ADMIN_LIST

	  case 'GET_ADMIN_LIST_PENDING': {
		return { ...defaultStateWithFetchingTrue };
	  }
	  case 'GET_ADMIN_LIST_SUCCESS': {
		// console.log(parseJwt(action.authData.data.token))
		return {
		  ...state,
		  adminList: [...action.payload.list],
		  isFetching: false,
		};
	  }
	  case 'GET_ADMIN_LIST_FAILURE': {
		return { ...defaultErrorState(action) };
	  }
	  /// GET_USER_BY_ID

	  case 'GET_USER_BY_ID_PENDING': {
		return { ...defaultStateWithFetchingTrue };
	  }
	  case 'GET_USER_BY_ID_SUCCESS': {
		// console.log(parseJwt(action.authData.data.token))
		return {
		  ...state,
		  currentUser: {...action.payload.record},
		  currentUserId: action.payload.id,
		  isFetching: false,
		};
	  }
	  case 'GET_USER_BY_ID_FAILURE': {
		return { ...defaultErrorState(action) };
	  }

	  /// CREATE_USER

	  case 'CREATE_USER_PENDING': {
		return { ...defaultStateWithFetchingTrue };
	  }
	  case 'CREATE_USER_SUCCESS': {
		// console.log(parseJwt(action.authData.data.token))
		return {
		  ...state,
		  isFetching: false,
		};
	  }
	  case 'CREATE_USER_FAILURE': {
		return { ...defaultErrorState(action) };
	  }



	  
	  
	  /// LOGIN_USER

	  case 'LOGIN_USER_PENDING': {
		return { ...defaultStateWithFetchingTrue };
	  }
	  case 'LOGIN_USER_SUCCESS': {
		  console.log('LOGIN_USER_SUCCESS')
		return {
		  ...state,
		  token: action.authData.data.token,
		  userRole: [parseJwt(action.authData.data.token).auth],
		  isFetching: false,
		};
	  }
	  case 'LOGIN_USER_FAILURE': {
		return { ...defaultErrorState(action) };
	  }


	  /// LOGOUT_USER

	  case 'LOGOUT_USER_PENDING': {
		return { ...defaultStateWithFetchingTrue };
	  }
	  case 'LOGOUT_USER_SUCCESS': {
		return {
		  ...state,
		  token: '',
		  userRole: [],
		  isFetching: false,
		};
	  }
	  case 'LOGOUT_USER_FAILURE': {
		return { ...defaultStateWithFetchingFalse };
	  }
	  default:

        switch(action.type){
            	  /// EDIT_USER

            case 'EDIT_USER_PENDING': {
                return { ...defaultStateWithFetchingTrue };
            }
            case 'EDIT_USER_SUCCESS': {
                // console.log(parseJwt(action.authData.data.token))
                return { ...defaultStateWithFetchingFalse };
            }
            case 'EDIT_USER_FAILURE': {
                return { ...defaultErrorState(action) };
            }

            /// EDIT_CURRENT_USER

            case 'EDIT_CURRENT_USER_PENDING': {
                return { ...defaultStateWithFetchingTrue };
            }
            case 'EDIT_CURRENT_USER_SUCCESS': {
                // console.log(parseJwt(action.authData.data.token))
                return { ...defaultStateWithFetchingFalse };
            }
            case 'EDIT_CURRENT_USER_FAILURE': {
                return { ...defaultErrorState(action) };
            }

            /// CHANGE_USER_STATUS

            case 'CHANGE_USER_STATUS_PENDING': {
                return { ...defaultStateWithFetchingTrue };
            }
            case 'CHANGE_USER_STATUS_SUCCESS': {
                // console.log(parseJwt(action.authData.data.token))
                return { ...defaultStateWithFetchingFalse };
            }
            case 'CHANGE_USER_STATUS_FAILURE': {
                return { ...defaultErrorState(action) };
            }

            /// DELETE_USER

            case 'DELETE_USER_PENDING': {
                return { ...defaultStateWithFetchingTrue };
            }
            case 'DELETE_USER_SUCCESS': {
                // console.log(parseJwt(action.authData.data.token))
                return { ...defaultStateWithFetchingFalse };
            }
            case 'DELETE_USER_FAILURE': {
                return { ...defaultErrorState(action) };
            }

            /// CREATE_USER_PASSWORD

            case 'CREATE_USER_PASSWORD_PENDING': {
                return { ...defaultStateWithFetchingTrue };
            }
            case 'CREATE_USER_PASSWORD_SUCCESS': {
                // console.log(parseJwt(action.authData.data.token))
                return { ...defaultStateWithFetchingFalse };
            }
            case 'CREATE_USER_PASSWORD_FAILURE': {
                return { ...defaultErrorState(action) };
            }

            /// EDIT_USER_PASSWORD

            case 'EDIT_USER_PASSWORD_PENDING': {
                return { ...defaultStateWithFetchingTrue };
            }
            case 'EDIT_USER_PASSWORD_SUCCESS': {
                return { ...defaultStateWithFetchingFalse };
            }
            case 'EDIT_USER_PASSWORD_FAILURE': {
                return { ...defaultErrorState(action) };
            }
            
            /// RESET_PASSWORD_BY_EMAIL
            case 'RESET_PASSWORD_BY_EMAIL_PENDING': {
                return { ...defaultStateWithFetchingTrue };
            }
            case 'RESET_PASSWORD_BY_EMAIL_SUCCESS': {
                return { ...defaultStateWithFetchingFalse };
            }
            case 'RESET_PASSWORD_BY_EMAIL_FAILURE': {
                return { ...defaultErrorState(action) };
            }
            default:
                neverReached(action);

        }
        
		neverReached(action); // when a new action is created, this helps us remember to handle it in the reducer
	}
	return state;
};
  
  // tslint:disable-next-line:no-empty
const neverReached = (never: never) => {};