import { 
    REQUEST, 
    SUCCESS, 
    FAILURE, 
    IErrorPayload
} from '../types';
    
import {
    IRouteState,
    RouteActions,
} from "./types";

import { Reducer } from 'redux';

const initialRouteState: IRouteState = {
    isFetching: false,
    list: [],
    searchParams: {},
    currentRoute: {
        distance: null,
        downloadPoints: [],
        id: '',
        intermediatePoints: [],
        name: '',
        roads: [],
        sap: '',
        time: null,
        transports: [],
        type: 'TIME',
        uploadPoint: {
            city: {
                name: '',
                region: ''
            },
            comment: '',
            coordinates: '',
            type: "UPLOAD"
        }
    },
    currentRouteId: '',
    orderRoute: {
        distance: null,
        downloadPoints: [],
        id: '',
        intermediatePoints: [],
        name: '',
        roads: [],
        sap: '',
        time: null,
        transports: [],
        type: 'TIME',
        uploadPoint: {
            city: {
                name: '',
                region: ''
            },
            comment: '',
            coordinates: '',
            type: "UPLOAD"
        }
    },
    orderRouteId: '',
    required: [
        'registrationNumber'
    ]
}

export const routeReducer: Reducer<IRouteState, RouteActions> = (
    state = initialRouteState,
    action,
  ) => {

    const defaultStateWithFetchingTrue = {
        ...state,
        isFetching: true,
    };

    const defaultStateWithFetchingFalse = {
        ...state,
        isFetching: false,
    };

    const defaultErrorState = (action: { payload: IErrorPayload }) => ({
        ...state,
        isFetching: false,
        errorInfo: {
            hasError: true,
            ...action.payload
        },
    });

    switch (action.type) {

        // GET FULL LIST
        case 'GET_ROUTE_LIST_PENDING': {
            return { ...defaultStateWithFetchingTrue };
        }
        case 'GET_ROUTE_LIST_SUCCESS': {
            // console.log(parseJwt(action.authData.data.token))
            return {
                ...state,
                list: [...action.payload.list],
                pageble: {...action.payload.pageble},
                isFetching: false,
            };
        }
        case 'GET_ROUTE_LIST_FAILURE': {
            return {
                ...state,
                isFetching: false,
                errorInfo: {
                    hasError: true,
                    ...action.payload
                },
            };
        }

        // GET FULL LIST
        case 'GET_ROUTE_BY_CITY_PENDING': {
            return { ...defaultStateWithFetchingTrue };
        }
        case 'GET_ROUTE_BY_CITY_SUCCESS': {
            // console.log(parseJwt(action.authData.data.token))
            return {
                ...state,
                orderRoute: {...action.payload.record},
                orderRouteId: action.payload.id,
                isFetching: false,
            };
        }
        case 'GET_ROUTE_BY_CITY_FAILURE': {
            return { ...defaultErrorState(action) };
        }


        // GET BY ID
        case 'GET_ROUTE_BY_ID_PENDING': {
            return { ...defaultStateWithFetchingTrue };
        }
        case 'GET_ROUTE_BY_ID_SUCCESS': {
            // console.log(parseJwt(action.authData.data.token))
            return {
                ...state,
                currentRoute: {...action.payload.record},
                currentRouteId: action.payload.id,
                isFetching: false,
            };
        }
        case 'GET_ROUTE_BY_ID_FAILURE': {
            return { ...defaultErrorState(action) };
        }


        // CREATE 
        case 'CREATE_ROUTE_PENDING': {
            return { ...defaultStateWithFetchingTrue };
        }
        case 'CREATE_ROUTE_SUCCESS': {
            // console.log(parseJwt(action.authData.data.token))
            return { ...defaultStateWithFetchingFalse };
        }
        case 'CREATE_ROUTE_FAILURE': {
            return { ...defaultErrorState(action) };
        }


        // EDIT
        case 'EDIT_ROUTE_PENDING': {
            return { ...defaultStateWithFetchingTrue };
        }
        case 'EDIT_ROUTE_SUCCESS': {
            // console.log(parseJwt(action.authData.data.token))
            return { ...defaultStateWithFetchingFalse };
        }
        case 'EDIT_ROUTE_FAILURE': {
            return { ...defaultErrorState(action) };
        }


        // DELETE
        case 'DELETE_ROUTE_PENDING': {
            return { ...defaultStateWithFetchingTrue };
        }
        case 'DELETE_ROUTE_SUCCESS': {
            // console.log(parseJwt(action.authData.data.token))
            return { ...defaultStateWithFetchingFalse };
        }
        case 'DELETE_ROUTE_FAILURE': {
            return { ...defaultErrorState(action) };
        }

        default:
        neverReached(action); // when a new action is created, this helps us remember to handle it in the reducer
    }
    return state;
};
  
  // tslint:disable-next-line:no-empty
const neverReached = (never: never) => {};