import React, { Component } from 'react'
import { connect } from 'react-redux'
import { IAppState } from 'store';
import { TextField, InputAdornment, IconButton, InputLabel, FormControl, Input, FormHelperText, MenuItem, Popper, Grow, ClickAwayListener, Paper, Icon, Checkbox } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import DateFnsUtils from '@date-io/date-fns';
import NumberFormat, { FormatInputValueFunction } from 'react-number-format';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
import './InputField.scss';
import { placeholder } from '@babel/types';
import Datetime from "react-datetime";
import { ILocaleState } from 'store/locale/types';
import moment from 'moment';
// import AutocompleteCity from 'components/AutocompleteField/AutocompleteCity';
// import AutocompleteCompany from 'components/AutocompleteField/AutocompleteCompany';
import { emailIsValid, phoneIsValid } from 'services';
// import AutocompleteLogist from 'components/AutocompleteField/AutocompleteLogist';
// import AutocompletePerson from 'components/AutocompleteField/AutocompletePerson';
// import AutocompleteAdmin from 'components/AutocompleteField/AutocompleteAdmin';
// import AutocompleteWarehouse from 'components/AutocompleteField/AutocompleteWarehouse';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
// import AutocompleteVehicle from 'components/AutocompleteField/AutocompleteVehicle';
// import AutocompleteStaff from 'components/AutocompleteField/AutocompleteStaff';
// import AutocompleteCompanyByCity from 'components/AutocompleteField/AutocompleteCompanyByCity';
import { AutocompleteTemplate, IProps as IAutocompleteProps } from 'components/AutocompleteField/AutocompleteTemplate';
import { ISearchParams } from 'store/types';
import { getCompanyList, getCompanyListByCity } from 'store/company/actions';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { IGetCompanyListSuccessAction, IGetCompanyListFailureAction, ICompanyByCityModel, IGetCompanyListByCitySuccessAction, IGetCompanyListByCityFailureAction } from 'store/company/types';
import equal from 'deep-equal';
import { getAdminList, getLogistList, getPersonList } from 'store/user/actions';
import { IGetAdminListSuccessAction, IGetAdminListFailureAction, IGetPersonListSuccessAction, IGetPersonListFailureAction, IGetLogistListSuccessAction, IGetLogistListFailureAction } from 'store/user/types';
import { getWarehouseList } from 'store/warehouse/actions';
import { getCityList } from 'store/city/actions';
import { getStaffList } from 'store/staff/actions';
import { getVehicleList } from 'store/vehicle/actions';
import { IGetWarehouseListSuccessAction, IGetWarehouseListFailureAction } from 'store/warehouse/types';
import { IGetVehicleListSuccessAction, IGetVehicleListFailureAction } from 'store/vehicle/types';
import { IGetStaffListSuccessAction, IGetStaffListFailureAction } from 'store/staff/types';
import { IGetCityListSuccessAction, IGetCityListFailureAction } from 'store/city/types';

interface IProps {
    locale: ILocaleState;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    value: string | number | null;
    name: string;
    type: string;
    label: string;
    required?: boolean;
    options?: {label: string, value: string | number }[];
    changeDate? : ((name: string, value: string | moment.Moment) => void) | undefined;
    onSelect?: (resultObject: any) => any;
    hideLabel?: boolean;
    placeholder?: string;
    isValidDate?: ((currentDate: any, selectedDate: any) => boolean) | undefined;
    col?: number;
    padding?: boolean;
    disabled?: boolean;
    additionalFilters?: {[key: string]: number | string};
    isValid?: boolean;
    errorMsg?: string;
    autoSelect?: boolean;
    centerMode?: boolean;
    withoutLabelBorder?: boolean;
    centerText?: boolean;
    onChangeCheckbox?: (name: string, value: string) => void;
    cityName?: string;
    getCompanyList: (params: ISearchParams) => Promise<IGetCompanyListSuccessAction|IGetCompanyListFailureAction>;
    getAdminList: (params: ISearchParams) => Promise<IGetAdminListSuccessAction|IGetAdminListFailureAction>;
    getWarehouseList: (params: ISearchParams) => Promise<IGetWarehouseListSuccessAction|IGetWarehouseListFailureAction>;
    getVehicleList: (params: ISearchParams) => Promise<IGetVehicleListSuccessAction|IGetVehicleListFailureAction>;
    getStaffList: (params: ISearchParams) => Promise<IGetStaffListSuccessAction|IGetStaffListFailureAction>;
    getPersonList: (params: ISearchParams) => Promise<IGetPersonListSuccessAction|IGetPersonListFailureAction>;
    getLogistList: (params: ISearchParams) => Promise<IGetLogistListSuccessAction|IGetLogistListFailureAction>;
    getCompanyListByCity: (data: ICompanyByCityModel) => Promise<IGetCompanyListByCitySuccessAction|IGetCompanyListByCityFailureAction>;
    getCityList: (name: string) => Promise<IGetCityListSuccessAction|IGetCityListFailureAction>;
}
interface IState {
    showPassword: boolean;
    error: boolean;
    errorMsg: string;
    autocompleteListOpen: boolean;
    autocompleteData: {
        [key: string]: string
    }[];
    value: string;
    
}

/* Types
-text
-text with autocomplete
-select
-datepicker
*/
interface PhoneNumberFormatCustomProps {
    inputRef: (instance: NumberFormat | null) => void;
    onChange: (event: { target: { name: string, value: number } }) => void;
    name: string;
}
  
function PhoneNumberFormatCustom(props: PhoneNumberFormatCustomProps) {
    const { inputRef, onChange, name, ...other } = props;
    console.log("VALIDATE PHONE ", other )
    return (
        <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={values => {
            onChange({
                target: {
                    name: name,
                    value: +values.value,
                },
            });
        }}
        thousandSeparator
        // prefix="$"
        format="+## (###) ###-####" 
        mask="_"
        />
    );
}

const a: FormatInputValueFunction = (inputVal) => {
    console.log(inputVal)
    return inputVal;
}

interface NumberFormatCustomProps {
    inputRef: (instance: NumberFormat | null) => void;
    onChange: (event: { target: { name: string, value: string } }) => void;
    name: string;
}
  
function NumberFormatCustom(props: NumberFormatCustomProps) {
    const { inputRef, onChange, name, ...other } = props;
    // console.log("VALIDATE PHONE ", other, value )
    return (
        <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={values => {
            onChange({
                target: {
                    name: name,
                    value: values.value,
                },
            });
        }}
        // thousandSeparator
        // prefix="$"
        decimalSeparator="."
        allowedDecimalSeparators={'.'}
        format="################"
        // format={a} 
        mask=" "
        decimalScale={2}
        />
    );
}



export class InputField extends Component<IProps> {
    state: IState = {
        showPassword: false,
        error: false,
        errorMsg: '',
        autocompleteListOpen: false,
        autocompleteData: [],
        value: ''
    }

    private autocompleteRef = React.createRef<HTMLDivElement>()

    handleClickShowPassword = () => {
        this.setState( (prevState: IState) => ({showPassword: !prevState.showPassword}))
    }

    handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    }

    static getDerivedStateFromProps(props: IProps, state: IState){
        // console.log("new props INPUT", "(", props.value,")(", state.value,")", props.isValid, props);
        // return null;
        // if(props.type === 'number'){
        //     console.log(props.value);
        //     if(props.value !== null && +props.value < 0){
        //         return ({value: 0});
        //     }
        // }
        if(props.required && props.value === '' && state.value !== ''){
            // console.log('no valid')
            return ({error: true, errorMsg: props.locale.currentLocaleJSON['ERROR_NotEmpty'], value: props.value});
        } else {
            if(props.isValid !== undefined && !props.isValid && props.errorMsg && props.errorMsg !== '' && props.value !== '') {
                return ({error: true, errorMsg: props.errorMsg, value: props.value});
            } else if(props.type === 'email' && props.value !== '' && typeof props.value === 'string' && !emailIsValid(props.value)) {
                return ({error: true, errorMsg: props.locale.currentLocaleJSON['ERROR_RuleNotCorrect'], value: props.value});
            } else if(props.type === 'phone' && props.value !== null && typeof props.value === 'number' && !phoneIsValid(props.value)) {
                return ({error: true, errorMsg: props.locale.currentLocaleJSON['ERROR_RuleNotCorrect'], value: props.value});
            } else if ((props.type === 'admin' || props.type === 'logist' || props.type === 'city' || props.type === 'company') && props.value !== '' && props.isValid === false){
                return ({error: true, errorMsg: props.locale.currentLocaleJSON['ERROR_RuleNotCorrect'], value: props.value});
            } else {
                return ({error: false, errorMsg: ``, value: props.value});
            }
            
        }

        
    }

    //Поле обязательно к заполнению.

  


    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let fieldEvent = event;
        if(this.props.onChange){
            if(this.props.type === 'number'){
                // console.log('fieldEvent.target.value',fieldEvent.target.value, typeof fieldEvent.target.value)
                // console.log((/^\d{0,16}(\,\d{0,2})?$/).test(fieldEvent.target.value), 'pass validate before if' )  
                if( (/^\d{0,16}(\.\d{0,2})?$/).test(fieldEvent.target.value) ){
                    //&& fieldEvent.target.value.length <= 19 && +fieldEvent.target.value >= 0 
                // if( (/\d*\.?\d*$/).test(fieldEvent.target.value) && fieldEvent.target.value.length <= 16 && +fieldEvent.target.value >= 0 ){
                    // console.log((/^\d{0,16}(\,\d{0,2})?$/).test(fieldEvent.target.value), 'pass validate' )    
                    this.props.onChange(fieldEvent)
                }
            } else {
                // console.log('chenge number with out type')
                this.props.onChange(fieldEvent);
            }
            
        }
    }
    

    displayValue = (inputValue: string | number | null): string => {
        if(inputValue !== null){
            if(this.props.type === 'number' && typeof inputValue === 'string'){
                if( (/^\d{0,16}(\.\d{0,2})?$/).test(inputValue) ){
                    // console.log('DISPLAY INPUT VALUE')
                    return inputValue;
                }
            } else {
                return inputValue.toString();
            }
        }
        return "";
    }

    handleChangeDate = (value: string | moment.Moment) => {
        if(this.props.changeDate)
            this.props.changeDate(this.props.name, value);

    }

    handleChangeCheck = (name: string, value: string) => {
        if(this.props.onChangeCheckbox)
            this.props.onChangeCheckbox(name, value);

    }


    handleChangeAutocomplete = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(this.props.onChange)
            this.props.onChange(event);

       

            // this.props.onChange(event);

    }


    closeAutocompleteList = (event: any) => {
        if (this.autocompleteRef.current && this.autocompleteRef.current.contains(event.target)) {
            return;
        }
        this.setState({
            autocompleteListOpen: false
        });
    }

    // handleDateChange = (date: Date | null) => {
    //     setSelectedDate(date);
    // }
    shouldUpdateAutocompleteCity = (props: IAutocompleteProps, prevProps: IAutocompleteProps): boolean => {
        if(props.value.length >= 2 && props.value !== prevProps.value) {
            return true;
        } else {
            return false;
        }
    }

    shouldUpdateAutocompleteByCity = (props: IAutocompleteProps, prevProps: IAutocompleteProps): boolean => {
        if(
            (!props.autoSelect && props.value.length >= 2 && props.value !== prevProps.value) ||
            (props.autoSelect && props.value !== prevProps.value) ||
            (props.autoSelect && props.cityName && props.cityName !== '' && prevProps.cityName !== props.cityName)
        ) {
            // console.log('tru')
            return true;
        } else {
            return false;
        }
    }

    shouldUpdateAutocompleteWithFilter = (props: IAutocompleteProps, prevProps: IAutocompleteProps): boolean => {
        let additionalFilters = {};
        if(props.additionalFilters){
            additionalFilters = { ...this.props.additionalFilters }
        }

        if( 
            (!props.autoSelect &&  props.value.length >= 2 && props.value !== prevProps.value) ||
            (props.autoSelect && props.value !== prevProps.value) ||
            (props.autoSelect && !equal(additionalFilters, prevProps.additionalFilters? prevProps.additionalFilters: {}))
        ) {
            return true;
        } else {
            return false;
        }
    }

    updateAutocomplete = async (): Promise<{[key: string]: any}[]>  => {
        try{
            let additionalFilters = {};
            if(this.props.additionalFilters){
                additionalFilters = { ...this.props.additionalFilters };
            }
            switch (this.props.type) {
                case 'company':
                    const respCompany = await this.props.getCompanyList({size: 1000, page: 0, q:{'shortName|regex': this.props.value, ...additionalFilters}})
                    if(respCompany.type === "GET_COMPANY_LIST_SUCCESS"){
                        return respCompany.payload.list;
                    } else {
                        return [];
                    }
                case 'city':
                    const respCity = await this.props.getCityList(this.props.value && typeof this.props.value === 'string'? this.props.value: '')
                    if(respCity.type === "GET_CITY_LIST_SUCCESS"){
                        return respCity.list;
                    } else {
                        return [];
                    }
                case 'companyByCity':
                    const respCompanyByCity = await this.props.getCompanyListByCity({namePart: this.props.value && typeof this.props.value === 'string'? this.props.value: '', city: this.props.cityName? this.props.cityName: ''})
                    if(respCompanyByCity.type === "GET_COMPANY_LIST_BY_CITY_SUCCESS"){
                        return respCompanyByCity.list;
                    } else {
                        return [];
                    }

                case 'logist':
                    const respLogist = await this.props.getLogistList({size: 1000, page: 0, q:{'authorities|or': ['ROLE_MANAGER_LOGIST', 'ROLE_LOGIST'], 'fio|regex': this.props.value, ...additionalFilters}})
                    if(respLogist.type === "GET_LOGIST_LIST_SUCCESS"){
                        return respLogist.payload.list;
                    } else {
                        return [];
                    }
                case 'person':
                    const respPerson = await this.props.getPersonList({size: 1000, page: 0, q:{'fio|regex': this.props.value, ...additionalFilters}})
                    if(respPerson.type === "GET_PERSON_LIST_SUCCESS"){
                        return respPerson.payload.list;
                    } else {
                        return [];
                    }
                case 'staff':
                    const respStaff = await this.props.getStaffList({size: 1000, page: 0, q:{'fio|regex': this.props.value, ...additionalFilters}})
                    if(respStaff.type === "GET_STAFF_LIST_SUCCESS"){
                        return respStaff.payload.list;
                    } else {
                        return [];
                    }
                case 'vehicle':
                    const respVehicle = await this.props.getVehicleList({size: 1000, page: 0, q:{'registrationNumber|regex': this.props.value, ...additionalFilters}})
                    if(respVehicle.type === "GET_VEHICLE_LIST_SUCCESS"){
                        return respVehicle.payload.list;
                    } else {
                        return [];
                    }
                case 'warehouse':
                    const respWarehouse = await this.props.getWarehouseList({size: 1000, page: 0, q:{'name|regex': this.props.value, ...additionalFilters}})
                    if(respWarehouse.type === "GET_WAREHOUSE_LIST_SUCCESS"){
                        return respWarehouse.payload.list;
                    } else {
                        return [];
                    }
                case 'admin':
                    const respAdmin = await this.props.getAdminList({size: 1000, page: 0, q:{'authorities|full': 'ROLE_WAREHOUSE_MANAGER', 'fio|regex': this.props.value, ...additionalFilters}});
                    if(respAdmin.type === "GET_ADMIN_LIST_SUCCESS"){
                        return respAdmin.payload.list;
                    } else {
                        return [];
                    }
                
                
                default:
                    return [];
            }


        } catch(e) {
            return [];
        }
    }


    autocompleteField = (listingParamName: string, shouldUpdate: (props: IAutocompleteProps, prevProps: IAutocompleteProps) => boolean ) =>
        <AutocompleteTemplate
            listingParamName={listingParamName}
            updateFunction={this.updateAutocomplete}
            shouldUpdate={shouldUpdate}
            autoSelect={this.props.autoSelect} 
            additionalFilters={this.props.additionalFilters} 
            error={this.state.error} errorMsg={this.state.errorMsg} 
            hideLabel={this.props.hideLabel} 
            placeholder={this.props.placeholder} 
            disabled={this.props.disabled} 
            required={this.props.required} 
            value={typeof this.props.value === 'string'? this.displayValue(this.props.value) : 'Invalid Data'} 
            label={this.props.label} 
            name={this.props.name} 
            onSelect={this.props.onSelect} 
            onChange={this.props.onChange}
            cityName={this.props.cityName? this.props.cityName: ''}
            />

    

    formatInputByType = (type: string) => {
        // const fioWithFilters = (value: string): boolean => value === 'logist' || value === 'admin' || value === 'person'? true : false;
        
        let defaultField = (inputType: string, additionalFields?: {[key: string]: any}, textfield?: boolean): JSX.Element => {
            
            return (
                !textfield?
                    <FormControl error={this.state.error} fullWidth className={'field-wrapper'}>
                        {
                            !this.props.hideLabel?
                                <InputLabel shrink htmlFor={`input_${this.props.label}`}>{this.props.required? `${this.props.label} *` : this.props.label}</InputLabel>
                            :
                                null
                        }
                        <Input
                            { ...additionalFields }
                            disabled={this.props.disabled}
                            id = {`input_${this.props.label}`}
                            name = {this.props.name? this.props.name : `input_${this.props.label}`}
                            type={inputType}
                            value={this.displayValue(this.props.value)}
                            onChange={this.handleChange}
                            placeholder={this.props.placeholder ? this.props.required? `${this.props.placeholder} *`: `${this.props.placeholder}` : ''}
                        />
                        {this.state.error?
                            <FormHelperText id="component-error-text">{this.state.errorMsg}</FormHelperText>
                            : null
                        }
                    </FormControl>
                :
                    <FormControl error={this.state.error} fullWidth className={'field-wrapper'}>
                        <TextField
                        disabled={this.props.disabled}
                        // select
                        id = {`input_${this.props.label}`}
                        name = {this.props.name? this.props.name : `input_${this.props.label}`}
                        type={type === 'phone'? 'text': inputType}
                        value={this.displayValue(this.props.value)}
                        onChange={this.handleChange}
                        label={!this.props.hideLabel? this.props.label : null}
                        InputLabelProps={{ shrink: true, disabled: true }}
                        placeholder={this.props.placeholder ? this.props.required? `${this.props.placeholder} *`: `${this.props.placeholder}` : ''}
                        { ...additionalFields }
                        InputProps={type === 'phone'? {
                                inputComponent: PhoneNumberFormatCustom as any,
                            }: {}}
                        />
                        {this.state.error?
                            <FormHelperText id="component-error-text">{this.state.errorMsg}</FormHelperText>
                            : null
                        }
                    </FormControl>
            )
        }
        
        switch (type) {
            // case 'email':
            //     return defaultField;
            //     break;
            case 'multiline':
                const multilineFields = {
                    multiline: true,
                    rows: 5,
                    rowsMax: 5  
                }
                return defaultField('text', multilineFields);
                    // <FormControl error={this.state.error} fullWidth className={'field-wrapper'}>
                    //     {
                    //         !this.props.hideLabel?
                    //             <InputLabel shrink htmlFor={`input_${this.props.label}`}>{this.props.required? `${this.props.label} *` : this.props.label}</InputLabel>
                    //         :
                    //             null
                    //     }
                    //     <Input
                    //         multiline={true}
                    //         rows={5}
                    //         rowsMax={5}
                    //         disabled={this.props.disabled}
                    //         id = {`input_${this.props.label}`}
                    //         name = {this.props.name? this.props.name : `input_${this.props.label}`}
                    //         type={'text'}
                    //         value={this.props.value}
                    //         onChange={this.handleChange}
                    //         placeholder={this.props.placeholder ? this.props.required? `${this.props.placeholder} *`: `${this.props.placeholder}` : ''}
                    //         />
                    //     {this.state.error?
                    //         <FormHelperText id="component-error-text">{this.state.errorMsg}</FormHelperText>
                    //         : null
                    //     }
                    // </FormControl>
                    
                break;
            case 'email':
            case 'text':
                return defaultField('text');
                    // <FormControl error={this.state.error} fullWidth className={'field-wrapper'}>
                    //     {
                    //         !this.props.hideLabel?
                    //             <InputLabel shrink htmlFor={`input_${this.props.label}`}>{this.props.required? `${this.props.label} *` : this.props.label}</InputLabel>
                    //         :
                    //             null
                    //     }
                    //     <Input
                    //         disabled={this.props.disabled}
                    //         id = {`input_${this.props.label}`}
                    //         name = {this.props.name? this.props.name : `input_${this.props.label}`}
                    //         type={'text'}
                    //         value={this.props.value}
                    //         onChange={this.handleChange}
                    //         placeholder={this.props.placeholder ? this.props.required? `${this.props.placeholder} *`: `${this.props.placeholder}` : ''}
                    //         />
                    //     {this.state.error?
                    //         <FormHelperText id="component-error-text">{this.state.errorMsg}</FormHelperText>
                    //         : null
                    //     }
                    // </FormControl>
                    
                break;
            case 'checkbox':
                return(
                    <FormControl error={this.state.error} fullWidth className={'field-wrapper'}>
                        {
                            !this.props.hideLabel?
                                <InputLabel shrink htmlFor={`input_${this.props.label}`}>{this.props.required? `${this.props.label} *` : this.props.label}</InputLabel>
                            :
                                null
                        }
                        {
                            this.props.value === 'true'?
                                <CheckBoxIcon className="checked" onClick={this.props.disabled? () => {} : () => {this.handleChangeCheck(this.props.name, 'false')}} fontSize="small" />
                            :
                                <CheckBoxOutlineBlankIcon className="unchecked" onClick={this.props.disabled? () => {} :  () => {this.handleChangeCheck(this.props.name, 'true')}} fontSize="small" />
                        }
                        
                        {this.state.error?
                            <FormHelperText id="component-error-text">{this.state.errorMsg}</FormHelperText>
                            : null
                        }
                    </FormControl>
                    )
                break;
            case 'phone':
                // const phoneFieldProps = {
                //     InputProps: {
                //         inputComponent: PhoneNumberFormatCustom as any
                //     }
                // };
                // console.log(defaultField('number', phoneFieldProps, true));
                // return defaultField('number', {}, true);
                    // <FormControl error={this.state.error} fullWidth className={'field-wrapper'}>
                    //     <TextField
                    //     disabled={this.props.disabled}
                    //     // select
                    //     id = {`input_${this.props.label}`}
                    //     name = {this.props.name? this.props.name : `input_${this.props.label}`}
                    //     // type={'select'}
                    //     value={this.props.value}
                    //     onChange={this.handleChange}
                    //     label={!this.props.hideLabel? this.props.label : null}
                    //     InputLabelProps={{ shrink: true, disabled: true }}
                    //     placeholder={this.props.placeholder ? this.props.required? `${this.props.placeholder} *`: `${this.props.placeholder}` : ''}
                    //         InputProps={{
                    //             inputComponent: PhoneNumberFormatCustom as any,
                    //         }}
                    //     />
                    //     {this.state.error?
                    //         <FormHelperText id="component-error-text">{this.state.errorMsg}</FormHelperText>
                    //         : null
                    //     }
                    // </FormControl>
                    
                // break;
            case 'number':
                return defaultField('text', {}, true);
                    // <FormControl error={this.state.error} fullWidth className={'field-wrapper'}>
                    //     <TextField
                    //         // label="react-number-format"
                    //     disabled={this.props.disabled}
                    //     // select
                    //     id = {`input_${this.props.label}`}
                    //     name = {this.props.name? this.props.name : `input_${this.props.label}`}
                    //     // type={'select'}
                    //     type="number"
                    //     value={this.props.value? this.props.value: ''}
                    //     onChange={this.handleChange}
                    //     label={!this.props.hideLabel? this.props.label : null}
                    //     InputLabelProps={{ shrink: true, disabled: true }}
                    //     placeholder={this.props.placeholder ? this.props.required? `${this.props.placeholder} *`: `${this.props.placeholder}` : ''}
                    //         // InputProps={{
                    //         //     inputComponent: NumberFormatCustom as any,
                    //         // }}
                    //     />
                    //     {this.state.error?
                    //         <FormHelperText id="component-error-text">{this.state.errorMsg}</FormHelperText>
                    //         : null
                    //     }
                    // </FormControl>
                    
                break;
            


            case 'city':
                return(
                    this.autocompleteField("city", this.shouldUpdateAutocompleteCity)
                    // <AutocompleteCity error={this.state.error} errorMsg={this.state.errorMsg} hideLabel={this.props.hideLabel} placeholder={this.props.placeholder} disabled={this.props.disabled} required={this.props.required} value={typeof this.props.value === 'string'? this.props.value : 'Invalid Data'} label={this.props.label} name={this.props.name} onSelect={this.props.onSelect} onChange={this.props.onChange}/>
                )
            // case 'company':
            //     return(
            //         <AutocompleteCompany 
            //             autoSelect={this.props.autoSelect} additionalFilters={this.props.additionalFilters} error={this.state.error} errorMsg={this.state.errorMsg} hideLabel={this.props.hideLabel} placeholder={this.props.placeholder} disabled={this.props.disabled} required={this.props.required} value={typeof this.props.value === 'string'? this.props.value : 'Invalid Data'} label={this.props.label} name={this.props.name} onSelect={this.props.onSelect} onChange={this.props.onChange}/>
            //     )
            case 'company':
                return(
                    this.autocompleteField("shortName", this.shouldUpdateAutocompleteWithFilter)
                )
            case 'companyByCity':
                return(
                    this.autocompleteField("name", this.shouldUpdateAutocompleteByCity)
                    // <AutocompleteCompanyByCity cityName={this.props.cityName? this.props.cityName: ''} autoSelect={this.props.autoSelect} additionalFilters={this.props.additionalFilters} error={this.state.error} errorMsg={this.state.errorMsg} hideLabel={this.props.hideLabel} placeholder={this.props.placeholder} disabled={this.props.disabled} required={this.props.required} value={typeof this.props.value === 'string'? this.props.value : 'Invalid Data'} label={this.props.label} name={this.props.name} onSelect={this.props.onSelect} onChange={this.props.onChange}/>
                )
            // case 'logist':
            //     return(
            //         this.autocompleteField("fio", this.shouldUpdateAutocompleteWithFilter)
            //         // <AutocompleteLogist autoSelect={this.props.autoSelect} additionalFilters={this.props.additionalFilters} error={this.state.error} errorMsg={this.state.errorMsg} hideLabel={this.props.hideLabel} placeholder={this.props.placeholder} disabled={this.props.disabled} required={this.props.required} value={typeof this.props.value === 'string'? this.props.value : 'Invalid Data'} label={this.props.label} name={this.props.name} onSelect={this.props.onSelect} onChange={this.props.onChange}/>
            //     )
            // case 'admin':
            //     return(
            //         <AutocompleteAdmin autoSelect={this.props.autoSelect} additionalFilters={this.props.additionalFilters} error={this.state.error} errorMsg={this.state.errorMsg} hideLabel={this.props.hideLabel} placeholder={this.props.placeholder} disabled={this.props.disabled} required={this.props.required} value={typeof this.props.value === 'string'? this.props.value : 'Invalid Data'} label={this.props.label} name={this.props.name} onSelect={this.props.onSelect} onChange={this.props.onChange}/>
            //     )
            case 'logist':
            case 'person':
            case 'staff':
            case 'admin':
                return(
                    this.autocompleteField("fio", this.shouldUpdateAutocompleteWithFilter)
                )
            // case 'person':
            //     return(
            //         this.autocompleteField("fio", this.shouldUpdateAutocompleteWithFilter)
            //         // <AutocompletePerson autoSelect={this.props.autoSelect} additionalFilters={this.props.additionalFilters} error={this.state.error} errorMsg={this.state.errorMsg} hideLabel={this.props.hideLabel} placeholder={this.props.placeholder} disabled={this.props.disabled} required={this.props.required} value={typeof this.props.value === 'string'? this.props.value : 'Invalid Data'} label={this.props.label} name={this.props.name} onSelect={this.props.onSelect} onChange={this.props.onChange}/>
            //     )
            case 'vehicle':
            case 'warehouse':
                return(
                    this.autocompleteField("name", this.shouldUpdateAutocompleteWithFilter)
                    // <AutocompleteWarehouse autoSelect={this.props.autoSelect} additionalFilters={this.props.additionalFilters} error={this.state.error} errorMsg={this.state.errorMsg} hideLabel={this.props.hideLabel} placeholder={this.props.placeholder} disabled={this.props.disabled} required={this.props.required} value={typeof this.props.value === 'string'? this.props.value : 'Invalid Data'} label={this.props.label} name={this.props.name} onSelect={this.props.onSelect} onChange={this.props.onChange}/>
                )
            // case 'vehicle':
            //     return(
            //         this.autocompleteField("name", this.shouldUpdateAutocompleteWithFilter)
            //         // <AutocompleteVehicle autoSelect={this.props.autoSelect} additionalFilters={this.props.additionalFilters} error={this.state.error} errorMsg={this.state.errorMsg} hideLabel={this.props.hideLabel} placeholder={this.props.placeholder} disabled={this.props.disabled} required={this.props.required} value={typeof this.props.value === 'string'? this.props.value : 'Invalid Data'} label={this.props.label} name={this.props.name} onSelect={this.props.onSelect} onChange={this.props.onChange}/>
            //     )
            // case 'staff':
            //     return(
            //         this.autocompleteField("fio", this.shouldUpdateAutocompleteWithFilter)
            //         // <AutocompleteStaff autoSelect={this.props.autoSelect} additionalFilters={this.props.additionalFilters} error={this.state.error} errorMsg={this.state.errorMsg} hideLabel={this.props.hideLabel} placeholder={this.props.placeholder} disabled={this.props.disabled} required={this.props.required} value={typeof this.props.value === 'string'? this.props.value : 'Invalid Data'} label={this.props.label} name={this.props.name} onSelect={this.props.onSelect} onChange={this.props.onChange}/>
            //     )




            case 'select':
                return(
                    <FormControl error={this.state.error} fullWidth className={'field-wrapper'}>
                        {/* <InputLabel shrink htmlFor={`input_${this.props.label}`}>{this.props.label}</InputLabel> */}
                        <TextField
                            disabled={this.props.disabled}
                            defaultValue={this.props.placeholder ? this.props.placeholder : ''}
                            select={!this.props.disabled}
                            id = {`input_${this.props.label}`}
                            name = {this.props.name? this.props.name : `input_${this.props.label}`}
                            type={this.props.disabled? 'text': 'select'}
                            value={this.props.value === null? '' : this.props.value}
                            onChange={this.handleChange}
                            label={!this.props.hideLabel? this.props.required? `${this.props.label} *` : this.props.label : null}
                            placeholder={this.props.placeholder ? this.props.required? `${this.props.placeholder} *`: `${this.props.placeholder}` : ''}
                            InputLabelProps={{ shrink: true, disabled: true }}
                            >
                            {
                                this.props.placeholder?
                                    this.props.required?
                                        <MenuItem disabled key={'placeholder'} value="">
                                            {`${this.props.placeholder} *`}
                                        </MenuItem>
                                    :
                                        <MenuItem disabled key={'placeholder'} value="">
                                            {`${this.props.placeholder}`}
                                        </MenuItem>
                                :
                                    null
                            }
                            {this.props.options && this.props.options.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                                ))}
                            
                            
                        </TextField>
                        {this.state.error?
                            <FormHelperText id="component-error-text">{this.state.errorMsg}</FormHelperText>
                            : null
                        }
                    </FormControl>
                    )
                break;
            case 'date':
            //     return(
            //         <FormControl required={this.props.required} error={this.state.error} fullWidth className={'field-wrapper'}>
            //             {
            //                 !this.props.hideLabel?
            //                     <InputLabel shrink htmlFor={`input_${this.props.label}`}>{this.props.required? `${this.props.label} *` : this.props.label}</InputLabel>
            //                 :
            //                     null
            //             }
            //             <Icon>date_range</Icon>
            //             {this.props.disabled?
            //                 <Input
            //                     disabled={this.props.disabled}
            //                     id = {`input_${this.props.label}`}
            //                     name = {this.props.name? this.props.name : `input_${this.props.label}`}
            //                     type={'text'}
            //                     value={this.props.value}
            //                     onChange={this.handleChange}
            //                     placeholder={this.props.placeholder ? this.props.required? `${this.props.placeholder} *`: `${this.props.placeholder}` : ''}
            //                     />
            //             :
            //                 <Datetime
            //                     // disabled={this.props.disabled}
            //                     locale={this.props.locale.currentLocale}
            //                     isValidDate={this.props.isValidDate? this.props.isValidDate : undefined} 
            //                     timeFormat={false} 
            //                     inputProps={{ placeholder: this.props.placeholder ? this.props.placeholder: '' }}
            //                     onChange={this.handleChangeDate}
            //                     closeOnSelect={true}
            //                     value={typeof this.props.value === 'string'? this.props.value: 'Invalid Data'}
            //                     ></Datetime>
            //             }
                        
                        
            //             {this.state.error?
            //                 <FormHelperText id="component-error-text">{this.state.errorMsg}</FormHelperText>
            //                 : null
            //             }
            //         </FormControl>)
            //   break;
            case 'time':
                return(
                    <FormControl required={this.props.required} error={this.state.error} fullWidth className={'field-wrapper'}>
                        {
                            !this.props.hideLabel?
                                <InputLabel shrink htmlFor={`input_${this.props.label}`}>{this.props.required? `${this.props.label} *` : this.props.label}</InputLabel>
                            :
                                null
                        }
                        {this.props.disabled?
                            <Input
                                disabled={this.props.disabled}
                                id = {`input_${this.props.label}`}
                                name = {this.props.name? this.props.name : `input_${this.props.label}`}
                                type={'text'}
                                value={this.props.value}
                                onChange={this.handleChange}
                                placeholder={this.props.placeholder ? this.props.required? `${this.props.placeholder} *`: `${this.props.placeholder}` : ''}
                                />
                        :
                            <Datetime
                                // disabled={this.props.disabled}
                                locale={this.props.locale.currentLocale}
                                isValidDate={this.props.isValidDate? this.props.isValidDate : undefined} 
                                timeFormat={type === 'time'} 
                                dateFormat={type === 'date'} 
                                inputProps={{ placeholder: this.props.placeholder ? this.props.placeholder: '' }}
                                onChange={this.handleChangeDate}
                                closeOnSelect={true}
                                value={typeof this.props.value === 'string'? this.props.value: 'Invalid Data'}
                                ></Datetime>
                        }
                        
                        <Icon>{type === 'date'? 'date_range' : 'access_time'}</Icon>
                        {this.state.error?
                            <FormHelperText id="component-error-text">{this.state.errorMsg}</FormHelperText>
                            : null
                        }
                    </FormControl>)
              break;
            // case 'email':
            //     return(
            //         <FormControl required={this.props.required} error={this.state.error} fullWidth className={'field-wrapper'}>
            //             <InputLabel shrink htmlFor={`input_${this.props.label}`}>{this.props.label}</InputLabel>
            //             <Input
            //             disabled={this.props.disabled}
            //             id = {`input_${this.props.label}`}
            //             name = {this.props.name? this.props.name : `input_${this.props.label}`}
            //             type={'email'}
            //             value={this.props.value}
            //             onChange={this.handleChange}                        
            //             />
            //             {
            //                 this.state.error?
            //                     <FormHelperText id="component-error-text">{this.state.errorMsg}</FormHelperText>
            //                 :
            //                     null
            //             }
            //         </FormControl>
            //         )
            //     break;
            case 'password': 
                const endAdornment=(
                    <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                    >
                        {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                    </InputAdornment>
                )
                const passwordFieldProps = {
                    endAdornment
                }
                        
                return defaultField(this.state.showPassword ? 'text' : 'password', passwordFieldProps);
                // return  (<FormControl error={this.state.error} fullWidth className={'field-wrapper'}>
                //         {
                //             !this.props.hideLabel?
                //                 <InputLabel shrink htmlFor={`input_${this.props.label}`}>{this.props.required? `${this.props.label} *` : this.props.label}</InputLabel>
                //             :
                //                 null
                //         }
                //         <Input
                //         disabled={this.props.disabled}
                //         id = {`input_${this.props.label}`}
                //         name = {this.props.name? this.props.name : `input_${this.props.label}`}
                //         type={this.state.showPassword ? 'text' : 'password'}
                //         value={this.props.value}
                //         onChange={this.props.onChange}
                //         endAdornment={
                //             <InputAdornment position="end">
                //             <IconButton
                //                 aria-label="toggle password visibility"
                //                 onClick={this.handleClickShowPassword}
                //                 onMouseDown={this.handleMouseDownPassword}
                //             >
                //                 {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                //             </IconButton>
                //             </InputAdornment>
                //         }
                //         />
                //         {this.state.error?
                //             <FormHelperText id="component-error-text">{this.state.errorMsg}</FormHelperText>
                //             : null
                //         }
                //     </FormControl>)
                
                break;

            default:
                break;
        }
    }

    render() {
        return (
            <div style={{width: `${this.props.col? (100 / 12 * this.props.col) : 100}%`, padding: this.props.padding? "0 15px": "0px"}}  className={`field-wrapper${this.props.withoutLabelBorder? ' without-label-border': ''}${this.props.centerText? ' center-input-text': ''}${this.props.centerMode? ' center-field-mode': ''}${this.props.type === 'date' || this.props.type === 'time'? ' datepicker': ''}`}>

                {this.formatInputByType(this.props.type)}
               
            </div>
        )
    }
}

const mapStateToProps = (state: IAppState) => ({
    locale: state.locale
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ( {
    // getCompanyNamesList: (partName: string) => dispatch(getCompanyNamesList(partName)),
    getCompanyListByCity: (data: ICompanyByCityModel) => dispatch(getCompanyListByCity(data)),
    getCompanyList: (params: ISearchParams) => dispatch(getCompanyList(params)),
    getAdminList: (params: ISearchParams) => dispatch(getAdminList(params)),
    getWarehouseList: (params: ISearchParams) => dispatch(getWarehouseList(params)),
    getVehicleList: (params: ISearchParams) => dispatch(getVehicleList(params)),
    getStaffList: (params: ISearchParams) => dispatch(getStaffList(params)),
    getPersonList: (params: ISearchParams) => dispatch(getPersonList(params)),
    getLogistList: (params: ISearchParams) => dispatch(getLogistList(params)),
    getCityList: (partName: string) => dispatch(getCityList(partName))
})


export default connect(mapStateToProps, mapDispatchToProps)(InputField)
