import React from 'react';
import './FormPageTemplate.scss';

interface IProps {
    title: string;
    col?: number;
    width?: number;
}

export const FormPageTemplate: React.FC<IProps> = props => {
    return (
        <div className="simple-page-form">
            <div className="simple-page-card-form" style={{width: `${props.width? `${props.width}px` : '100%'}`, flex: `0 0 ${props.col? (100 / 12 * props.col) : 100}%`, maxWidth: `${props.col? (100 / 12 * props.col) : 100}%`}}>
                <div className="simple-page-card-header">
                    <p>{props.title}</p>
                </div>
                <div className="content">
                    {props.children}
                </div>
            </div>
        </div>
    )
}
