import { 
    REQUEST, 
    SUCCESS, 
    FAILURE, 
    IErrorFieldsInfo,
    IErrorPayload
} from '../types';
    
import {
    ICompanyState,
    CompanyActions,
    ICompanyNameModel,
} from "./types";

import { Reducer } from 'redux';

const initialCompanyState: ICompanyState = {
    isFetching: false,
    list: [],
    namesList: [],
    searchParams: {},
    currentCompany: {},
    currentCompanyId: '',
    errorFieldsInfo: {},
    required: ['type', 'sap', 'shortName', 'longName']
}

export const companyReducer: Reducer<ICompanyState, CompanyActions> = (
    state = initialCompanyState,
    action,
  ) => {

    const defaultStateWithFetchingTrue = {
        ...state,
        isFetching: true,
    };

    const defaultStateWithFetchingFalse = {
        ...state,
        isFetching: false,
    };

    const defaultErrorState = (action: { payload: IErrorPayload }) => ({
        ...state,
        isFetching: false,
        errorInfo: {
            hasError: true,
            ...action.payload
        },
    });

    const defaultNameList = (action: { list: ICompanyNameModel[]} ) => ({
        ...state,
        namesList: [...action.list],
        isFetching: false,
    })
    
    switch (action.type) {

        
        // GET FULL LIST
        case 'GET_COMPANY_LIST_PENDING': {
            return { ...defaultStateWithFetchingTrue };
        }
        case 'GET_COMPANY_LIST_SUCCESS': {
            // console.log(parseJwt(action.authData.data.token))
            return {
                ...state,
                list: [...action.payload.list],
                pageble: {...action.payload.pageble},
                isFetching: false,
            };
        }
        case 'GET_COMPANY_LIST_FAILURE': {
            return {
                ...state,
                isFetching: false,
                errorInfo: {
                    hasError: true,
                    ...action.payload
                },
            };
        }

        // CET NAMES LIST
        case 'GET_COMPANY_NAMES_LIST_PENDING': {
            return { ...defaultStateWithFetchingTrue };
        }
        case 'GET_COMPANY_NAMES_LIST_SUCCESS': {
            // console.log(parseJwt(action.authData.data.token))
            return { ...defaultNameList(action) };
        }
        case 'GET_COMPANY_NAMES_LIST_FAILURE': {
            return { ...defaultErrorState(action) };
        }

        // CET NAMES LIST
        case 'GET_COMPANY_LIST_BY_CITY_PENDING': {
            return { ...defaultStateWithFetchingTrue };
        }

        case 'GET_COMPANY_LIST_BY_CITY_SUCCESS': {
            // console.log(parseJwt(action.authData.data.token))
            return { ...defaultNameList(action) };
        }
        case 'GET_COMPANY_LIST_BY_CITY_FAILURE': {
            return { ...defaultErrorState(action) };
        }


        // GET BY ID
        case 'GET_COMPANY_BY_ID_PENDING': {
            return { ...defaultStateWithFetchingTrue };
        }
        case 'GET_COMPANY_BY_ID_SUCCESS': {
            // console.log(parseJwt(action.authData.data.token))
            return {
                ...state,
                currentCompany: {...action.payload.record},
                currentCompanyId: action.payload.id,
                isFetching: false,
            };
        }
        case 'GET_COMPANY_BY_ID_FAILURE': {
            return { ...defaultErrorState(action) };
        }


        // CREATE 
        case 'CREATE_COMPANY_PENDING': {
            return { ...defaultStateWithFetchingTrue };
        }
        case 'CREATE_COMPANY_SUCCESS': {
            // console.log(parseJwt(action.authData.data.token))
            return {
                ...state,
                isFetching: false,
            };
        }
        case 'CREATE_COMPANY_FAILURE': {
            const createFieldsError: IErrorFieldsInfo = {};
            action.payload.fieldErrors && action.payload.fieldErrors.map((errorObject, errorNumber) => {
                createFieldsError[errorObject.field] = errorObject.message;
            })
            return {
                ...state,
                isFetching: false,
                errorInfo: {
                    hasError: true,
                    ...action.payload
                },
                errorFieldsInfo: createFieldsError
            };
        }


        // EDIT
        case 'EDIT_COMPANY_PENDING': {
            return { ...defaultStateWithFetchingTrue };
        }
        case 'EDIT_COMPANY_SUCCESS': {
            // console.log(parseJwt(action.authData.data.token))
            return { ...defaultStateWithFetchingFalse };
        }
        case 'EDIT_COMPANY_FAILURE': {
            return { ...defaultErrorState(action) };
        }


        // DELETE
        case 'DELETE_COMPANY_PENDING': {
            return { ...defaultStateWithFetchingTrue };
        }
        case 'DELETE_COMPANY_SUCCESS': {
            // console.log(parseJwt(action.authData.data.token))
            return { ...defaultStateWithFetchingFalse };
        }
        case 'DELETE_COMPANY_FAILURE': {
            return { ...defaultErrorState(action) };
        }

        default:
        neverReached(action); // when a new action is created, this helps us remember to handle it in the reducer
    }
    return state;
};
  
  // tslint:disable-next-line:no-empty
const neverReached = (never: never) => {};