
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Icon from '@material-ui/core/Icon';
import { IAppState } from 'store';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import { loginUser, getUserInfo } from 'store/user/actions';
import { ILoginData, IUserState, ILoginUserSuccessAction, ILoginUserFailureAction, IGetUserInfoSuccessAction, IGetUserInfoFailureAction } from 'store/user/types';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { App } from 'App';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import InputField from 'components/InputField/InputField';
import { ILocaleState } from 'store/locale/types';
import { Card } from 'components/Card/Card';

// import './LoginCard.scss';
import { Link } from 'react-router-dom';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { checkAccess } from 'services';

interface OwnProps {
}

interface IProps {
    user: IUserState;
    userRoles: string[],
    roles: string[],
    children?: any;
}
interface IState {
}


class RoleControl extends Component<IProps, IState> {
    state: IState = {
    }

   

    render() {

        return (
            <>
            {
                this.props.roles && this.props.roles.length && checkAccess(this.props.roles, this.props.userRoles)?
                    this.props.children
                :
                    null
            }
            </>
        )
    }
}



const mapStateToProps = (store: IAppState) => ({
    user: store.user,
    locale: store.locale,
    userRoles: store.user.userInfo.authorities
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
    loginUserHandle: (email: string, password: string) => dispatch(loginUser({email, password})),
    getUserInfo: () => dispatch(getUserInfo())
});

export default connect(mapStateToProps, mapDispatchToProps)(RoleControl)
