
import React, { Component } from 'react'
import { connect } from 'react-redux'

import Icon from '@material-ui/core/Icon';
import { IAppState } from 'store';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
// import Button from '@material-ui/core/Button';
import { Button } from 'components/Button';
import { loginUser, resetPasswodByEmailPassword } from 'store/user/actions';
import { ILoginData, IUserState, ILoginUserSuccessAction, ILoginUserFailureAction, IResetPasswodByEmailSuccessAction, IResetPasswodByEmailFailureAction } from 'store/user/types';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { App } from 'App';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import InputField from 'components/InputField/InputField';
import { ILocaleState } from 'store/locale/types';
import { Card } from 'components/Card/Card';

import './ResetPasswordCard.scss';
import { Link } from 'react-router-dom';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { emailIsValid } from 'services';
import { ErrorMessage } from 'components/ErrorMessage/ErrorMessage';
import { withPageBase, IWithPageBase } from 'hoc/withPageBase/withPageBase';
import { openDoneDialog } from 'store/dialog/actions';
import { IDoneDialogData, IOpenDoneDialogAction } from 'store/dialog/types';


interface IProps {
    loginUserHandle: (email: string, password: string) => Promise<ILoginUserSuccessAction | ILoginUserFailureAction>;
    resetPasswodByEmailPassword: (email: string) => Promise<IResetPasswodByEmailSuccessAction|IResetPasswodByEmailFailureAction>;
    user: IUserState;
    locale: ILocaleState;
    history?: any;
    openSuccessDialog: (data: IDoneDialogData) => Promise<IOpenDoneDialogAction>;
}
interface IState {
    email: string;
    errorMsg: string;
}


class ResetPasswordCard extends Component<IProps> {
    state: IState = {
        email: '',
        errorMsg: ''
    }


    handleChangeField = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.name, event.target.value);
        this.setState(
            {
                [event.target.name]: event.target.value
            } //as Pick<State, keyof State>
        );
    }

    handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.name, event.target.value);
        this.setState(
            {
                [event.target.name]: event.target.checked
            } //as Pick<State, keyof State>
        );
    }

    handleSendReset = () => {
        if(emailIsValid(this.state.email)){
            this.props.resetPasswodByEmailPassword(this.state.email)
                .then((data) => {
                    if(data.type === 'RESET_PASSWORD_BY_EMAIL_FAILURE'){
                        this.setState({
                            errorMsg: this.props.locale.currentLocaleJSON[`ERROR_${data.payload.error}`]
                        })
                        
                    } else if(data.type === 'RESET_PASSWORD_BY_EMAIL_SUCCESS') {
                        if(this.props.history){
                            this.props.openSuccessDialog({});
                            this.props.history.push('/')
                        }
                    }
                });
        }
        
    }

    render() {
        const { locale } = this.props;
        const { currentLocaleJSON: loc } = locale;
        return (
            <Card icon="security" title={loc.ResetPasswordScreen_CardTitle} width="730px">
                <p className="info-block">{loc.ResetPasswordScreen_Info}</p>
                {
                    this.state.errorMsg !== ''?
                        <ErrorMessage>
                            {this.state.errorMsg}
                        </ErrorMessage>
                    :
                        null
                }
                <InputField required label={loc.ResetPasswordScreen_EmailTitle} type="email" onChange={this.handleChangeField} value={this.state.email} name='email'/>
 
                <Button disabled={!emailIsValid(this.state.email)} col={4} onClick={this.handleSendReset} color="primary" >
                    {loc.ResetPasswordScreen_ResetButtton}
                </Button>
            </Card>
        )
    }
}



const mapStateToProps = (store: IAppState) => ({
    user: store.user,
    locale: store.locale
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
    loginUserHandle: (email: string, password: string) => dispatch(loginUser({email, password})),
    resetPasswodByEmailPassword: (email: string) => dispatch(resetPasswodByEmailPassword(email)),
    openSuccessDialog: (data: IDoneDialogData) => dispatch(openDoneDialog(data))

});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordCard);
