import { 
    REQUEST, 
    SUCCESS, 
    FAILURE, 
    IErrorPayload
} from '../types';
    
import {
    IVehicleState,
    VehicleActions,
} from "./types";

import { Reducer } from 'redux';

const initialVehicleState: IVehicleState = {
    isFetching: false,
    list: [],
    searchParams: {},
    currentVehicle: {
        bodyType: "",
        comment: "",
        company: {
            name: "",
            id: ""
        },
        high: null,
        length: null,
        loadCapacity: null,
        mark: "",
        model: "",
        registrationNumber: "",
        status: "ACTIVE",
        trackerNumber: "",
        type: "",
        vehicleType: "CAR",
        width: null,
        id: "",
    },
    currentVehicleId: '',
    required: [
        'registrationNumber'
    ]
}

export const vehicleReducer: Reducer<IVehicleState, VehicleActions> = (
    state = initialVehicleState,
    action,
  ) => {

    const defaultStateWithFetchingTrue = {
        ...state,
        isFetching: true,
    };

    const defaultStateWithFetchingFalse = {
        ...state,
        isFetching: false,
    };

    const defaultErrorState = (action: { payload: IErrorPayload }) => ({
        ...state,
        isFetching: false,
        errorInfo: {
            hasError: true,
            ...action.payload
        },
    });


    switch (action.type) {

        // GET FULL LIST
        case 'GET_VEHICLE_LIST_PENDING': {
            return {
                ...state,
                isFetching: true,
            };
        }
        case 'GET_VEHICLE_LIST_SUCCESS': {
            // console.log(parseJwt(action.authData.data.token))
            return {
                ...state,
                list: [...action.payload.list],
                pageble: {...action.payload.pageble},
                isFetching: false,
            };
        }
        case 'GET_VEHICLE_LIST_FAILURE': {
            return { ...defaultErrorState(action) };
        }

        // CET NAMES LIST
        case 'CHANGE_VEHICLE_STATUS_PENDING': {
            return { ...defaultStateWithFetchingTrue };
        }
        case 'CHANGE_VEHICLE_STATUS_SUCCESS': {
            // console.log(parseJwt(action.authData.data.token))
            return {
                ...state,
                isFetching: false,
            };
        }
        case 'CHANGE_VEHICLE_STATUS_FAILURE': {
            return { ...defaultErrorState(action) };
        }


        // GET BY ID
        case 'GET_VEHICLE_BY_ID_PENDING': {
            return { ...defaultStateWithFetchingTrue };
        }
        case 'GET_VEHICLE_BY_ID_SUCCESS': {
            // console.log(parseJwt(action.authData.data.token))
            return {
                ...state,
                currentVehicle: {...action.payload.record},
                currentVehicleId: action.payload.id,
                isFetching: false,
            };
        }
        case 'GET_VEHICLE_BY_ID_FAILURE': {
            return { ...defaultErrorState(action) };
        }


        // CREATE 
        case 'CREATE_VEHICLE_PENDING': {
            return { ...defaultStateWithFetchingTrue };
        }
        case 'CREATE_VEHICLE_SUCCESS': {
            // console.log(parseJwt(action.authData.data.token))
            return { ...defaultStateWithFetchingFalse };
        }
        case 'CREATE_VEHICLE_FAILURE': {
            return { ...defaultErrorState(action) };
        }


        // EDIT
        case 'EDIT_VEHICLE_PENDING': {
            return { ...defaultStateWithFetchingTrue };
        }
        case 'EDIT_VEHICLE_SUCCESS': {
            // console.log(parseJwt(action.authData.data.token))
            return { ...defaultStateWithFetchingFalse };
        }
        case 'EDIT_VEHICLE_FAILURE': {
            return { ...defaultErrorState(action) };
        }


        // DELETE
        case 'DELETE_VEHICLE_PENDING': {
            return { ...defaultStateWithFetchingTrue };
        }
        case 'DELETE_VEHICLE_SUCCESS': {
            // console.log(parseJwt(action.authData.data.token))
            return { ...defaultStateWithFetchingFalse };
        }
        case 'DELETE_VEHICLE_FAILURE': {
            return { ...defaultErrorState(action) };
        }

        default:
        neverReached(action); // when a new action is created, this helps us remember to handle it in the reducer
    }
    return state;
};
  
  // tslint:disable-next-line:no-empty
const neverReached = (never: never) => {};