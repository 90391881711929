import React, { Component } from 'react'
import { connect } from 'react-redux'
import { IAppState } from 'store';
import { PageHeader } from 'components/PageHeader/PageHeader';
import { ILocaleState } from 'store/locale/types';
import { FormPageTemplate } from 'components/FormPageTemplate';
import { FormBlock } from 'components/FormBlock';
import InputField from 'components/InputField/InputField';
import { IUserCreateModel, ICreateUserSuccessAction, ICreateUserFailureAction, IGetUserByIdSuccessAction, IGetUserByIdFailureAction, IUserState, IUserEditModel, IEditUserSuccessAction, IEditUserFailureAction, notificationType, IEditCurrentUserSuccessAction, IEditCurrentUserFailureAction, ICurrentUserEditModel, IChangeUserStatusModel, IChangeUserStatusSuccessAction, IChangeUserStatusFailureAction, UserStatusType, IUserModel } from 'store/user/types';

import moment from 'moment';
import Timepicker from 'components/Timepicker';
import { createUser, getUserById, editUser, editCurrentUser, changeUserStatus } from 'store/user/actions';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { match } from 'react-router-dom';
import { Button } from 'components/Button';
import { FormControlLabel, Checkbox, CircularProgress } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { ICityModel } from 'store/city/types';
import { ICompanyNameModel, ICompanyModel } from 'store/company/types';
import { validateRequiredFields, emailIsValid, phoneIsValid, getErrorsFromDictionary } from 'services';
import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import WarningDialog from 'components/WarningDialog';
import { withPageBase, IWithPageBase } from 'hoc/withPageBase/withPageBase';
import { IErrorDialogData, IWarningDialogData } from 'store/dialog/types';
import deepEqual from 'deep-equal';

interface IRouterParams  {
    id: string;
}

interface IProps extends IWithPageBase{
    edit?: boolean;
    create?: boolean;
    view?: boolean;
    user: IUserState;
    locale: ILocaleState;
    createUser: (userData: IUserCreateModel) => Promise<ICreateUserSuccessAction|ICreateUserFailureAction>;
    editUser: (userData: IUserEditModel) => Promise<IEditUserSuccessAction|IEditUserFailureAction>;
    changeUserStatus: (userData: IChangeUserStatusModel) => Promise<IChangeUserStatusSuccessAction|IChangeUserStatusFailureAction>;
    editCurrentUser: (UserData: ICurrentUserEditModel) => Promise<IEditCurrentUserSuccessAction|IEditCurrentUserFailureAction>;
    getUserById: (id: string) => Promise<IGetUserByIdSuccessAction|IGetUserByIdFailureAction>;
    match: match<IRouterParams>;
    history: any;
//     openErrorDialog: (data: IErrorDialogData) => void;
//     openWarningDialog: (data: IWarningDialogData) => void;
//     openSuccessDialog: () => void;
//     handleChangeField: (event: React.ChangeEvent<HTMLInputElement>) => void;
//     handleChangeCompany: (resultObject: ICompanyModel) => void;
//     handleChangeCity: (resultObject: ICityModel) => void;
//     handleCancelEdit: (isEdited: boolean) => void;
}
interface IState {
    authorities: string;
    city: {
        name: string;
        region: string;
    }
    company: {
        name: string;
        id: string;
    };
    contactPhone: number | null;
    email: string;
    fio: string;
    login: string;
    notificationType: notificationType | null;
    position: string;
    workPhone: number | null;
    cityName: string;
    companyName: string;
    // edited?: IUserEditModel;
    // viewProfileIsLoad: boolean;
    // displayErrorDialog: boolean;
    // displayWarningDialog: boolean;
    // errorMessages: string[] | [];
}

export class UserBlock extends Component<IProps> {
    
    handleChangeField = this.props.handleChangeField.bind(this);
    handleChangeCompany = this.props.handleChangeCompany.bind(this);
    handleChangeCity = this.props.handleChangeCity.bind(this);
    
    state: IState = {
        authorities: '',
        city: {
            name: '',
            region: ''
        },
        company: {
            name: '',
            id: '',
        },
        contactPhone: null,
        email: '',
        fio: '',
        login: '',
        notificationType: 'NONE',
        position: '',
        workPhone: null,
        cityName: '',
        companyName: '',
        // viewProfileIsLoad: false,
        // displayErrorDialog: false,
        // displayWarningDialog: false,
        // errorMessages: []
    }

    beforeEdit = {};

    adaptToState = (userInfo: IUserModel): IState => {
        const { authorities, city, company, ...rest } = userInfo;
        return {
            authorities: authorities[0], 
            company, 
            companyName: `${company && company.name? company.name: ''}`, 
            city, 
            cityName: city.name && city.region?`${city.name} (${city.region})`: '',
            ...rest
        };
    }

    componentDidMount = () => {
        // console.log(this.props.user)
        if(this.props.view && this.props.user.userInfo){
            // console.log(this.props.user.userInfo, this.adaptToState(this.props.user.userInfo))
            this.setState({...this.adaptToState(this.props.user.userInfo)});
        }

        
        if(this.props.edit && this.props.match.params.id !== ''){
            // console.log("EDIT GET BY ID")
            this.props.getUserById(this.props.match.params.id)
                .then(() => {
                    // const { authorities, city, company, ...rest } = this.props.user.currentUser;
                    const newState = this.adaptToState(this.props.user.currentUser);
                    this.beforeEdit = { ...newState };
                    this.setState({ ...newState });
                });
        }
    }
    
    static getDerivedStateFromProps = (nextProps: IProps, prevState: IState) => {
        // console.log('cwrp', nextProps)
        
        
            let newState: any = null;

            // if(nextProps.view && nextProps.user.userInfo && nextProps.user.userProfileIsLoad && !prevState.viewProfileIsLoad) {
                // console.log('Have a symbol', nextProps.value)
                // nextProps.getCompanyById(nextProps.user.userInfo.company.id)
                    // .then(() => {
                        // newState = {...nextProps.company.currentCompany};
                    // });
                // nextProps.getCityList(nextProps.value);
                // const { authorities, city, company, ...rest } = nextProps.user.userInfo;
                // console.log('nextProps.user.userInfo', nextProps.user.userInfo)
                // return ({edited: {}, authorities: authorities[0], company, companyName: `${company && company.name? company.name: ''}`, city, cityName: city.name && city.region?`${city.name} (${city.region})`: '', ...rest, viewProfileIsLoad: true});
            // }

            if(prevState.cityName && prevState.cityName !== `${prevState.city.name} (${prevState.city.region})`){
                return ({city: {name: '', region: ''}})
            }

            if(prevState.companyName && prevState.company && prevState.company.name && prevState.companyName !== `${prevState.company.name}`){
                return ({company: {name: '', id: ''}})
            }

            // console.log(newState)
            return null;
        
    }

    getTitle = (): string => {

        if(this.props.edit) return this.props.locale.currentLocaleJSON.UserBlock_EditPageTitle;
        if(this.props.create) return this.props.locale.currentLocaleJSON.UserBlock_CreatePageTitle;
        if(this.props.view) return this.props.locale.currentLocaleJSON.UserBlock_ProfilePageTitle;

        return "";
    }

    // handleChangeField = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     console.log(event.target, event.target.name, event.target.value);
    //     let name = event.target.name;
    //     let type = event.target.type
    //     let value = type === 'number'? +event.target.value : event.target.value;

    //     this.setState((prevState: IState) => 
    //         ({
    //             [name]: value
    //         })
    //     );

    // }

    handleEdit = () => {
        // if(this.state.edited && Object.keys(this.state.edited).length > 0){
            let { cityName, companyName, authorities, ...editedData} = this.state;
            if(this.props.view){
                this.props.editCurrentUser({...editedData, authorities: [authorities]})
                    .then((data) => {
                        if(data.type === "EDIT_CURRENT_USER_FAILURE"){
                            // let error = this.props.locale.currentLocaleJSON[`ERROR_${data.payload.error}`]? this.props.locale.currentLocaleJSON[`ERROR_${data.payload.error}`]: this.props.locale.currentLocaleJSON[`ERROR_UnknownError`];
                            this.props.openErrorDialog(
                                {
                                    messages: getErrorsFromDictionary(this.props.locale.currentLocaleJSON, [data.payload.error]) 
                                }
                            );
                        } else {
                            this.props.openSuccessDialog();
                            // this.props.history.push(`/dashboard/administration/users`);
                        }
                        
                    });
            } else if (this.props.edit) {
                this.props.editUser({...editedData, authorities: [authorities], id: this.props.match.params.id})
                    .then((data) => {
                        if(data.type === "EDIT_USER_FAILURE"){
                            // let error = this.props.locale.currentLocaleJSON[`ERROR_${data.payload.error}`]? this.props.locale.currentLocaleJSON[`ERROR_${data.payload.error}`]: this.props.locale.currentLocaleJSON[`ERROR_UnknownError`];
                            // this.props.openErrorDialog({messages:  [error] })
                            this.props.openErrorDialog(
                                {
                                    messages: getErrorsFromDictionary(this.props.locale.currentLocaleJSON, [data.payload.error]) 
                                }
                            );
                        } else {
                            this.props.openSuccessDialog();
                            this.props.history.push(`/dashboard/administration/users`);
                        }
                        
                    });
            }
        // }
    }

    handleBlock = () => {
        // if(this.state.edited){
            const newStatus: UserStatusType = this.props.user.currentUser.status === 'BLOCKED'? 'ACTIVE' : 'BLOCKED';

            this.props.changeUserStatus({status: newStatus, id: this.props.match.params.id})
                .then((data) => {
                    if(data.type === "CHANGE_USER_STATUS_FAILURE"){
                        // let error = this.props.locale.currentLocaleJSON[`ERROR_${data.payload.error}`]? this.props.locale.currentLocaleJSON[`ERROR_${data.payload.error}`]: this.props.locale.currentLocaleJSON[`ERROR_UnknownError`];
                        this.props.openErrorDialog(
                            {
                                messages: getErrorsFromDictionary(this.props.locale.currentLocaleJSON, [data.payload.error])  
                            }
                        );
                        // this.setState({displayErrorDialog: true, errorMessages: [error]});
                    } else {
                        this.props.openSuccessDialog();
                        this.props.getUserById(this.props.match.params.id)
                            .then(() => {
                                
                                // const { status, ...rest } = this.props.user.currentUser;
                                // this.setState({edited: {}, authorities: authorities[0], company, companyName: `${company && company.name? company.name: ''}`, city, cityName: city.name && city.region?`${city.name} (${city.region})`: '', ...rest});
                            });
                        // this.setState({status: newStatus});
                        // this.props.history.push(`/dashboard/administration/users`);
                    }
                    
                });
        }
    // }


    handleCreate = () => {
        if(this.props.create){
            let { cityName, companyName, authorities, ...createData } = this.state;
            this.props.createUser({...createData, authorities: [authorities]})
                .then((createData) => {
                    if(createData.type === "CREATE_USER_FAILURE"){
                        // let error = this.props.locale.currentLocaleJSON[`ERROR_${createData.payload.error}`]? this.props.locale.currentLocaleJSON[`ERROR_${createData.payload.error}`]: this.props.locale.currentLocaleJSON[`ERROR_UnknownError`];
                        
                        this.props.openErrorDialog(
                            { 
                                messages: getErrorsFromDictionary(this.props.locale.currentLocaleJSON, [createData.payload.error]) 
                            }
                        );
                        // this.setState({displayErrorDialog: true, errorMessages: [error]});
                    } else {
                        this.props.openSuccessDialog();
                        this.props.history.push(`/dashboard/administration/users`);
                    }
                    
                });
        }
        
    }

    // handleChangeDate = (name: string, value: string | moment.Moment) => {
    //     this.setState(
    //         {
    //             [name]: value.valueOf()
    //         }
    //     );
    // }

    // renderMonth = ( props: any, month: any, year: any, selectedDate: any ) => {
    //     return <td {...props}>{ `month` }</td>;
    // }

    // selectCity = (resultCity: any) => {
    //     this.setState({cityName: `${resultCity.name} (${resultCity.region})`});
    // }



    changeSwitch = (type: 'PHONE' | 'EMAIL') => {
        let typeValue: string = 'NONE';
        if(type === 'PHONE') {
            switch (this.state.notificationType) {
                case 'NONE':
                    typeValue = 'PHONE';
                    break;
                case 'EMAIL':
                    typeValue = 'BOTH';
                    // this.setState({notificationType: 'BOTH'});
                    break;
                case 'BOTH':
                    typeValue = 'EMAIL';
                    break;
                case 'PHONE':
                    typeValue = 'NONE';
                    break;
                default:
                    typeValue = 'NONE';
                    break;
            }
        } else {
            switch (this.state.notificationType) {
                case 'NONE':
                    typeValue = 'EMAIL';
                    break;
                case 'PHONE':
                    typeValue = 'BOTH';
                    break;
                case 'BOTH':
                    typeValue = 'PHONE';
                    break;
                case 'EMAIL':
                    typeValue = 'NONE';
                    break;
                default:
                    typeValue = 'NONE';
                    break;
            }
        }

        this.setState((prevState: IState) => {
            // console.log(event.target.name, event.target.value);
            return({
                notificationType: typeValue,
                // edited: {
                //     ...prevState.edited,
                //     notificationType: typeValue
                // }
            })}
        );

         
    }

    hanldeSwitchEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        // console.log(event.target.name, event.target.value);
        // const emailChacked = event.target.checked;
        this.changeSwitch('EMAIL');
        // this.setState({notificationType: typeValue});this.state.notificationType
        // this.setState({notificationType: typeValue});
    }

    hanldeSwitchPhone = (event: React.ChangeEvent<HTMLInputElement>) => {
        // console.log(event.target.name, event.target.value);
        // const smsChecked = event.target.checked;
        this.changeSwitch('PHONE');
        // console.log(typeValue, this.state.notificationType)
        // this.setState({notificationType: typeValue});
    }


    // handleChangeCompany = (resultObject: ICompanyModel) => {
    //     // console.log('resultObject',resultObject)
    //     this.setState((prevState: IState) => ({
    //         company: {
    //             name: resultObject.shortName,
    //             id: resultObject.id
    //             // ...resultObject
    //         },
    //         companyName: resultObject.shortName
    //     }))
    // }

    // handleChangeCity = (resultObject: ICityModel) => {
    //     // console.log(resultObject)
    //     this.setState((prevState: IState) => ({
    //         city: {
    //             name: resultObject.name,
    //             region: resultObject.region
    //             // ...resultObject
    //         },
    //         cityName: `${resultObject.name} (${resultObject.region})`
    //     }))
    // }

    // handleCloseDialog = () => {
    //     this.setState({displayErrorDialog: false});
    // }

    // handleCloseWarningDialog = () => {
    //     this.setState({displayWarningDialog: false});
    // }

    handleCancel = () => {
        this.props.handleCancelEdit(this.isEdited());
        // if(!this.isEdited()){
        //     this.props.openWarningDialog({
        //         callback: () => {
        //             this.props.history.goBack();
        //         }
        //     })
        //     // this.setState({displayWarningDialog: true});
        // } else {
        //     this.props.history.goBack();
        // }
        
    }

    additionIsValid = () => {
        const companyValid = (this.state.company && this.state.company.name !== '' && this.state.company.id !== '');
        const cityValid = (this.state.city && this.state.city.name !== '' && this.state.city.region !== '');
        const phone = this.state.contactPhone !== null? phoneIsValid(this.state.contactPhone) : true;

        return (emailIsValid(this.state.email) && companyValid && cityValid && phoneIsValid(this.state.workPhone? this.state.workPhone: 0) && phone);
    }

    isEdited = () => {
        return deepEqual(this.state, this.beforeEdit);
    }


    render() {
        const { locale } = this.props;
        const { currentLocaleJSON: loc } = locale;

        return (
            <div className="page-template">
                <PageHeader pageIcon={"work"} pageTitle={this.getTitle()}/>
                {/* { */
                    // !this.props.user.isFetching?
                }
                        <FormPageTemplate col={8} width={this.props.view? 600 : 890} title={loc.UserBlock_MainTitle}>
                            <FormBlock colLeft={4} colRight={8} title={loc.UserBlock_BlockTitle_Role}>
                                <InputField required={true} disabled={this.props.view} padding={true} placeholder={loc.UserBlock_FieldTitle_Role} hideLabel={true} type="select" onChange={this.handleChangeField} name="authorities" value={this.state.authorities} label={loc.UserBlock_FieldTitle_Role}
                                    options={[
                                            {
                                                value: 'ROLE_LOGIST',
                                                label: loc.User_Role_ROLE_LOGIST,
                                            },
                                            {
                                                value: 'ROLE_MANAGER_LOGIST',
                                                label: loc.User_Role_ROLE_MANAGER_LOGIST,
                                            },
                                            {
                                                value: 'ROLE_TC_MANAGER',
                                                label: loc.User_Role_ROLE_TC_MANAGER,
                                            },
                                            {
                                                value: 'ROLE_WAREHOUSE_MANAGER',
                                                label: loc.User_Role_ROLE_WAREHOUSE_MANAGER,
                                            },
                                            {
                                                value: 'CLIENT_LOGIST',
                                                label: loc.User_Role_CLIENT_LOGIST,
                                            }
                                        ]}/>
                            </FormBlock>
                            <FormBlock colLeft={4} colRight={8} title={loc.UserBlock_BlockTitle_Fullname}>
                                <InputField required={true} padding={true} placeholder={loc.UserBlock_FieldTitle_Fullname} hideLabel={true} label={loc.UserBlock_FieldTitle_Fullname} type="text" onChange={this.handleChangeField} value={this.state.fio} name='fio'/>
                            </FormBlock>
                            <FormBlock colLeft={4} colRight={8} title={loc.UserBlock_BlockTitle_Login}>
                                <InputField required={true} padding={true} placeholder={loc.UserBlock_FieldTitle_Login} hideLabel={true} label={loc.UserBlock_FieldTitle_Login} type="text" onChange={this.handleChangeField} value={this.state.login} name='login'/>
                            </FormBlock>
                            <FormBlock colLeft={4} colRight={8} title={loc.UserBlock_BlockTitle_Company}>
                                <InputField isValid={(this.state.company && this.state.company.name !== '' && this.state.company.id !== '')} required={true} disabled={this.props.view} padding={true} placeholder={loc.UserBlock_FieldTitle_Company} hideLabel={true} label={loc.UserBlock_FieldTitle_Company} type="company" onSelect={this.handleChangeCompany} onChange={this.handleChangeField} value={this.state.companyName} name='companyName'/>
                            </FormBlock>
                            <FormBlock colLeft={4} colRight={8} title={loc.UserBlock_BlockTitle_City}>
                                <InputField isValid={(this.state.city && this.state.city.name !== '' && this.state.city.region !== '')} required={true} disabled={this.props.view} padding={true} placeholder={loc.UserBlock_FieldTitle_City} hideLabel={true} label={loc.UserBlock_FieldTitle_City} type="city" onSelect={this.handleChangeCity} onChange={this.handleChangeField} value={this.state.cityName} name='cityName'/>
                            </FormBlock>
                            <FormBlock colLeft={4} colRight={8} title={loc.UserBlock_BlockTitle_Position}>
                                <InputField required={true} disabled={this.props.view} padding={true} placeholder={loc.UserBlock_FieldTitle_Position} hideLabel={true} label={loc.UserBlock_FieldTitle_Position} type="text" onChange={this.handleChangeField} value={this.state.position} name='position'/>
                            </FormBlock>
                            <FormBlock colLeft={4} colRight={8} title={loc.UserBlock_BlockTitle_WorkPhone}>
                                <InputField required={true} padding={true} placeholder={loc.UserBlock_FieldTitle_WorkPhone} hideLabel={true} label={loc.UserBlock_FieldTitle_WorkPhone} type="phone" onChange={this.handleChangeField} value={this.state.workPhone} name='workPhone'/>
                            </FormBlock>
                            <FormBlock colLeft={4} colRight={8} title={loc.UserBlock_BlockTitle_ContactPhone}>
                                <InputField padding={true} placeholder={loc.UserBlock_FieldTitle_ContactPhone} hideLabel={true} label={loc.UserBlock_FieldTitle_ContactPhone} type="phone" onChange={this.handleChangeField} value={this.state.contactPhone} name='contactPhone'/>
                            </FormBlock>
                            <FormBlock colLeft={4} colRight={8} title={loc.UserBlock_BlockTitle_Email}>
                                <InputField required={true} padding={true} placeholder={loc.UserBlock_FieldTitle_Email} hideLabel={true} label={loc.UserBlock_FieldTitle_Email} type="email" onChange={this.handleChangeField} value={this.state.email} name='email'/>
                            </FormBlock>
                            <FormBlock colLeft={4} colRight={8} title={loc.UserBlock_BlockTitle_NotificationType}>
                                {/* <InputField padding={true} placeholder={loc.UserBlock_FieldTitle_NotificationType} hideLabel={true} type="checkbox" onChange={this.handleChangeField} name="authorities" value={this.state.authorities} label={loc.UserBlock_FieldTitle_NotificationType}
                                    options={[
                                            {
                                                value: 'EMAIL',
                                                label: loc.User_NotificationType_EMAIL,
                                            },
                                            {
                                                value: 'PHONE',
                                                label: loc.User_NotificationType_PHONE,
                                            }
                                        ]}/> */}
                                    <FormControlLabel
                                        className="checked-me-checkbox half-padding"
                                            control={
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                checked={this.state.notificationType === 'PHONE' || this.state.notificationType === 'BOTH'}
                                                onChange={this.hanldeSwitchPhone}
                                                value={this.state.notificationType === 'PHONE' || this.state.notificationType === 'BOTH'}
                                                color="primary"
                                                name="notificationType"
                                                
                                            />
                                            }
                                            label={loc.User_NotificationType_PHONE}
                                    />
                                    <FormControlLabel
                                        className="checked-me-checkbox half-padding"
                                            control={
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                checked={this.state.notificationType === 'EMAIL' || this.state.notificationType === 'BOTH'}
                                                onChange={this.hanldeSwitchEmail}
                                                value={this.state.notificationType === 'EMAIL' || this.state.notificationType === 'BOTH'}
                                                color="primary"
                                                name="notificationType"
                                                
                                            />
                                            }
                                            label={loc.User_NotificationType_EMAIL}
                                    />
    
                            </FormBlock>
                            <FormBlock colLeft={4} colRight={8} title={loc.UserBlock_BlockTitle_UserRequisites}>
                                {
                                    !this.props.view?
                                        <>
                                            <Button onClick={this.handleCancel} padding col={4}>
                                                {loc.Button_Cancel}
                                            </Button>
                                            </>
                                    :
                                        null
                                }
                                {
                                    this.props.create?
                                        <Button 
                                            disabled={!this.additionIsValid() || !validateRequiredFields(this.props.user.required? this.props.user.required : [], this.state)}
                                            onClick={this.handleCreate} 
                                            padding 
                                            col={6} 
                                            color="primary"
                                        >
                                            {loc.Button_Add}
                                        </Button>
                                    :
                                        null
                                }
                                {
                                    (this.props.edit)?
                                        <Button padding onClick={this.handleBlock} col={4} color={this.props.user.currentUser.status === 'ACTIVE'? "red" : "green"}>
                                            {this.props.user.currentUser.status === 'ACTIVE'? loc.Button_Blocked : loc.Button_Unblocked}
                                        </Button>
                                    :
                                        null
                                }
                                {
                                    (this.props.edit || this.props.view)?
                                        <Button 
                                            disabled={
                                                !this.additionIsValid() || !validateRequiredFields(this.props.user.required? this.props.user.required : [], this.state) || this.isEdited()} 
                                            onClick={this.handleEdit} 
                                            padding 
                                            col={this.props.view? 6 : 4} 
                                            color="primary"
                                        >
                                            {loc.Button_Save}
                                        </Button>
                                    :
                                        null
                                }
                                        
                                        
                                
                            </FormBlock>
                        </FormPageTemplate>
                {
                    //     :
                //      <div>Spinner</div>
                }
                {/* <div className="spinner">
                    <CircularProgress />
                </div> */}
                {/* <WarningDialog display={this.state.displayWarningDialog} okAction={() => {this.props.history.goBack()}} closeDialog={this.handleCloseWarningDialog}/> */}
                {/* <ErrorDialog errorMessages={this.state.errorMessages} display={this.state.displayErrorDialog} closeDialog={this.handleCloseDialog}/> */}

            </div>
        )
    }
}

const mapStateToProps = (state: IAppState) => ({
    locale: state.locale,
    user: state.user,
})

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
    createUser: (userData: IUserCreateModel) => dispatch(createUser(userData)),
    editUser: (userData: IUserEditModel) => dispatch(editUser(userData)),
    editCurrentUser: (userData: ICurrentUserEditModel) => dispatch(editCurrentUser(userData)),
    changeUserStatus: (statusData: IChangeUserStatusModel) => dispatch(changeUserStatus(statusData)),
    getUserById: (id: string) => dispatch(getUserById(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(withPageBase(UserBlock));
