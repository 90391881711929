import { AnyAction } from "redux";

export const AUTHORITIES_FULL_LIST = {
    ROLE_LOGIST: 'ROLE_LOGIST',
    ROLE_MANAGER_LOGIST: 'ROLE_MANAGER_LOGIST',
    ROLE_TC_MANAGER: 'ROLE_TC_MANAGER',
    ROLE_WAREHOUSE_MANAGER: 'ROLE_WAREHOUSE_MANAGER',
    CLIENT_LOGIST: 'CLIENT_LOGIST'
};

export const ACTIONS_WITHOUT_TOKEN: string[] = [
    // 'LOGOUT_USER_PENDING',
    // 'INIT_LOCALES_PENDING',
    // 'INIT_LOCALES_SUCCESS',
    'OPEN_DONE_DIALOG',
    'CLOSE_DONE_DIALOG',
    'OPEN_WARNING_DIALOG',
    'OK_WARNING_DIALOG',
    'CLOSE_WARNING_DIALOG',
    'OPEN_ERROR_DIALOG',
    'CLOSE_ERROR_DIALOG',

    'INIT_LOCALES_PENDING',
    'INIT_LOCALES_SUCCESS',
    'INIT_LOCALES_FAILURE',
    'SET_LOCALE_PENDING',
    'SET_LOCALE_SUCCESS',
    'SET_LOCALE_FAILURE',
    
    'LOGIN_USER_PENDING',
    'LOGIN_USER_SUCCESS',
    'LOGIN_USER_FAILURE',
    'LOGOUT_USER_PENDING',
    'LOGOUT_USER_SUCCESS',
    'LOGOUT_USER_FAILURE',
    'CREATE_USER_PASSWORD_PENDING',
    'CREATE_USER_PASSWORD_SUCCESS',
    'CREATE_USER_PASSWORD_FAILURE',
    'EDIT_USER_PASSWORD_PENDING',
    'EDIT_USER_PASSWORD_SUCCESS',
    'EDIT_USER_PASSWORD_FAILURE',
    'RESET_PASSWORD_BY_EMAIL_PENDING',
    'RESET_PASSWORD_BY_EMAIL_SUCCESS',
    'RESET_PASSWORD_BY_EMAIL_FAILURE',
]